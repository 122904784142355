import React from "react";
import APIbackend from "../config.js";

class Popup extends React.Component {
  state = {
    siteId: 1,
    showPopUp: "",
  };

  handleDeleteAccount = (e) => {
    e.preventDefault();
    fetch(`${APIbackend}v1/account/delete`, {
      method: "POST",
      body: JSON.stringify({
        siteId: this.state.siteId,
      }),
      credentials: "include",
    }).then((response) =>
      response.json().then((responseData) => {
        const data = JSON.parse(responseData);
        if (data === false) {
          this.setState({
            showPopUp: this.props.showPopUp,
          });
        } else {
          this.setState({
            showPop: false,
          });
        }
      })
    );
  };

  render() {
    const {closePopup} = this.props;
    const {handleDeleteAccount} = this;
    return (
      <div className="deleteAccountPopup">
        <div className="popupInner">
          <button className="closeButton" onClick={closePopup}>
            ✖
          </button>
          <h1>Usuwanie konta</h1>
          <p>Czy chcesz usunąć konto?</p>
          <button className="primaryBtn" onClick={handleDeleteAccount}>
            Potwierdzam
          </button>
          <div className="linkPassword" onClick={closePopup}>
            <span>
              Wróć do edycji danych<i className="arrow right"></i>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const DeleteAccount = ({togglePopup, name, info, showPopup}) => {
  return (
    <div className="deleteAccount">
      <div className="btnBox">
        <button className="primaryBtn" onClick={() => togglePopup()}>
          Usuń konto
        </button>
      </div>
      {showPopup && (
        <Popup text='Click "Close Button" to hide popup' closePopup={togglePopup} name={name} info={info} />
      )}
    </div>
  );
};

export default DeleteAccount;
