import React, {useEffect} from "react";
import "./Vouchers.scss";

const VouchersCreate = ({handleChange, description, handleBack, handleSubmit, value, messageVoucherCreate}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <h4>Wygeneruj nowy voucher</h4>
      <form className="form" noValidate>
        <p>Nazwa</p>
        <input name="description" onChange={handleChange} value={description} placeholder="Wpisz nazwę voucher'a" />
        <p>Kwota</p>
        <input name="value" onChange={handleChange} value={value} placeholder="Wpisz kwotę voucher'a" />
      </form>
      <p style={{color: "green"}}>{messageVoucherCreate}</p>
      <div className="containerButton">
        <button className="primaryBtn" onClick={handleSubmit}>
          Wygeneruj nowy voucher
        </button>
        <button className="secondaryBtn" onClick={handleBack} style={{marginTop: "20px"}}>
          Wróć do widoku swoich voucher'ów
        </button>
      </div>
    </>
  );
};

export default VouchersCreate;
