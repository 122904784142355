import React from "react";
import "./Participants.scss";
import ParticipantDetails from "./ParticipantDetails.js";
import APIbackend from "../config.js";

class ParticipantsWrapper extends React.Component {
  state = {
    participantDetails: false,
    participantNumber: "",
    participantsInfo: [],
    colorMessage: "",
    message: "",
    participantsForm: true,
    Reservations: this.props.Reservations,
    participantButton: true,
    errorMessageParametersGetParticipants: "",
  };

  handleCancelParticipants = (event) => {
    event.preventDefault();
    fetch(`${APIbackend}v1/account/reservations`, {
      method: "POST",
      body: JSON.stringify({
        siteId: 1,
      }),
      credentials: "include",
    }).then((response) => {
      response.json().then((data) => {
        this.setState(
          {
            Reservations: data.Current,
          },
          () =>
            setTimeout(() => {
              const reservationArr = this.state.Reservations.find(
                ({ReservationId}) => ReservationId === this.props.reservationId
              );
              this.setState({
                participantsInfo: reservationArr.StepConfig.Step5.participantsInfo,
                message: "",
                participantDetails: false,
                participantButton: true,
              });
            }, 100)
        );
      });
    });
  };

  handleChangeFirstName = (userNumber, event) => {
    const {participantsInfo} = this.state;
    const elIndex = participantsInfo.findIndex(({userNum}) => userNum === userNumber);
    participantsInfo[elIndex].firstName = event.target.value;
    this.setState({participantsInfo});
  };

  handleChangeLastName = (userNumber, event) => {
    const {participantsInfo} = this.state;
    const elIndex = participantsInfo.findIndex(({userNum}) => userNum === userNumber);
    participantsInfo[elIndex].lastName = event.target.value;
    this.setState({participantsInfo});
  };

  handleChangeBirthDate = (userNumber, event) => {
    const {participantsInfo} = this.state;
    const elIndex = participantsInfo.findIndex(({userNum}) => userNum === userNumber);
    participantsInfo[elIndex].birthDate = event.target.value;
    this.setState({participantsInfo});
  };

  handleChangeIdDocument = (userNumber, event) => {
    const {participantsInfo} = this.state;
    const elIndex = participantsInfo.findIndex(({userNum}) => userNum === userNumber);
    participantsInfo[elIndex].idDocument = event.target.value;
    this.setState({participantsInfo});
  };

  handleChangeGender = (userNumber, event) => {
    const {participantsInfo} = this.state;
    const elIndex = participantsInfo.findIndex(({userNum}) => userNum === userNumber);
    participantsInfo[elIndex].gender = event.target.value;
    this.setState({participantsInfo});
  };

  handleChangePhone = (userNumber, event) => {
    const {participantsInfo} = this.state;
    const elIndex = participantsInfo.findIndex(({userNum}) => userNum === userNumber);
    participantsInfo[elIndex].phone = event.target.value;
    this.setState({participantsInfo});
  };

  handleChangeEmail = (userNumber, event) => {
    const {participantsInfo} = this.state;
    const elIndex = participantsInfo.findIndex(({userNum}) => userNum === userNumber);
    participantsInfo[elIndex].email = event.target.value;
    this.setState({participantsInfo});
  };

  handleChangeCountry = (userNumber, event) => {
    const {participantsInfo} = this.state;
    const elIndex = participantsInfo.findIndex(({userNum}) => userNum === userNumber);
    participantsInfo[elIndex].country = event.target.value;
    this.setState({participantsInfo});
  };

  handleChangeFillInLater = (userNumber, event) => {
    const {participantsInfo} = this.state;
    const elIndex = participantsInfo.findIndex(({userNum}) => userNum === userNumber);
    participantsInfo[elIndex].fillInLater = event.target.checked ? 1 : 0;
    this.setState({participantsInfo});
  };

  handleChangeAllowed = (id) => {
    var data = {
      siteId: 1,
      reservationId: this.props.reservationId,
    };

    this.setState({
      message: "",
    });

    fetch(`${APIbackend}v1/reservationsUpdates/allowed`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    }).then((response) => {
      response.json().then(() => {
        if (response.status === 200) {
          this.setState({
            participantDetails: true,
            participantNumber: id,
            participantsForm: true,
            participantButton: false,
          });
          fetch(`${APIbackend}v1/reservationsUpdates/participants/get`, {
            method: "POST",
            body: JSON.stringify(data),
            credentials: "include",
          }).then((response) => {
            response.json().then((data) => {
              if (response.status !== 200) {
                this.setState({
                  errorMessageParametersGetParticipants: "Minął termin na edycję danych.",
                  participantsForm: false,
                });
              }
              if (response.status === 200) {
                this.setState({
                  errorMessageParametersGetParticipants: "",
                  participantsInfo: data.Participants,
                  participantsForm: true,
                });
              }
            });
          });
        }
      });
    });
  };

  handleSubmit = (userNum, event, email, phone, idDocument) => {
    event.preventDefault();
 
        fetch(`${APIbackend}v1/reservationsUpdates/participants/change`, {
          method: "POST",
          body: JSON.stringify({
            siteId: 1,
            reservationId: this.props.reservationId,
            data: this.state.participantsInfo.map(
              ({
                userNum,
                firstName,
                lastName,
                birthDate,
                policyAccepted,
                newsletterAccepted,
                idDocument,
                gender,
                email,
                country,
                phone
              }) => ({
                userNum: userNum,
                fillInLater: firstName === "" || lastName === "" || birthDate === "" ? 1 : 0,
                firstName: firstName,
                lastName: lastName,
                birthDate: birthDate,
                policyAccepted: policyAccepted,
                newsletterAccepted: newsletterAccepted,
                country: country,
                idDocument: idDocument,
                gender: gender,
                phone: phone,
                email: email,
              })
            ),
          }),
          credentials: "include",
        }).then((response) => {
          response.json().then((responseData) => {
            if (response.status !== 200) {
              this.setState(
                {
                  message: "Dane nie zostały zapisane",
                  colorMessage: "red",
                  participantsForm: true,
                },
                () => window.scrollTo(0, 0)
              );
            }
            if (response.status === 200) {
              this.setState(
                {
                  participantsInfo: this.state.participantsInfo,
                  participantsForm: true,
                },
                () => window.scrollTo(0, 0)
              );

              if (responseData.ChangesFound && responseData.ChangesSaved && responseData.ChangesValidated) {
                fetch(`${APIbackend}v1/account/reservations`, {
                  method: "POST",
                  body: JSON.stringify({
                    siteId: 1,
                  }),
                  credentials: "include",
                }).then((response) => {
                  response.json().then((data) => {
                    this.setState(
                      {
                        Reservations: data.Current,
                      },
                      () =>
                        setTimeout(() => {
                          this.setState({
                            message: "Dane zostały zapisane poprawnie.",
                            colorMessage: "green",
                            participantsForm: false,
                            participantsInfo: this.state.Reservations.find(
                              ({ReservationId}) => ReservationId === this.props.reservationId
                            ).StepConfig.Step5.participantsConfig,
                          });
                        }, 500)
                    );
                  });
                });
              } else if (!responseData.ChangesFound || !responseData.ChangesSaved || !responseData.ChangesValidated) {
                this.setState({
                  message:
                    "Wprowadzone przez Ciebie dane nie zostały zapisane. Uzupełnij wymagane pola: Imię, Nazwisko, data urodzenia",
                  colorMessage: "red",
                  participantsForm: true,
                });
              }
            }
          });
        });
  };

  render() {
    const {
      Reservations,
      participantDetails,
      participantButton,
      participantNumber,
      participantsInfo,
      errorMessageParametersGetParticipants,
    } = this.state;
    const {userNumParticipant, ReservationHistory, reservationId} = this.props;
    const {handleChangeAllowed} = this;
    const allReservations = Reservations.concat(ReservationHistory);
    const reservationArr = allReservations?.find(({ReservationId}) => ReservationId === reservationId);
    return (
      <>
        {reservationArr?.ReserveList && (
          <div className="orangeBox">
            <p>
              <span style={{color: "orange", fontFamily: "SpaceGrotesk-SemiBold"}}></span> Twoja rezerwacja jest na
              liście rezerwowej. Rezerwację potwierdzimy telefonicznie lub mailowo - prosimy do tego momentu nie wpłacać
              zaliczki.
            </p>
          </div>
        )}
        {reservationArr?.StepConfig.Step5.participantsConfig.map(
          ({firstName, lastName, userNum, birthDate, phone, email, gender, idDocument, country}, index) => (
            
            <div id="participants">
              {firstName === "" && lastName === "" ? (
                <div className="name">
                  <p>Uczestnik {userNum}</p>
                </div>
              ) : (
                <div className="name">
                  <p>
                    {userNum}. {firstName} {lastName}
                  </p>
                </div>
              )}
              <h4>Dane osobowe</h4>
              <table className="detailsBox">
                {firstName !== "" && (
                  <tr>
                    <td className="firstColumn">Imię:</td>
                    <td className="secondColumn">{firstName}</td>
                  </tr>
                )}
                {lastName !== "" && (
                  <tr>
                    <td className="firstColumn">Nazwisko:</td>
                    <td className="secondColumn">{lastName}</td>
                  </tr>
                )}
                {birthDate !== "" && (
                  <tr>
                    <td className="firstColumn">Data urodzenia:</td>
                    <td className="secondColumn">{birthDate}</td>
                  </tr>
                )}
                {country !== "" && (
                  <tr>
                    <td className="firstColumn">Kraj zamieszkania:</td>
                    <td className="secondColumn">{country}</td>
                  </tr>
                )}
                {idDocument !== "" && (
                  <tr>
                    <td className="firstColumn">Seria i numer dowodu:</td>
                    <td className="secondColumn">{idDocument}</td>
                  </tr>
                )}
                {gender !== "" && (
                  <tr>
                    <td className="firstColumn">Płeć:</td>
                    <td className="secondColumn">{gender === "1" ? "kobieta" : "mężczyzna"}</td>
                  </tr>
                )}
                {phone !== "" && (
                  <tr>
                    <td className="firstColumn">Numer telefonu:</td>
                    <td className="secondColumn">{phone}</td>
                  </tr>
                )}
                {email !== "" && (
                  <tr>
                    <td className="firstColumn">E-mail:</td>
                    <td className="secondColumn">{email}</td>
                  </tr>
                )}
              </table>

              {((!reservationArr.ReserveList &&
              userNum !== 1 &&
              reservationArr.Status !== "Anulowana" &&
              !reservationArr.IsHistorical) && (!participantDetails &&
                participantButton &&
                userNumParticipant[index] !== participantNumber)) && (
                <div className="btnBox">
                  <button className="primaryBtn" onClick={() => handleChangeAllowed(userNum)}>
                    Zmień dane
                  </button>
                </div>
              )}
              {userNumParticipant[index] === participantNumber && (
                <p className="errorMessage">{errorMessageParametersGetParticipants}</p>
              )}
              {participantDetails && userNum === participantNumber && (
                <ParticipantDetails {...this} {...this.state} participantsInfo={participantsInfo[index]} />
              )}
            </div>
          )
        )}
      </>
    );
  }
}

export default ParticipantsWrapper;
