import React, { useEffect } from 'react';
import "./StepSix.scss";
import "./StepSubmit.scss";
import { Link } from 'react-router-dom';
import image from "./img/italyCov.jpg";


const StepSubmitPayUError = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
          
                <div id="stepSubmit" style={{ paddingTop: "150px" }}>
                    <div className="container">
                        <div className="image">
                            <img src={image} alt="Rejsy morskie"></img>
                        </div>
                        <h4>Płatność PayU</h4>
                        <p style={{ width: "400px", margin: "auto", textAlign: "center", maxWidth: "90vw" }}></p>
                        <div className="greenBox" style={{ background: "rgba(255, 193, 7, 0.28)" }}>
                            <p className="infoTitle" style={{ fontWeight: "300", color: "orange", textAlign: "center" }}>Wystąpił problem z platnością. <br></br>Prosimy o kontakt  biurem.</p>
                        </div>
                        <Link to="/twoj-panel" title="Przejdź do Panelu Klienta" ><button  className="secondaryBtn" style={{ cursor: "pointer", margin: "auto", marginTop: "20px", display: "flex" }}>Przejdź do panelu klienta</button></Link>
                    </div>
                </div>
            
        </>
    );
}

export default StepSubmitPayUError;