import React, {useState} from "react";
import "./OfferTagBox.scss";
import {RiBusFill, RiCarFill} from "react-icons/ri";
import {AiOutlineClockCircle} from "react-icons/ai";
import {AiOutlineInfoCircle} from "react-icons/ai";
import ReactHtmlParser from "react-html-parser";
import {GiSailboat} from "react-icons/gi";
import Party from "../img/offermai-tai.png";
import Explore from "../img/offermai-tai.png";
import Chill from "../img/offermai-tai.png";
import Adventure from "../img/offermai-tai.png";
import OfferTagBoxItem from "./OfferTagBoxItem";
import Wind from "./images/tbt_wiatr.jpg";
import Chilling from "./images/tbt_chill.jpg";
import Bay from "./images/tbt_zatoki.jpg";

const OfferTagBox = ({offerBoxes, offerDetails, offerType, offerRooms, offerCountry, shortInfo}) => {
  const [isOpen1, setIsOpen1] = useState(false);

  const togglePopup1 = () => {
    setIsOpen1(!isOpen1);
  };
  const [isOpen2, setIsOpen2] = useState(false);

  const togglePopup2 = () => {
    setIsOpen2(!isOpen2);
  };
  const [isOpen3, setIsOpen3] = useState(false);

  const togglePopup3 = () => {
    setIsOpen3(!isOpen3);
  };

  let firstBoxesValue = offerBoxes?.[0]?.[0];
  let firstBoxesItem = [];
  for (let fi = 0; fi < firstBoxesValue; fi++) {
    firstBoxesItem.push(<div className="offerTagScaleBarItem offerTagScaleBarItemActive"></div>);
  }

  let firstBoxesItemEmpty = 5 - firstBoxesValue;
  for (let fe = 0; fe < firstBoxesItemEmpty; fe++) {
    firstBoxesItem.push(<div className="offerTagScaleBarItem"></div>);
  }

  let secondBoxesValue = offerBoxes?.[1]?.[0];
  let secondBoxesItem = [];
  for (let si = 0; si < secondBoxesValue; si++) {
    secondBoxesItem.push(<div className="offerTagScaleBarItem offerTagScaleBarItemActive"></div>);
  }

  let secondBoxesItemEmpty = 5 - secondBoxesValue;
  for (let se = 0; se < secondBoxesItemEmpty; se++) {
    secondBoxesItem.push(<div className="offerTagScaleBarItem"></div>);
  }

  let thirdBoxesValue = offerBoxes?.[2]?.[0];
  let thirdBoxesItem = [];
  for (let si = 0; si < thirdBoxesValue; si++) {
    thirdBoxesItem.push(<div className="offerTagScaleBarItem offerTagScaleBarItemActive"></div>);
  }

  let thirdBoxesItemEmpty = 5 - thirdBoxesValue;
  for (let se = 0; se < thirdBoxesItemEmpty; se++) {
    thirdBoxesItem.push(<div className="offerTagScaleBarItem"></div>);
  }

  const organizatedTransport = offerDetails.Journeys.List.filter(
    ({Available, Name}) =>
      Available &&
      (Name.includes("bus") || Name.includes("autokar") || Name.includes("Bus") || Name.includes("Autokar"))
  );

  return (
    <>
      <div className="offerTagBoxWrapper">
        {offerType.OfferTypeId !== "6" && (
          <div className="offerTagBox">
            <AiOutlineClockCircle />
            {offerType.Name === "Sail & Party" && <span>4-5h pływania dziennie</span>}
            {offerType.Name === "Sail & Explore" && <span>6-8h pływania dziennie</span>}
            {offerType.Name === "Sail & Chill" && <span>5-6h pływania dziennie</span>}
            {offerType.Name === "Sail & Adventure" && <span>8-10h pływania dziennie</span>}
            {offerType.Name === "Szkolenia" && <span>ok. 9h pływania dziennie</span>}
          </div>
        )}
        {(offerType.Name === "Szkolenia" && offerDetails.Name.includes("czerwiec")) ||
        offerType.OfferTypeId === "6" ? null : (
          <div className="offerTagBox">
            <GiSailboat />
            <span>
              {offerRooms.AllocationsListCount}
              {offerRooms.AllocationsListCount === "2" ||
              offerRooms.AllocationsListCount === "3" ||
              offerRooms.AllocationsListCount === "4"
                ? "jachty"
                : offerRooms.AllocationsListCount === "1"
                ? "jacht"
                : "jachtów"}
            </span>
          </div>
        )}
        {offerType.OfferTypeId !== "6" && (
          <div className="offerTagBox">
            {offerCountry?.Name === "Chorwacja" || offerCountry?.Name === "Czarnogóra" ? <RiBusFill /> : <RiCarFill />}
            {organizatedTransport.length > 0 ? <span>Zorganizowany dojazd</span> : <span>Dojazd własny</span>}
          </div>
        )}

        {offerType.OfferTypeId !== "6" && (
          <div className="offerTagBox">
            {offerType?.Name === "Sail & Party" && <img src={Party} alt="Formuła rejsu - Sail and Party" />}
            {offerType?.Name === "Sail & Adventure" && <img src={Adventure} alt="Formuła rejsu - Sail and Adventure" />}
            {offerType?.Name === "Sail & Explore" && <img src={Explore} alt="Formuła rejsu - Sail and Explore" />}
            {offerType?.Name === "Sail & Chill" && <img src={Chill} alt="Formuła rejsu - Sail and Chill" />}
            <span>{offerType.Name}</span>
          </div>
        )}
      </div>
      <div className="shortInfoDesciption">
        {ReactHtmlParser(shortInfo.Long)}{" "}
        {offerDetails.OfferId === "82" ? (
          <div className="orangeBox" style={{background: "orange"}}>
            <p style={{color: "white"}}>Cena dla skipperów TBT: 2090 PLN</p>
          </div>
        ) : (
          offerDetails.OfferId === "83" && (
            <div className="orangeBox">
              <p>Ofera skierowana dla osób 26+</p>
            </div>
          )
        )}
      </div>

      {(offerDetails.OfferId === "3" || offerDetails.OfferId === "54") && (
        <div className="greenBox orangeBox">
          <p class="infoTitle" style={{color: "orange"}}>
            <span style={{color: "orange", fontFamily: "SpaceGrotesk-SemiBold"}}>UWAGA:</span> Zapisy indywidualne
            przyjmujemy na listę rezerwową, rezerwacje będziemy potwierdzać telefonicznie lub mailowo.
          </p>
        </div>
      )}
      {offerDetails.OfferId === "15" && (
        <div className="greenBox orangeBox" style={{background: "#fae5e5"}}>
          <p class="infoTitle" style={{color: "#d50101"}}>
            <span style={{color: "#d50101", fontFamily: "SpaceGrotesk-SemiBold"}}>UWAGA:</span> Brak miejsc
          </p>
        </div>
      )}

      <div className="offerTagScaleWrapper">
        {firstBoxesItem.length !== 0 && (
          <div className="offerTagScaleItem">
            <span>Słaby wiatr</span>
            <div className="offerTagScaleBar">{firstBoxesItem}</div>
            <span>Silny wiatr</span>
            <AiOutlineInfoCircle onClick={togglePopup1} />
            {isOpen1 && (
              <OfferTagBoxItem
                handleClose={togglePopup1}
                name="Słaby wiatr - Silny wiatr"
                altImage="Siła wiatru na rejsie"
                image={Wind}
                description="Na skali opisujemy jakich warunków wietrznych możecie się spodziewać na rejsie. Dwie kreski oznaczają bardzo delikatny wiatr,taki jak zazwyczaj spotkacie np. w Chorwacji, natomiast 4 albo więcej kresek to wiatr jakiego możecie się spodziewać w trakcie rejsu po Cykladach. Jako bardziej wietrzen oznaczamy też rejsy gdzie organizujemy regaty - aby oddać ich sportowy charakter"
              />
            )}
          </div>
        )}
        {secondBoxesItem.length !== 0 && (
          <div className="offerTagScaleItem">
            <span>Zatoki</span>
            <div className="offerTagScaleBar">{secondBoxesItem}</div>
            <span>Porty</span>
            <AiOutlineInfoCircle onClick={togglePopup2} />
            {isOpen2 && (
              <OfferTagBoxItem
                handleClose={togglePopup2}
                name="Zatoki - Porty"
                altImage="Zatoki i porty na rejsie"
                image={Bay}
                description="Na skali opisujemy czy w trakcie rejsów częściej będziemy stać na bojce lub w zatokach czy też w marinach. W trakcie każdego rejsu chcemy przynajmniej jedną noc spędzić w zatoce pod gwiazdami, abyście mogli poczuć cały urok jaki twki w spędzaniu nocy na morzu. Jednak bez obaw! Najczęściej wybieramy zatoki, które oferują sanitariaty, a na wyspie jest restauracja, tak że bez problemu będziecie mogli skorzystać z toalety, prysznica i zjeść"
              />
            )}
          </div>
        )}
        {thirdBoxesItem.length !== 0 && (
          <div className="offerTagScaleItem">
            <span>Chill</span>
            <div className="offerTagScaleBar">{thirdBoxesItem}</div>
            <span>Impreza</span>
            <AiOutlineInfoCircle onClick={togglePopup3} />
            {isOpen3 && (
              <OfferTagBoxItem
                handleClose={togglePopup3}
                name="Chill - Impreza"
                altImage="Charakter rejsu"
                image={Chilling}
                description="Na tej skali opisujemy czy rejs będzie miał bardziej imprezowy charakter czy też planujemy więcej spokojnej integracji międzi łódkami. Nie mniej na niemal każdym rejsie możecie się spodziewać przynajmniej jednej większej imprezy"
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default OfferTagBox;
