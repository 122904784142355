import React from "react";
import "./TransportInfo.scss";
import { Tabs, Tab, TabPanel, TabList } from "react-web-tabs";

const TransportInfo = ({ transportImages, transportArr }) => {
  const heading = transportArr?.shift();
  const tabsName = transportArr?.filter((element, index) => index % 3 === 0);
  const contentHeading = transportArr
    ?.slice(1, transportArr.length)
    .filter((element, index) => index % 3 === 0);
  const contentTabs = transportArr
    ?.slice(2, transportArr.length)
    .filter((element, index) => index % 3 === 0);
  const tabs = ["basic-tab-one", "basic-tab-two", "basic-tab-three"];

  return (
    <div id="transportInfo">
      <div className="container">
        <h2>{heading}</h2>
        <div className="transportBox">
          <Tabs defaultTab="basic-tab-one">
            <TabList>
              {tabsName?.map((element, index) => (
                <Tab key={index} tabFor={tabs[index]}>
                  {element}
                </Tab>
              ))}
            </TabList>
            {transportArr?.map((element, index) => {
              return (
                <TabPanel key={index} tabId={tabs[index]}>
                  <div id="oneTransport">
                    <div className="image">
                      <img
                        src={transportImages[index]?.url}
                        alt={transportImages[index]?.alt}
                      />
                    </div>
                    <div className="text">
                      <h3>{contentHeading[index]}</h3>
                      <p>{contentTabs[index]}</p>
                    </div>
                  </div>
                </TabPanel>
              );
            })}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TransportInfo;
