import React from "react";
import "./Vouchers.scss";
import VouchersCreate from "./VouchersCreate.js";
import Moment from "moment";
import APIbackend from "../config.js";

class Vouchers extends React.Component {
  state = {
    numPages: null,
    pageNumber: 1,
    siteId: 1,
    vouchersArr: [],
    vouchers: true,
    vouchersCreate: false,
    description: "",
    value: "",
    downloadVoucherPage: false,
    messageVoucherCreate: "",
  };

  componentDidMount = () => {
    var data = {
      siteId: this.state.siteId,
    };

    fetch(`${APIbackend}v1/account/vouchers`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    }).then((response) => {
      response.json().then((data) => {
        this.setState({
          vouchersArr: data.Vouchers,
        });
      });
    });
  };

  generateVoucher = () => {
    this.setState({
      vouchers: false,
      vouchersCreate: true,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = () => {
    var data = {
      siteId: this.state.siteId,
      description: this.state.description,
      value: this.state.value,
    };

    fetch(`${APIbackend}v1/account/vouchers/create`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    }).then((response) =>
      response.json().then(() => {
        if (response.status === 200) {
          fetch(`${APIbackend}v1/account/vouchers`, {
            method: "POST",
            body: JSON.stringify(data),
            credentials: "include",
          }).then((response) => {
            response.json().then((data) => {
              this.setState({
                vouchersArr: data.Vouchers,
                messageVoucherCreate: "✔ Voucher został wygenerowany. W celu aktywacji, napisz do nas maila.",
              });
            });
          });
        }
      })
    );
  };
  handleBack = () => {
    this.setState({
      vouchers: true,
      vouchersCreate: false,
    });
  };

  handleDownloadVoucher = () => {
    this.setState({
      downloadVoucherPage: true,
      vouchers: false,
    });
  };

  render() {
    const {messageVoucherCreate, vouchersCreate, downloadVoucherPage, value, description, vouchers, vouchersArr} =
      this.state;
    const {generateVoucher, handleChange, handleBack, handleSubmit} = this;
    const {vouchersActive} = this.props;
    return (
      vouchersActive && (
        <div id="vouchers">
          <h2>Twoje Vouchery</h2>
          {vouchers && (
            <>
              {vouchersArr.length === 0 ? (
                <div className="greenBox">
                  <p className="infoTitle">
                    Nie masz jeszcze żadnych voucherów. Aby utworzyć nowy voucher - kliknij w przycisk poniżej.
                  </p>
                </div>
              ) : (
                <table className="vouchersTable">
                  <thead>
                    <tr className="tableHead">
                      <th>Nazwa i kod Voucher'a</th>
                      <th>Kwota</th>
                      <th>Aktywny</th>
                      <th>Wykorzystany</th>
                      <th>Zasięg</th>
                      <th>Data ważności</th>
                    </tr>
                  </thead>
                  {vouchersArr.map(({Used, Name, Available, Description, Value, RangeId, ValidUntil}) => (
                    <tbody>
                      <tr className="voucherRow" style={Used ? {opacity: "0.5"} : null}>
                        <td data-label="Kod vouchera" style={Available ? {color: "green"} : {color: "#ad1010"}}>
                          <span> {Description}</span>
                          {Available && !Used ? (
                            <input style={{color: "green"}} name="voucher" value={Name} disabled></input>
                          ) : Used === true ? (
                            <input style={{color: "grey"}} name="voucher" value={Name} disabled></input>
                          ) : (
                            <input style={{color: "#ad1010"}} name="voucher" value={Name} disabled></input>
                          )}
                        </td>
                        <td
                          data-label="Kwota"
                          style={Available && !Used ? {color: "green"} : Used ? {color: "grey"} : {color: "#ad1010"}}
                        >
                          {Value} PLN
                        </td>
                        <td
                          data-label="Aktywny"
                          style={Available && !Used ? {color: "green"} : Used ? {color: "grey"} : {color: "#ad1010"}}
                        >
                          {Available ? "TAK" : "NIE"}
                        </td>
                        <td
                          data-label="Wykorzystany"
                          style={Available && !Used ? {color: "green"} : Used ? {color: "grey"} : {color: "#ad1010"}}
                        >
                          {Used === true ? "TAK" : "NIE"}
                        </td>
                        <td
                          data-label="Zasięg"
                          style={Available && !Used ? {color: "green"} : Used ? {color: "grey"} : {color: "#ad1010"}}
                        >
                          {RangeId === "All" ? "TheBoatTrip\n Taksidi" : "TheBoatTrip"}
                        </td>
                        <td
                          data-label="Data ważności"
                          style={Available && !Used ? {color: "green"} : Used ? {color: "grey"} : {color: "#ad1010"}}
                        >
                          {ValidUntil === null ? "bezterminowy" : Moment(ValidUntil).format("DD.MM.YYYY")}
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              )}
              <button className="primaryBtn" onClick={generateVoucher}>
                Wygeneruj nowy voucher
              </button>
            </>
          )}

          {vouchersCreate && (
            <VouchersCreate
              handleBack={handleBack}
              messageVoucherCreate={messageVoucherCreate}
              description={description}
              value={value}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
            />
          )}
          <div className="greenBox">
            <p className="infoText">Jak to działa?</p>
            <p>
              Voucher podarunkowy możesz wygenerować na dowolną kwotę.<br></br>
              Wartość zakupionego Vouchera zostanie odjęta od ceny całkowitej przy rezerwacji jednego z naszych
              wyjazdów.<br></br>
              Osoba, ktora otrzyma Voucher może go wykorzystać u wszystkich naszych marek (Taksidi, The Boat Trip,
              Offcourse i Boats&Friends).<br></br>
              Wartość kuponu łączy się ze wszystkimi zniżkami - ze zniżką lojalnościową i ze zniżką grupową.
            </p>
          </div>

          {downloadVoucherPage && <h1>Pobierz voucher</h1>}
        </div>
      )
    );
  }
}

export default Vouchers;
