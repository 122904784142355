import React from 'react';
import { TripFilter } from './TripFilter.js';
import './OurTrips.scss';
import PartnersWrapper from './PartnersWrapper.js';
// import ListOfTrips from "./ListOfTrips"
import _ from 'lodash';
import { Helmet } from 'react-helmet';

import { fetchData } from './store/actions';
import { ourTripsCachedUrl, ourTripsFilterCachedUrl } from './cachedStoreUrls';
import { connect } from 'react-redux';

let cacheRequestOffer;

export const prefetchMethod = (dispatch, match, location = {}) => {
  const requestOffer = {
    siteId: 1,
    filterCountry:
      location.state !== undefined ? location.state.filterCountry : [],
    filterType: location.state !== undefined ? location.state.filterType : [],
    filterOptions: [],
    filterTerm: [],
  };

  const ignoreCache = !_.isEqual(cacheRequestOffer, requestOffer);

  cacheRequestOffer = requestOffer;

  return Promise.all([
    dispatch(
      fetchData(
        '/v1/offers/list',
        {
          siteId: 1,
          filterCountry:
            location.state !== undefined ? location.state.filterCountry : [],
          filterType:
            location.state !== undefined ? location.state.filterType : [],
          filterOptions: [],
          filterTerm: [],
        },
        ourTripsCachedUrl,
        ignoreCache
      )
    ),
    dispatch(
      fetchData(
        '/v1/offers/filters',
        {
          siteId: 1,
        },
        ourTripsFilterCachedUrl
      )
    ),
  ]);
};

const enhance = connect((state) => {
  return {
    List: state.fetchedUrls[ourTripsCachedUrl]?.List || [],
    Types: state.fetchedUrls[ourTripsFilterCachedUrl]?.Types || [],
    Countries: state.fetchedUrls[ourTripsFilterCachedUrl]?.Countries || [],
    Terms: state.fetchedUrls[ourTripsFilterCachedUrl]?.Terms || [],
    Options: state.fetchedUrls[ourTripsFilterCachedUrl]?.Options || [],
  };
});

class OurTrips extends React.Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    const query = {};

    new URLSearchParams(this.props.location.search).forEach((value, key) => {
      query[key] = value;
    });

    prefetchMethod(this.props.dispatch, this.props.match, {
      ...this.props.location,
      query,
    }).then(() => {
      this.setState({
        loading: false,
      });
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    }

    return (
      <>
        <Helmet>
          <title>Rejsy morskie 2024 - oferta</title>
          <meta
            name="description"
            content="Organizujemy dobrze zaplanowane rejsy, w różne zakątki świata. Znajdziesz tu listę naszych aktualnych rejsów. Sprawdź co dla Was przygotowaliśmy w tyk roku!"
            data-react-helmet="true"
          />
        </Helmet>
        <div id="ourTrips">
          <TripFilter {...this.props} />
        </div>
        <PartnersWrapper />
      </>
    );
  }
}

OurTrips.prefetchMethod = prefetchMethod;

export default enhance(OurTrips);
