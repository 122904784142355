import React, { useContext } from 'react';
import BookButton from './OfferBookButton.js';
import './OfferMenu.scss';
import { DataDostawca } from './DataDostawca.js';

const Menu = ({
  offerDetails,
  offerPrices,
  offerType,
  offerClasses,
  analiticsDataAddToCart,
}) => {
  const { scrollToElement } = useContext(DataDostawca);

  const menuArr = [
    { menuName: 'Opis ogólny', scrollElement: 'header' },
    { menuName: 'Trasa', scrollElement: 'routes' },
    { menuName: 'Jachty', scrollElement: 'accommodation' },
    { menuName: 'Atrakcje', scrollElement: 'attraction' },
    { menuName: 'Dojazd', scrollElement: 'transport' },
    { menuName: 'Cena', scrollElement: 'price' },
  ];

  return (
    <>
      <div className="menuPage">
        <ul className="menuWrapper">
          {menuArr.map(({ menuName, scrollElement }) => (
            <li
              className="menuButton"
              onClick={() => scrollToElement(scrollElement)}
            >
              {menuName}
            </li>
          ))}
        </ul>
        <BookButton
          name={offerDetails.Name}
          price={offerPrices.Base}
          promoPrice={offerPrices.Strikethrough}
          offerid={offerDetails.OfferId}
          grayedOut={offerDetails.Availability.GrayedOut}
          availableOffer={offerDetails.Availability.Available}
          offerClasses={offerClasses}
          offerType={offerType}
          analiticsDataAddToCart={analiticsDataAddToCart}
          offerTypeId={offerDetails.Type.OfferTypeId}
        />
      </div>
    </>
  );
};

export default Menu;
