import React from "react";
import "./SailingTraining.scss";
import "./userRegistration/Trips.scss";
import "./FilteredTrips.scss";
import Moment from "moment";
import {Link} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import imageSter from "./img/ster.png";
import {Accordion, AccordionItem, AccordionItemPanel} from "react-accessible-accordion";
import {connect} from "react-redux";
import {fetchData} from "./store/actions";
import {offerListCacheUrl, offerFilterUrlCacheUrl, dataPageCacheUrl} from "./cachedStoreUrls";
import calendarIcon from "./img/calendar2.png";
import {Helmet} from "react-helmet";

const FilteredTrips = (props) => {
  let actualDate = new Date();

  const sortedArray = props.List.sort((a, b) =>
    a.Availability.From.split("-").join().localeCompare(b.Availability.From.split("-").join())
  );
  const updatedArray = sortedArray.filter(({Type}) => Type.OfferTypeId === "5");

  return (
    <div id="filteredTrips">
      <div className="container">
        {updatedArray.map(
          ({
            Region,
            Type,
            Availability,
            OfferId,
            Name,
            FriendlyUrl,
            Prices,
            ListImg,
            RibbonTextExt,
            Rooms,
            RibbonTextType,
            RibbonText,
          }) => {
            const regionImg = Region.Country.Image;
            const typeImg = Type.Image;
            return (Availability.Available && Availability.GrayedOut === false) ||
              actualDate.getTime() < new Date(Availability.To).getTime() ? (
              <div>
                <Link to={`/oferta/${FriendlyUrl}`}>
                  <div id="oneTripFiltered">
                    <div className="photoContainer">
                      {Availability.GrayedOut && (
                        <>
                          <div className="overlayOffer desktopVersion">
                            <img src={imageSter} alt="Lista rezerwowa"></img>
                          </div>
                          <div className="overlayOffer responsive">
                            <img src={imageSter} alt="Lista rezerwowa"></img>
                          </div>
                        </>
                      )}

                      <Link to={`/oferta/${FriendlyUrl}`}>
                        <img src={ListImg?.url} alt={ListImg?.alt} />
                        <div className="overlay"></div>
                      </Link>

                      <div className="tripInfo">
                        <div className="infoTripBox">
                          <div>
                            <h3>
                              <Link to={`/oferta/${FriendlyUrl}`}>{Name}</Link>
                            </h3>
                            <p>
                              <img src={regionImg?.url} alt={regionImg?.alt} />
                              {Region.Country.Name}, {Region.Name}
                            </p>
                            <p>
                              <img src={calendarIcon} alt="termin" />
                              {Moment(Availability.From).format("DD.MM")} -{" "}
                              {Moment(Availability.To).format("DD.MM.YYYY")}
                            </p>
                          </div>
                          <div className="tagsBox">
                            <div className="oneTag">
                              <img src={typeImg?.url} alt={typeImg?.alt} /> {Type.Name}
                            </div>
                          </div>
                        </div>

                        {RibbonTextExt?.includes("Potwierdzony") || RibbonTextExt?.includes("potwierdzony") ? (
                          <p className="confirmedTrip">
                            <span>✔</span> {RibbonTextExt}
                          </p>
                        ) : (
                          RibbonTextExt && <p className="confirmedTrip">{RibbonTextExt}</p>
                        )}

                        <div className="priceSection">
                          <div className="priceBox">
                            {Prices.Strikethrough === null ? (
                              <div className="regularPrice">
                                <p className="price">{Prices.Base} PLN</p>
                                <p className="text">Cena od osoby</p>
                              </div>
                            ) : (
                              <>
                                <div className="discount">
                                  <p className="newPrice">{Prices.Base} PLN</p>
                                  <p className="text">Promocyjna cena od osoby</p>
                                </div>
                                <p className="price">{Prices.Strikethrough} PLN</p>
                                <p className="text">Cena od osoby</p>
                              </>
                            )}
                          </div>
                          <div className="detailsBox">
                            <p className="amountOfPeople">Liczba jachtów: {Rooms.AllocationsListCount}</p>
                            {actualDate.getTime() < new Date(Availability.To).getTime() && !Availability.Available ? (
                              <p className="availability red">Wyjazd w trakcie</p>
                            ) : (
                              <>
                                {RibbonTextType === "red" && <p className="availability red">{RibbonText}</p>}
                                {RibbonTextType === "green" && <p className="availability green">{RibbonText}</p>}
                                {RibbonTextType === "orange" && <p className="availability orange">{RibbonText}</p>}
                              </>
                            )}
                            <button className="primaryBtn">
                              <Link to={`/oferta/${FriendlyUrl}`} title="Zobacz szczegóły">
                                Zobacz szczegóły
                              </Link>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ) : null;
          }
        )}
      </div>
    </div>
  );
};

const enhance = connect((store) => {
  return {
    dataFetched: {
      Page: store.fetchedUrls[dataPageCacheUrl]?.Page || {},
      Types: store.fetchedUrls[offerFilterUrlCacheUrl]?.Types,
      Countries: store.fetchedUrls[offerFilterUrlCacheUrl]?.Countries,
      Terms: store.fetchedUrls[offerFilterUrlCacheUrl]?.Terms,
      Options: store.fetchedUrls[offerFilterUrlCacheUrl]?.Options,
      List: store.fetchedUrls[offerListCacheUrl]?.List || [],
      FirstList: store.fetchedUrls[offerListCacheUrl]?.List || [],
      IsLoading: true || false,
    },
    dataLoaded:
      store.urlStatus[dataPageCacheUrl] === "done" &&
      store.urlStatus[offerFilterUrlCacheUrl] === "done" &&
      store.urlStatus[dataPageCacheUrl] === "done",
  };
});

export const prefetchMethod = (dispatch) => {
  return Promise.all([
    dispatch(
      fetchData(
        "/v1/offers/list",
        {
          siteId: 1,
          filterType: [5],
          filterCountry: [],
          filterTerm: [],
          filterOptions: [],
        },
        offerListCacheUrl
      )
    ),
    dispatch(
      fetchData(
        "/v1/offers/filters",
        {
          siteId: 1,
        },
        offerFilterUrlCacheUrl
      )
    ),
    dispatch(
      fetchData(
        "/v1/data/page",
        {
          siteId: 1,
          pageId: "academyMainPage",
        },
        dataPageCacheUrl
      )
    ),
  ]);
};

class SailingTraining extends React.Component {
  state = {
    siteId: 1,
    Page: {},
    filterType: [5],
    academyArr: false,
    FirstList: [],
    polandArr: false,
    croatiaArr: false,
    sailorArr: false,
    sailorArrCroatia: false,
    filterTypeSailing: "",
    loading: true,
  };

  componentDidMount = () => {
    prefetchMethod(this.props.dispatch).then(() => {
      this.setState({
        loading: this.props.dataFetched.IsLoading || false,
        List: this.props.dataFetched.FirstList || [],
      });
    });
  };

  handleShowAcademy = (e) => {
    const {FirstList} = this.props.dataFetched;
    this.setState({
      filterTypeSailing: e.target.value,
    });
    setTimeout(() => {
      if (this.state.filterTypeSailing === "academy") {
        this.setState({
          List: FirstList.filter(({Name}) => Name.includes("Akademia")),
        });
      } else if (this.state.filterTypeSailing === "sailor") {
        this.setState({
          List: FirstList.filter(({Name}) => Name.includes("Żeglarza")),
        });
      } else if (this.state.filterTypeSailing === "sailorCroatia") {
        this.setState({
          List: FirstList.filter(({Name}) => Name.includes("Sternika")),
        });
      } else if (this.state.filterTypeSailing === "poland") {
        this.setState({
          List: FirstList.filter(({Name}) => Name.includes("Mazury")),
        });
      } else if (this.state.filterTypeSailing === "croatia") {
        this.setState({
          List: FirstList.filter(({Name}) => !Name.includes("Mazury")),
        });
      } else if (this.state.filterTypeSailing === "all") {
        this.setState({
          List: FirstList,
        });
      }
    }, 100);
  };

  render() {
    if (!this.props.dataLoaded) {
      return (
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    }

    const {FirstList, Page} = this.props.dataFetched;
    const List = this.state.List || FirstList || [];
    let actualDate = new Date();
    const trainingsArray = FirstList.filter(
      ({Availability}) =>
        (Availability.Available && Availability.GrayedOut === false) ||
        actualDate.getTime() < new Date(Availability.To).getTime()
    );

    const academyArrLength = trainingsArray?.filter(({Name}) => Name.includes("Akademia")).length;
    const polandArrLength = trainingsArray?.filter(({Name}) => Name.includes("Mazury")).length;
    const croatiaArrLength = trainingsArray?.filter(({Name}) => !Name.includes("Mazury")).length;
    const sailorArrCroatiaLength = trainingsArray?.filter(({Name}) => Name.includes("Sternika")).length;
    const sailorArrLength = trainingsArray?.filter(({Name}) => Name.includes("Żeglarza")).length;
    const wholeArrLength = trainingsArray?.length;

    const arrWithTextImages = Page?.Contents?.filter(({Type}) => Type === "TextWithImages");
    const sliderObject = Page?.Contents ? arrWithTextImages?.shift() : null;
    const metaData = Page?.META;

    return (
      <>
        <div id="sailingTraining">
          <Helmet>
            <title>{metaData?.Title}</title>
            <meta name="description" content={metaData?.Description} data-react-helmet="true" />
          </Helmet>
          {Page.Contents !== undefined && (
            <div className="slider">
              <img src={sliderObject.Contents.Images[0]?.url} alt={sliderObject.Contents.Images[0]?.alt} />
            </div>
          )}
          <div className="info">
            {Page?.Contents &&
              arrWithTextImages?.map(({Contents}) => (
                <>
                  <div className="container">
                    <div className="secondSection">
                      <div className="imageSection">
                        <img className="firstPhoto" src={Contents.Images[0]?.url} alt={Contents.Images[0]?.alt} />
                        <img className="secondPhoto" src={Contents.Images[1]?.url} alt={Contents.Images[1]?.alt} />
                      </div>
                      <div className="textSection">{ReactHtmlParser(Contents.Text)}</div>
                    </div>
                  </div>
                  <Link to={Contents.Buttons[0][0]} title="Dowiedz się więcej o naszych szkoleniach">
                    <button className="secondaryBtn">Dowiedz się więcej o naszych szkoleniach</button>
                  </Link>
                </>
              ))}
            <div id="tripFilter" style={{margin: "0", padding: "0"}}>
              <h1 style={{textAlign: "center", marginTop: "80px"}}>Rejsy szkoleniowe</h1>
              <div className="container" id="boxFiltersContainer">
                <div className="boxFilter" style={{width: "260px"}}>
                  <Accordion preExpanded={[1, 2]} allowZeroExpanded>
                    <AccordionItem uuid={1}>
                      <AccordionItemPanel>
                        <div className="tripProgram">
                          <label className="containerInput">
                            <input
                              type="radio"
                              name="filterTypeSailing"
                              value="all"
                              onChange={this.handleShowAcademy}
                            ></input>
                            <span className="checkmark"></span>
                            <div className="oneBox"></div>
                            <p className="titleText">
                              Wszystkie szkolenia
                              <span>({wholeArrLength})</span>
                            </p>
                          </label>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>

                <div className="boxFilter" style={{width: "260px"}}>
                  <Accordion preExpanded={[1, 2]} allowZeroExpanded>
                    <AccordionItem uuid={1}>
                      <AccordionItemPanel>
                        <div className="tripProgram">
                          <label className="containerInput">
                            <input
                              type="radio"
                              name="filterTypeSailing"
                              value="academy"
                              onChange={this.handleShowAcademy}
                            ></input>
                            <span className="checkmark"></span>
                            <div className="oneBox"></div>
                            <p className="titleText">
                              Szkolenia Manewrowe
                              <span>({academyArrLength})</span>
                            </p>
                          </label>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>

                <div className="boxFilter" style={{width: "260px"}}>
                  <Accordion preExpanded={[1, 2]} allowZeroExpanded>
                    <AccordionItem uuid={1}>
                      <AccordionItemPanel>
                        <div className="tripProgram">
                          <label className="containerInput">
                            <input
                              type="radio"
                              name="filterTypeSailing"
                              value="sailor"
                              onChange={this.handleShowAcademy}
                            ></input>
                            <span className="checkmark"></span>
                            <div className="oneBox"></div>
                            <p className="titleText">
                              Żeglarz Jachtowy
                              <span>({sailorArrLength})</span>
                            </p>
                          </label>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>

                <div className="boxFilter" style={{width: "260px"}}>
                  <Accordion preExpanded={[1, 2]} allowZeroExpanded>
                    <AccordionItem uuid={1}>
                      <AccordionItemPanel>
                        <div className="tripProgram">
                          <label className="containerInput">
                            <input
                              type="radio"
                              name="filterTypeSailing"
                              value="sailorCroatia"
                              onChange={this.handleShowAcademy}
                            ></input>
                            <span className="checkmark"></span>
                            <div className="oneBox"></div>
                            <p className="titleText">
                              Sternik Morski
                              <span>({sailorArrCroatiaLength})</span>
                            </p>
                          </label>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
                <div className="boxFilter" style={{width: "260px"}}>
                  <Accordion preExpanded={[1, 2]} allowZeroExpanded>
                    <AccordionItem uuid={1}>
                      <AccordionItemPanel>
                        <div className="tripProgram">
                          <label className="containerInput">
                            <input
                              type="radio"
                              name="filterTypeSailing"
                              value="croatia"
                              onChange={this.handleShowAcademy}
                            ></input>
                            <span className="checkmark"></span>
                            <div className="oneBox"></div>
                            <p className="titleText">
                              Szkolenia w Chorwacji
                              <span>({croatiaArrLength})</span>
                            </p>
                          </label>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>

                <div className="boxFilter" style={{width: "260px"}}>
                  <Accordion preExpanded={[1, 2]} allowZeroExpanded>
                    <AccordionItem uuid={1}>
                      <AccordionItemPanel>
                        <div className="tripProgram">
                          <label className="containerInput">
                            <input
                              type="radio"
                              name="filterTypeSailing"
                              value="poland"
                              onChange={this.handleShowAcademy}
                            ></input>
                            <span className="checkmark"></span>
                            <div className="oneBox"></div>
                            <p className="titleText">
                              Szkolenia w Polsce
                              <span>({polandArrLength})</span>
                            </p>
                          </label>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
              <FilteredTrips
                academyArr={this.state.academyArr}
                handleShowPoland={this.handleShowPoland}
                List={List}
                handleShowAcademy={this.handleShowAcademy}
                handleShowCroatia={this.handleShowCroatia}
                handleShowSailor={this.handleShowSailor}
                handleShowSailorCroatia={this.handleShowSailorCroatia}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

SailingTraining.prefetchMethod = prefetchMethod;
export default enhance(SailingTraining);
