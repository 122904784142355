import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import './ClassicCruise.scss';
import imageCalendar from '../img/calendar2.png';
import APIbackend from '../config.js';

const FilteredTrips = ({ List, HomePage }) => {
  const sortedList = List.filter(
    ({ Availability }) => Availability.Available && !Availability.GrayedOut
  ).sort((a, b) =>
    a.Availability.From.split('-')
      .join()
      .localeCompare(b.Availability.From.split('-').join())
  );
  const selectTripsCMS = HomePage?.Contents?.filter(
    ({ Type }) => Type === 'HTML'
  )[0]?.Contents?.HTML?.split(',');
  const TripArrSelect =
    selectTripsCMS &&
    sortedList.filter(
      ({ FriendlyUrl }) =>
        FriendlyUrl === selectTripsCMS[0] ||
        FriendlyUrl === selectTripsCMS[1] ||
        FriendlyUrl === selectTripsCMS[2]
    );

  return (
    <div id="filteredTrips">
      <div className="container">
        {(TripArrSelect && TripArrSelect.length > 2
          ? TripArrSelect
          : sortedList?.slice(0, 3)
        ).map(
          ({
            Region,
            Type,
            FriendlyUrl,
            ListImg,
            Name,
            Availability,
            RibbonTextExt,
            Prices,
            RibbonText,
            RibbonTextType,
            Rooms,
          }) => {
            const regionImg = Region.Country.Image;
            const typeImg = Type.Image;
            return (
              <div>
                <Link to={`oferta/${FriendlyUrl}`}>
                  <div id="oneTripFiltered">
                    <div className="photoContainer">
                      <Link to={`oferta/${FriendlyUrl}`}>
                        <img src={ListImg?.url} alt={ListImg?.alt} />
                        <div className="overlay"></div>
                      </Link>

                      <div className="tripInfo">
                        <div className="infoTripBox">
                          <div>
                            <h3>
                              <Link to={`oferta/${FriendlyUrl}`}>{Name}</Link>
                            </h3>
                            <p>
                              <img src={regionImg?.url} alt={regionImg?.alt} />
                              {Region.Country.Name}, {Region.Name}
                            </p>

                            <p>
                              <img src={imageCalendar} alt="Termin rejsu" />
                              {Moment(Availability.From).format('DD.MM')} -{' '}
                              {Moment(Availability.To).format('DD.MM.YYYY')}
                            </p>
                          </div>
                          <div className="tagsBox">
                            <div className="oneTag">
                              <img src={typeImg?.url} alt={typeImg?.alt} />{' '}
                              {Type.Name}
                            </div>
                          </div>
                        </div>
                        {RibbonTextExt?.includes('Potwierdzony') ||
                        RibbonTextExt?.includes('potwierdzony') ? (
                          <p className="confirmedTrip">
                            <span
                              style={{ color: 'white', marginRight: '8px' }}
                            >
                              ✔
                            </span>{' '}
                            {RibbonTextExt}
                          </p>
                        ) : (
                          RibbonTextExt && (
                            <p className="confirmedTrip">{RibbonTextExt}</p>
                          )
                        )}
                        <div className="priceSection">
                          <div className="priceBox">
                            {Prices.Strikethrough === null ? (
                              <div className="regularPrice">
                                <p className="price">{Prices.Base} PLN</p>
                                <p className="text">Cena od osoby</p>
                              </div>
                            ) : (
                              <>
                                <div className="discount">
                                  <p className="newPrice">{Prices.Base} PLN</p>
                                  <p className="text">
                                    Promocyjna cena od osoby
                                  </p>
                                </div>
                                <p className="price">
                                  {Prices.Strikethrough} PLN
                                </p>
                                <p className="text">Cena od osoby</p>
                              </>
                            )}
                          </div>
                          <div className="detailsBox">
                            <p className="amountOfPeople">
                              Liczba jachtów: {Rooms.AllocationsListCount}
                            </p>
                            {RibbonTextType === 'red' && (
                              <p className="availability red">{RibbonText}</p>
                            )}
                            {RibbonTextType === 'green' && (
                              <p className="availability green">{RibbonText}</p>
                            )}
                            {RibbonTextType === 'orange' && (
                              <p className="availability orange">
                                {RibbonText}
                              </p>
                            )}
                            <button className="primaryBtn">
                              <Link
                                to={`/oferta/${FriendlyUrl}`}
                                title="Zobacz szczegóły oferty"
                              >
                                Zobacz szczegóły
                              </Link>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

class ClassicCruise extends React.Component {
  state = {
    siteId: 1,
    filterTerm: [],
    List: [],
    HomePage: {},
  };

  componentDidMount = () => {
    fetch(`${APIbackend}v1/offers/list`, {
      method: 'POST',
      body: JSON.stringify({
        siteId: 1,
        filterTerm: [],
        filterOptions: [],
        filterCountry: [],
        filterType: [],
      }),
    })
      .then((response) => response.json())
      .then((data) => this.setState({ List: data.List }));

    fetch(`${APIbackend}v1/data/page`, {
      method: 'POST',
      body: JSON.stringify({
        siteId: 1,
        pageId: 'homePage',
      }),
    })
      .then((response) => response.json())
      .then((data) => this.setState({ HomePage: data.Page }));
  };

  render() {
    const { List, HomePage } = this.state;
    return (
      <div id="classicCruiseWrapper">
        <div className="classicCruiseTitle">
          <h2>Wybrane rejsy morskie w sezonie 2023/24</h2>
        </div>
        <FilteredTrips List={List} HomePage={HomePage} />
        <a href="/rejsy-morskie" title="Zobacz inne rejsy morskie">
          <button className="button primaryBtn">
            Zobacz inne rejsy morskie
          </button>
        </a>
      </div>
    );
  }
}

export default ClassicCruise;
