import React from "react";
import "./Trips.scss";
import "./CurrentTrip.scss";
import Moment from "moment";
import calendarTwoImage from "../img/calendar2.png";

const CanceledTrip = ({isLoading, Reservations, ReservationHistory, handleClick}) => {
  const canceledTrips = Reservations?.concat(ReservationHistory);
  const canceledTripsUpdate = canceledTrips.filter(({Status}) => Status === "Anulowana");

  return (
    <div>
      {isLoading ? (
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <>
          <>
            {ReservationHistory !== [] &&
              ReservationHistory !== null &&
              canceledTripsUpdate.map(({Offer, ReservationId, Name, StepConfig, ReserveList, Price, Status}) => {
                const regionImg = Offer.Region.Country.Image;
                return (
                  <div id="currentOneTrip" onClick={() => handleClick(ReservationId)}>
                    <div className="photoContainer">
                      <img src={Offer.CoverImg?.url} alt={Offer.CoverImg?.alt} />
                      <div className="overlay"></div>
                      <div className="tripInfo">
                      
                        <div className="infoTripBox">
                        <h3 style={{marginBottom: "10px"}}>{Name} </h3>
                          <p>
                            <img src={regionImg?.url} alt={regionImg?.alt} />
                            <span style={{color: "white"}}>{Offer.Region.Country.Name}</span>
                          </p>
                          <p>
                            <img src={calendarTwoImage} alt="termin" />
                            {Moment(Offer.Availability.From).format("DD.MM")} -
                            {Moment(Offer.Availability.To).format("DD.MM.YYYY")}
                          </p>
                          <p style={{marginTop: "20px"}}>
                            Zapłacono: {Price.Paid === null ? 0 : Price.Paid}/ {Price.CurrentPrice} PLN
                          </p>
                          <p>Liczba osób: {StepConfig.Step1.participantsCount} </p>
                          <div
                            className="reservationStatus"
                            style={ReserveList ? {background: "#ffecb2", color: "orange"} : null}
                          >
                            <p style={ReserveList ? {color: "orange"} : null}>
                              {ReserveList ? "Lista rezerwowa" : "Status rezerwacji: " + Status}
                            </p>
                          </div>
                        </div>
                        <div className="priceSection">
                        <div className="priceBox">
                          <p className="price">{Price.CurrentPrice} PLN</p>
                          <p className="text">Cena całkowita</p>
                        </div>
                        <div className="detailsBox">
                          <div className="numberReservation" style={ReserveList ? {background: "#ffecb2"} : null}>
                            <p style={ReserveList ? {color: "orange"} : null}>Rezerwacja: {ReservationId}</p>
                          </div>
                          <button
                            className="primaryBtn"
                            style={{marginLeft: 0}}
                            onClick={() => handleClick(ReservationId)}
                          >
                            Zobacz szczegóły
                          </button>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </>

          {ReservationHistory.length === 0 && (
            <div className="greenBox">
              <p className="infoTitle">Tutaj pojawią się Twoje anulowane wyjazdy</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CanceledTrip;
