import React from "react";
import "./Registration.scss";
import APIbackend from "../config.js";

class RegistrationConfirm extends React.Component {
  state = {
    siteId: 1,
    token: "",
    errorMessage2: "",
    secondTrueMessage: "",
    errorMessage3: "",
  };

  componentDidMount = () => {
    const dataToken = {
      token: this.props.match.params.token,
    };

    fetch(`${APIbackend}v1/account/confirm`, {
      method: "POST",
      body: JSON.stringify(dataToken),
      credentials: "include",
    }).then((response) =>
      response.json().then(() => {
        if (response.status === 200) {
          this.setState({
            secondTrueMessage: "Twoje konto jest już aktywne",
            errorMessage3: "",
          });
        } else {
          this.setState({
            secondTrueMessage: "",
            errorMessage3: "Wystąpił problem z aktywacją konta",
          });
        }
      })
    );
  };

  render() {
    const {secondTrueMessage, errorMessage3} = this.state;
    return (
      <div id="registration">
        <div className="container" style={{paddingBottom: "10px"}}>
          <div className="box" style={{borderBottom: "none"}}>
            <div className="errorMessage" style={{fontSize: "16px"}}>
              {errorMessage3}
            </div>
            <div className="trueMessage" style={{fontSize: "16px"}}>
              {secondTrueMessage}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RegistrationConfirm;
