import React from "react";
import "./CompanyTrips.scss";
import "./userRegistration/Trips.scss";
import {Link} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import {fetchData} from "./store/actions";
import {companyTripCachedUrl} from "./cachedStoreUrls";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";

export const prefetchMethod = (dispatch, match) => {
  return dispatch(
    fetchData(
      "/v1/data/page",
      {
        siteId: 1,
        pageId: "companyTrip",
      },
      companyTripCachedUrl
    )
  );
};

const enhance = connect((state) => {
  return {
    Page: state.fetchedUrls[companyTripCachedUrl]?.Page || {},
  };
});

class CompanyTrips extends React.Component {
  componentDidMount = () => {
    prefetchMethod(this.props.dispatch);
  };

  render() {
    const pageData = this.props.Page;
    const metaData = pageData?.META;
    const sliderObject = pageData?.Contents?.filter(({Type}) => Type === "TextWithImages").shift();
    const boxArray = pageData?.Contents?.slice(1, 5);
    const sliderObjectText = sliderObject?.Contents?.Text?.split("\r\n");
    const sliderObjectImage = sliderObject?.Contents?.Images[0];
    const addText = pageData?.Contents?.[5]?.Contents?.Text;
    const buttonContact = pageData?.Contents?.[5]?.Contents?.Buttons;

    return (
      <div id="companyTrips">
        <Helmet>
          <title>{metaData?.Title}</title>
          <meta name="description" content={metaData?.Description} data-react-helmet="true" />
          <meta name="keywords" content={metaData?.Keywords} data-react-helmet="true" />
        </Helmet>
        <div className="slider">
          <img src={sliderObjectImage?.url} alt={sliderObjectImage?.alt} />
          <h1>{ReactHtmlParser(sliderObjectText?.[0])}</h1>
        </div>
        <div className="info">
          <h2>{ReactHtmlParser(sliderObjectText?.[1])}</h2>
          <div className="container">
            {boxArray?.map(({Contents}) => (
              <div className="firstSection">
                <div className="imageSection">
                  <img src={Contents.Images[0]?.url} alt={Contents.Images[0]?.alt} />
                </div>
                <div className="textSection">{ReactHtmlParser(Contents.Text)}</div>
              </div>
            ))}
          </div>
          <div
            style={{
              marginTop: "70px",
              marginBottom: "50px",
              textAlign: "center",
            }}
          >
            {addText && (
              <>
                <p>{ReactHtmlParser(addText)}</p>
                <Link to={`${buttonContact?.[0][1]}`} target={"_blank"}>
                  <button className="secondaryBtn">{buttonContact?.[0][0]}</button>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

CompanyTrips.prefetchMethod = prefetchMethod;

export default enhance(CompanyTrips);
