import React from "react";
import "./Attraction.scss";

const AttractionWrapper = ({
  reservationArr,
  handleAllowedAddons,
  userNumParticipant,
  userNum,
  errorMessageParametersGetAddons,
  addonsDetails,
  handleChangeAddons,
  addonsConfig,
  parameters,
  handleChangeAddonsOption,
  handleSubmitParametersAddons,
  handleCancelAddons,
  handleDeleteAddon,
  colorMessage,
  firstMessage,
  secondMessage,
  addonsMessage,
}) => {
  const nameParticipant = reservationArr.StepConfig.Step5.participantsConfig.map(({firstName, lastName}, index) => (
    <>{firstName === "" ? "Uczestnik " + (index + 1) : firstName + " " + lastName}</>
  ));

  return (
    <div id="attraction">
      {reservationArr.ReserveList && (
        <div className="orangeBox">
          <p>
            Twoja rezerwacja jest na liście rezerwowej. Rezerwację potwierdzimy telefonicznie lub mailowo - prosimy do
            tego momentu nie wpłacać zaliczki.
          </p>
        </div>
      )}
      {!reservationArr.IsHistorical && reservationArr.Owner && !reservationArr.ReserveList && (
        <div className="greenBox">
          <p className="infoText">
            <i class="fas fa-unlock"></i> Zmiana danych jest możliwa do 2 tygodni przed wyjazdem
          </p>
          <p>
            Do 2 tygodni przed wyjazdem macie możliwość samodzielnej edycji danych. Następnie ta opcja jest blokowana i
            wszelkie zmiany oraz prośby możecie zgłaszać na info@theboattrip.eu
          </p>
        </div>
      )}
      {reservationArr.StepDetails.Step4[1].map((el, index) => (
        <div className="onePerson">
          <div className="name">
            <p>{nameParticipant[index]}</p>
          </div>
          {el?.[1] || el[1].length === 0 ? (
            <h4 style={{marginBottom: "20px"}}>Nie wybrano opcji dodatkowych</h4>
          ) : (
            <>
              <h4>Wybrane opcje dodatkowe</h4>
              <table className="detailsBox">
                <tr>
                  <td className="firstColumn">{el[1].map((el) => el[0].slice(18, el[0].length))}</td>
                  <td className="secondColumn">{el[1].map((el) => el[1].slice(14, el[1].length))}</td>
                </tr>
              </table>
            </>
          )}

          {!reservationArr.ReserveList &&
            reservationArr.Owner &&
            !reservationArr.IsHistorical &&
            reservationArr.Status !== "Anulowana" && (
              <div className="btnBox">
                <button
                  className="primaryBtn"
                  onClick={(event) => handleAllowedAddons(userNumParticipant[index], event)}
                >
                  Zmień opcje
                </button>
              </div>
            )}
          {userNumParticipant[index] === userNum && <p className="errorMessage">{errorMessageParametersGetAddons}</p>}
          {addonsDetails && userNumParticipant[index] === userNum && (
            <>
              <h4 style={{marginTop: "40px"}}>Zmień opcje dodatkowe</h4>
              <div className="containerSelect">
                <select onChange={(event) => handleChangeAddons(addonsConfig[index].userNum, event)}>
                  <option className="placeholderList" selected="true" disabled="disabled">
                    Wybierz z listy
                  </option>
                  {parameters?.AddonsList?.[1]?.map(
                    ({Addon}) =>
                      Addon.Available && (
                        <>
                          <option
                            name={userNum}
                            value={Addon.AddonId}
                            selected={
                              addonsConfig[index]?.addonsSelected?.[0] &&
                              addonsConfig[index]?.addonsSelected?.[0]?.addonId
                                ? Addon.AddonId === addonsConfig[index].addonsSelected[0].addonId
                                : null
                            }
                          >
                            {Addon.Name}
                          </option>
                        </>
                      )
                  )}
                </select>
              </div>
              {addonsConfig[index].userNum === userNum && (
                <div className="containerSelect">
                  <select onChange={(event) => handleChangeAddonsOption(addonsConfig[index].userNum, event)}>
                    <option
                      className="placeholderList"
                      selected={
                        (addonsConfig[index]?.addonsSelected?.[0] &&
                          addonsConfig[index].addonsSelected[0].addonOptionId === "0") ||
                        addonsConfig[index].addonsSelected[0].addonOptionId === ""
                          ? true
                          : null
                      }
                      disabled="disabled"
                    >
                      Wybierz z listy
                    </option>
                    {parameters?.AddonsList?.[1]?.map(
                      ({Addon}) =>
                        addonsConfig[index]?.addonsSelected?.[0] &&
                        addonsConfig[index]?.addonsSelected[0]?.addonId &&
                        Addon.AddonId === addonsConfig[index]?.addonsSelected[0]?.addonId &&
                        Addon.OptionsList.map(
                          ({Available, AddonOptionId, Name}) =>
                            Available && (
                              <>
                                <option
                                  name={AddonOptionId}
                                  value={AddonOptionId}
                                  selected={
                                    addonsConfig[index]?.addonsSelected[0] &&
                                    addonsConfig[index]?.addonsSelected[0]?.addonId
                                      ? AddonOptionId === addonsConfig[index].addonsSelected[0].addonOptionId
                                      : null
                                  }
                                >
                                  {Name}
                                </option>
                              </>
                            )
                        )
                    )}
                  </select>
                  <div className="btnBox" style={{display: "flex"}}>
                    <button
                      className="primaryBtn"
                      style={{margin: "auto 20px"}}
                      onClick={(event) => handleSubmitParametersAddons(addonsConfig[index].userNum, event)}
                    >
                      Zapisz zmiany
                    </button>
                    <button className="primaryBtn" style={{margin: "auto 20px"}} onClick={handleCancelAddons}>
                      Anuluj
                    </button>
                    <button
                      className="primaryBtn"
                      style={{margin: "auto 20px"}}
                      onClick={() => handleDeleteAddon(userNumParticipant[index])}
                    >
                      Usuń dodatek
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
          {userNumParticipant[index] === userNum && addonsMessage && (
            <>
              <p style={{color: colorMessage, marginLeft: "30px"}}>{firstMessage}</p>
              <p style={{color: colorMessage, marginLeft: "30px"}}>{secondMessage}</p>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default AttractionWrapper;
