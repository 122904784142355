import React from "react";
import "./Messages.scss";
import Moment from "moment";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import ReactHtmlParser from "react-html-parser";

const Messages = ({messagesActive, Messages}) => {
  return (
    messagesActive && (
      <div id="messages">
        <h2>Wiadomości</h2>
        {Messages.length === 0 ? (
          <div className="greenBox">
            <p className="infoTitle">Nie masz jeszcze żadnych wiadomości</p>
          </div>
        ) : (
          <table className="messagesTable">
            <thead>
              <tr className="tableHead">
                <th className="sender">
                  <div></div>
                  <span>Nadawca</span>
                </th>
                <th className="subject">
                  <span>Temat</span>
                </th>
                <th className="date">
                  <span>Data</span>
                </th>
              </tr>
            </thead>
            <div className="desktopArray">
              <Accordion allowZeroExpanded>
                {Messages.map(({Author, WasRead, Title, Related, CreatedTime, Contents, Attachments}) => (
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <tbody>
                          <tr>
                            <td data-label="Nadawca" className="sender">
                              <div className="senderBox">
                                <div className={WasRead ? "led-box" : "led-red"}></div>
                                <span>{Author}</span>
                              </div>
                            </td>
                            <td data-label="Temat" className="subject">
                              <div className="subjectBox">
                                <span>{Title}</span>
                                <div className="titleMessages">{Related}</div>
                              </div>
                            </td>
                            <td data-label="Data" className="date">
                              <span>{Moment(CreatedTime).format("DD.MM.YYYY")}</span>
                            </td>
                          </tr>
                        </tbody>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      {ReactHtmlParser(Contents)}
                      {Attachments &&
                        Attachments?.map(({name, url}) => (
                          <div className="attachmentWrapper">
                            <p>{name}</p>
                            <a href={url} target="_blank" title="Pobierz plik" rel="noreferrer">
                              <button className="primaryBtn">POBIERZ PLIK</button>
                            </a>
                          </div>
                        ))}
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>

            <div className="mobileArray">
              <Accordion allowZeroExpanded>
                {Messages.map(({Title, WasRead, Author, Related, CreatedTime, Attachments, Contents}) => (
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <tbody>
                          <tr>
                            <td className="subject">
                              <div className="subjectBox">
                                <span>{Title}</span>
                                <div className="senderBox">
                                  <div className={WasRead ? "led-box" : "led-red"}></div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <div className="titleBox">
                        <p className="sender">
                          <span>{Author}</span>
                        </p>
                        <div className="titleMessages">{Related}</div>
                      </div>
                      <p className="date">
                        <span>{Moment(CreatedTime).format("DD.MM.YYYY")} </span>
                      </p>
                      <div>{ReactHtmlParser(Contents)}</div>
                      {Attachments &&
                        Attachments?.map(({name, url}) => (
                          <div className="attachmentWrapper">
                            <p>{name}</p>
                            <a href={url} target="_blank" title="Pobierz plik" rel="noreferrer">
                              <button className="primaryBtn">POBIERZ PLIK</button>
                            </a>
                          </div>
                        ))}
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          </table>
        )}
      </div>
    )
  );
};

export default Messages;
