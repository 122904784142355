import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk';



const websiteReducer = (state = {fetchedUrls:{}, urlStatus:{}}, action) => {

    switch(action.type){
        case 'FETCH_DATA':
            return {
                ...state,
                fetchedUrls: {
                    ...state.fetchedUrls,
                    [action.url]: action.body
                }
            }
        case 'UPDATE_STATUS':
            return {
                ...state,
                urlStatus: {
                    ...state.urlStatus,
                    [action.url]: action.status
                }
            }
        default:
            return state;
    }
}
export const getStoreServer = () => createStore(websiteReducer, applyMiddleware(thunk))
let store = createStore(websiteReducer, applyMiddleware(thunk));
if(global?.window){
    store = createStore(websiteReducer, global?.window.__PRELOADED_STATE__, applyMiddleware(thunk));
    delete global?.window.__PRELOADED_STATE__;
}

export default store;