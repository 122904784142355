import React from "react";
import "./SecureBooking.scss";

const SecureBooking = ({desc, image, alt}) => {
  return (
    <div className="secureBookingItem">
      <img src={image} alt={alt} />
      <p>{desc}</p>
    </div>
  );
};

export default SecureBooking;
