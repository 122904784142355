import React, {PureComponent} from "react";

// eslint-disable-next-line import/no-anonymous-default-export
export default function (WrappedComponent) {
  class HOC extends PureComponent {
    static displayName = `HOC(${WrappedComponent.displayName || WrappedComponent.name || "Component"})`;

    render = () => {
      const {forwardedRef, loading, ...props} = this.props;
      if (loading) {
        return <div className="loader">Loading...</div>;
      }
      return <WrappedComponent ref={forwardedRef} {...props} />;
    };
  }

  return React.forwardRef((props, ref) => <HOC {...props} forwardedRef={ref} />);
}
