import React from "react";
import Slider from "react-slick";
import InstagramFeed from "./InstagramFeed";
import "./InstagramFeedWrapper.scss";
import image1 from "./img/instagram-1.jpg";
import image2 from "./img/instagram-2.jpg";
import image3 from "./img/instagram-3.jpg";
import image4 from "./img/instagram-4.jpg";
import image5 from "./img/instagram-5.jpg";

const InstagramFeedArr = [
  {
    image: image1,
    alt: "Rejsy morskie jachtem",
    link: "https://www.instagram.com/p/CLKEM1qI1PL/",
  },
  {
    image: image2,
    alt: "Rejsy morskie jachtem",
    link: "https://www.instagram.com/p/CKeTCcSIgeL/",
  },
  {
    image: image3,
    alt: "Rejsy morskie jachtem",
    link: "https://www.instagram.com/p/BxzlMsNAouj/",
  },
  {
    image: image4,
    alt: "Rejsy morskie jachtem",
    link: "https://www.instagram.com/p/Bl7RnYvHJwj/",
  },
  {
    image: image5,
    alt: "Rejsy morskie jachtem",
    link: "https://www.instagram.com/p/Bnjxp4yHlCE/",
  },
];

const InstagramFeedWrapper = () => {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div id="instagramFeedWrapper">
      <div className="instagramFeedTitle">
        <h2>Obserwuj nas w mediach społecznościowych</h2>
      </div>
      <Slider {...settings}>
        {InstagramFeedArr.map(({image, alt, link}, index) => (
          <InstagramFeed key={index} image={image} link={link} alt={alt} />
        ))}
      </Slider>
      <div className="buttonWrapper">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/theboattrip/"
          class="buttonInstagram"
          title="Obserwuj nasz profil na instagramie!"
        >
          <button class="button primaryBtn">Follow us on Instagram</button>
        </a>
      </div>
    </div>
  );
};

export default InstagramFeedWrapper;
