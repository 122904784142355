import React from 'react';
import "./Team.scss"
import paulaImage from "./img/paula.jpg";
import paulinaImage from "./img/paulina.jpg";
import kamilImage from "./img/kamil.jpg";
import pawelImage from "./img/pawel.jpg";
import michalImage from "./img/michal.jpg";
import piotrImage from "./img/piotr.jpg";
import michal2Image from "./img/michal2.jpg";
import martynaImage from "./img/martyna.png";
import teamCover from "./img/team-cover-1.jpg";
import { Helmet } from "react-helmet";

const TeamArr = [
    {
        name: "Paula",
        description: "",
        image: paulaImage,
    },
    {
        name: "Paulina",
        description: "",
        image: paulinaImage,
    },
    {
        name: "Kamil",
        description: "",
        image: kamilImage
    },
    {
        name: "Paweł",
        description: "",
        image: pawelImage
    },
    {
        name: "Michał",
        description: "",
        image: michalImage
    },
    {
        name: "Piotr",
        description: "",
        image: piotrImage
    },
    {
        name: "Michal",
        description: "",
        image: michal2Image
    },
    {
        name: "Martyna",
        description: "",
        image: martynaImage
    },
    // {
    //     name: "Adam",
    //     description: "",
    //     image: "./img/adam.jpg"
    // }


]
class Team extends React.Component {

    state = {
        isLoading: true
    }


    componentDidMount = () => {
        this.setState({
            isLoading: false
        })
    }
    
    render() {
        if (this.state.isLoading) {

            return <div class="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>;
    
        }
        return (
            <div id="team">
                <Helmet>
                    <title>Nasz team</title>
                    <meta name="description"
                        content="Poznaj ludzi, którzy tworzą dla Ciebie niepowtarzalne wyjazdy."
                        data-react-helmet="true" />
                         <meta name="keywords" content="zespół, ludzie, theboattrip, rejsy" />
                </Helmet>
                <div className="slider">
                    <img src={teamCover} alt="Nasz zespół" />
                    <h2>Ludzie</h2>
                </div>
                <div className="info" >
                    <div className="container">

                        <div className="textSection">
                            <h1>Nasz zespół</h1>
                            <p>The Boat Trip to dynamiczny zespół młodych ludzi pełnych pozytywnej energii,
                            pasjonujących się żeglarstwem i aktywnym wypoczynkiem. Oficjalnie jako marka
                            działamy na rynku od 2011 roku, ale dużo wcześniej organizowaliśmy rejsy dla
                                     siebie i znajomych - zamieniliśmy naszą pasję w sposób na życie.</p>
                        </div>

                    </div>
                    <div className="boxes">

                        {TeamArr.map((el) =>
                            <div className="oneBox">
                                <div className="imageBox">
                                    <img src={el.image} alt={el.name} ></img>
                                </div>
                                <div className="text">
                                    <p className="name">{el.name}</p>
                                    <p className="description">{el.description}</p>
                                </div>
                            </div>)}


                    </div>
                </div>

            </div>
        );
    }
}

export default Team;