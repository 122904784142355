import React from "react";
import "./Country.scss";
import TransportInfo from "./TransportInfo.js";
import Questions from "./Questions.js";
import InfoDestination from "./InfoDestination.js";
import DetailedInformation from "./DetailedInformation.js";
import CruiseList from "./CruiseList.js";
import GalleryCountry from "./GalleryCountry.js";
import {getDestinationCachedUrlName} from "./cachedStoreUrls";
import {fetchData} from "./store/actions";
import {connect} from "react-redux";
import {Link, Element, Events, animateScroll as scroll, scrollSpy} from "react-scroll";
import "./FilteredTrips.scss";
import APIbackend from "./config.js";
import {Helmet} from "react-helmet";

export const prefetchMethod = (dispatch, match) => {
  const countryName = match.params.countryName;
  const cachedDestinationName = getDestinationCachedUrlName(countryName);
  return dispatch(
    fetchData(
      "/v1/data/page",
      {
        siteId: 1,
        pageId: countryName,
      },
      cachedDestinationName
    )
  );
};

const enhance = connect(
  (state, ownProps) => {
    const countryName = ownProps.match.params.countryName;
    const cachedDestinationName = getDestinationCachedUrlName(countryName);

    return {
      Page: state.fetchedUrls[cachedDestinationName]?.Page || {},
      countryName,
      cachedDestinationName,
    };
  },
  {fetchData}
);

let elementToScroll = {};

const scrollToElement = (name) => {
  if (elementToScroll[name]) {
    elementToScroll[name].scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }
};

const setElementToScroll = (name) => (element) => {
  elementToScroll = {
    ...elementToScroll,
    [name]: element,
  };
};

class Country extends React.Component {
  state = {
    List: [],
  };

  componentDidMount() {
    this.props.fetchData(
      "/v1/data/page",
      {
        siteId: 1,
        pageId: this.props.countryName,
      },
      this.props.cachedDestinationName
    );

    Events.scrollEvent.register("begin", function () {});

    Events.scrollEvent.register("end", function () {});

    scrollSpy.update();

    fetch(`${APIbackend}v1/offers/list`, {
      method: "POST",
      body: JSON.stringify({
        siteId: 1,
        filterCountry: [],
        filterOptions: [],
        filterType: [],
        filterTerm: [],
      }),
    })
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          List: data.List,
        })
      );

    fetch(`${APIbackend}v1/offers/filters`, {
      method: "POST",
      body: JSON.stringify({
        siteId: 1,
      }),
    }).then((response) => {
      response.json().then((data) => {
        this.setState({
          Types: data.Types,
          Countries: data.Countries,
          Terms: data.Terms,
          Options: data.Options,
        });
      });
    });
  }

  componentWillUnmount = () =>
    function () {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };

  scrollToTop = () =>
    function () {
      scroll.scrollToTop();
    };
  scrollToBottom = () =>
    function () {
      scroll.scrollToBottom();
    };
  scrollTo = () =>
    function () {
      scroll.scrollTo(100);
    };
  scrollMore = () =>
    function () {
      scroll.scrollMore(100);
    };
  handleSetActive = () => function (to) {};

  render() {
    const {Page} = this.props;
    const {List} = this.state;
    const contentPage = Page.Contents;
    const arrWithTextImages = contentPage?.filter(({Type}) => Type === "TextWithImages");
    const sliderObject = contentPage !== undefined ? arrWithTextImages?.shift() : null;
    const sliderText = sliderObject?.Contents?.Text;
    const faqArr = contentPage?.filter(({Type}) => Type === "TextWithImages")[8]?.Contents.Text.split("\r\n");
    const transportArr = contentPage?.filter(({Type}) => Type === "TextWithImages")[9]?.Contents.Text.split("\r\n");
    const transportImages = contentPage?.filter(({Type}) => Type === "TextWithImages")[9]?.Contents.Images;
    const galleryArr1 = contentPage?.filter(({Type}) => Type === "MiniBoxes")[0]?.Contents;
    const galleryArr2 = contentPage?.filter(({Type}) => Type === "MiniBoxes")[1]?.Contents;
    const countryFilter = contentPage?.filter(({Type}) => Type === "HTML")[0]?.Contents?.HTML;
    const listArr = List.filter(
      ({Availability, Region}) => Availability.Available && Region.Country.CountryId === countryFilter
    );
    const metaData = Page.META;

    return (
      Object.keys(Page)?.length !== 0 && (
        <>
          <Helmet>
            <title>{metaData?.Title}</title>
            <meta name="description" content={metaData?.Description} data-react-helmet="true" />
          </Helmet>
          <div id="country">
            <div className="slider">
              <img src={sliderObject?.Contents?.Images[0]?.url} alt={sliderObject?.Contents?.Images[0]?.alt} />
              <div className="textContainer">
                <h1>{sliderText}</h1>
                {countryFilter && (
                  <button
                    className="primaryBtn"
                    key="Wyjazdy"
                    onClick={() => scrollToElement(`Wyjazdy`)}
                    title="Sprawdź nasze rejsy"
                  >
                    Sprawdź nasze rejsy
                  </button>
                )}
              </div>
            </div>
            {arrWithTextImages.length !== 0 && (
              <div className="menuPage">
                <div className="container">
                  <Link
                    activeClass="active"
                    to="infoDestination"
                    spy={true}
                    smooth={true}
                    offset={-80}
                    duration={500}
                    onSetActive={this.handleSetActive}
                  >
                    Informacje ogólne
                  </Link>
                  {listArr.length !== 0 && (
                    <Link
                      activeClass="active"
                      to="cruiseList"
                      spy={true}
                      smooth={true}
                      offset={-80}
                      duration={500}
                      onSetActive={this.handleSetActive}
                    >
                      Lista rejsów
                    </Link>
                  )}
                  {arrWithTextImages?.[1] && (
                    <Link
                      activeClass="active"
                      to="detailedInformation"
                      spy={true}
                      smooth={true}
                      offset={-80}
                      duration={500}
                      onSetActive={this.handleSetActive}
                    >
                      Ciekawostki
                    </Link>
                  )}
                  {arrWithTextImages?.[2] && (
                    <Link
                      activeClass="active"
                      to="questions"
                      spy={true}
                      smooth={true}
                      offset={-80}
                      duration={500}
                      onSetActive={this.handleSetActive}
                    >
                      Częste Pytania
                    </Link>
                  )}
                  {arrWithTextImages?.[3] && (
                    <Link
                      activeClass="active"
                      to="transportInfo"
                      spy={true}
                      smooth={true}
                      offset={-80}
                      duration={500}
                      onSetActive={this.handleSetActive}
                    >
                      Dojazd
                    </Link>
                  )}
                  {galleryArr1 && (
                    <Link
                      activeClass="active"
                      to="gallery"
                      spy={true}
                      smooth={true}
                      offset={-80}
                      duration={500}
                      onSetActive={this.handleSetActive}
                    >
                      Galeria
                    </Link>
                  )}
                </div>
              </div>
            )}
            <>
              {arrWithTextImages?.[0] && (
                <Element name="infoDestination" className="element">
                  <InfoDestination infoDestination={arrWithTextImages} countryName="Chorwacja" />
                </Element>
              )}
              {listArr.length !== 0 && (
                <div ref={setElementToScroll("Wyjazdy")}>
                  <Element name="cruiseList" className="element">
                    <CruiseList List={this.state.List} heading={sliderText} countryFilter={countryFilter} />
                  </Element>
                </div>
              )}
              {arrWithTextImages?.[1] && (
                <Element name="detailedInformation" className="element">
                  <DetailedInformation detailedInfo={arrWithTextImages} valueCountry="Chorwacja" />
                </Element>
              )}
              {arrWithTextImages?.[2] && (
                <Element name="questions" className="element">
                  <Questions faqArr={faqArr} />
                </Element>
              )}
              {arrWithTextImages?.[3] && (
                <Element name="transportInfo" className="element">
                  <TransportInfo transportImages={transportImages} transportArr={transportArr} />
                </Element>
              )}
              {galleryArr1 && (
                <Element name="gallery" className="element">
                  <GalleryCountry galleryArr1={galleryArr1} galleryArr2={galleryArr2} />
                </Element>
              )}
            </>
          </div>
        </>
      )
    );
  }
}

Country.prefetchMethod = prefetchMethod;

export default enhance(Country);
