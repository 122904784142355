export const setUrlStatus = (url, status)=>{
    return {
        type: 'UPDATE_STATUS',
        url,
        status
    }
}

export const setDataFromServer = (url, body)=>{
    return {
        type: 'FETCH_DATA',
        url,
        body,
    }
}

export const  fetchData = (urlSuffix, body = {}, memoUrl = '', ignoreCache = false)=> {
    return (dispatch, getState) => {

    const cachedUrlName = memoUrl || urlSuffix;

    const isDataFetched = getState().urlStatus[cachedUrlName] === 'done';
    const isDataFetching = getState().urlStatus[cachedUrlName] === 'pending';
    if((isDataFetched && ignoreCache === false) || isDataFetching){
        return null;
    }

    dispatch(setUrlStatus(cachedUrlName, 'pending'));
    return fetch(`https://api.theboattrip.eu${urlSuffix}`, {
        method: "POST",
        body: JSON.stringify(body),
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(`${response.status}`);
            }
            return response.json();
        })
        .then((data) => {
            dispatch(setDataFromServer(cachedUrlName, data));
            dispatch(setUrlStatus(cachedUrlName, 'done'))
        }).catch((status)=>{
            dispatch(setUrlStatus(cachedUrlName, 'error'))
        });
  }
}

export const fetchPosts = async (urlSuffix, memoUrl = '', ignoreCache = false)=> {
    return async (dispatch, getState) => {

    const cachedUrlName = memoUrl || urlSuffix;

    const isDataFetched = getState().urlStatus[cachedUrlName] === 'done';
    const isDataFetching = getState().urlStatus[cachedUrlName] === 'pending';
    if((isDataFetched && ignoreCache === false) || isDataFetching){
        return null;
    }

    dispatch(setUrlStatus(cachedUrlName, 'pending'));
    const response = await fetch(`https://blog.theboattrip.eu/wp-json/wp/v2/posts`).catch((status)=>{
        dispatch(setUrlStatus(cachedUrlName, 'error'))
    })
    if (!response.ok) {
        throw new Error(`${response.status}`);
    }
    const posts = await response.json();
    const postsWithMedia = await Promise.all(posts.map((async (post) => {
        const response = await fetch( `https://blog.theboattrip.eu/wp-json/wp/v2/media/${post.featured_media}`);
        const media = await response.json();
        const resizedImg = media.media_details?.sizes['travelera-featured']?.source_url;
        return {
            ...post,
            featured_media: resizedImg || media.source_url,
        }
        })))

    dispatch(setDataFromServer(cachedUrlName, postsWithMedia));
    dispatch(setUrlStatus(cachedUrlName, 'done'))
  }
}

export const fetchPostBySlug = async (urlSuffix, memoUrl = '', ignoreCache = false)=> {
    return async (dispatch, getState) => {

    const cachedUrlName = memoUrl || urlSuffix;

    const isDataFetched = getState().urlStatus[cachedUrlName] === 'done';
    const isDataFetching = getState().urlStatus[cachedUrlName] === 'pending';
    if((isDataFetched && ignoreCache === false) || isDataFetching){
        return null;
    }

    dispatch(setUrlStatus(cachedUrlName, 'pending'));
    const response = await fetch(`https://blog.theboattrip.eu/wp-json/wp/v2/posts${urlSuffix}`).catch((status)=>{
        dispatch(setUrlStatus(cachedUrlName, 'error'))
    })
    if (!response.ok) {
        throw new Error(`${response.status}`);
    }
    const posts = await response.json();

    dispatch(setDataFromServer(cachedUrlName, posts[0]));
    dispatch(setUrlStatus(cachedUrlName, 'done'))
  }
}