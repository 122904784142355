import React from 'react';
import "./PaymentsInfo.scss"

const PaymentsIncluded = (props) => {

    return (
        <div id="paymentsIncluded" ref={props.scrollDiv}>
            <div className="container price" ref={props.scrollDiv}>
                <h2>Co zawiera cena rejsu?</h2>
                <div className="boxes">
                    <div className="oneBox priceInclude">
                        <div className="titleContainer"><h4>Cena zawiera</h4></div>
                        <div className="elements">
                            <p>Miejsce na jachcie / kabina na jachcie / jacht</p>
                            <p>Opieka i prowadzenie jachtu przez profesjonalnego skippera</p>
                            <p>Mapy, pomoce nawigacyjne</p>
                            <p>Wyposażenie jachtu</p>
                            <p>Ponton do jachtu</p>
                            <p>Ubezpieczenie</p>
                            <p>Wstęp na nasze imprezy</p>
                            <p>Koszulki wyjazdowe</p>
                        </div>
                        {/* <div className="infoBox">
                            <p>Zaliczka wynosi 100 PLN zapewniamy możliwość bezskosztowej rezygnacji na 30 dni roboczych przed rejsem.</p>
                        </div> */}

                    </div>
                    <div className="oneBox priceNotInclude">
                        <div className="titleContainer"><h4>Cenia nie zawiera</h4></div>
                        <div className="elements">
                            <p>Dojazd</p>
                            <p>Wyżywienie, alkohol i restauracje</p>
                            <p>Paliwo do jachtu</p>
                            <p>Opłaty portowe</p>
                            <p>Taksa turystyczna</p>
                            <p>Transit log (końcowe sprzątanie jachtu, pościel, ręczniki, butla z gazem)</p>
                            <p>Kaucja jachtu lub ubezpieczenie kaucji (przeczytaj więcej w FAQ)</p>
                            {/* <p>Wyżywienie i napiwki dla skippera i hostessy</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}



export default PaymentsIncluded;







