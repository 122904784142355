import React from "react";
import "./Registration.scss";
import politykaPrywatnosci from "./documents/polityka-prywatnosci.pdf";
import regulaminTaksidi from "./documents/regulamin-taksidi.pdf";
import {Link} from "react-router-dom";
import APIbackend from "../config.js";

class Registration extends React.Component {
  state = {
    email: "",
    password: "",
    policyAccepted: false,
    newsletterAccepted: false,
    policyAcceptedIncorrect: "",
    newsletterAcceptedIncorrect: "",
    incorrectRegistrationMessage: "",
    siteId: 1,
    loginActive: false,
    registrationActive: true,
    resetActive: false,
    type: "password",
    confirm: true,
    token: "",
    redirectReset: false,
    redirectLogin: false,
    trueMessage: "",
    errorMessage1: "",
    errorMessage2: "",
    secondTrueMessage: "",
    errorMessage3: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeRegistration = (e) => {
    if (e.target.type === "checkbox") {
      this.setState({
        [e.target.name]: e.target.checked,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  handleClick = () => {
    this.setState(({type}) => ({
      type: type === "password" ? "text" : "password",
    }));
  };

  handleSubmitRegistration = (e) => {
    e.preventDefault();
    const {email, password, policyAccepted, newsletterAccepted, siteId} = this.state;
    const data = {
      email: email,
      password: password,
      policyAccepted: policyAccepted,
      newsletterAccepted: newsletterAccepted,
      siteId: siteId,
    };

    fetch(`${APIbackend}v1/account/create`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    }).then((response) =>
      response.json().then((responseData) => {
        if (response.status === 200) {
          this.setState({
            registrationActive: true,
            loginActive: false,
            confirm: true,
            trueMessage: "Na twojego maila został wysłany link aktywacyjny",
            emailIncorrect: "",
            passwordIncorrect: "",
            policyAcceptedIncorrect: "",
            newsletterAcceptedIncorrect: "",
          });
        } else if (response.status === 400) {
          this.setState({
            loginActive: false,
            registrationActive: true,
          });

          if (responseData.details.email === "Wartość pola: AccountCreate_ValueName_email jest pusta") {
            this.setState({
              emailIncorrect: "Uzupełnij pole email",
            });
          } else if (responseData.details.email === "Podany adres e-mail ma niepoprawny format") {
            this.setState({
              emailIncorrect: "Podany adres ma niepoprawny format",
            });
          } else {
            this.setState({
              emailIncorrect: "",
            });
          }

          if (responseData.details.password === "Wartość pola: AccountCreate_ValueName_password jest pusta") {
            this.setState({
              passwordIncorrect: "Uzupełnij pole z hasłem",
            });
          } else if (responseData.details.password === "Podane hasło jest za krótkie. Minimalna ilość znaków: 6") {
            this.setState({
              passwordIncorrect: "Podane hasło jest za krótkie. Minimalna ilość znaków: 6",
            });
          } else {
            this.setState({
              passwordIncorrect: "",
            });
          }

          if (responseData?.details?.policyAccepted) {
            this.setState({
              policyAcceptedIncorrect: "#de1212",
            });
          } else {
            this.setState({
              policyAcceptedIncorrect: "",
            });
          }

          if (responseData?.details?.newsletterAccepted) {
            this.setState({
              newsletterAcceptedIncorrect: "#de1212",
            });
          } else {
            this.setState({
              newsletterAcceptedIncorrect: "",
            });
          }
        }
      })
    );
  };

  handleConfirm = (e) => {
    e.preventDefault();
    const dataToken = {
      token: this.props.match.params.token,
    };

    fetch(`${APIbackend}v1/account/confirm`, {
      method: "POST",
      body: JSON.stringify(dataToken),
      credentials: "include",
    }).then((response) =>
      response
        .json()

        .then(() => {
          if (response.status === 200) {
            this.setState({
              secondTrueMessage: "Twoje konto jest już aktywne",
              errorMessage3: "",
              token: "",
            });
          } else {
            this.setState({
              errorMessage3: "Link jest niepoprawny",
            });
          }
        })
    );
  };

  render() {
    const {
      registrationActive,
      email,
      emailIncorrect,
      type,
      password,
      policyAcceptedIncorrect,
      policyAccepted,
      newsletterAccepted,
      passwordIncorrect,
      newsletterAcceptedIncorrect,
      errorMessage1,
      errorMessage2,
      trueMessage,
    } = this.state;
    const {handleSubmitRegistration, handleChangeRegistration, handleClick} = this;
    return (
      <>
        {registrationActive && (
          <div id="registration">
            <div className="container">
              <div className="box">
                <h3>Zarejestruj się</h3>
                <div className="form">
                  <form onSubmit={handleSubmitRegistration} noValidate>
                    <p>E-mail</p>
                    <input
                      name="email"
                      onChange={handleChangeRegistration}
                      value={email}
                      type="email"
                      placeholder="Twój adres email"
                    ></input>
                    <div className="errorMessage">{emailIncorrect}</div>
                    <p>Hasło</p>
                    <div className="passwordBox">
                      <input
                        name="password"
                        onChange={handleChangeRegistration}
                        type={type}
                        value={password}
                        placeholder="Wpisz hasło"
                      ></input>
                      <span onClick={handleClick}>
                        {type === "password" ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
                      </span>
                    </div>
                    <div className="errorMessage">{passwordIncorrect}</div>
                    <div className="agree">
                      <label className="containerInputCheckbox">
                        <p className="textSmall" style={{color: policyAcceptedIncorrect}}>
                          Zapoznałem się z
                          <a
                            style={{color: policyAcceptedIncorrect}}
                            href={politykaPrywatnosci}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Polityka prywatności The Boat Trip"
                          >
                            Polityką Prywatności
                          </a>
                          i akceptuję
                          <a
                            href={regulaminTaksidi}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Przeczytaj regulamin strony"
                            style={{color: policyAcceptedIncorrect}}
                          >
                            Regulamin Strony
                          </a>
                        </p>
                        <input
                          type="checkbox"
                          name="policyAccepted"
                          checked={policyAccepted}
                          value={policyAccepted}
                          onChange={handleChangeRegistration}
                        ></input>
                        <span className="checkmark"></span>
                      </label>
                      <div className="errorMessage">{errorMessage1}</div>
                      <label className="containerInputCheckbox">
                        <p className="textSmall" style={{color: newsletterAcceptedIncorrect}}>
                          Wyrażam zgodę na otrzymywanie od Taksidi Sp. z o.o. z siedzibą w Górze Kalwarii drogą
                          elektroniczną informacji handlowej w celu przedstawienia mi ofert organizowanych wyjazdach
                          oraz eventach i promocjach.
                        </p>
                        <input
                          type="checkbox"
                          name="newsletterAccepted"
                          checked={newsletterAccepted}
                          onChange={handleChangeRegistration}
                          value={newsletterAccepted}
                        ></input>
                        <span className="checkmark"></span>
                      </label>
                      <div className="errorMessage">{errorMessage2}</div>
                    </div>
                    <div className="btnBox">
                      <button className="primaryBtn">Zarejestruj się</button>
                    </div>
                    <div className="trueMessage">{trueMessage}</div>
                  </form>
                </div>
              </div>
              <div className="box two">
                <h3>Jesteś już zarejestrowany?</h3>
                <div className="btnBox">
                  <Link to="/zaloguj" title="Zaloguj">
                    <button type="button" className="primaryBtn">
                      zaloguj się
                    </button>
                  </Link>
                </div>
                <div className="redBox">
                  <p className="infoText">Uwaga! Masz konto na The Boat Trip?</p>
                  <p>
                    Jeżeli tak, to skorzystaj z opcji
                    <Link to="/reset-hasła" title="Resetuj hasło">
                      <span>zapomniałem hasła</span>
                    </Link>{" "}
                    podając ten sam email. Konta na Taksidi i na The Boat Trip są połączone. Jedynie w ten sposób
                    poprawnie naliczą Ci się zniżki lojalnościowe na Taksidi.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Registration;
