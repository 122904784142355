import React from "react";
import "./OfferAddInfo.scss";
import ReactHtmlParser from "react-html-parser";
import skippers10 from "../img/skippers10.jpg";

const OfferAddInfoBox = ({offerHTML}) => {
  return (
    <>
      <div id="addHtml">
        <div className="addHtml3">
          <p className="paragraph">{ReactHtmlParser(offerHTML.HTML3)}</p>
          <div className="image">
            <img src={skippers10} alt="Skipper" />
          </div>
        </div>
      </div>
    </>
  );
};

export default OfferAddInfoBox;
