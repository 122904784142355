
import React from 'react';
import './Contact.scss';
import "./ContactPage.scss";
import "./Location.scss";
import ReactHtmlParser from 'react-html-parser';

const Location = (props) => {

    return (
        <div id="location">
            <div className="wrapper">
                <div className="wrapperInfo">
                    {props.Page.Contents !== undefined ?
                        <iframe title={"Mapa"} alt="Mapa" src={props.findHTMLPageHTML[2].Contents.HTML}
                            style={{ border: 0, width: "100%", height: "100%" }}></iframe> : null}
                </div>
                <div className="wrapperForm">
                    {props.Page.Contents !== undefined ? ReactHtmlParser(props.findHTMLPageHTML[1].Contents.HTML) : null}
                </div>
            </div>
        </div>
    );
}



export default Location
