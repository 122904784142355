import React from "react";
import "./TourDetails.scss";
import TourDetailsMenu from "./TourDetailsMenu";

const TourDetails = (props) => {
  const {reservationArr} = props;
  const nameParticipant = reservationArr.StepConfig.Step5.participantsConfig.map(({firstName, lastName}, index) => (
    <>{firstName === "" ? "Uczestnik " + (index + 1) : firstName + " " + lastName}</>
  ));
  const userNumParticipant = reservationArr.StepConfig.Step5.participantsConfig.map(({userNum}) => userNum);

  return (
    <div id="tourDetails">
      <div className="mainTitleTrip">
        <h2>
          {reservationArr.Name} {reservationArr.ReserveList && <span>LISTA REZERWOWA</span>}
        </h2>
        <div className="priceBox">
          <p className="price" style={{fontFamily: "SpaceGrotesk-SemiBold"}}>
            {reservationArr.Price.CurrentPrice} PLN
          </p>
          <p>Łączna cena Twojego wyjazdu</p>
        </div>
      </div>
      <TourDetailsMenu {...props} nameParticipant={nameParticipant} userNumParticipant={userNumParticipant} />
      <button className="secondaryBtn" style={{marginTop: "20px", marginLeft: "20px"}} onClick={props.showYourTrips}>
        Wróć do widoku wyjazdów
      </button>
    </div>
  );
};

export default TourDetails;
