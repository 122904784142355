import React, { useState } from 'react';
import './ReservationButton.scss';
import { Link } from 'react-router-dom';

const ReservationButton = ({ offerId, offerDetails }) => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 800) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 800) {
      setShowScroll(false);
    }
  };

  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', checkScrollTop);
  }
  return (
    <>
      {showScroll &&
        !offerDetails.Availability.GrayedOut &&
        offerDetails.Availability.Available && (
          <div id="reservationButtonWrapper">
            <Link
              to={{
                pathname: '/rezerwacja',
                state: {
                  id: offerId,
                },
              }}
              title="Rezerwuj"
            >
              <button id="reservationButtonStyled">REZERWUJ</button>
            </Link>
          </div>
        )}
    </>
  );
};

export default ReservationButton;
