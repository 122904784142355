import React, {useState} from "react";
import Tabs from "./Tabs";
import "./OfferResidences.scss";
import ReactHtmlParser from "react-html-parser";
import "./PopUpImageYachts.scss";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import OfferResidencesItem from "./OfferResidencesItem";

const Popup = ({image, toggle, classImage}) => {
  const popUpImage = image;
  return (
    <div className="popUpImageYachts" onClick={toggle} style={{cursor: "pointer"}}>
      <div className="popupInner">
        <button className="closeButton" onClick={toggle} style={{cursor: "pointer"}}>
          ✖
        </button>
        <img src={popUpImage?.url} className={classImage} alt={popUpImage?.alt} />
      </div>
    </div>
  );
};

function ifResortIsAvailable(resort) {
  return resort.RoomsList.some(({Availability}) => {
    return Availability.Available;
  });
}

const OfferResidences = ({
  activeElementIndex,
  setActiveElementIndex,
  accommodationArr,
  offerType,
  offerDetails,
  offerPrices,
  offerRegion,
}) => {
  const [showPopup, togglePopup] = useState(false);
  const [image, showImage] = useState("");
  const [classImage, changeClassImage] = useState("");

  function toggle(imageRoom, classImage) {
    togglePopup(!showPopup);
    showImage(imageRoom);
    changeClassImage(classImage);
  }

  const accommodationArrWithoutReservationList = accommodationArr.filter(({Name}) => !Name.includes("Lista"));
  const basePrice = parseInt(offerPrices?.Base);

  return (
    <>
      <div id="offerResidenceWrapper">
        <Tabs
          overwriteActiveIndex={activeElementIndex}
          clickNewIndex={(x) => {
            setActiveElementIndex(x);
          }}
          tabs={accommodationArrWithoutReservationList
            .filter(ifResortIsAvailable)
            .map(({Name, Description, Images, RoomsList}) => {
              return {
                title: <p>{Name}</p>,
                content: (
                  <div className="residencesTab">
                    {offerType.OfferTypeId !== "5" ? (
                      <>
                        <div className="offerResidenceRoomsWrapper">
                          {RoomsList.map(
                            ({Name, Price, Description, Images, Availability}) =>
                              Availability.Available && (
                                <>
                                  <OfferResidencesItem
                                    offerType={offerType}
                                    offerDetails={offerDetails}
                                    Availability={Availability}
                                    images0={Images[0]}
                                    price={Price}
                                    totalPrice={offerPrices}
                                    name={Name}
                                    description={ReactHtmlParser(Description)}
                                    offerRegion={offerRegion}
                                    images0a={() => toggle(Images[0])}
                                    images0b={Images[0]}
                                    images1a={() => toggle(Images[1])}
                                    images1b={Images[1]}
                                    images2a={() => toggle(Images[2])}
                                    images2b={Images[2]}
                                    images3a={() => toggle(Images[3])}
                                    images3b={Images[3]}
                                    images4a={() => toggle(Images[4], "planImage")}
                                    images4b={Images[4]}
                                  />
                                  {showPopup && (
                                    <Popup
                                      togglePopup={togglePopup}
                                      toggle={toggle}
                                      showImage={showImage}
                                      image={image}
                                      changeClassImage={changeClassImage}
                                      classImage={classImage}
                                    />
                                  )}
                                </>
                              )
                          )}
                        </div>
                        <div className="offerResidencesButton">
                          <a href="/jak-to-dziala">
                            <button
                              id="button"
                              class="primaryBtn"
                              title="Sprawdź jak wygląda organizacja naszych rejsów"
                            >
                              Zbierasz ekipę? Dowiedz się więcej o rezerwacji całego jachtu
                            </button>
                          </a>
                        </div>
                      </>
                    ) : (
                      <>
                        <div id="offerAccommodationGeneralWrapper">
                          <p className="descriptionAcademy">{ReactHtmlParser(Description)}</p>
                          <img src={Images[0]?.url} alt={Images[0]?.alt} />
                        </div>
                        {offerType.OfferTypeId === "5" ? (
                          <p style={!offerDetails.Name.includes("patent") ? {marginTop: "50px"} : null}></p>
                        ) : (
                          <h5 className="offerResidenceRoomsTitle">Jachty dostępne w tej klasie</h5>
                        )}

                        <div className="offerResidenceRoomsWrapper academy">
                          {RoomsList.map(
                            ({Name, Price, Description, Images, Availability}) =>
                              Availability.Available && (
                                <div className="offerResidenceRoom">
                                  <div className="offerResidenceRoomTitle">
                                    <h5>
                                      {Name}
                                      {parseInt(Price.Place) === 0 && <span>W cenie wyjazdu</span>}
                                      {parseInt(Price.Place) !== 0 && <span>+{Price.Place} PLN/osoba</span>}
                                    </h5>
                                  </div>
                                  {offerType.OfferTypeId !== "5" && <p>{ReactHtmlParser(Description)}</p>}
                                  <div style={{overflowX: "auto"}}>
                                    {offerType.OfferTypeId === "5" ? (
                                      <div className="offerResidenceRoomImagesWrapper">
                                        <img src={Images[0]?.url} alt={Images[0]?.alt} />
                                        <img src={Images[1]?.url} alt={Images[1]?.alt} />
                                      </div>
                                    ) : (
                                      <div className="offerResidenceRoomImagesWrapper">
                                        {Images.map((el) => (
                                          <img src={el?.url} alt={el?.alt} />
                                        ))}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      </>
                    )}
                  </div>
                ),
              };
            })}
        />
      </div>
      <div id="responsiveAccommodation">
        {accommodationArr.filter(ifResortIsAvailable).map(({RoomsList}) =>
          RoomsList.map(({Name, Price, Description, Images, Availability}) =>
            Availability.Available && !Name.includes("Lista") && (
              <Accordion allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="offerResidenceRoomTitle">
                        <h5>
                          {Name}
                          {parseInt(Price?.Whole) !== 0 ? (
                            <span>
                              <strong>{parseInt(Price?.Place) + basePrice}</strong> PLN/osoba lub{" "}
                              <strong>{parseInt(Price?.Whole)}</strong> PLN/jacht
                            </span>
                          ) : (
                            <span>
                              <strong>{parseInt(Price?.Place) + basePrice}</strong> PLN/osoba
                            </span>
                          )}
                        </h5>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <>
                      <OfferResidencesItem
                        offerType={offerType}
                        offerDetails={offerDetails}
                        Availability={Availability}
                        images0={Images[0]}
                        price={Price}
                        totalPrice={offerPrices}
                        name={Name}
                        description={ReactHtmlParser(Description)}
                        offerRegion={offerRegion}
                        images0a={() => toggle(Images[0])}
                        images0b={Images[0]}
                        images1a={() => toggle(Images[1])}
                        images1b={Images[1]}
                        images2a={() => toggle(Images[2])}
                        images2b={Images[2]}
                        images3a={() => toggle(Images[3])}
                        images3b={Images[3]}
                        images4a={() => toggle(Images[4], "planImage")}
                        images4b={Images[4]}
                      />

                      {showPopup && (
                        <Popup
                          togglePopup={togglePopup}
                          toggle={toggle}
                          showImage={showImage}
                          image={image}
                          changeClassImage={changeClassImage}
                          classImage={classImage}
                        />
                      )}
                    </>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            )
          )
        )}
      </div>
    </>
  );
};

export default OfferResidences;
