import React from "react";
import "./Login.scss";
import PanelMenu from "./PanelMenu.js";
import {Link} from "react-router-dom";
import ReservationTrip from "../ReservationTrip.js";

const Login = ({
  reservation,
  userPanelActive,
  loginActive,
  handleChange,
  handleClick,
  email,
  type,
  password,
  incorrectLoginMessage,
  handleLoginClick
}) => {
  return (
    <>
      {reservation && <ReservationTrip />}
      {userPanelActive && <PanelMenu />}
      {loginActive && (
        <div id="login">
          <div className="container">
            <div className="box one">
              <div className="greenBox">
                <p className="infoText">Czy masz już konto na The Boat Trip lub Taksidi?</p>
                <p>
                  Do złożenia rezerwacji konieczne jest posiadanie konta, ale nie martw się, jeżeli jeszcze go nie masz
                  - po drodze zapytamy Cię o wszystkie dane niezbędne do jego utworzenia.
                </p>
              </div>
            </div>
            <div className="box two">
              <h3>zaloguj się</h3>
              <div className="form">
                <form noValidate>
                  <p>E-mail</p>
                  <input
                    name="email"
                    onChange={handleChange}
                    value={email}
                    type="email"
                    placeholder="Twój adres email"
                  ></input>
                  <p>Hasło</p>
                  <div className="passwordBox">
                    <input
                      name="password"
                      onChange={handleChange}
                      type={type}
                      value={password}
                      placeholder="Wpisz hasło"
                    ></input>
                    <span onClick={handleClick}>
                      {type === "password" ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
                    </span>
                  </div>
                  <div className="errorMessage">{incorrectLoginMessage}</div>
                  <div className="linkPassword">
                    <Link to="/reset-hasła" title="Resetuj hasło">
                      <span>
                        Nie pamiętasz hasła?<i className="arrow"></i>
                      </span>
                    </Link>
                  </div>
                  <div className="btnBox">
                    <button className="primaryBtn" onClick={handleLoginClick}>
                      zaloguj się
                    </button>
                  </div>
                </form>
                <div className="btnBox2">
                  <Link to="/rejestracja" title="Zarejestruj się">
                    <button type="button" className="secondaryBtn">
                      Nie mam konta
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
