import React, { useContext } from 'react';
import './OfferCover.scss';
import OfferHeader from "../OfferHeader.js";
// import OfferTagBox from "../OfferTagBox.js";
import { DataDostawca } from "../DataDostawca";
import facebookImage from '../images/facebook-icon.png';

function OfferCover({ offerDetails, offerRegion, offerCountry, offerAvailability, offerType, offerRooms, offerPrices, offerClasses }) {
    const { setElementToScroll } = useContext(DataDostawca);

    return (
        <div className='mainOfferCoverWrapper' ref={setElementToScroll('header')}>
            <img id='mainOfferCoverImage' src={offerDetails.CoverImg?.url} alt={offerDetails.CoverImg?.alt} />
            <OfferHeader offerType={offerType} offerRooms={offerRooms} offerPrices={offerPrices} offerClasses={offerClasses} offerRegion={offerRegion} offerCountry={offerCountry} offerDetails={offerDetails} offerFrom={offerAvailability.From} offerTo={offerAvailability.To} />
            {/* <OfferTagBox offerType={offerType} offerCountry={offerCountry}  /> */}
            {(!offerDetails.Name.includes("Kurs") && offerDetails?.HTML?.HTML3 && offerDetails?.HTML?.HTML3.includes("https")) &&
                <div id="facebookEvent" className="containerButton" style={{ paddingBottom: "40px", cursor: "pointer" }}>
                    <button onClick={() => window.open(offerDetails.HTML.HTML3, '_blank')} className='primaryBtn' style={{ background: "white", color: "black", cursor: "pointer", display: "flex", alignItems: "center" }}>
                        <img className="facebookIcon" src={facebookImage} alt={{}}></img><span style={{ marginLeft: "10px", fontFamily: "SpaceGrotesk-SemiBold", cursor: "pointer" }}>Dołącz do wydarzenia</span></button>
                </div>
            }
        </div>
    )
}

export default OfferCover
