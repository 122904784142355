
import React from 'react';
import './userRegistration/Login.scss';
import { Link } from 'react-router-dom';
import ReservationTrip from "./ReservationTrip.js"

class LoginReservation extends React.Component {
    state = {
        offerId: this.props.location.state.id,
        nameTrip: this.props.location.state.nameTrip
    }

    render() {
        return (
            <>
                {this.props.userLogged === true || this.props.reservation === true ?
                    <ReservationTrip 
                    {...this.props}
                        email={this.props.email}
                        password={this.props.password}
                        incorrectLoginMessage={this.props.incorrectLoginMessage}
                        emailIncorrect={this.props.emailIncorrect}
                        passwordIncorrect={this.props.passwordIncorrect}
                        loginActive={this.props.loginActive}
                        reservation={this.props.reservation}
                        buttons={this.props.buttons}
                        userLogged={this.props.userLogged}
                        stepsNumber={this.props.stepsNumber}
                        type={this.props.type}
                        error={this.props.error}
                        handleChange={this.props.handleChange}
                        handleClick={this.props.handleClick}
                        handleLoginClick={this.props.handleLoginClick} /> :

                    <div id="login">
                        <div className="container">
                            <div className="box one">

                                {/* <div className="greenBox">
                                    <p className="infoText" >Rezerwacja</p>
                                    <p >Działamy, żeby udostępnić proces logowania i rezerwacji tak szybko jak to tylko możliwe. Prace mogą potrwać do kilku dni.</p>
                                    <p>Do złożenia rezerwacji konieczne jest posiadanie konta, ale nie martw się, jeżeli jeszcze go nie masz - po drodze zapytamy Cię o wszystkie dane niezbędne do jego utworzenia.</p>
                                </div> */}
                                 <div className="greenBox" 
                                // style={{ background: "#ffa50059" }}
                                >
                                    <p className="infoText"
                                    //  style={{ color: "orange" }}
                                     >Czy masz już konto na The Boat Trip lub Taksidi?</p>
                                    {/* <p className="infoText" style={{color: "orange"}}>UWAGA - trwają prace techniczne</p> */}
                                    <p>
                                        {/* Jeśli jesteś Klientem TBT, to musisz przed złożeniem rezerwacji dokonać RESETU HASŁA. */}
                                       {/* Jeśli jesteś Klientem TBT, to w dniu dzisiejszym 11.02 otrzymasz od Nas mail z zaproszeniem do resetu hasła. */}
                                        </p>
                                    {/* <p >Planowany start zapisów to poniedziałek 9.02. Prosimy do tego czasu wstrzymać się ze składaniem rezerwacji.</p>
                                    <p>Jeżeli miałeś konto na theboattrip.eu, prosimy o nie zakładanie nowego konta. Wkrótce wyślemy maila z linkiem do resetu hasła.
                                        <br></br>Twoje konto będzie dostępne w nowym systemie The Boat Trip.</p> */}

                                    <p>Do złożenia rezerwacji konieczne jest posiadanie konta, ale nie martw się, jeżeli jeszcze go nie masz - po drodze zapytamy Cię o wszystkie dane niezbędne do jego utworzenia.</p>
                                </div>
                          
                            </div>

                            <div className="box two">
                                <p>{this.props.secondTrueMessage !== undefined ? this.props.secondTrueMessage : null}</p>
                                <h3>Zaloguj się</h3>

                                <div className="form">
                                    <form noValidate>
                                        <p>E-mail</p>
                                        <input name="email" onChange={this.props.handleChange} value={this.props.email} type="email" placeholder="Twój adres email"></input>
                                        <p>Hasło</p>
                                        <div className="passwordBox"><input name="password" onChange={this.props.handleChange} type={this.props.type} value={this.props.password} placeholder="Wpisz hasło"></input>
                                            <span onClick={this.props.handleClick}>{this.props.type === 'password' ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}</span></div>
                                        <div className="errorMessage">{this.props.incorrectLoginMessage}</div>
                                        <div className="linkPassword"><Link to="/reset-hasła" title="Zresetuj hasło"><span>Nie pamiętasz hasła?<i className="arrow"></i></span></Link></div>
                                        <div className="btnBox"><button className="primaryBtn" onClick={this.props.handleLoginClick}>zaloguj się</button>
                                        </div>
                                    </form>
                                    <div className="btnBox2">
                                        <button type="button" className="secondaryBtn" onClick={this.props.handleReservation}>Nie mam konta</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

            </>
        );
    }
}


export default LoginReservation

