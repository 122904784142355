import React from "react";
import "./Adventure.scss";

const Adventure = ({image, alt, desc}) => {
  const adventureImage = image;
  return (
    <div className="adventureItem">
      <img src={adventureImage} alt={alt} />
      <p>{desc}</p>
    </div>
  );
};
export default Adventure;
