import React, { Component } from "react";
import "./Partner.scss";

class Partner extends Component {
    render() {
        const partnersImg = this.props.image;
        return (
            <div className="onePartner">
                <a className="imageLogo" href={this.props.page} title={this.props.title} target="_blank" rel="noopener noreferrer">
                    <img src={partnersImg} alt={this.props.alt} />
                </a>
            </div>
        );
    }
}

export default Partner;
