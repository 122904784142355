import React from "react";
import OfferTripMap from "./OfferTripMap";

function Resort({offerPortArr, offerRegion, offerDetails}) {
  if (offerPortArr[0] === undefined) return null;

  return (
    <div className="sectionResort">
      <OfferTripMap offerPortArr={offerPortArr} offerDetails={offerDetails} offerRegion={offerRegion} />
    </div>
  );
}

export default Resort;
