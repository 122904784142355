import React from "react";
import "./SailingTrainingLp.scss";
import "./userRegistration/Trips.scss";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import image1 from "./img/akademia-skipperów-cover.jpg";
import image2 from "./img/skippers5.jpg";
import image3 from "./img/skipper-image10.jpg";
import image4 from "./img/skippers6.jpg";
import image5 from "./img/szkolenia2.png";
import image6 from "./img/skippers.jpg";
import image7 from "./img/skippers3.jpg";
import image8 from "./img/skippers2.jpg";
import { fetchData } from "./store/actions";
import { sailingTrainingCachedUrl } from "./cachedStoreUrls";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

export const prefetchMethod = (dispatch, match) => {
  return dispatch(
    fetchData(
      "/v1/data/page",
      {
        siteId: 1,
        pageId: "academy",
      },
      sailingTrainingCachedUrl
    )
  );
};

const enhance = connect((state) => {
  return {
    Page: state.fetchedUrls[sailingTrainingCachedUrl]?.Page || {},
    IsLoading: true || false,
  };
});

class SailingTrainingLp extends React.Component {
  componentDidMount = () => {
    prefetchMethod(this.props.dispatch);
  };

  render() {
    const findHTMLPageText =
      this.props.Page.Contents !== undefined
        ? this.props.Page.Contents.filter((el) => el.Contents.Text)
        : null;
    const mainText =
      this.props.Page.Contents !== undefined
        ? findHTMLPageText[0].Contents.Text
        : null;
    const mainText2 =
      this.props.Page.Contents !== undefined
        ? findHTMLPageText[1].Contents.Text
        : null;
    const infoDayPlan =
      this.props.Page.Contents !== undefined
        ? findHTMLPageText[2].Contents.Text
        : null;
    const whyWorth =
      this.props.Page.Contents !== undefined
        ? findHTMLPageText[3].Contents.Text
        : null;
    const whyWorth2 =
      this.props.Page.Contents !== undefined
        ? findHTMLPageText[4].Contents.Text
        : null;
    const buttonWhyWorth2 =
      this.props.Page.Contents !== undefined
        ? findHTMLPageText[4].Contents.Buttons[0][0]
        : null;

    const findHTMLPageHTML =
      this.props.Page.Contents !== undefined
        ? this.props.Page.Contents.filter((el) => el.Contents.HTML)
        : null;
    const infoProgram =
      this.props.Page.Contents !== undefined
        ? findHTMLPageHTML[0].Contents.HTML
        : null;

    const metaData = this.props.Page?.META;

    return (
      <div id="sailingTrainingLp">
        <Helmet>
          <title>{metaData?.Title}</title>
          <meta
            name="description"
            content={metaData?.Description}
            data-react-helmet="true"
          />
        </Helmet>
        <div className="slider">
          <img src={image1} alt="Skipper TBT" />
        </div>
        <div className="info">
          <div className="container">
            <div className="firstSection">
              <div className="imageSection">
                <img src={image2} alt="Skipper TBT" />
              </div>
              <div className="textSection">{ReactHtmlParser(mainText)}</div>
            </div>
            <div className="secondSection">
              <div className="textSection">{ReactHtmlParser(mainText2)}</div>
              <div className="imageSection">
                <img
                  className="firstPhoto"
                  style={{ height: "280px" }}
                  src={image8}
                  alt="Skipper TBT"
                />
              </div>
            </div>

            <div className="asInfoContainer">
              {ReactHtmlParser(infoProgram)}
            </div>

            <div className="firstSection" id="DayPlan">
              <div className="imageSection" id="imgDayPlan">
                <img src={image3} alt="Skipper TBT" />
              </div>
              <div className="textSection plan">
                {ReactHtmlParser(infoDayPlan)}
              </div>
            </div>

            <h1>
              Dlaczego warto skorzystać ze szkoleń organizowanych przez nas pod
              marką TBT Academy?{" "}
            </h1>

            <div className="secondSection" id="whyIsWorth">
              <div className="textSection">{ReactHtmlParser(whyWorth)}</div>
              <div className="imageSection">
                <img className="firstPhoto" src={image4} alt="Skipper TBT" />
                <img className="secondPhoto" src={image5} alt="Skipper TBT" />
              </div>
            </div>

            <div className="secondSection" id="whyIsWorth">
              <div className="imageSection">
                <img className="firstPhoto" src={image6} alt="Skipper TBT" />
                <img className="secondPhoto" src={image7} alt="Skipper TBT" />
              </div>
              <div className="textSection">{ReactHtmlParser(whyWorth2)}</div>
              <Link to={buttonWhyWorth2} title="Wróć do listy szkoleń">
                <button className="secondaryBtn">Wróć do listy szkoleń</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SailingTrainingLp.prefetchMethod = prefetchMethod;

export default enhance(SailingTrainingLp);
