import React from 'react';
import "./PaymentsInfo.scss"
import logo from "./img/payu-image.png";
import imageHowItWorks from "./img/image1-HowItWorks.jpg";
import imageHowItWorks2 from "./img/image2-HowItWorks.jpg";
import imageHowItWorks3 from "./img/image4-HowItWorks.jpg";

const infoPayments = [
    {
        title: "Przelew",
        description: "Możesz zapłacić tradycyjnym przelewem pieniężnym w banku, na poczcie czy korzystając z bankowości mobilnej. Wystarczy, że w tytule podasz numer rezerwacji, imię i nazwisko uczestnika oraz nazwę wyjazdu. Możesz w ten sposób zapłacić za kogoś innego, albo ktoś może zapłacić za Ciebie. Wpłata pojawi się w Panelu Klienta po 2 dniach.",
        logo: ""
    },
    {
        title: "",
        description: "W ostatnim kroku rezerwacji będziesz mógł zapłacić automatycznie korzystając z PayU. Nie musisz robić tego od razu, możesz wrócić do płatności PayU w dowolnym momencie w swoim Panelu Klienta. Korzystając z PayU, możesz też skorzystać z rat PayU i rozłożyć płatność nawet na 36 rat! PayU doliczy 0.75% prowizji. Wpłatę od razu zobaczysz w Panelu Klienta.",
        logo: logo,
        altImage: "przelew na rejs przez payU"
    }
]

const PaymentsInfo = (props) => {

    return (
        <div id="paymentsInfo" ref={props.scrollDiv}>
            <div className="container">
                <h2>Płatności za rejs</h2>
                <div className="boxes">
                    {infoPayments.map((el) =>
                        <div className="oneBox">
                            <div className="titleContainer">{el.logo === "" ? <h4>{el.title}</h4> : <img src={el.logo} alt={el?.altImage} />}</div>
                            <p>{el.description}</p>
                        </div>

                         
                    )}
                </div>
            </div>
            <div id="otherPaymentsInfo" >
                <div className="container">
                    <div className="firstSection">
                        <div className="textSection">
                            <h2>Płatności za rezerwacje grupowe</h2>
                            <p>Składając rezerwację grupową masz dostęp do wszystkich powyższych metod płatności.
                            Jednak pamiętaj, że dostęp do płatności automatycznych PayU ma tylko osoba
                            zalogowana i składająca rezerwację. Na osobie składającej rezerwację wieloosobową
                                     spoczywa odpowiedzialność za opłacenie tej rezerwacji w jeden z poniższych sposobów:</p>
                            <ul>
                                <li>Rezerwujący może zapłacić za wszystkich opłacając należność za całą rezerwację w dowolny sposób
                                     (tradycyjny, PayU)</li>
                                <li>Rezerwujący może przekazać zgłaszanym przez siebie uczestnikom dane do przelewu tradycyjnego - każdy płaci za siebie
                                    (podając w tytule przelewu: nr rezerwacji, swoje imię i nazwisko, nazwę wyjazdu)</li>
                            </ul>
                        </div>
                        <div className="imageSection">
                            <img src={imageHowItWorks} alt="płatność za rezerwację grupową na rejs" />
                        </div>
                    </div>
                    <div className="secondSection">
                        <div className="textSection">
                            <h2>Zarezerwuj teraz, zapłać później</h2>
                            <p>Składając rezerwację grupową masz dostęp do wszystkich powyższych metod płatności.
                            Jednak pamiętaj, że dostęp do płatności automatycznych PayU ma tylko osoba
                            zalogowana i składająca rezerwację. Na osobie składającej rezerwację wieloosobową
                                spoczywa odpowiedzialność za opłacenie tej rezerwacji w jeden z poniższych sposobów:</p>
                            <ul>
                                <li><span>Zaliczka - 500 PLN na rejsy europejskie (1500 PLN na rejsy egzotyczne) </span><br />
                                    W ciągu 24 godzin od złożenia rezerwacji należy wpłacić zaliczkę 500 PLN. Wpłata zaliczki stanowi potwierdzenie rezerwacji. W przypadku
                                         niewpłacenia zaliczki rezerwacja zostanie anulowana.</li>
                                <li><span>Druga rata - dopełnienie do 50% ceny</span><br />Najpóźniej 60 dni przed wyjazdem należy dopłacić kwotę będącą dopełnieniem 50% ceny.</li>
                                <li><span>Trzecia rata - pozostała kwota</span><br />Najpóźniej 30 dni przed rozpoczęciem rejsu należy wpłacić pozostałą kwotę. Przy okazji warto sprawdzić, czy ostatecznie zaznaczyłeś wszystkie dodatkowe opcje, które chciałeś i czy poprawnie podałeś wszystkie dane</li>
                            </ul>
                        </div>
                        <div className="imageSection">
                            <img className="firstPhoto" src={imageHowItWorks2} alt="płatność za rejs" />
                            <img className="secondPhoto" src={imageHowItWorks3} alt="płatność za rejs" />
                        </div>
                    </div>
                </div>
            </div>
          
        </div>
    );
}



export default PaymentsInfo;







