import React from "react";
import "./Accomodation.scss";

const AccomodationWrapper = ({
  errorMessageParametersGetAccommodation,
  handleSubmitParametersAccommodation,
  accommodationButton,
  accommodationMessage,
  handleCancelAccommodation,
  handleAllowedAccommodation,
  accommodationConfig,
  userNumParticipant,
  nameParticipant,
  reservationArr,
  userNum,
  firstMessage,
  colorMessage,
  accommodationDetails,
  parameters,
  secondMessage,
  handleChangeAccommodation,
}) => {
  return (
    <div id="accomodation">
      {reservationArr.ReserveList && (
        <div className="greenBox reserveListInfo">
          <p className="infoTitle">
            Twoja rezerwacja jest na liście rezerwowej. Rezerwację potwierdzimy telefonicznie lub mailowo - prosimy do
            tego momentu nie wpłacać zaliczki.
          </p>
        </div>
      )}
      {!reservationArr.IsHistorical && reservationArr.Owner && !reservationArr.ReserveList && (
        <div className="greenBox">
          <p className="infoText">
            <i class="fas fa-unlock"></i> Zmiana danych jest możliwa do 2 tygodni przed wyjazdem
          </p>
          <p>
            Do 2 tygodni przed wyjazdem macie możliwość samodzielnej edycji danych. Następnie ta opcja jest blokowana i
            wszelkie zmiany oraz prośby możecie zgłaszać na info@theboattrip.eu
          </p>
        </div>
      )}
      {reservationArr?.Placement?.RoomPlacement && (
        <>
          <h4>Twój jacht i ekipa</h4>
          <div className="greenBox yourGroup">
            <p className="infoText">{reservationArr?.Placement?.RoomPlacement?.RoomName}</p>
            {reservationArr?.Placement?.RoomPlacement?.Placement.map((participantName, index) => (
              <p style={index === 0 ? {fontFamily: "SpaceGrotesk-SemiBold"} : null}>
                {index === 0 ? "Skipper: " + participantName : participantName}
              </p>
            ))}
          </div>
        </>
      )}
      {reservationArr.StepDetails.Step2[0].map((el, index) => (
        <div className="onePerson">
          <div className="name">
            <p>{nameParticipant[index]}</p>
          </div>
          <h4>Wybrane opcje zakwaterowania</h4>
          <table className="detailsBox">
            <tr>
              <td className="firstColumn">{el[1].slice(0, 12)}</td>
              <td className="secondColumn">{el[1].slice(12, el[1].length)}</td>
            </tr>
            <tr>
              <td className="firstColumn">Rezerwacja kabiny:</td>
              <td className="secondColumn">{el[2][0][0].includes("Tak") ? "TAK" : "NIE"}</td>
            </tr>
          </table>
          {!reservationArr.IsHistorical &&
            reservationArr.Status !== "Anulowana" &&
            (reservationArr.StepConfig.Step1.accommodationOption === "whole" ||
            reservationArr.ReserveList ||
            !reservationArr.Owner ? null : accommodationDetails &&
              !accommodationButton &&
              userNumParticipant[index] === userNum ? null : (
              <div className="btnBox">
                <button
                  className="primaryBtn"
                  onClick={(event) => handleAllowedAccommodation(userNumParticipant[index], event)}
                >
                  Zmień opcje
                </button>
              </div>
            ))}
          {userNumParticipant[index] === userNum && (
            <p className="errorMessage">{errorMessageParametersGetAccommodation}</p>
          )}
          {accommodationDetails && userNumParticipant[index] === userNum && (
            <>
              <h4 style={{marginTop: "40px"}}>Zmień rodzaj zakwaterowania</h4>
              <div className="containerSelect changeOption">
                <select onChange={(event) => handleChangeAccommodation(userNumParticipant[index], event)}>
                  <option className="placeholderList" selected="true" disabled="disabled">
                    Wybierz z listy
                  </option>
                  {parameters.RoomsList[1].map(
                    ({Room}) =>
                      Room.Availability.Available && (
                        <>
                          <option
                            name={userNum}
                            value={Room.RoomId}
                            selected={Room.RoomId === accommodationConfig[index].roomId}
                          >
                            {Room.Name.includes("Własny") ? "Dojazd Własny" : Room.Name}
                          </option>
                        </>
                      )
                  )}
                </select>
                <div className="btnBox">
                  <button
                    className="primaryBtn"
                    style={{margin: "auto 20px"}}
                    onClick={(event) => handleSubmitParametersAccommodation(accommodationConfig[index].userNum, event)}
                  >
                    Zapisz zmiany
                  </button>
                  <button className="primaryBtn" style={{margin: "auto 20px"}} onClick={handleCancelAccommodation}>
                    Anuluj
                  </button>
                </div>
              </div>
            </>
          )}
          {!reservationArr.IsHistorical &&
            reservationArr.Status !== "Anulowana" &&
            userNumParticipant[index] === userNum &&
            accommodationMessage && (
              <>
                <p style={{color: colorMessage, marginLeft: "30px"}}>{firstMessage}</p>
                <p style={{color: colorMessage, marginLeft: "30px"}}>{secondMessage}</p>
              </>
            )}
        </div>
      ))}
    </div>
  );
};

export default AccomodationWrapper;
