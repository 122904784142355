import React from "react";
import Slider from "./Slider.js";
import TestimonialsWrapper from "./TestimonialsWrapper";
import DestinationWrapper from "../DestinationWrapper.js";
import Newsletter from "./Newsletter.js";
import PartnersWrapper from "../PartnersWrapper.js";
import MenuWrapper from "./MenuWrapper.js";
import CruiseSelectionInfo from "../CruiseSelectionInfo.js";
import AdventureWrapper from "./AdventureWrapper";
import SecureBookingWrapper from "./SecureBookingWrapper";
import HowItWorksWrapper from "./HowItWorksWrapper.js";
import InstagramFeedWrapper from "./InstagramFeedWrapper.js";
import ClassicCruise from "./ClassicCruise";
import {fetchData} from "../store/actions";
import {homePageCachedUrl} from "../cachedStoreUrls";
import {connect} from "react-redux";

export const prefetchMethod = (dispatch, match) => {
  return dispatch(
    fetchData(
      "/v1/data/page",
      {
        siteId: 1,
        pageId: "howItWorks",
      },
      homePageCachedUrl
    )
  );
};

const enhance = connect((state) => {
  return {
    Page: state.fetchedUrls[homePageCachedUrl]?.Page || {},
  };
});
class MainPage extends React.Component {
  componentDidMount = () => {
    prefetchMethod(this.props.dispatch);
  };
  render() {
    const {Page} = this.props;
    const findHTMLPageHTML = Page?.Contents?.filter(({Contents}) => Contents.HTML);
    return (
      <div className="pageContainer">
        <MenuWrapper />
        <Slider />
        <AdventureWrapper />
        <TestimonialsWrapper />
        <DestinationWrapper />
        <CruiseSelectionInfo findHTMLPageHTML={findHTMLPageHTML} Page={Page} />
        <ClassicCruise />
        <SecureBookingWrapper />
        <HowItWorksWrapper />
        <Newsletter />
        <InstagramFeedWrapper />
        <PartnersWrapper />
      </div>
    );
  }
}

MainPage.prefetchMethod = prefetchMethod;

export default enhance(MainPage);
