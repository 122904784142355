import React, { useEffect } from 'react';
import './StepTwo.scss';
import './TripBox.scss';
import ReactHtmlParser from 'react-html-parser';
import Moment from 'moment';
import { IAmForAnalitics } from './utilsApp';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import imageCalendar from './img/calendar.png';
import imageSter from './img/ster-rwd.png';
import imagePen from './img/pen.png';
import { useMediaQuery } from 'react-responsive';

const TripBox = (props) => {
  return (
    <div id="tripBox">
      <div>
        <div className="imageContainer">
          <img
            src={props.Reservation.Offer.ListImg?.url}
            alt={props.Reservation.Offer.ListImg?.alt}
          />
        </div>
        <div className="textBox">
          <h4>{props.Reservation.Offer.Name}</h4>
          <p className="date">
            <img src={imageCalendar} alt="termin" />
            {Moment(props.Reservation.Offer.From).format('DD.MM') +
              '-' +
              Moment(props.Reservation.Offer.To).format('DD.MM.YYYY')}
          </p>
          {props.accommodationOption === 'whole' ? (
            <p
              className="price"
              style={{ fontSize: '15px', marginBottom: '20px' }}
            >
              Cena jachtu pojawi się w kroku trzecim - po wyborze jednostki{' '}
            </p>
          ) : props.accommodationOption === 'cabin' ? (
            <p
              className="price"
              style={{
                fontSize: '15px',
                marginBottom: '20px',
                textAlign: 'left',
              }}
            >
              Cena za rezerwację kabiny pojawi się w kroku trzecim - po wyborze
              jednostki
            </p>
          ) : (
            <>
              <p className="price">
                {props.Reservation.Offer.CurrentPrice} PLN
              </p>
              <p className="postscript">Łączna cena Twojego wyjazdu</p>
            </>
          )}
          {props.Reservation.Offer.CurrentRebate !== 0 &&
          props.accommodationOption === 'single' ? (
            <>
              {/* <p className="postscript">Cena do zapłaty po uwzględnieniu rabatu</p> */}
              <p className="rebate">
                <span style={{ fontFamily: 'SpaceGrotesk-SemiBold' }}>
                  W tym rabat, na który składa się:
                </span>
                {props.Reservation.Offer.RebatesList.map((el) => (
                  <p className="rebate">
                    {el.Name}:<span> {el.Value + ' PLN'}</span>
                  </p>
                ))}
              </p>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const OnePerson = (props) => {
  const isMobileScreen = useMediaQuery({ query: '(max-width: 768px)' });
  const StepTwoArr = props.Reservation.Data.find((el) => el.Step === 2);
  const accommodationArray = StepTwoArr.ResortsList.map((el) =>
    el.RoomsList.map((el) => el)
  );
  const updatedAccommodationArray =
    accommodationArray[4] !== undefined
      ? accommodationArray[0].concat(
          accommodationArray[1],
          accommodationArray[2],
          accommodationArray[3],
          accommodationArray[4]
        )
      : accommodationArray[3] !== undefined
      ? accommodationArray[0].concat(
          accommodationArray[1],
          accommodationArray[2],
          accommodationArray[3]
        )
      : accommodationArray[2] !== undefined
      ? accommodationArray[0].concat(
          accommodationArray[1],
          accommodationArray[2]
        )
      : accommodationArray[1] !== undefined
      ? accommodationArray[0].concat(accommodationArray[1])
      : accommodationArray[0];

  // RESERVE SINGLE PLACE
  const updatedAccommodationArray2 = updatedAccommodationArray.map((el) => ({
    ...el,
    canAvailablePlace:
      el.Availability.RoomsAvailableForReservationCount *
        el.Availability.AccommodationsCount >
        el.Availability.ReservedPlacesCount &&
      el.Availability.RoomsAvailableForReservationCount *
        el.Availability.AccommodationsCount !==
        el.Availability.ReservedPlacesCount,
  }));
  const reserveListArray = updatedAccommodationArray2.filter((el) =>
    el.Name.includes('Lista')
  );
  const arrayWithoutReservList = updatedAccommodationArray2.filter(
    (item) => !reserveListArray.includes(item)
  );
  const arrayFilteredAvailablePlaces = arrayWithoutReservList.filter(
    (el) => el.canAvailablePlace === true
  );

  updatedAccommodationArray2.sort(function (x, y) {
    // true values first
    return x.canAvailablePlace === y.canAvailablePlace
      ? 0
      : x.canAvailablePlace
      ? -1
      : 1;
    // false values first
    // return (x === y)? 0 : x? 1 : -1;
  });

  // RESERVE WHOLE YACHT
  // const updatedAccommodationArrayWhole = updatedAccommodationArray.map(el => ({ ...el, canAvailablePlace: (((el.Availability.RoomsAvailableForReservationCount * el.Availability.AccommodationsCount) - el.Availability.ReservedPlacesCount) >= (el.Availability.AccommodationsCount * 2) -1) }));
  // const reserveListArrayWhole = updatedAccommodationArrayWhole.filter(el => el.Name.includes("Lista") || el.Name.includes("lista"));
  // const arrayWithoutReservListWhole = updatedAccommodationArrayWhole.filter(item => !reserveListArrayWhole.includes(item));
  // const arrayFilteredAvailablePlacesWhole = arrayWithoutReservListWhole.filter(el => el.canAvailablePlace === true)

  const updatedJourneyArray = StepTwoArr.Journeys.filter(
    (el) => el.Available === true
  );
  const updatedJourneyArrayName = updatedJourneyArray.filter((el) =>
    el.Name.includes('asny')
  );

  // SETTINGS CAROUSEL DESKTOP
  let settings = {
    infinite: false,
    speed: 500,

    slidesToShow:
      updatedAccommodationArray2.length === 1
        ? 1
        : updatedAccommodationArray2.length === 2
        ? 2
        : 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 770,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    // SECTION SINGLE OR CABIN RESERVATION
    props.accommodationOption === 'single' ||
      props.accommodationOption === 'cabin' ? (
      <>
        {
          // FIRST USER - SINGLE OR CABIN RESERVATION
          props.userNum === 1 ? (
            <div id="person">
              <div className="onePerson">
                <div id={props.id}>
                  <div className="name">
                    {props.accommodationOption === 'cabin' ? (
                      <p>Rezerwacja kajuty 2-osobowej</p>
                    ) : (
                      <p>{props.userNum}. Uczestnik (Ty)</p>
                    )}
                  </div>
                  <div id="classSelect" className="selectBox">
                    <h3 style={{ color: props.colorRoom }}>
                      Rodzaj jednostki{' '}
                      <span
                        style={{
                          color: props.colorRoom,
                          fontSize: '15px',
                          display: 'block',
                          marginTop: '10px',
                        }}
                      >
                        {props.messageStepTwoRoom}
                      </span>
                    </h3>
                    <div className="boxes">
                      <>
                        <Slider {...settings} className="">
                          {updatedAccommodationArray2.map((el, index) =>
                            el.Availability.Available === true
                              ? el.Availability.ReservePlace &&
                                (props.accommodationOption === 'single' ||
                                  props.accommodationOption === 'cabin') && (
                                  <div
                                    className="boxCont"
                                    style={
                                      arrayFilteredAvailablePlaces.length > 0 &&
                                      el.Name?.includes('Lista')
                                        ? { display: 'none' }
                                        : null
                                    }
                                  >
                                    {el.Availability
                                      .RoomsAvailableForReservationCount *
                                      el.Availability.AccommodationsCount >
                                      el.Availability.ReservedPlacesCount &&
                                    el.Availability
                                      .RoomsAvailableForReservationCount *
                                      el.Availability.AccommodationsCount !==
                                      el.Availability.ReservedPlacesCount &&
                                    props.Reservation.Data[0].Config
                                      .ParticipantsCount <=
                                      el?.Availability
                                        ?.RoomsAvailableForReservationCount *
                                        el?.Availability?.AccommodationsCount -
                                        el?.Availability
                                          ?.ReservedPlacesCount ? (
                                      <label className="boxInputRadio">
                                        <p className="titleText">
                                          {el.Name}{' '}
                                          {el.Quality === '***' ? (
                                            <p>★★★</p>
                                          ) : el.Quality === '***+' ? (
                                            <p>★★★+</p>
                                          ) : el.Quality === '****' ? (
                                            <p>★★★★</p>
                                          ) : el.Quality === '****+' ? (
                                            <p>★★★★+</p>
                                          ) : el.Quality === '*****' ? (
                                            <p>★★★★★</p>
                                          ) : null}
                                        </p>
                                        <div className="imageTraining">
                                          <img
                                            src={el.Images[0]?.url}
                                            alt={el.Images[0]?.alt}
                                          />
                                        </div>
                                        <p className="description">
                                          {ReactHtmlParser(el.ShortDescription)}
                                        </p>

                                        {props.accommodationOption ===
                                          'cabin' &&
                                        (el.Price.Place === '0' ||
                                          el.Price.Place === 0) ? (
                                          <>
                                            <p
                                              className="price"
                                              style={
                                                el.Name.includes('Lista')
                                                  ? { display: 'none' }
                                                  : { paddingBottom: '25px' }
                                              }
                                            >
                                              {props.Reservation.Offer
                                                .CurrentPrice +
                                                400 +
                                                props.Reservation.Offer
                                                  .CurrentRebate}{' '}
                                              PLN
                                            </p>
                                            <p
                                              className="cabinPrice"
                                              style={
                                                el.Name.includes('Lista')
                                                  ? { display: 'none' }
                                                  : null
                                              }
                                            >
                                              {(props.Reservation.Offer
                                                .CurrentPrice +
                                                400) /
                                                2 +
                                                props.Reservation.Offer
                                                  .CurrentRebate /
                                                  2}{' '}
                                              PLN/osoba
                                            </p>
                                          </>
                                        ) : props.accommodationOption !==
                                            'cabin' &&
                                          (el.Price.Place === '0' ||
                                            el.Price.Place === 0) ? (
                                          <p
                                            className="price"
                                            style={
                                              el.Name.includes('Lista')
                                                ? { display: 'none' }
                                                : null
                                            }
                                          >
                                            {(props.Reservation.Offer
                                              .CurrentPrice +
                                              props.Reservation.Offer
                                                .CurrentRebate) /
                                              props.participantsCount}{' '}
                                            PLN
                                          </p>
                                        ) : props.accommodationOption ===
                                            'cabin' &&
                                          el.Price.Place !== 0 &&
                                          el.Price.Place !== '0' ? (
                                          <>
                                            <p
                                              className="price"
                                              style={{ paddingBottom: '25px' }}
                                            >
                                              {props.Reservation.Offer
                                                .CurrentPrice +
                                                props.Reservation.Offer
                                                  .CurrentRebate +
                                                400 +
                                                el.Price.Place * 2}{' '}
                                              PLN
                                            </p>
                                            <p className="cabinPrice">
                                              {(props.Reservation.Offer
                                                .CurrentPrice +
                                                400) /
                                                2 +
                                                props.Reservation.Offer
                                                  .CurrentRebate /
                                                  2 +
                                                parseInt(el.Price.Place)}{' '}
                                              PLN/osoba
                                            </p>
                                          </>
                                        ) : (
                                          <>
                                            <p className="price">
                                              {(props.Reservation.Offer
                                                .CurrentPrice +
                                                props.Reservation.Offer
                                                  .CurrentRebate) /
                                                props.participantsCount +
                                                parseInt(el.Price.Place)}{' '}
                                              PLN
                                            </p>
                                          </>
                                        )}
                                        <input
                                          type="radio"
                                          name="roomId"
                                          title={
                                            el.Availability.AccommodationsCount
                                          }
                                          onChange={(event) =>
                                            props.handleChange4(props.id, event)
                                          }
                                          value={el.RoomId}
                                          id={el.Name}

                                          // checked={props.roomId === el.RoomId}
                                        ></input>
                                        <span className="checkmarkRadio"></span>
                                        <div className="boxAfterClick"></div>
                                        {el?.Availability?.ReservePlace &&
                                        el?.Availability
                                          ?.RoomsAvailableForReservationCount *
                                          el?.Availability
                                            ?.AccommodationsCount -
                                          (el?.Availability
                                            ?.AccommodationsCount -
                                            1) <=
                                          el?.Availability
                                            ?.ReservedPlacesCount &&
                                        el?.Availability
                                          ?.RoomsAvailableForReservationCount *
                                          el?.Availability
                                            ?.AccommodationsCount -
                                          (el?.Availability
                                            ?.AccommodationsCount -
                                            1) ? (
                                          <div className="infoAboutPlace">
                                            <p>
                                              Ostatnie wolne miejsca: (
                                              {el?.Availability
                                                ?.RoomsAvailableForReservationCount *
                                                el?.Availability
                                                  ?.AccommodationsCount -
                                                el?.Availability
                                                  ?.ReservedPlacesCount}
                                              )
                                            </p>
                                          </div>
                                        ) : el?.Availability?.ReservePlace &&
                                          el?.Availability
                                            ?.RoomsAvailableForReservationCount *
                                            el?.Availability
                                              ?.AccommodationsCount -
                                            (2 *
                                              el?.Availability
                                                ?.AccommodationsCount -
                                              1) <=
                                            el?.Availability
                                              ?.ReservedPlacesCount &&
                                          el?.Availability
                                            ?.RoomsAvailableForReservationCount *
                                            el?.Availability
                                              ?.AccommodationsCount -
                                            (el?.Availability
                                              ?.AccommodationsCount -
                                              1) *
                                              2 ? (
                                          <div className="infoAboutPlace">
                                            {' '}
                                            <p>Ostatni jacht</p>
                                          </div>
                                        ) : null}
                                      </label>
                                    ) : el.Availability
                                        .RoomsAvailableForReservationCount *
                                        el.Availability.AccommodationsCount >
                                        el.Availability.ReservedPlacesCount &&
                                      el.Availability
                                        .RoomsAvailableForReservationCount *
                                        el.Availability.AccommodationsCount !==
                                        el.Availability.ReservedPlacesCount &&
                                      props.Reservation.Data[0].Config
                                        .ParticipantsCount >=
                                        el?.Availability
                                          ?.RoomsAvailableForReservationCount *
                                          el?.Availability
                                            ?.AccommodationsCount -
                                          el?.Availability
                                            ?.ReservedPlacesCount ? (
                                      <label className="boxInputRadio">
                                        <p className="titleText">
                                          {el.Name}{' '}
                                          {el.Quality === '***' ? (
                                            <p>★★★</p>
                                          ) : el.Quality === '***+' ? (
                                            <p>★★★+</p>
                                          ) : el.Quality === '****' ? (
                                            <p>★★★★</p>
                                          ) : el.Quality === '****+' ? (
                                            <p>★★★★+</p>
                                          ) : el.Quality === '*****' ? (
                                            <p>★★★★★</p>
                                          ) : null}
                                        </p>
                                        <div className="imageTraining">
                                          <img
                                            src={el.Images[0]?.url}
                                            alt={el.Images[0]?.alt}
                                          />
                                        </div>
                                        <p className="description">
                                          {ReactHtmlParser(el.ShortDescription)}
                                        </p>

                                        {props.accommodationOption ===
                                          'cabin' &&
                                        (el.Price.Place === '0' ||
                                          el.Price.Place === 0) ? (
                                          <>
                                            <p
                                              className="price"
                                              style={
                                                el.Name.includes('Lista')
                                                  ? { display: 'none' }
                                                  : { paddingBottom: '25px' }
                                              }
                                            >
                                              {props.Reservation.Offer
                                                .CurrentPrice +
                                                400 +
                                                props.Reservation.Offer
                                                  .CurrentRebate}{' '}
                                              PLN
                                            </p>
                                            <p
                                              className="cabinPrice"
                                              style={
                                                el.Name.includes('Lista')
                                                  ? { display: 'none' }
                                                  : null
                                              }
                                            >
                                              {(props.Reservation.Offer
                                                .CurrentPrice +
                                                400) /
                                                2 +
                                                props.Reservation.Offer
                                                  .CurrentRebate /
                                                  2}{' '}
                                              PLN/osoba
                                            </p>
                                          </>
                                        ) : props.accommodationOption !==
                                            'cabin' &&
                                          (el.Price.Place === '0' ||
                                            el.Price.Place === 0) ? (
                                          <p
                                            className="price"
                                            style={
                                              el.Name.includes('Lista')
                                                ? { display: 'none' }
                                                : null
                                            }
                                          >
                                            {(props.Reservation.Offer
                                              .CurrentPrice +
                                              props.Reservation.Offer
                                                .CurrentRebate) /
                                              props.participantsCount}{' '}
                                            PLN
                                          </p>
                                        ) : props.accommodationOption ===
                                            'cabin' &&
                                          el.Price.Place !== 0 &&
                                          el.Price.Place !== '0' ? (
                                          <>
                                            <p
                                              className="price"
                                              style={{ paddingBottom: '25px' }}
                                            >
                                              {props.Reservation.Offer
                                                .CurrentPrice +
                                                props.Reservation.Offer
                                                  .CurrentRebate +
                                                400 +
                                                el.Price.Place * 2}{' '}
                                              PLN
                                            </p>
                                            <p className="cabinPrice">
                                              {(props.Reservation.Offer
                                                .CurrentPrice +
                                                400) /
                                                2 +
                                                props.Reservation.Offer
                                                  .CurrentRebate /
                                                  2 +
                                                parseInt(el.Price.Place)}{' '}
                                              PLN/osoba
                                            </p>
                                          </>
                                        ) : (
                                          <>
                                            <p className="price">
                                              {(props.Reservation.Offer
                                                .CurrentPrice +
                                                props.Reservation.Offer
                                                  .CurrentRebate) /
                                                props.participantsCount +
                                                parseInt(el.Price.Place)}{' '}
                                              PLN
                                            </p>
                                          </>
                                        )}
                                        <input
                                          type="radio"
                                          name="roomId"
                                          title={
                                            el.Availability.AccommodationsCount
                                          }
                                          onChange={(event) =>
                                            props.handleChange4(props.id, event)
                                          }
                                          value={el.RoomId}
                                          id={el.Name}

                                          // checked={props.roomId === el.RoomId}
                                        ></input>

                                        <span className="checkmarkRadio"></span>
                                        <div className="boxAfterClick"></div>
                                        {el?.Availability?.ReservePlace &&
                                        el?.Availability
                                          ?.RoomsAvailableForReservationCount *
                                          el?.Availability
                                            ?.AccommodationsCount -
                                          (el?.Availability
                                            ?.AccommodationsCount -
                                            1) <=
                                          el?.Availability
                                            ?.ReservedPlacesCount &&
                                        el?.Availability
                                          ?.RoomsAvailableForReservationCount *
                                          el?.Availability
                                            ?.AccommodationsCount -
                                          (el?.Availability
                                            ?.AccommodationsCount -
                                            1) ? (
                                          <div className="infoAboutPlace">
                                            <p>
                                              Ostatnie wolne miejsca: (
                                              {el?.Availability
                                                ?.RoomsAvailableForReservationCount *
                                                el?.Availability
                                                  ?.AccommodationsCount -
                                                el?.Availability
                                                  ?.ReservedPlacesCount}
                                              )
                                            </p>
                                          </div>
                                        ) : el?.Availability?.ReservePlace &&
                                          el?.Availability
                                            ?.RoomsAvailableForReservationCount *
                                            el?.Availability
                                              ?.AccommodationsCount -
                                            (2 *
                                              el?.Availability
                                                ?.AccommodationsCount -
                                              1) <=
                                            el?.Availability
                                              ?.ReservedPlacesCount &&
                                          el?.Availability
                                            ?.RoomsAvailableForReservationCount *
                                            el?.Availability
                                              ?.AccommodationsCount -
                                            (el?.Availability
                                              ?.AccommodationsCount -
                                              1) *
                                              2 ? (
                                          <div className="infoAboutPlace">
                                            {' '}
                                            <p>Ostatni jacht</p>
                                          </div>
                                        ) : null}
                                      </label>
                                    ) : (
                                      <label className="boxInputRadio">
                                        <div
                                          className="overlayOffer"
                                          style={
                                            isMobileScreen
                                              ? { display: 'none' }
                                              : null
                                          }
                                        >
                                          {' '}
                                          <img
                                            src={imageSter}
                                            alt="Lista rezerwowa"
                                          ></img>
                                        </div>

                                        <p className="titleText">
                                          {el.Name.includes('Lista')
                                            ? 'Lista rezerwowa'
                                            : el.Name}{' '}
                                          {el.Quality === '***' ? (
                                            <p>★★★</p>
                                          ) : el.Quality === '***+' ? (
                                            <p>★★★+</p>
                                          ) : el.Quality === '****' ? (
                                            <p>★★★★</p>
                                          ) : el.Quality === '****+' ? (
                                            <p>★★★★+</p>
                                          ) : el.Quality === '*****' ? (
                                            <p>★★★★★</p>
                                          ) : null}
                                        </p>
                                        <div className="imageTraining">
                                          <img
                                            src={el.Images[0]?.url}
                                            alt={el.Images[0]?.alt}
                                          />
                                        </div>
                                        <p className="description">
                                          {ReactHtmlParser(el.ShortDescription)}
                                        </p>

                                        {props.accommodationOption ===
                                          'cabin' &&
                                        (el.Price.Place === '0' ||
                                          el.Price.Place === 0) ? (
                                          <>
                                            <p
                                              className="price"
                                              style={
                                                el.Name.includes('Lista')
                                                  ? { display: 'none' }
                                                  : { paddingBottom: '25px' }
                                              }
                                            >
                                              {props.Reservation.Offer
                                                .CurrentPrice +
                                                400 +
                                                props.Reservation.Offer
                                                  .CurrentRebate}{' '}
                                              PLN
                                            </p>
                                            <p
                                              className="cabinPrice"
                                              style={
                                                el.Name.includes('Lista')
                                                  ? { display: 'none' }
                                                  : null
                                              }
                                            >
                                              {(props.Reservation.Offer
                                                .CurrentPrice +
                                                400) /
                                                2 +
                                                props.Reservation.Offer
                                                  .CurrentRebate /
                                                  2}{' '}
                                              PLN/osoba
                                            </p>
                                          </>
                                        ) : props.accommodationOption !==
                                            'cabin' &&
                                          (el.Price.Place === '0' ||
                                            el.Price.Place === 0) ? (
                                          <p
                                            className="price"
                                            style={
                                              el.Name.includes('Lista')
                                                ? { display: 'none' }
                                                : null
                                            }
                                          >
                                            {(props.Reservation.Offer
                                              .CurrentPrice +
                                              props.Reservation.Offer
                                                .CurrentRebate) /
                                              props.participantsCount}{' '}
                                            PLN
                                          </p>
                                        ) : props.accommodationOption ===
                                            'cabin' &&
                                          el.Price.Place !== 0 &&
                                          el.Price.Place !== '0' ? (
                                          <>
                                            <p
                                              className="price"
                                              style={{ paddingBottom: '25px' }}
                                            >
                                              {props.Reservation.Offer
                                                .CurrentPrice +
                                                props.Reservation.Offer
                                                  .CurrentRebate +
                                                400 +
                                                el.Price.Place * 2}{' '}
                                              PLN
                                            </p>
                                            <p className="cabinPrice">
                                              {(props.Reservation.Offer
                                                .CurrentPrice +
                                                400) /
                                                2 +
                                                props.Reservation.Offer
                                                  .CurrentRebate /
                                                  2 +
                                                parseInt(el.Price.Place)}{' '}
                                              PLN/osoba
                                            </p>
                                          </>
                                        ) : (
                                          <>
                                            <p className="price">
                                              {(props.Reservation.Offer
                                                .CurrentPrice +
                                                props.Reservation.Offer
                                                  .CurrentRebate) /
                                                props.participantsCount +
                                                parseInt(el.Price.Place)}{' '}
                                              PLN
                                            </p>
                                          </>
                                        )}
                                        <input
                                          type="radio"
                                          name="roomId"
                                          title={
                                            el.Availability.AccommodationsCount
                                          }
                                          onChange={(event) =>
                                            props.handleChange4(props.id, event)
                                          }
                                          value={el.RoomId}
                                          id={el.Name}

                                          // checked={props.roomId === el.RoomId}
                                        ></input>

                                        <span className="checkmarkRadio"></span>
                                        <div className="boxAfterClick"></div>
                                        <div
                                          className="infoAboutPlace"
                                          style={
                                            isMobileScreen
                                              ? {
                                                  background: '#c33138',
                                                  top: '90%',
                                                  padding: '0px',
                                                }
                                              : { background: '#c33138' }
                                          }
                                        >
                                          <p>
                                            {isMobileScreen
                                              ? 'Brak miejsc. Lista rezerwowa'
                                              : 'Lista rezerwowa'}{' '}
                                          </p>
                                        </div>
                                      </label>
                                    )}
                                  </div>
                                )
                              : null
                          )}
                        </Slider>
                        {props.infoReserveList !== '' &&
                          props.infoReserveList !== null && (
                            <div
                              className="orangeBox"
                              style={{ background: 'rgb(195, 49, 56)' }}
                            >
                              <p style={{ color: 'white' }}>
                                {props.infoReserveList}
                              </p>
                            </div>
                          )}
                      </>
                      {/* )} */}
                    </div>
                  </div>

                  {/* RESPONSIVE ACCOMMODATION */}
                  <div id="classSelect" className="selectBox responsive">
                    <h3 style={{ color: props.colorRoom }}>
                      Rodzaj jednostki{' '}
                      <span
                        style={{
                          color: props.colorRoom,
                          fontSize: '15px',
                          display: 'block',
                          marginTop: '10px',
                        }}
                      >
                        {props.messageStepTwoRoom}
                      </span>
                    </h3>
                    <div className="boxes">
                      <>
                        {updatedAccommodationArray2.map((el, index) =>
                          el.Availability.Available === true
                            ? el.Availability.ReservePlace &&
                              (props.accommodationOption === 'single' ||
                                props.accommodationOption === 'cabin') && (
                                <div
                                  className="boxCont"
                                  // style={arrayFilteredAvailablePlaces.length > 0 && el.Name?.includes("Lista") ? {display: "none"} : null}
                                >
                                  {el.Availability
                                    .RoomsAvailableForReservationCount *
                                    el.Availability.AccommodationsCount >
                                    el.Availability.ReservedPlacesCount &&
                                  el.Availability
                                    .RoomsAvailableForReservationCount *
                                    el.Availability.AccommodationsCount !==
                                    el.Availability.ReservedPlacesCount ? (
                                    <label className="boxInputRadio">
                                      <p className="titleText">
                                        {el.Name.includes('Lista')
                                          ? 'Lista rezerwowa'
                                          : el.Name}{' '}
                                        {el.Quality === '***' ? (
                                          <p>★★★</p>
                                        ) : el.Quality === '***+' ? (
                                          <p>★★★+</p>
                                        ) : el.Quality === '****' ? (
                                          <p>★★★★</p>
                                        ) : el.Quality === '****+' ? (
                                          <p>★★★★+</p>
                                        ) : el.Quality === '*****' ? (
                                          <p>★★★★★</p>
                                        ) : null}
                                      </p>
                                      <div className="imageTraining">
                                        <img
                                          src={el.Images[0]?.url}
                                          alt={el.Images[0]?.alt}
                                        />
                                      </div>
                                      <p className="description">
                                        {ReactHtmlParser(el.ShortDescription)}
                                      </p>

                                      {props.accommodationOption === 'cabin' &&
                                      (el.Price.Place === '0' ||
                                        el.Price.Place === 0) ? (
                                        <>
                                          <p
                                            className="price"
                                            style={
                                              el.Name.includes('Lista')
                                                ? { display: 'none' }
                                                : { paddingBottom: '25px' }
                                            }
                                          >
                                            {props.Reservation.Offer
                                              .CurrentPrice +
                                              400 +
                                              props.Reservation.Offer
                                                .CurrentRebate}{' '}
                                            PLN
                                          </p>
                                          <p
                                            className="cabinPrice"
                                            style={
                                              el.Name.includes('Lista')
                                                ? { display: 'none' }
                                                : null
                                            }
                                          >
                                            {(props.Reservation.Offer
                                              .CurrentPrice +
                                              400) /
                                              2 +
                                              props.Reservation.Offer
                                                .CurrentRebate /
                                                2}{' '}
                                            PLN/osoba
                                          </p>
                                        </>
                                      ) : props.accommodationOption !==
                                          'cabin' &&
                                        (el.Price.Place === '0' ||
                                          el.Price.Place === 0) ? (
                                        <p
                                          className="price"
                                          style={
                                            el.Name.includes('Lista')
                                              ? { display: 'none' }
                                              : null
                                          }
                                        >
                                          {props.Reservation.Offer
                                            .CurrentPrice +
                                            props.Reservation.Offer
                                              .CurrentRebate}{' '}
                                          PLN
                                        </p>
                                      ) : props.accommodationOption ===
                                          'cabin' &&
                                        el.Price.Place !== 0 &&
                                        el.Price.Place !== '0' ? (
                                        <>
                                          <p
                                            className="price"
                                            style={{ paddingBottom: '25px' }}
                                          >
                                            {props.Reservation.Offer
                                              .CurrentPrice +
                                              props.Reservation.Offer
                                                .CurrentRebate +
                                              400 +
                                              el.Price.Place * 2}{' '}
                                            PLN
                                          </p>
                                          <p className="cabinPrice">
                                            {(props.Reservation.Offer
                                              .CurrentPrice +
                                              400) /
                                              2 +
                                              props.Reservation.Offer
                                                .CurrentRebate /
                                                2 +
                                              parseInt(el.Price.Place)}{' '}
                                            PLN/osoba
                                          </p>
                                        </>
                                      ) : (
                                        <>
                                          <p className="price">
                                            {props.Reservation.Offer
                                              .CurrentPrice +
                                              props.Reservation.Offer
                                                .CurrentRebate +
                                              parseInt(el.Price.Place)}{' '}
                                            PLN
                                          </p>
                                        </>
                                      )}
                                      <input
                                        type="radio"
                                        name="roomId"
                                        title={
                                          el.Availability.AccommodationsCount
                                        }
                                        onChange={(event) =>
                                          props.handleChange4(props.id, event)
                                        }
                                        value={el.RoomId}
                                        id={el.Name}

                                        // checked={props.roomId === el.RoomId}
                                      ></input>
                                      <span className="checkmarkRadio"></span>
                                      <div className="boxAfterClick"></div>
                                      {el?.Availability?.ReservePlace &&
                                      el?.Availability
                                        ?.RoomsAvailableForReservationCount *
                                        el?.Availability?.AccommodationsCount -
                                        (el?.Availability?.AccommodationsCount -
                                          1) <=
                                        el?.Availability?.ReservedPlacesCount &&
                                      el?.Availability
                                        ?.RoomsAvailableForReservationCount *
                                        el?.Availability?.AccommodationsCount -
                                        (el?.Availability?.AccommodationsCount -
                                          1) ? (
                                        <div
                                          className="infoAboutPlace"
                                          style={{ padding: '0', top: '90%' }}
                                        >
                                          <p>
                                            Ostatnie wolne miejsca: (
                                            {el?.Availability
                                              ?.RoomsAvailableForReservationCount *
                                              el?.Availability
                                                ?.AccommodationsCount -
                                              el?.Availability
                                                ?.ReservedPlacesCount}
                                            )
                                          </p>
                                        </div>
                                      ) : el?.Availability?.ReservePlace &&
                                        el?.Availability
                                          ?.RoomsAvailableForReservationCount *
                                          el?.Availability
                                            ?.AccommodationsCount -
                                          el?.Availability
                                            ?.AccommodationsCount <=
                                          el?.Availability
                                            ?.ReservedPlacesCount &&
                                        el?.Availability
                                          ?.RoomsAvailableForReservationCount *
                                          el?.Availability
                                            ?.AccommodationsCount -
                                          el?.Availability
                                            ?.AccommodationsCount ? (
                                        <div
                                          className="infoAboutPlace"
                                          style={{ padding: '0', top: '90%' }}
                                        >
                                          {' '}
                                          <p>Ostatni jacht</p>
                                        </div>
                                      ) : null}
                                    </label>
                                  ) : (
                                    <label className="boxInputRadio">
                                      {' '}
                                      <div
                                        className="overlayOffer"
                                        style={
                                          isMobileScreen
                                            ? { display: 'none' }
                                            : null
                                        }
                                      >
                                        {' '}
                                        <img
                                          src={imageSter}
                                          alt="Lista rezerwowa"
                                        ></img>
                                      </div>{' '}
                                      <p className="titleText">
                                        {el.Name}{' '}
                                        {el.Quality === '***' ? (
                                          <p>★★★</p>
                                        ) : el.Quality === '***+' ? (
                                          <p>★★★+</p>
                                        ) : el.Quality === '****' ? (
                                          <p>★★★★</p>
                                        ) : el.Quality === '****+' ? (
                                          <p>★★★★+</p>
                                        ) : el.Quality === '*****' ? (
                                          <p>★★★★★</p>
                                        ) : null}
                                      </p>
                                      <div className="imageTraining">
                                        <img
                                          src={el.Images[0]?.url}
                                          alt={el.Images[0]?.alt}
                                        />
                                      </div>
                                      <p className="description">
                                        {ReactHtmlParser(el.ShortDescription)}
                                      </p>
                                      {props.accommodationOption === 'cabin' &&
                                      (el.Price.Place === '0' ||
                                        el.Price.Place === 0) ? (
                                        <>
                                          <p
                                            className="price"
                                            style={
                                              el.Name.includes('Lista')
                                                ? { display: 'none' }
                                                : { paddingBottom: '25px' }
                                            }
                                          >
                                            {props.Reservation.Offer
                                              .CurrentPrice +
                                              400 +
                                              props.Reservation.Offer
                                                .CurrentRebate}{' '}
                                            PLN
                                          </p>
                                          <p
                                            className="cabinPrice"
                                            style={
                                              el.Name.includes('Lista')
                                                ? { display: 'none' }
                                                : null
                                            }
                                          >
                                            {(props.Reservation.Offer
                                              .CurrentPrice +
                                              400) /
                                              2 +
                                              props.Reservation.Offer
                                                .CurrentRebate /
                                                2}{' '}
                                            PLN/osoba
                                          </p>
                                        </>
                                      ) : props.accommodationOption !==
                                          'cabin' &&
                                        (el.Price.Place === '0' ||
                                          el.Price.Place === 0) ? (
                                        <p
                                          className="price"
                                          style={
                                            el.Name.includes('Lista')
                                              ? { display: 'none' }
                                              : null
                                          }
                                        >
                                          {props.Reservation.Offer
                                            .CurrentPrice +
                                            props.Reservation.Offer
                                              .CurrentRebate}{' '}
                                          PLN
                                        </p>
                                      ) : props.accommodationOption ===
                                          'cabin' &&
                                        el.Price.Place !== 0 &&
                                        el.Price.Place !== '0' ? (
                                        <>
                                          <p
                                            className="price"
                                            style={{ paddingBottom: '25px' }}
                                          >
                                            {props.Reservation.Offer
                                              .CurrentPrice +
                                              props.Reservation.Offer
                                                .CurrentRebate +
                                              400 +
                                              el.Price.Place * 2}{' '}
                                            PLN
                                          </p>
                                          <p className="cabinPrice">
                                            {(props.Reservation.Offer
                                              .CurrentPrice +
                                              400) /
                                              2 +
                                              props.Reservation.Offer
                                                .CurrentRebate /
                                                2 +
                                              parseInt(el.Price.Place)}{' '}
                                            PLN/osoba
                                          </p>
                                        </>
                                      ) : (
                                        <>
                                          <p className="price">
                                            {props.Reservation.Offer
                                              .CurrentPrice +
                                              props.Reservation.Offer
                                                .CurrentRebate +
                                              parseInt(el.Price.Place)}{' '}
                                            PLN
                                          </p>
                                        </>
                                      )}
                                      <input
                                        type="radio"
                                        name="roomId"
                                        title={
                                          el.Availability.AccommodationsCount
                                        }
                                        onChange={(event) =>
                                          props.handleChange4(props.id, event)
                                        }
                                        value={el.RoomId}
                                        id={el.Name}

                                        // checked={props.roomId === el.RoomId}
                                      ></input>
                                      <span className="checkmarkRadio"></span>
                                      <div className="boxAfterClick"></div>
                                      <div
                                        className="infoAboutPlace"
                                        style={
                                          isMobileScreen
                                            ? {
                                                background: '#c33138',
                                                top: '90%',
                                                padding: '0px',
                                              }
                                            : { background: '#c33138' }
                                        }
                                      >
                                        {' '}
                                        <p>
                                          {isMobileScreen
                                            ? 'Brak miejsc. Lista rezerwowa'
                                            : 'Lista rezerwowa'}{' '}
                                        </p>
                                      </div>
                                    </label>
                                  )}
                                </div>
                              )
                            : null
                        )}
                      </>
                      {props.infoReserveList !== '' &&
                        props.infoReserveList !== null && (
                          <div
                            className="orangeBox"
                            style={{ background: 'rgb(195, 49, 56)' }}
                          >
                            <p style={{ color: 'white' }}>
                              {props.infoReserveList}
                            </p>
                          </div>
                        )}
                    </div>
                  </div>
                  {/* RESPONSIVE ACCOMMODATION - END*/}
                  {props.yachtClass === 'Klasa LUXURY' ||
                  props.accommodationOption === 'cabin' ||
                  props.accommodationOption === 'whole' ||
                  props.Reservation.Offer.Name.includes('Kurs') ||
                  props.Reservation.Offer.Name.includes('Akademia') ||
                  props.Reservation.Offer.Name.includes('Cup')
                    ? null
                    : props.nameClass !== 'Katamaran LUXURY' && (
                        <div id="transportSelect" className="selectBox">
                          <h3>Opcje dodatkowe</h3>
                          <p style={{ fontFamily: 'SpaceGrotesk-SemiBold' }}>
                            Rezerwacja Kabiny 2-osobowej
                          </p>
                          <p
                            style={{
                              fontSize: '14px',
                              marginTop: '20px',
                              maxWidth: '450px',
                            }}
                          >
                            Opcja dostępna dla rezerwacji 2-osobowych.<br></br>{' '}
                            Jeśli chcesz zarezerwować pojedyncze miejsce w
                            kajucie, skontaktuj się z{' '}
                            <Link
                              to="/kontakt"
                              target={'_blank'}
                              style={{ fontFamily: 'SpaceGrotesk-SemiBold' }}
                              title="Kontakt"
                            >
                              Obsługą Klienta
                            </Link>
                            .
                          </p>
                        </div>
                      )}

                  {updatedJourneyArray.length === 1 &&
                  updatedJourneyArrayName.length === 1 ? null : (
                    <div id="transportSelect" className="selectBox">
                      <h3 style={{ color: props.colorJourney }}>
                        Dojazd
                        <span
                          style={{
                            color: props.colorJourney,
                            fontSize: '15px',
                            display: 'block',
                            marginTop: '10px',
                          }}
                        >
                          {props.messageStepTwoJourney}
                        </span>
                      </h3>

                      <div className="boxes" id="transportBoxes">
                        {StepTwoArr.Journeys.map((el) =>
                          el.Available === true ? (
                            <label
                              className="boxInputRadio"
                              style={{ marginRight: '20px' }}
                            >
                              <p
                                className="titleText"
                                style={{ marginTop: '12px' }}
                              >
                                {el.Name.includes('własny') ||
                                el.Name.includes('Własny')
                                  ? 'Dojazd Własny'
                                  : el.Name}
                              </p>
                              <p
                                className="description"
                                style={{ marginTop: '10px' }}
                              >
                                {el?.Details}
                              </p>
                              {/* <p>{el.Price.Place} PLN/osoba</p> */}
                              {el.IsPayOnPlace ? (
                                <p
                                  className="price"
                                  style={{
                                    marginTop: '10px',
                                    color: '#113852',
                                  }}
                                >
                                  Płatność na miejscu
                                </p>
                              ) : el.Price === '0' || el.Price === 0 ? (
                                <p
                                  className="price include"
                                  style={{ marginTop: '10px' }}
                                ></p>
                              ) : (
                                <p
                                  className="price"
                                  style={{ marginTop: '10px' }}
                                >
                                  +{el.Price} PLN
                                </p>
                              )}
                              <input
                                type="radio"
                                name={props.id}
                                checked={props.journeyId === el.JourneyId}
                                onChange={(event) =>
                                  props.handleChange5(props.id, event)
                                }
                                value={el.JourneyId}
                              ></input>
                              <span className="checkmarkRadio"></span>
                              <div className="boxAfterClick"></div>
                            </label>
                          ) : null
                        )}
                      </div>
                    </div>
                  )}
                  {props.journeyId === '1' ? (
                    <div className="containerSelect">
                      <h4>Miejsce odbioru</h4>
                      <p>
                        Wybierz najdogodniejsze dla siebie. Jeśli nie zbierze
                        się grupa - skontaktujemy się z Tobą min. 2 tygodnie
                        przed wyjazdem.
                      </p>
                      <select
                        style={{ border: props.colorJourneyPoinId }}
                        name={props.id}
                        onChange={(event) =>
                          props.handleChange6(props.id, event)
                        }
                      >
                        <option
                          className="placeholderList"
                          selected="true"
                          disabled="disabled"
                        >
                          Wybierz z listy
                        </option>
                        {StepTwoArr.Journeys.map(
                          (el) =>
                            el.PointsList !== null &&
                            el.PointsList.map(
                              (el) =>
                                el.Available && (
                                  <option
                                    selected={
                                      el.JourneyPointId === props.journeyPointId
                                    }
                                    name={props.id}
                                    value={el.JourneyPointId}
                                  >
                                    {el.Name}{' '}
                                    {el.Price === 0 || el.Price === '0'
                                      ? ' '
                                      : '+' + el.Price + ' PLN'}
                                  </option>
                                )
                            )
                        )}
                      </select>
                      {props.journeyPointId === '2' ||
                      props.journeyPointId === '5' ||
                      props.journeyPointId === '6' ? (
                        <div className="orangeBox">
                          {' '}
                          <p style={{ color: 'orange' }}>
                            Wyjazd możliwy pod warunkiem uzbierania się grupy
                            min. 15 osób
                          </p>
                        </div>
                      ) : null}
                      <p style={{ color: '#de1212' }}>
                        {props.messageStepTwoJourneyPointId}
                      </p>
                    </div>
                  ) : null}

                  {props.journeyId !== '1' &&
                  props.journeyId !== '' &&
                  updatedJourneyArray.length !== 1 &&
                  !StepTwoArr.Journeys.some(
                    (el) => el.Available && el.Name.includes('zorganizowany')
                  ) ? (
                    <div
                      className="containerSelect"
                      style={{ marginTop: '20px' }}
                    >
                      <label
                        style={{ marginBottom: '5px' }}
                        className="containerInputCheckbox"
                      >
                        <p>Transport jednej sztuki bagażu 20 kg (+200 PLN)</p>
                        <input
                          type="checkbox"
                          name="transportAddLuggage"
                          checked={props.transportAddLuggage === true}
                          value={props.transportAddLuggage}
                          onChange={(event) =>
                            props.handleChangeAddTransport(props.id, event)
                          }
                        ></input>
                        <span className="checkmark"></span>
                      </label>
                      <p
                        style={{
                          marginLeft: '35px',
                          marginTop: 0,
                          color: 'grey',
                        }}
                      >
                        Osoby, które zdecydują się na dojazd własny mogą dokupić
                        transport bagażu
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : // FIRST USER - SINGLE OR CABIN RESERVATION - END
          props.accommodationOption === 'cabin' ? null : (
            // NEXT USERS - SINGLE OR CABIN RESERVATION
            <div className="onePerson">
              <div id={props.id}>
                <div className="name">
                  {props.inputAddNameActive ? (
                    <>
                      {props.newName ? (
                        <div className="containerAddName">
                          <p>{props.id}. </p>
                          <input
                            type="text"
                            placeholder="Imię"
                            name="FirstName"
                            className=""
                            value={props.valueName}
                            onChange={(event) =>
                              props.handleEditNameSingleReservation(
                                props.id,
                                event
                              )
                            }
                          ></input>
                          <input
                            type="text"
                            placeholder="Nazwisko"
                            name="SurName"
                            className=""
                            value={props.valueSurname}
                            onChange={(event) =>
                              props.handleEditNameSingleReservation2(
                                props.id,
                                event
                              )
                            }
                          ></input>
                          <button
                            className="secondaryBtn"
                            onClick={(event) =>
                              props.handleSaveName(props.id, event)
                            }
                          >
                            Zapisz
                          </button>
                        </div>
                      ) : (
                        <>
                          <p>
                            {props.id}. {props.valueName} {props.valueSurname}
                          </p>
                          <p
                            className="price edit"
                            onClick={() => props.handleEditNameSingle(props.id)}
                          >
                            <img src={imagePen} alt="Edycja uczestnika" />
                            edytuj imię i nazwisko
                          </p>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <p>{props.id}. Uczestnik</p>
                      <>
                        <p
                          className="price edit"
                          onClick={() => props.handleAddName(props.id)}
                        >
                          <img src={imagePen} alt="Edycja uczestnika" />
                          Dodaj imię i nazwisko
                        </p>
                      </>
                    </>
                  )}
                </div>
                <div id="accomodationSelect" className="selectBox">
                  <h3>Zakwaterowanie </h3>
                  <div className="containerSelect">
                    <label className="containerInputCheckbox">
                      <p>Tak samo jak 1. uczestnik</p>
                      <input
                        type="checkbox"
                        name="sameAsTheFirst"
                        checked={props.sameAsTheFirst === true}
                        value={props.sameAsTheFirst}
                        onChange={(event) =>
                          props.handleChange4(props.id, event)
                        }
                      ></input>
                      <span className="checkmark"></span>
                    </label>
                    {props.sameAsTheFirst === false && (
                      <>
                        {props.reserveListJourneyOption && (
                          <>
                            <p>Rodzaj jednostki</p>
                            <select
                              onChange={(event) =>
                                props.handleChange4(props.id, event)
                              }
                            >
                              <option
                                className="placeholderList"
                                selected="true"
                                disabled="disabled"
                              >
                                Wybierz z listy
                              </option>
                              {/* {StepTwoArr.ResortsList.map((el) => */}
                              {/* <> */}

                              <>
                                {updatedAccommodationArray2.map(
                                  (el) =>
                                    el.Availability.Available === true &&
                                    el.Availability.ReservePlace &&
                                    el.Availability
                                      .RoomsAvailableForReservationCount *
                                      el.Availability.AccommodationsCount >
                                      el.Availability.ReservedPlacesCount &&
                                    el.Availability
                                      .RoomsAvailableForReservationCount *
                                      el.Availability.AccommodationsCount !==
                                      el.Availability.ReservedPlacesCount &&
                                    props.accommodationOption === 'single' && (
                                      <>
                                        <option
                                          name={props.id}
                                          value={el.RoomId}
                                          selected={el.RoomId === props.roomId}
                                        >
                                          {el.Name}{' '}
                                          {el.Price.Place === 0 ||
                                          el.Price.Place === '0'
                                            ? ' '
                                            : '+' + el.Price.Place + ' PLN'}
                                        </option>
                                      </>
                                    )
                                )}
                              </>

                              {/* </> */}

                              {/* )} */}
                            </select>
                          </>
                        )}
                        {props.accommodationOption === 'whole' && (
                          <>
                            {updatedJourneyArray.length === 1 &&
                            updatedJourneyArrayName.length === 1 ? null : (
                              <div className="containerSelect">
                                <h3>Dojazd</h3>
                                <p>Środek transportu</p>
                                <select
                                  onChange={(event) =>
                                    props.handleChange5(props.id, event)
                                  }
                                >
                                  <option
                                    className="placeholderList"
                                    selected="true"
                                    disabled="disabled"
                                  >
                                    Wybierz z listy
                                  </option>
                                  {StepTwoArr.Journeys.map((el) => (
                                    <>
                                      {el.Available === true ? (
                                        <option
                                          name={props.id}
                                          value={el.JourneyId}
                                          selected={
                                            el.JourneyId === props.journeyId
                                          }
                                        >
                                          {el.Name.includes('własny') ||
                                          el.Name.includes('Własny')
                                            ? 'Dojazd Własny'
                                            : el.Name}
                                        </option>
                                      ) : null}
                                    </>
                                  ))}
                                </select>
                              </div>
                            )}

                            {StepTwoArr.Journeys.map((el) =>
                              el.JourneyId === '1' &&
                              props.journeyId === '1' ? (
                                <div className="addOption">
                                  <h4>Miejsce odbioru</h4>
                                  <form>
                                    {el.PointsList.map((item) => (
                                      <label className="containerInputCheckbox">
                                        <p>
                                          {item.Name}{' '}
                                          <span
                                            style={{
                                              display: 'inline',
                                              color: '#193e57',
                                              fontSize: '13px',
                                            }}
                                          >
                                            {item.Name === 'Kraków' ||
                                            item.Name === 'Szczecin' ||
                                            item.Name === 'Trójmiasto' ||
                                            item.Name === 'Wrocław' ||
                                            item.Name === 'Poznań'
                                              ? '(wyjazd możliwy pod warunkiem uzbierania się grupy min. 15 osób)'
                                              : null}
                                          </span>{' '}
                                          {item.Price === 0 ||
                                          item.Price === '0'
                                            ? ' '
                                            : '+' + item.Price + ' PLN'}
                                        </p>
                                        <input
                                          type="radio"
                                          name={props.id}
                                          onChange={(event) =>
                                            props.handleChange6(props.id, event)
                                          }
                                          value={item.JourneyPointId}
                                        ></input>
                                        <span className="checkmark"></span>
                                      </label>
                                    ))}
                                  </form>
                                  {/* <p className="warningText"><span>Uwaga:</span> Przy innych opcjach odbioru możecie nie być razem w autokarze</p> */}
                                </div>
                              ) : null
                            )}
                          </>
                        )}
                        {/* <p className="warningText"><span>Uwaga:</span> Wybrano kilka różnych rodzajów i/lub klas zakwaterowania, co oznacza, ze nie wszyscy uczestnicy zostaną zakwaterowani w tym samym obiekcie.</p> */}
                        {updatedJourneyArray.length === 1 &&
                        updatedJourneyArrayName.length === 1 ? null : (
                          <div className="containerSelect">
                            <h3>Dojazd</h3>
                            <p>Środek transportu</p>
                            <select
                              onChange={(event) =>
                                props.handleChange5(props.id, event)
                              }
                            >
                              <option
                                className="placeholderList"
                                selected="true"
                                disabled="disabled"
                              >
                                Wybierz z listy
                              </option>
                              {StepTwoArr.Journeys.map((el) => (
                                <>
                                  {el.Available === true ? (
                                    <option
                                      name={props.id}
                                      value={el.JourneyId}
                                      selected={
                                        el.JourneyId === props.journeyId
                                      }
                                    >
                                      {el.Name.includes('własny') ||
                                      el.Name.includes('Własny')
                                        ? 'Dojazd Własny'
                                        : el.Name}
                                    </option>
                                  ) : null}
                                </>
                              ))}
                            </select>

                            {StepTwoArr.Journeys.map((el) =>
                              el.JourneyId === '1' &&
                              props.journeyId === '1' ? (
                                <div className="addOption">
                                  <h4>Miejsce odbioru</h4>
                                  <form>
                                    {el.PointsList.map((item) => (
                                      <label className="containerInputCheckbox">
                                        <p>
                                          {item.Name}{' '}
                                          <span
                                            style={{
                                              display: 'inline',
                                              color: '#193e57',
                                              fontSize: '13px',
                                            }}
                                          >
                                            {item.Name === 'Kraków' ||
                                            item.Name === 'Szczecin' ||
                                            item.Name === 'Trójmiasto' ||
                                            item.Name === 'Wrocław' ||
                                            item.Name === 'Poznań'
                                              ? '(wyjazd możliwy pod warunkiem uzbierania się grupy min. 15 osób)'
                                              : null}
                                          </span>{' '}
                                          {item.Price === 0 ||
                                          item.Price === '0'
                                            ? ' '
                                            : '+' + item.Price + ' PLN'}
                                        </p>
                                        <input
                                          type="radio"
                                          name={props.id}
                                          onChange={(event) =>
                                            props.handleChange6(props.id, event)
                                          }
                                          value={item.JourneyPointId}
                                        ></input>
                                        <span className="checkmark"></span>
                                      </label>
                                    ))}
                                  </form>
                                  {/* <p className="warningText"><span>Uwaga:</span> Przy innych opcjach odbioru możecie nie być razem w autokarze</p> */}
                                </div>
                              ) : //         :

                              // (props.journeyIdWhole !== "1" && props.journeyIdWhole !== "") && (updatedJourneyArray.length !== 1) ?
                              //     <div className="containerSelect" style={{ marginTop: "20px" }}>
                              //         <label style={{ marginBottom: "5px" }} className="containerInputCheckbox"><p>Transport jednej sztuki bagażu 20 kg (+200 PLN)</p><input type="checkbox" name="transportAddLuggage" checked={props.transportAddLuggage === true} value={props.transportAddLuggage} onChange={(event) => props.handleChangeAddTransport(props.id, event)}></input>
                              //             <span className="checkmark"></span>
                              //         </label>

                              //     </div>

                              null
                            )}
                          </div>
                        )}
                      </>
                    )}

                    {props.yachtClass === 'Klasa LUXURY' ||
                    props.Reservation.Offer.Name.includes('Kurs') ||
                    props.Reservation.Offer.Name.includes('Akademia') ||
                    props.accommodationOption === 'whole' ||
                    props.accommodationOption === 'cabin' ||
                    (props.accommodationConfig[0].yachtClass ===
                      'Klasa LUXURY' &&
                      props.sameAsTheFirst === true) ? null : (
                      <>
                        <>
                          {StepTwoArr.Journeys.map((el) =>
                            (props.journeyId !== '1' &&
                              el.JourneyId !== '1' &&
                              props.journeyId !== '' &&
                              el.Available &&
                              updatedJourneyArray.length !== 1) ||
                            (props.accommodationConfig[0].journeyId !== '1' &&
                              props.sameAsTheFirst &&
                              el.Available &&
                              el.JourneyId !== '1' &&
                              updatedJourneyArray.length !== 1) ? (
                              <>
                                <div id="transportSelect" className="selectBox">
                                  <h3>Opcje dodatkowe (dojazd własny)</h3>
                                </div>
                                <div
                                  className="containerSelect"
                                  style={{ marginTop: '20px' }}
                                >
                                  <label
                                    style={{ marginBottom: '5px' }}
                                    className="containerInputCheckbox"
                                  >
                                    <p>
                                      Transport jednej sztuki bagażu 20 kg (+200
                                      PLN)
                                    </p>
                                    <input
                                      type="checkbox"
                                      name="transportAddLuggage"
                                      checked={
                                        props.transportAddLuggage === true
                                      }
                                      value={props.transportAddLuggage}
                                      onChange={(event) =>
                                        props.handleChangeAddTransport(
                                          props.id,
                                          event
                                        )
                                      }
                                    ></input>
                                    <span className="checkmark"></span>
                                  </label>
                                  <p
                                    style={{
                                      marginLeft: '35px',
                                      marginTop: 0,
                                      color: 'grey',
                                    }}
                                  >
                                    Osoby, które zdecydują się na dojazd własny
                                    mogą dokupić transport bagażu
                                  </p>
                                </div>
                              </>
                            ) : null
                          )}
                        </>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
          // NEXT USERS - SINGLE OR CABIN RESERVATION - END
        }
      </>
    ) : (
      // SECTION SINGLE OR CABIN RESERVATION - END

      // SECTION WHOLE RESERVATION
      <>
        {props.accommodationOption === 'whole' && (
          <>
            {
              // FIRST USER - WHOLE RESERVATION
              props.id === 1 ? (
                <div id="person">
                  <div className="onePerson">
                    <div>
                      <div className="name">
                        <p>Uczestnik (Ty)</p>
                      </div>

                      <div id="classSelect" className="selectBox">
                        <h3>Rodzaj jednostki</h3>
                        <div className="boxes">
                          {/* {StepTwoArr.ResortsList.map((el) => */}
                          {/* <> */}
                          <Slider {...settings}>
                            {updatedAccommodationArray2.map((el, index) =>
                              el.Availability.ReserveWhole &&
                              el.Availability.Available === true ? (
                                <div className="boxCont">
                                  {el.Availability
                                    .RoomsAvailableForReservationCount *
                                    el.Availability.AccommodationsCount -
                                    el.Availability.ReservedPlacesCount >=
                                  el.Availability.AccommodationsCount ? (
                                    //  style={(props.accommodationOption === "whole") && (el.Availability.AccommodationsCount < props.participantsCountWhole) && (props.skipper === "TAK") ? { opacity: "0.8", cursor: "default" } :
                                    //  (props.accommodationOption === "whole") && (el.Availability.AccommodationsCount < props.participantsCountWhole + 1) && (props.skipper === "NIE") ? { opacity: "0.8", cursor: "default" } : {}}

                                    <label className="boxInputRadio">
                                      <p className="titleText">
                                        {el.Name}{' '}
                                        {el.Quality === '***' ? (
                                          <p>★★★</p>
                                        ) : el.Quality === '***+' ? (
                                          <p>★★★+</p>
                                        ) : el.Quality === '****' ? (
                                          <p>★★★★</p>
                                        ) : el.Quality === '****+' ? (
                                          <p>★★★★+</p>
                                        ) : el.Quality === '*****' ? (
                                          <p>★★★★★</p>
                                        ) : null}
                                      </p>
                                      <div className="imageTraining">
                                        <img
                                          src={el.Images[0]?.url}
                                          alt={el.Images[0]?.alt}
                                        />
                                      </div>
                                      <p className="description">
                                        {ReactHtmlParser(el.ShortDescription)}
                                      </p>
                                      <p
                                        className="price"
                                        style={
                                          el.Name.includes('Lista')
                                            ? { display: 'none' }
                                            : null
                                        }
                                      >
                                        {el.Price.Whole} PLN
                                      </p>

                                      <input
                                        type="radio"
                                        name="participantsCountWhole"
                                        title={
                                          el.Availability.AccommodationsCount
                                        }
                                        onClick={(event) =>
                                          props.handleChangeParticipantWholeYacht(
                                            props.id,
                                            event
                                          )
                                        }
                                        value={el.RoomId}
                                        id={el.Name}

                                        // checked={props.roomIdwhole === el.RoomId}
                                      ></input>

                                      <span className="checkmarkRadio"></span>
                                      <div className="boxAfterClick"></div>
                                      {el?.Availability?.ReserveWhole &&
                                      el?.Availability
                                        ?.RoomsAvailableForReservationCount *
                                        el?.Availability?.AccommodationsCount -
                                        el?.Availability?.AccommodationsCount *
                                          2 <=
                                        el?.Availability?.ReservedPlacesCount &&
                                      el?.Availability
                                        ?.RoomsAvailableForReservationCount *
                                        el?.Availability?.AccommodationsCount -
                                        el?.Availability?.AccommodationsCount *
                                          2 ? (
                                        <div className="infoAboutPlace">
                                          {' '}
                                          <p>Ostatni jacht</p>
                                        </div>
                                      ) : null}
                                    </label>
                                  ) : (
                                    <label className="boxInputRadio">
                                      <div
                                        className="overlayOffer"
                                        style={
                                          isMobileScreen
                                            ? { display: 'none' }
                                            : null
                                        }
                                      >
                                        {' '}
                                        <img
                                          src={imageSter}
                                          alt="Lista rezerwowa"
                                        ></img>
                                      </div>
                                      <p className="titleText">
                                        {el.Name}{' '}
                                        {el.Quality === '***' ? (
                                          <p>★★★</p>
                                        ) : el.Quality === '***+' ? (
                                          <p>★★★+</p>
                                        ) : el.Quality === '****' ? (
                                          <p>★★★★</p>
                                        ) : el.Quality === '****+' ? (
                                          <p>★★★★+</p>
                                        ) : el.Quality === '*****' ? (
                                          <p>★★★★★</p>
                                        ) : null}
                                      </p>
                                      <div className="imageTraining">
                                        <img
                                          src={el.Images[0]?.url}
                                          alt={el.Images[0]?.alt}
                                        />
                                      </div>
                                      <p className="description">
                                        {ReactHtmlParser(el.ShortDescription)}
                                      </p>
                                      <p
                                        className="price"
                                        style={
                                          el.Name.includes('Lista')
                                            ? { display: 'none' }
                                            : null
                                        }
                                      >
                                        {el.Price.Whole} PLN
                                      </p>

                                      <input
                                        type="radio"
                                        name="participantsCountWhole"
                                        title={
                                          el.Availability.AccommodationsCount
                                        }
                                        onClick={(event) =>
                                          props.handleChangeParticipantWholeYacht(
                                            props.id,
                                            event
                                          )
                                        }
                                        value={el.RoomId}
                                        id={el.Name}

                                        // checked={props.roomIdwhole === el.RoomId}
                                      ></input>

                                      <span className="checkmarkRadio"></span>
                                      <div className="boxAfterClick"></div>
                                      <div
                                        className="infoAboutPlace"
                                        style={
                                          isMobileScreen
                                            ? {
                                                background: '#c33138',
                                                top: '90%',
                                                padding: '0px',
                                              }
                                            : { background: '#c33138' }
                                        }
                                      >
                                        {' '}
                                        <p>
                                          {isMobileScreen
                                            ? 'Brak miejsc. Lista rezerwowa'
                                            : 'Lista rezerwowa'}{' '}
                                        </p>
                                      </div>
                                    </label>
                                  )}
                                </div>
                              ) : null
                            )}
                          </Slider>
                          {props.infoReserveList !== '' &&
                            props.infoReserveList !== null && (
                              <div
                                className="orangeBox"
                                style={{ background: 'rgb(195, 49, 56)' }}
                              >
                                <p style={{ color: 'white' }}>
                                  {props.infoReserveList}
                                </p>
                              </div>
                            )}
                        </div>
                      </div>

                      <div id="classSelect" className="selectBox responsive">
                        <h3>Rodzaj jednostki</h3>
                        <div className="boxes">
                          {/* {StepTwoArr.ResortsList.map((el) => */}
                          {/* <> */}

                          {updatedAccommodationArray2.map((el, index) =>
                            el.Availability.ReserveWhole &&
                            el.Availability.Available === true ? (
                              <div className="boxCont">
                                {el.Availability
                                  .RoomsAvailableForReservationCount *
                                  el.Availability.AccommodationsCount -
                                  el.Availability.ReservedPlacesCount >=
                                el.Availability.AccommodationsCount ? (
                                  //  style={(props.accommodationOption === "whole") && (el.Availability.AccommodationsCount < props.participantsCountWhole) && (props.skipper === "TAK") ? { opacity: "0.8", cursor: "default" } :
                                  //  (props.accommodationOption === "whole") && (el.Availability.AccommodationsCount < props.participantsCountWhole + 1) && (props.skipper === "NIE") ? { opacity: "0.8", cursor: "default" } : {}}

                                  <label className="boxInputRadio">
                                    <p className="titleText">
                                      {el.Name.includes('Lista')
                                        ? 'Lista rezerwowa'
                                        : el.Name}{' '}
                                      {el.Quality === '***' ? (
                                        <p>★★★</p>
                                      ) : el.Quality === '***+' ? (
                                        <p>★★★+</p>
                                      ) : el.Quality === '****' ? (
                                        <p>★★★★</p>
                                      ) : el.Quality === '****+' ? (
                                        <p>★★★★+</p>
                                      ) : el.Quality === '*****' ? (
                                        <p>★★★★★</p>
                                      ) : null}
                                    </p>
                                    <div className="imageTraining">
                                      <img
                                        src={el.Images[0]?.url}
                                        alt={el.Images[0]?.alt}
                                      />
                                    </div>
                                    <p className="description">
                                      {ReactHtmlParser(el.ShortDescription)}
                                    </p>
                                    <p
                                      className="price"
                                      style={
                                        el.Name.includes('Lista')
                                          ? { display: 'none' }
                                          : null
                                      }
                                    >
                                      {el.Price.Whole} PLN
                                    </p>

                                    <input
                                      type="radio"
                                      name="participantsCountWhole"
                                      title={
                                        el.Availability.AccommodationsCount
                                      }
                                      onClick={(event) =>
                                        props.handleChangeParticipantWholeYacht(
                                          props.id,
                                          event
                                        )
                                      }
                                      value={el.RoomId}
                                      id={el.Name}

                                      // checked={props.roomIdwhole === el.RoomId}
                                    ></input>

                                    <span className="checkmarkRadio"></span>
                                    <div className="boxAfterClick"></div>
                                  </label>
                                ) : (
                                  <label className="boxInputRadio">
                                    <div
                                      className="overlayOffer"
                                      style={
                                        isMobileScreen
                                          ? { display: 'none' }
                                          : null
                                      }
                                    >
                                      {' '}
                                      <img
                                        src={imageSter}
                                        alt="Lista rezerwowa"
                                      ></img>
                                    </div>
                                    <p className="titleText">
                                      {el.Name}{' '}
                                      {el.Quality === '***' ? (
                                        <p>★★★</p>
                                      ) : el.Quality === '***+' ? (
                                        <p>★★★+</p>
                                      ) : el.Quality === '****' ? (
                                        <p>★★★★</p>
                                      ) : el.Quality === '****+' ? (
                                        <p>★★★★+</p>
                                      ) : el.Quality === '*****' ? (
                                        <p>★★★★★</p>
                                      ) : null}
                                    </p>
                                    <div className="imageTraining">
                                      <img
                                        src={el.Images[0]?.url}
                                        alt={el.Images[0]?.alt}
                                      />
                                    </div>
                                    <p className="description">
                                      {ReactHtmlParser(el.ShortDescription)}
                                    </p>
                                    <p
                                      className="price"
                                      style={
                                        el.Name.includes('Lista')
                                          ? { display: 'none' }
                                          : null
                                      }
                                    >
                                      {el.Price.Whole} PLN
                                    </p>

                                    <input
                                      type="radio"
                                      name="participantsCountWhole"
                                      title={
                                        el.Availability.AccommodationsCount
                                      }
                                      onClick={(event) =>
                                        props.handleChangeParticipantWholeYacht(
                                          props.id,
                                          event
                                        )
                                      }
                                      value={el.RoomId}
                                      id={el.Name}

                                      // checked={props.roomIdwhole === el.RoomId}
                                    ></input>

                                    <span className="checkmarkRadio"></span>
                                    <div className="boxAfterClick"></div>
                                    <div
                                      className="infoAboutPlace"
                                      style={
                                        isMobileScreen
                                          ? {
                                              background: '#c33138',
                                              top: '90%',
                                              padding: '0px',
                                            }
                                          : { background: '#c33138' }
                                      }
                                    >
                                      <p>
                                        {isMobileScreen
                                          ? 'Brak miejsc. Lista rezerwowa'
                                          : 'Lista rezerwowa'}{' '}
                                      </p>
                                    </div>
                                  </label>
                                )}
                              </div>
                            ) : null
                          )}
                          {props.infoReserveList !== '' &&
                            props.infoReserveList !== null && (
                              <div
                                className="orangeBox"
                                style={{ background: 'rgb(195, 49, 56)' }}
                              >
                                <p style={{ color: 'white' }}>
                                  {props.infoReserveList}
                                </p>
                              </div>
                            )}
                        </div>
                      </div>
                      {updatedJourneyArray.length === 1 &&
                      updatedJourneyArrayName.length === 1 ? null : (
                        <div id="transportSelect" className="selectBox">
                          <h3>Dojazd</h3>
                          <div className="boxes" id="transportBoxes">
                            {StepTwoArr.Journeys.map((el) =>
                              el.Available === true ? (
                                <label
                                  className="boxInputRadio"
                                  style={{ marginRight: '20px' }}
                                >
                                  <p
                                    className="titleText"
                                    style={{ marginTop: '12px' }}
                                  >
                                    {el.Name.includes('własny') ||
                                    el.Name.includes('Własny')
                                      ? 'Dojazd Własny'
                                      : el.Name}
                                  </p>
                                  <p
                                    className="description"
                                    style={{ marginTop: '10px' }}
                                  >
                                    {el?.Details}
                                  </p>
                                  {el.IsPayOnPlace ? (
                                    <p
                                      className="price include"
                                      style={{
                                        marginTop: '10px',
                                        color: '#113852',
                                      }}
                                    >
                                      Płatność na miejscu
                                    </p>
                                  ) : el.Price === '0' || el.Price === 0 ? (
                                    <p
                                      className="price include"
                                      style={{ marginTop: '10px' }}
                                    ></p>
                                  ) : (
                                    <p
                                      className="price"
                                      style={{ marginTop: '10px' }}
                                    >
                                      +{el.Price} PLN
                                    </p>
                                  )}
                                  <input
                                    type="radio"
                                    name={props.id}
                                    checked={
                                      props.journeyIdWhole === el.JourneyId
                                    }
                                    onChange={(event) =>
                                      props.handleChangeJourneyWholeYacht(
                                        props.id,
                                        event
                                      )
                                    }
                                    value={el.JourneyId}
                                  ></input>
                                  <span className="checkmarkRadio"></span>
                                  <div className="boxAfterClick"></div>
                                </label>
                              ) : null
                            )}
                          </div>
                        </div>
                      )}

                      {props.journeyIdWhole === '1' ? (
                        <div className="containerSelect">
                          <h4>Miejsce odbioru</h4>

                          <p>
                            Wybierz najdogodniejsze dla siebie. Jeśli nie
                            zbierze się grupa - skontaktujemy się z Tobą min. 2
                            tygodnie przed wyjazdem.
                          </p>
                          <select
                            style={{ border: props.colorJourneyPoinId }}
                            name={props.id}
                            onChange={(event) =>
                              props.handleChangePointId(props.id, event)
                            }
                          >
                            <option
                              className="placeholderList"
                              selected="true"
                              disabled="disabled"
                            >
                              Wybierz z listy
                            </option>
                            {StepTwoArr.Journeys.map(
                              (el) =>
                                el.PointsList !== null &&
                                el.PointsList.map(
                                  (el) =>
                                    el.Available === true && (
                                      <option
                                        selected={
                                          el.JourneyPointId ===
                                          props.journeyPointIdWhole
                                        }
                                        name={props.id}
                                        value={el.JourneyPointId}
                                      >
                                        {el.Name}{' '}
                                        {el.Name === 'Kraków' ||
                                        el.Name === 'Szczecin' ||
                                        el.Name === 'Trójmiasto' ||
                                        el.Name === 'Wrocław' ||
                                        el.Name === 'Poznań'
                                          ? '(wyjazd możliwy pod warunkiem uzbierania się grupy min. 15 osób)'
                                          : null}{' '}
                                        {el.Price === 0 || el.Price === '0'
                                          ? ' '
                                          : '+' + el.Price + ' PLN'}
                                      </option>
                                    )
                                )
                            )}
                          </select>
                          <p style={{ color: '#de1212' }}>
                            {props.messageStepTwoJourneyPointId}
                          </p>
                        </div>
                      ) : //         :

                      //     props.journeyIdWhole !== "1" && props.journeyIdWhole !== "" && (updatedJourneyArray.length !== 1) ?
                      //         <div className="containerSelect">
                      //             <label style={{ marginBottom: "5px", marginTop: "20px" }} className="containerInputCheckbox">
                      //                 <p>Transport jednej sztuki bagażu 20 kg (+200 PLN)</p>
                      //                 <input type="checkbox" name="transportAddLuggage" checked={props.transportAddLuggage === true} value={props.transportAddLuggage} onChange={(event) => props.handleChangeAddTransport(props.id, event)}></input>
                      //                 <span className="checkmark"></span>
                      //             </label>
                      //             <p style={{ marginLeft: "35px", marginTop: 0, color: "grey" }}>Osoby, które zdecydują się na dojazd własny mogą dokupić transport bagażu</p>

                      // </div>

                      null}
                    </div>
                  </div>
                </div>
              ) : (
                // FIRST USER - WHOLE RESERVATION - END
                // NEXT USERS - WHOLE RESERVATION
                <div className="onePerson">
                  {/* {props.userNum.map((item) => */}
                  <div id={props.id}>
                    <div className="name">
                      {props.inputAddNameActive ? (
                        <>
                          {props.newName ? (
                            <div className="containerAddName">
                              <p>{props.id}. </p>

                              <input
                                type="text"
                                placeholder="Imię"
                                name="FirstName"
                                className=""
                                value={props.valueName}
                                onChange={(event) =>
                                  props.handleEditNameWholeReservation(
                                    props.id,
                                    event
                                  )
                                }
                              ></input>
                              <input
                                type="text"
                                placeholder="Nazwisko"
                                name="SurName"
                                className=""
                                value={props.valueSurname}
                                onChange={(event) =>
                                  props.handleEditNameWholeReservation2(
                                    props.id,
                                    event
                                  )
                                }
                              ></input>
                              <button
                                className="secondaryBtn"
                                onClick={(event) =>
                                  props.handleSaveName(props.id, event)
                                }
                              >
                                Zapisz
                              </button>
                            </div>
                          ) : (
                            <>
                              <p>
                                {props.id}. {props.valueName}{' '}
                                {props.valueSurname}
                              </p>
                              <p
                                className="price edit"
                                onClick={() =>
                                  props.handleEditNameWhole(props.id)
                                }
                              >
                                <img src={imagePen} alt="Edycja uczestnika" />
                                edytuj imię i nazwisko
                              </p>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <p>{props.id}. Uczestnik</p>
                          <>
                            <p
                              className="price edit"
                              onClick={() => props.handleAddName(props.id)}
                            >
                              <img src={imagePen} alt="Edycja uczestnika" />
                              Dodaj imię i nazwisko
                            </p>
                          </>
                        </>
                      )}
                    </div>
                    {updatedJourneyArray.length === 1 &&
                    updatedJourneyArrayName.length === 1 ? null : (
                      <div id="accomodationSelect" className="selectBox">
                        <h3>Dojazd</h3>
                        <div className="containerSelect">
                          <label className="containerInputCheckbox">
                            <p>Tak samo jak 1. uczestnik</p>
                            <input
                              type="checkbox"
                              name="sameAsTheFirst"
                              checked={props.sameAsTheFirst === true}
                              value={props.sameAsTheFirst}
                              onChange={(event) =>
                                props.handleChange4(props.id, event)
                              }
                            ></input>
                            <span className="checkmark"></span>
                          </label>
                          {props.sameAsTheFirst !== true && (
                            <>
                              <div className="containerSelect">
                                <p>Środek transportu</p>
                                <select
                                  onChange={(event) =>
                                    props.handleChange5(props.id, event)
                                  }
                                >
                                  <option
                                    className="placeholderList"
                                    selected="true"
                                    disabled="disabled"
                                  >
                                    Wybierz z listy
                                  </option>
                                  {StepTwoArr.Journeys.map((el) => (
                                    <>
                                      {el.Available === true ? (
                                        <option
                                          name={props.id}
                                          value={el.JourneyId}
                                          selected={
                                            el.JourneyId === props.journeyId
                                          }
                                        >
                                          {el.Name.includes('własny') ||
                                          el.Name.includes('Własny')
                                            ? 'Dojazd Własny'
                                            : el.Name}
                                        </option>
                                      ) : null}
                                    </>
                                  ))}
                                </select>
                              </div>
                              {StepTwoArr.Journeys.map((el) =>
                                el.JourneyId === '1' &&
                                props.journeyId === '1' ? (
                                  <div className="addOption">
                                    <h4>Miejsce odbioru</h4>
                                    <form>
                                      {el.PointsList.map((item) => (
                                        <label className="containerInputCheckbox">
                                          <p>
                                            {item.Name}{' '}
                                            <span
                                              style={{
                                                display: 'inline',
                                                color: '#193e57',
                                                fontSize: '13px',
                                              }}
                                            >
                                              {item.Name === 'Kraków' ||
                                              item.Name === 'Szczecin' ||
                                              item.Name === 'Trójmiasto' ||
                                              item.Name === 'Wrocław' ||
                                              item.Name === 'Poznań'
                                                ? '(wyjazd możliwy pod warunkiem uzbierania się grupy min. 15 osób)'
                                                : null}
                                            </span>{' '}
                                            {item.Price === 0 ||
                                            item.Price === '0'
                                              ? ' '
                                              : '+' + item.Price + ' PLN'}
                                          </p>
                                          <input
                                            type="radio"
                                            name={props.id}
                                            onChange={(event) =>
                                              props.handleChange6(
                                                props.id,
                                                event
                                              )
                                            }
                                            value={item.JourneyPointId}
                                          ></input>
                                          <span className="checkmark"></span>
                                        </label>
                                      ))}
                                    </form>
                                    {/* <p className="warningText"><span>Uwaga:</span> Przy innych opcjach odbioru możecie nie być razem w autokarze</p> */}
                                  </div>
                                ) : null
                              )}
                            </>
                          )}
                          {/* <p className="warningText"><span>Uwaga:</span> Wybrano kilka różnych rodzajów i/lub klas zakwaterowania, co oznacza, ze nie wszyscy uczestnicy zostaną zakwaterowani w tym samym obiekcie.</p> */}
                          {/* {(props.journeyIdWhole !== "1" && props.journeyIdWhole !== "") && (updatedJourneyArray.length !== 1) ?
                                                        <div className="containerSelect" style={{ marginTop: "20px" }}>
                                                            <label style={{ marginBottom: "5px" }} className="containerInputCheckbox"><p>Transport jednej sztuki bagażu 20 kg (+200 PLN)</p><input type="checkbox" name="transportAddLuggage" checked={props.transportAddLuggage === true} value={props.transportAddLuggage} onChange={(event) => props.handleChangeAddTransport(props.id, event)}></input>
                                                                <span className="checkmark"></span>
                                                            </label>
                                                            <p>Osoby, które zdecydują się na dojazd własny mogą dokupić transport bagażu</p>

                                                        </div>


                                                        : null} */}
                          {props.yachtClass === 'Klasa LUXURY' ||
                          props.accommodationOption === 'whole' ||
                          (props.accommodationConfig[0].yachtClass ===
                            'Klasa LUXURY' &&
                            props.sameAsTheFirst === true) ? null : (
                            <div id="transportSelect" className="selectBox">
                              <h3>Opcje dodatkowe</h3>
                              <p
                                style={{ fontFamily: 'SpaceGrotesk-SemiBold' }}
                              >
                                Rezerwacja Kabiny 2-osobowej
                              </p>
                              <p
                                style={{
                                  fontSize: '14px',
                                  marginTop: '20px',
                                  maxWidth: '450px',
                                }}
                              >
                                Opcja dostępna dla rezerwacji 2-osobowych.
                                <br></br> Jeśli chcesz zarezerwować pojedyncze
                                miejsce w kajucie, skontaktuj się z{' '}
                                <Link
                                  to="/kontakt"
                                  target={'_blank'}
                                  style={{
                                    fontFamily: 'SpaceGrotesk-SemiBold',
                                  }}
                                  title="Kontakt"
                                >
                                  Obsługą Klienta
                                </Link>
                                .
                              </p>
                            </div>
                          )}
                        </div>

                        {props.accommodationOption === 'single' &&
                          (updatedJourneyArray.length === 1 &&
                          updatedJourneyArrayName.length === 1 ? null : (
                            <>
                              <div className="containerSelect">
                                <h3>Dojazd</h3>
                                <p>Środek transportu</p>
                                <select
                                  onChange={(event) =>
                                    props.handleChange5(props.id, event)
                                  }
                                >
                                  <option
                                    className="placeholderList"
                                    selected="true"
                                    disabled="disabled"
                                  >
                                    Wybierz z listy
                                  </option>
                                  {StepTwoArr.Journeys.map((el) => (
                                    <>
                                      {el.Available === true ? (
                                        <option
                                          name={props.id}
                                          value={el.JourneyId}
                                          selected={
                                            el.JourneyId === props.journeyId
                                          }
                                        >
                                          {el.Name.includes('własny') ||
                                          el.Name.includes('Własny')
                                            ? 'Dojazd Własny'
                                            : el.Name}
                                        </option>
                                      ) : null}
                                    </>
                                  ))}
                                </select>
                              </div>
                              {StepTwoArr.Journeys.map((el) =>
                                el.JourneyId === '1' &&
                                props.journeyId === '1' ? (
                                  <div className="addOption">
                                    <h4>Miejsce odbioru</h4>
                                    <form>
                                      {el.PointsList.map((item) => (
                                        <label className="containerInputCheckbox">
                                          <p>
                                            {item.Name}{' '}
                                            <span
                                              style={{
                                                display: 'inline',
                                                color: '#193e57',
                                                fontSize: '13px',
                                              }}
                                            >
                                              {item.Name === 'Kraków' ||
                                              item.Name === 'Szczecin' ||
                                              item.Name === 'Trójmiasto' ||
                                              item.Name === 'Wrocław' ||
                                              item.Name === 'Poznań'
                                                ? '(wyjazd możliwy pod warunkiem uzbierania się grupy min. 15 osób)'
                                                : null}
                                            </span>{' '}
                                            {item.Price === 0 ||
                                            item.Price === '0'
                                              ? ' '
                                              : '+' + item.Price + ' PLN'}
                                          </p>
                                          <input
                                            type="radio"
                                            name={props.id}
                                            onChange={(event) =>
                                              props.handleChange6(
                                                props.id,
                                                event
                                              )
                                            }
                                            value={item.JourneyPointId}
                                          ></input>
                                          <span className="checkmark"></span>
                                        </label>
                                      ))}
                                    </form>
                                    {/* <p className="warningText"><span>Uwaga:</span> Przy innych opcjach odbioru możecie nie być razem w autokarze</p> */}
                                  </div>
                                ) : null
                              )}

                              {/* {(props.journeyIdWhole !== "1" && props.journeyIdWhole !== "") && (updatedJourneyArray.length !== 1) ?
                                                                <div className="containerSelect" style={{ marginTop: "20px" }}>
                                                                    <label style={{ marginBottom: "5px" }} className="containerInputCheckbox"><p>Transport jednej sztuki bagażu 20 kg (+200 PLN)</p><input type="checkbox" name="transportAddLuggage" checked={props.transportAddLuggage === true} value={props.transportAddLuggage} onChange={(event) => props.handleChangeAddTransport(props.id, event)}></input>
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                    <p>Osoby, które zdecydują się na dojazd własny mogą dokupić transport bagażu</p>

                                                                </div>


                                                                : null} */}
                            </>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              )
              // NEXT USERS - WHOLE RESERVATION - END
            }
          </>
        )}
      </>
    )
    // SECTION WHOLE RESERVATION - END
  );
};

const StepTwo = (props) => {
  useEffect(() => {
    IAmForAnalitics();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      id="stepTwo"
      style={{ position: 'relative' }}
      // style={props.buttonGrey === true ? { opacity: "0.4", cursor: "default" } : { opacity: "1", cursor: "pointer" }}
    >
      {(props.buttonGrey === true || !props.buttonAvailableStepTwo) && (
        <div id="stepMask"></div>
      )}
      <div className="container">
        {/* CREATE ACCOMMODATION ARRAY BASED ON THE NUMBER OF PARTICIPANTS */}
        {props.accommodationConfig.map((el, index) => (
          <OnePerson
            key={index + 1}
            id={el.id}
            colorRoom={props.colorRoom}
            handleChangePointId={props.handleChangePointId}
            messageStepTwoRoom2={props.messageStepTwoRoom2}
            messageStepTwoJourney2={props.messageStepTwoJourney2}
            messageOnePersonJourney={el.messageOnePersonJourney}
            handleEditNameSingleReservation={
              props.handleEditNameSingleReservation
            }
            infoReserveList={props.infoReserveList}
            handleEditNameSingleReservation2={
              props.handleEditNameSingleReservation2
            }
            handleEditNameWholeReservation={
              props.handleEditNameWholeReservation
            }
            handleEditNameWholeReservation2={
              props.handleEditNameWholeReservation2
            }
            handleEditNameSingle={props.handleEditNameSingle}
            handleEditNameWhole={props.handleEditNameWhole}
            messageStepTwoJourneyPointId={props.messageStepTwoJourneyPointId}
            colorJourneyPoinId={props.colorJourneyPoinId}
            handleChangeAddTransport={props.handleChangeAddTransport}
            messageStepTwoJourney={props.messageStepTwoJourney}
            messageStepTwoRoom={props.messageStepTwoRoom}
            colorJourney={props.colorJourney}
            reservationId={props.reservationId}
            Reservation={props.Reservation}
            bedroomChosen={el.bedroomChosen}
            handleAddName={props.handleAddName}
            journeyIdWhole={props.journeyId}
            journeyPointIdWhole={props.journeyPointId}
            handleSaveName={props.handleSaveName}
            valueName={el.valueName}
            handleChangeJourneyWholeYacht={props.handleChangeJourneyWholeYacht}
            transportAddLuggage={el.transportAddLuggage}
            valueSurname={el.valueSurname}
            newName={el.newName}
            handleChangeParticipantWholeYacht={
              props.handleChangeParticipantWholeYacht
            }
            handleChange={props.handleChange}
            town={props.town}
            accommodationOption={props.accommodationOption}
            participantsCount={props.participantsCount}
            participantsCountWhole={props.participantsCountWhole}
            addOptions={props.addOptions}
            handleChange27={props.handleChange27}
            people={el.people}
            reserveListJourneyOption={el.reserveListJourneyOption}
            sameAsTheFirst={el.sameAsTheFirst}
            handleChange14={props.handleChange14}
            nameClass={el.nameClass}
            handleCheckboxChange={props.handleCheckboxChange}
            handleChange4={props.handleChange4}
            roomIdwhole={props.roomId}
            handleChange5={props.handleChange5}
            handleChange6={props.handleChange6}
            skipper={props.skipper}
            accommodationConfig={props.accommodationConfig}
            yachtClass={el.yachtClass}
            inputAddNameActive={el.inputAddNameActive}
            userNum={el.userNum}
            roomId={el.roomId}
            journeyId={el.journeyId}
            journeyPointId={el.journeyPointId}
          />
        ))}
        {/* CREATE ACCOMMODATION ARRAY BASED ON THE NUMBER OF PARTICIPANTS - END */}
        <p className="errorMessage" style={{ fontSize: '15px' }}>
          {props.messageStepTwo}
          <br></br>
          {props.messageStepTwoJourney2}
          <br></br>
          {props.messageStepTwoRoom2}
        </p>
        <p className="errorMessage">{props.messageStepTwoCabin}</p>

        {
          props.accommodationOption === 'whole' ? (
            // BUTTONS GET/UPDATE CURRENT STEP - WHOLE RESERVATION
            <div className="containerButtons">
              {props.buttonGrey === true ? (
                <>
                  <button
                    className="secondaryBtn"
                    id="disabled"
                    style={{ opacity: '0.5' }}
                    onClick={props.handleBackToStepOne}
                  >
                    Wstecz
                  </button>
                  <button
                    className="primaryBtn"
                    id="disabled"
                    style={{ backgroundColor: 'grey' }}
                    onClick={props.handleUpdateStepTwoWholeYacht}
                  >
                    Dalej
                  </button>
                  <div className="lds-roller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </>
              ) : (
                <>
                  <button
                    className="secondaryBtn"
                    onClick={props.handleBackToStepOne}
                  >
                    Wstecz
                  </button>
                  <button
                    className="primaryBtn"
                    onClick={props.handleUpdateStepTwoWholeYacht}
                  >
                    Dalej
                  </button>
                </>
              )}
            </div>
          ) : (
            //  BUTTONS GET/UPDATE CURRENT STEP - WHOLE RESERVATION - END
            // BUTTONS GET/UPDATE CURRENT STEP - SINGLE OR CABIN RESERVATION
            <div className="containerButtons">
              <button
                className="secondaryBtn"
                onClick={props.handleBackToStepOne}
              >
                Wstecz
              </button>
              <button
                className="primaryBtn"
                disabled={props.buttonAvailableStepTwo ? '' : 'disabled'}
                id={props.buttonAvailableStepTwo ? null : 'disabledButton'}
                onClick={props.handleUpdateStepTwoSingleReservation}
              >
                Dalej
              </button>
              {!props.buttonAvailableStepTwo && (
                <div className="lds-roller">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
            </div>
          )
          // BUTTONS GET/UPDATE CURRENT STEP - SINGLE OR CABIN RESERVATION - END
        }
      </div>

      <TripBox
        Reservation={props.Reservation}
        accommodationOption={props.accommodationOption}
      />
    </div>
  );
};

export default StepTwo;
