import React, { useEffect } from 'react';
import "./StepSix.scss";
import "./TripBox.scss";
import regulamin from "./userRegistration/documents/Regulamin_TBT_29.11.2021.pdf"
import Moment from 'moment';
import { IAmForAnalitics } from "./utilsApp";
import calendarImage from "./img/calendar.png";

const TripBox = (props) => {
    return (
        <div id="tripBox">
            <div>
                <div className="imageContainer">
                    <img src={props.Reservation.Offer.ListImg?.url} alt={props.Reservation.Offer.ListImg?.alt} />
                </div>
                <div className="textBox">
                    <h4>{props.Reservation.Offer.Name}</h4>
                    <p className="date"><img src={calendarImage} alt="termin" />{Moment(props.Reservation.Offer.From).format('DD.MM') + "-" + Moment(props.Reservation.Offer.To).format('DD.MM.YYYY')}</p>
                    <div className="chooseOptions">
                        <p className="title">Wybrane opcje:</p>
                        {props.Reservation.Offer.OptionsList.map((el) =>
                            <>
                                {el.PricePerSingleItem !== 0 ?
                                    <>
                                        <p className="options">{el.Name === "Rabaty" ? el.Name : `${el.Name} x ${el.ItemCount}`}</p>
                                        <p className="price">{el.PricePerSingleItem} PLN</p>
                                    </> : null
                                }
                            </>
                        )}
                    </div>
                    <p className="price">{props.Reservation.Offer.CurrentPrice} PLN</p>
                    {/* REBATES ARRAY */}
                    {(props?.Reservation?.Offer?.CurrentRebate !== 0) &&
                        <>
                            <p className="postscript">Cena do zapłaty po uwzględnieniu rabatu</p>
                            <p className="rebate">
                                <span style={{ fontFamily: "SpaceGrotesk-SemiBold" }}>W tym rabat, na który składa się:</span>
                                {props.Reservation.Offer.RebatesList.map(el =>
                                    <p className="rebate">{el.Name}:
                            <span> {props.Reservation.Data[0].Config.AccommodationOption === "single"
                                            && el.Name?.includes("First") ?
                                            props.Reservation.Data[0].Config.ParticipantsCount * 200 + " PLN" :
                                            props.Reservation.Data[0].Config.AccommodationOption === "whole"
                                                && el.Name?.includes("First") ?
                                                props.Reservation.Data[0].Config.ParticipantsCount * 300 + " PLN" :
                                                props.Reservation.Data[0].Config.AccommodationOption === "single"
                                                    && el.Name?.includes("Second") ?
                                                    props.Reservation.Data[0].Config.ParticipantsCount * 100 + " PLN" :
                                                    props.Reservation.Data[0].Config.AccommodationOption === "whole"
                                                        && el.Name?.includes("Second") ?
                                                        props.Reservation.Data[0].Config.ParticipantsCount * 200 + " PLN" :
                                                        el.Name?.includes("Voucher") ?
                                                            null :
                                                            el.Value + " PLN"}  </span></p>
                                )}

                            </p>
                        </>
                    }
                    {/* REBATES ARRAY - END */}
                </div>
            </div>
            {props.Reservation.Data[1].Config.IsReserveList === 1 && <div className="orangeBox" id="infoReservationListInStep"><p>UWAGA: Zapisujesz się na listę rezerwową</p></div>}
        </div>
    );
}

const OnePerson = (props) => {
    const StepSixArr = props.Reservation.Data.find((el) => el.Step === 6);
    const SummaryPerParticipant = StepSixArr.SummaryPerParticipant;
    const prices = SummaryPerParticipant.UserNum1.map(el => JSON.parse(el.Price));
    const sumPrices = prices.reduce((a, b) => a + b);

    return (
        // USER NR 1 - START
        props.userNum === 1 ?
            <div className="onePerson">
                <div className="name">
                    <p>{props.userNum}. Uczestnik (Ty)</p>
                    <p className="price">{sumPrices} PLN</p>
                </div>
                <div className="optionsContainer">
                    <h4>Wybrane opcje</h4>
                    <table className="detailsBox">
                        {SummaryPerParticipant.UserNum1.map((el) =>
                        el.Description !== "Rabaty" &&
                            <tr>
                                <td className="firstColumn">{el.Description}</td>
                                <td className="secondColumn">{el.extendData?.IsPayOnPlace ? "Płatność na miejscu" : el.Price === "0" || el.Price === 0 ? "W cenie wyjazdu" : el.Price + " PLN"} </td>
                            </tr>
                        )}
                    </table>
                    <div className="vouchersBox">
                        <div className="containerSelect">
                            <label className="containerInputCheckbox"><p>Posiadam voucher</p><input type="checkbox" name="haveAVoucher" checked={props.haveAVoucher} value={props.haveAVoucher} onChange={(event) => props.handleChange24(props.id, event)}></input>
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        {props.haveAVoucher ? <div className="vouchersContainer">
                            <p>Numer vouchera uczestnika</p>
                            <div className="vouchers">
                                <input type="text" name="voucherCode1" value={props.voucherCode1} onChange={props.handleChange} />
                                <button onClick={() => props.handleSubmitVoucher(props.id)} className="secondaryBtn">Przelicz</button>
                            </div>
                            <p style={{ color: props.colorMessage1 }}>{props.messageVoucher1}</p>
                        </div> : null}
                    </div>
                </div>
                <h4>Twoje dane</h4>
                <table className="detailsBox">
                    <tr>
                        <td className="firstColumn">Imię:</td>
                        <td className="secondColumn">{props.firstName}</td>
                    </tr>
                    <tr>
                        <td className="firstColumn">Nazwisko:</td>
                        <td className="secondColumn">{props.lastName}</td>
                    </tr>
                    <tr>
                        <td className="firstColumn">Data urodzenia:</td>
                        <td className="secondColumn">{props.birthDate}</td>
                    </tr>

                    <tr>
                        <td className="firstColumn">Kraj zamieszkania:</td>
                        <td className="secondColumn">{props.country}</td>
                    </tr>
                    <tr>
                        <td className="firstColumn">Seria i numer dowodu:</td>
                        <td className="secondColumn">{props.idDocument}</td>
                    </tr>
                    <tr>
                        <td className="firstColumn">Płeć:</td>
                        <td className="secondColumn">{props.gender === "1" ? "Kobieta" : "Mężczyzna"}</td>
                    </tr>
                    <tr>
                        <td className="firstColumn">Numer telefonu:</td>
                        <td className="secondColumn">{props.phone}</td>
                    </tr>
                    <tr>
                        <td className="firstColumn">E-mail:</td>
                        <td className="secondColumn">{props.email}</td>
                    </tr>

                </table>
            </div>
            // USER NR 1 - END
            :
            // USER NR 2 - START
            props.userNum === 2 ?
                <div className="onePerson">
                    <div className="name">
                        <p>{props.userNum}. {props.valueName !== "" ? props.valueName : "Uczestnik"} {props.valueSurname !== "" ? props.valueSurname : ""} </p>
                    </div>
                    <div className="optionsContainer">
                        <h4>Wybrane opcje</h4>
                        <table className="detailsBox">
                            {SummaryPerParticipant.UserNum2.map((el) =>
                            el.Description !== "Rabaty" &&
                                <tr>
                                    <td className="firstColumn">{el.Description}</td>
                                    <td className="secondColumn">{el.extendData?.IsPayOnPlace ? "Płatność na miejscu" : el.Price === "0" || el.Price === 0 ? "W cenie wyjazdu" : el.Price + " PLN"} </td>
                                </tr>
                            )}
                        </table>
                        <div className="vouchersBox">
                            <div className="containerSelect">
                                <label className="containerInputCheckbox"><p>Posiadam voucher</p><input type="checkbox" name="haveAVoucher" checked={props.haveAVoucher} value={props.haveAVoucher} onChange={(event) => props.handleChange24(props.id, event)}></input>
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            {props.haveAVoucher ? <div className="vouchersContainer">
                                <p>Numer vouchera uczestnika</p>
                                <div className="vouchers">
                                    <input type="text" name="voucherCode2" value={props.voucherCode2} onChange={props.handleChange} />
                                    <button onClick={() => props.handleSubmitVoucher(props.id)} className="secondaryBtn">Przelicz</button>
                                </div>
                                <p style={{ color: props.colorMessage2 }}>{props.messageVoucher2}</p>
                            </div> : null}
                        </div>
                    </div>
                </div>
                // USER NR 2 - END
                :
                // USER NR 3 - START
                props.userNum === 3 ?
                    <div className="onePerson">
                        <div className="name">
                            <p>{props.userNum}. {props.valueName !== "" ? props.valueName : "Uczestnik"} {props.valueSurname !== "" ? props.valueSurname : ""} </p>
                        </div>
                        <div className="optionsContainer">
                            <h4>Wybrane opcje</h4>
                            <table className="detailsBox">
                                {SummaryPerParticipant.UserNum3.map((el) =>
                                el.Description !== "Rabaty" &&
                                    <tr>
                                        <td className="firstColumn">{el.Description}</td>
                                        <td className="secondColumn">{el.extendData?.IsPayOnPlace ? "Płatność na miejscu" : el.Price === "0" || el.Price === 0 ? "W cenie wyjazdu" : el.Price + " PLN"} </td>
                                    </tr>
                                )}
                            </table>
                            <div className="vouchersBox">
                                <div className="containerSelect">
                                    <label className="containerInputCheckbox"><p>Posiadam voucher</p><input type="checkbox" name="haveAVoucher" checked={props.haveAVoucher} value={props.haveAVoucher} onChange={(event) => props.handleChange24(props.id, event)}></input>
                                        <span className="checkmark"></span>
                                    </label>
                                </div>
                                {props.haveAVoucher ? <div className="vouchersContainer">
                                    <p>Numer vouchera uczestnika</p>
                                    <div className="vouchers">
                                        <input type="text" name="voucherCode3" value={props.voucherCode3} onChange={props.handleChange} />
                                        <button onClick={() => props.handleSubmitVoucher(props.id)} className="secondaryBtn">Przelicz</button></div>
                                    <p style={{ color: props.colorMessage3 }}>{props.messageVoucher3}</p>
                                </div> : null}
                            </div>
                        </div>
                    </div>
                    // USER NR 3 - END
                    :
                    // USER NR 4 - START
                    props.userNum === 4 ?
                        <div className="onePerson">
                            <div className="name">
                                <p>{props.userNum}. {props.valueName !== "" ? props.valueName : "Uczestnik"} {props.valueSurname !== "" ? props.valueSurname : ""} </p>
                            </div>
                            <div className="optionsContainer">
                                <h4>Wybrane opcje</h4>
                                <table className="detailsBox">
                                    {SummaryPerParticipant.UserNum4.map((el) =>
                                    el.Description !== "Rabaty" &&
                                        <tr>
                                            <td className="firstColumn">{el.Description}</td>
                                            <td className="secondColumn">{el.extendData?.IsPayOnPlace ? "Płatność na miejscu" : el.Price === "0" || el.Price === 0 ? "W cenie wyjazdu" : el.Price + " PLN"} </td>
                                        </tr>
                                    )}
                                </table>
                                <div className="vouchersBox">
                                    <div className="containerSelect">
                                        <label className="containerInputCheckbox"><p>Posiadam voucher</p><input type="checkbox" name="haveAVoucher" checked={props.haveAVoucher} value={props.haveAVoucher} onChange={(event) => props.handleChange24(props.id, event)}></input>
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    {props.haveAVoucher ? <div className="vouchersContainer">
                                        <p>Numer vouchera uczestnika</p>
                                        <div className="vouchers">
                                            <input type="text" name="voucherCode4" value={props.voucherCode4} onChange={props.handleChange} />
                                            <button onClick={() => props.handleSubmitVoucher(props.id)} className="secondaryBtn">Przelicz</button></div>
                                        <p style={{ color: props.colorMessage4 }}>{props.messageVoucher4}</p>
                                    </div> : null}
                                </div>
                            </div>
                        </div>
                        // USER NR 4 - END
                        :
                        // USER NR 5 - START
                        props.userNum === 5 ?
                            <div className="onePerson">
                                <div className="name">
                                    <p>{props.userNum}. {props.valueName !== "" ? props.valueName : "Uczestnik"} {props.valueSurname !== "" ? props.valueSurname : ""} </p>
                                </div>
                                <div className="optionsContainer">
                                    <h4>Wybrane opcje</h4>
                                    <table className="detailsBox">
                                        {SummaryPerParticipant.UserNum5.map((el) =>
                                        el.Description !== "Rabaty" &&
                                            <tr>
                                                <td className="firstColumn">{el.Description}</td>
                                                <td className="secondColumn">{el.extendData?.IsPayOnPlace ? "Płatność na miejscu" : el.Price === "0" || el.Price === 0 ? "W cenie wyjazdu" : el.Price + " PLN"} </td>
                                            </tr>
                                        )}
                                    </table>
                                    <div className="vouchersBox">
                                        <div className="containerSelect">
                                            <label className="containerInputCheckbox"><p>Posiadam voucher</p><input type="checkbox" name="haveAVoucher" checked={props.haveAVoucher} value={props.haveAVoucher} onChange={(event) => props.handleChange24(props.id, event)}></input>
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        {props.haveAVoucher ? <div className="vouchersContainer">
                                            <p>Numer vouchera uczestnika</p>
                                            <div className="vouchers">
                                                <input type="text" name="voucherCode5" value={props.voucherCode5} onChange={props.handleChange} />
                                                <button onClick={() => props.handleSubmitVoucher(props.id)} className="secondaryBtn">Przelicz</button></div>
                                            <p style={{ color: props.colorMessage5 }}>{props.messageVoucher5}</p>
                                        </div> : null}
                                    </div>
                                </div>
                            </div>
                            // USER NR 5 - END
                            :
                            // USER NR 6 - START
                            props.userNum === 6 ?
                                <div className="onePerson">
                                    <div className="name">
                                        <p>{props.userNum}. {props.valueName !== "" ? props.valueName : "Uczestnik"} {props.valueSurname !== "" ? props.valueSurname : ""} </p>
                                    </div>
                                    <div className="optionsContainer">
                                        <h4>Wybrane opcje</h4>
                                        <table className="detailsBox">
                                            {SummaryPerParticipant.UserNum6.map((el) =>
                                            el.Description !== "Rabaty" &&
                                                <tr>
                                                    <td className="firstColumn">{el.Description}</td>
                                                    <td className="secondColumn">{el.extendData?.IsPayOnPlace ? "Płatność na miejscu" : el.Price === "0" || el.Price === 0 ? "W cenie wyjazdu" : el.Price + " PLN"} </td>
                                                </tr>
                                            )}
                                        </table>
                                        <div className="vouchersBox">
                                            <div className="containerSelect">
                                                <label className="containerInputCheckbox"><p>Posiadam voucher</p><input type="checkbox" name="haveAVoucher" checked={props.haveAVoucher} value={props.haveAVoucher} onChange={(event) => props.handleChange24(props.id, event)}></input>
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                            {props.haveAVoucher ? <div className="vouchersContainer">
                                                <p>Numer vouchera uczestnika</p>
                                                <div className="vouchers">
                                                    <input type="text" name="voucherCode6" value={props.voucherCode6} onChange={props.handleChange} />
                                                    <button onClick={() => props.handleSubmitVoucher(props.id)} className="secondaryBtn">Przelicz</button></div>
                                                <p style={{ color: props.colorMessage6 }}>{props.messageVoucher6}</p>
                                            </div> : null}
                                        </div>
                                    </div>
                                </div>
                                // USER NR 6 - END
                                :
                                // USER NR 7 - START
                                props.userNum === 7 ?
                                    <div className="onePerson">
                                        <div className="name">
                                            <p>{props.userNum}. {props.valueName !== "" ? props.valueName : "Uczestnik"} {props.valueSurname !== "" ? props.valueSurname : ""} </p>
                                        </div>
                                        <div className="optionsContainer">
                                            <h4>Wybrane opcje</h4>
                                            <table className="detailsBox">
                                                {SummaryPerParticipant.UserNum7.map((el) =>
                                                el.Description !== "Rabaty" &&
                                                    <tr>
                                                        <td className="firstColumn">{el.Description}</td>
                                                        <td className="secondColumn">{el.extendData?.IsPayOnPlace ? "Płatność na miejscu" : el.Price === "0" || el.Price === 0 ? "W cenie wyjazdu" : el.Price + " PLN"} </td>
                                                    </tr>
                                                )}
                                            </table>
                                            <div className="vouchersBox">
                                                <div className="containerSelect">
                                                    <label className="containerInputCheckbox"><p>Posiadam voucher</p><input type="checkbox" name="haveAVoucher" checked={props.haveAVoucher} value={props.haveAVoucher} onChange={(event) => props.handleChange24(props.id, event)}></input>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                {props.haveAVoucher ? <div className="vouchersContainer">
                                                    <p>Numer vouchera uczestnika</p>
                                                    <div className="vouchers">
                                                        <input type="text" name="voucherCode7" value={props.voucherCode7} onChange={props.handleChange} />
                                                        <button onClick={() => props.handleSubmitVoucher(props.id)} className="secondaryBtn">Przelicz</button></div>
                                                    <p style={{ color: props.colorMessage7 }}>{props.messageVoucher7}</p>
                                                </div> : null}
                                            </div>
                                        </div>
                                    </div>
                                    // USER NR 7 - END
                                    :
                                    // USER NR 8 - START
                                    props.userNum === 8 ?
                                        <div className="onePerson">
                                            <div className="name">
                                                <p>{props.userNum}. {props.valueName !== "" ? props.valueName : "Uczestnik"} {props.valueSurname !== "" ? props.valueSurname : ""} </p>

                                            </div>
                                            <div className="optionsContainer">
                                                <h4>Wybrane opcje</h4>
                                                <table className="detailsBox">
                                                    {SummaryPerParticipant.UserNum8.map((el) =>
                                                    el.Description !== "Rabaty" &&
                                                        <tr>
                                                            <td className="firstColumn">{el.Description}</td>
                                                            <td className="secondColumn">{el.extendData?.IsPayOnPlace ? "Płatność na miejscu" : el.Price === "0" || el.Price === 0 ? "W cenie wyjazdu" : el.Price + " PLN"} </td>
                                                        </tr>
                                                    )}

                                                </table>
                                                <div className="vouchersBox">
                                                    <div className="containerSelect">
                                                        <label className="containerInputCheckbox"><p>Posiadam voucher</p><input type="checkbox" name="haveAVoucher" checked={props.haveAVoucher} value={props.haveAVoucher} onChange={(event) => props.handleChange24(props.id, event)}></input>
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </div>
                                                    {props.haveAVoucher ? <div className="vouchersContainer">
                                                        <p>Numer vouchera uczestnika</p>
                                                        <div className="vouchers">
                                                            <input type="text" name="voucherCode8" value={props.voucherCode8} onChange={props.handleChange} />
                                                            <button onClick={() => props.handleSubmitVoucher(props.id)} className="secondaryBtn">Przelicz</button></div>
                                                        <p style={{ color: props.colorMessage8 }}>{props.messageVoucher8}</p>
                                                    </div> : null}
                                                </div>

                                            </div>
                                        </div>
                                        // USER NR 8 - END
                                        :
                                        // USER NR 9 - START
                                        props.userNum === 9 ?
                                            <div className="onePerson">

                                                <div className="name">
                                                    <p>{props.userNum}. {props.valueName !== "" ? props.valueName : "Uczestnik"} {props.valueSurname !== "" ? props.valueSurname : ""} </p>

                                                </div>
                                                <div className="optionsContainer">
                                                    <h4>Wybrane opcje</h4>
                                                    <table className="detailsBox">
                                                        {SummaryPerParticipant.UserNum9.map((el) =>
                                                        el.Description !== "Rabaty" &&
                                                            <tr>
                                                                <td className="firstColumn">{el.Description}</td>
                                                                <td className="secondColumn">{el.extendData?.IsPayOnPlace ? "Płatność na miejscu" : el.Price === "0" || el.Price === 0 ? "W cenie wyjazdu" : el.Price + " PLN"} </td>
                                                            </tr>
                                                        )}

                                                    </table>
                                                    <div className="vouchersBox">
                                                        <div className="containerSelect">
                                                            <label className="containerInputCheckbox"><p>Posiadam voucher</p><input type="checkbox" name="haveAVoucher" checked={props.haveAVoucher} value={props.haveAVoucher} onChange={(event) => props.handleChange24(props.id, event)}></input>
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        {props.haveAVoucher ? <div className="vouchersContainer">
                                                            <p>Numer vouchera uczestnika</p>
                                                            <div className="vouchers">
                                                                <input type="text" name="voucherCode9" value={props.voucherCode9} onChange={props.handleChange} />
                                                                <button onClick={() => props.handleSubmitVoucher(props.id)} className="secondaryBtn">Przelicz</button></div>
                                                            <p style={{ color: props.colorMessage9 }}>{props.messageVoucher9}</p>
                                                        </div> : null}
                                                    </div>

                                                </div>
                                            </div>
                                            // USER NR 9 - END
                                            :
                                            // USER NR 10 - START
                                            props.userNum === 10 ?
                                                <div className="onePerson">
                                                    <div className="name">
                                                        <p>{props.userNum}. {props.valueName !== "" ? props.valueName : "Uczestnik"} {props.valueSurname !== "" ? props.valueSurname : ""} </p>
                                                    </div>
                                                    <div className="optionsContainer">
                                                        <h4>Wybrane opcje</h4>
                                                        <table className="detailsBox">
                                                            {SummaryPerParticipant.UserNum10.map((el) =>
                                                            el.Description !== "Rabaty" &&
                                                                <tr>
                                                                    <td className="firstColumn">{el.Description}</td>
                                                                    <td className="secondColumn">{el.extendData?.IsPayOnPlace ? "Płatność na miejscu" : el.Price === "0" || el.Price === 0 ? "W cenie wyjazdu" : el.Price + " PLN"} </td>
                                                                </tr>
                                                            )}

                                                        </table>
                                                        <div className="vouchersBox">
                                                            <div className="containerSelect">
                                                                <label className="containerInputCheckbox"><p>Posiadam voucher</p><input type="checkbox" name="haveAVoucher" checked={props.haveAVoucher} value={props.haveAVoucher} onChange={(event) => props.handleChange24(props.id, event)}></input>
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                            </div>
                                                            {props.haveAVoucher ? <div className="vouchersContainer">
                                                                <p>Numer vouchera uczestnika</p>
                                                                <div className="vouchers">
                                                                    <input type="text" name="voucherCode10" value={props.voucherCode10} onChange={props.handleChange} />
                                                                    <button onClick={() => props.handleSubmitVoucher(props.id)} className="secondaryBtn">Przelicz</button></div>
                                                                <p style={{ color: props.colorMessage10 }}>{props.messageVoucher10}</p>
                                                            </div> : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                // USER NR 10 - END
                                                :
                                                // USER NR 11 - START
                                                props.userNum === 11 ?
                                                    <div className="onePerson">
                                                        <div className="name">
                                                            <p>{props.userNum}. {props.valueName !== "" ? props.valueName : "Uczestnik"} {props.valueSurname !== "" ? props.valueSurname : ""} </p>
                                                        </div>
                                                        <div className="optionsContainer">
                                                            <h4>Wybrane opcje</h4>
                                                            <table className="detailsBox">
                                                                {SummaryPerParticipant.UserNum11.map((el) =>
                                                                el.Description !== "Rabaty" &&
                                                                    <tr>
                                                                        <td className="firstColumn">{el.Description}</td>
                                                                        <td className="secondColumn">{el.extendData?.IsPayOnPlace ? "Płatność na miejscu" : el.Price === "0" || el.Price === 0 ? "W cenie wyjazdu" : el.Price + " PLN"} </td>
                                                                    </tr>
                                                                )}

                                                            </table>
                                                            <div className="vouchersBox">
                                                                <div className="containerSelect">
                                                                    <label className="containerInputCheckbox"><p>Posiadam voucher</p><input type="checkbox" name="haveAVoucher" checked={props.haveAVoucher} value={props.haveAVoucher} onChange={(event) => props.handleChange24(props.id, event)}></input>
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                                {props.haveAVoucher ? <div className="vouchersContainer">
                                                                    <p>Numer vouchera uczestnika</p>
                                                                    <div className="vouchers">
                                                                        <input type="text" name="voucherCode11" value={props.voucherCode11} onChange={props.handleChange} />
                                                                        <button onClick={() => props.handleSubmitVoucher(props.id)} className="secondaryBtn">Przelicz</button></div>
                                                                    <p style={{ color: props.colorMessage11 }}>{props.messageVoucher11}</p>
                                                                </div> : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    // USER NR 11 - END
                                                    :
                                                    // USER NR 12 - START
                                                    props.userNum === 12 ?
                                                        <div className="onePerson">
                                                            <div className="name">
                                                                <p>{props.userNum}. {props.valueName !== "" ? props.valueName : "Uczestnik"} {props.valueSurname !== "" ? props.valueSurname : ""} </p>
                                                            </div>
                                                            <div className="optionsContainer">
                                                                <h4>Wybrane opcje</h4>
                                                                <table className="detailsBox">
                                                                    {SummaryPerParticipant.UserNum12.map((el) =>
                                                                    el.Description !== "Rabaty" &&
                                                                        <tr>
                                                                            <td className="firstColumn">{el.Description}</td>
                                                                            <td className="secondColumn">{el.extendData?.IsPayOnPlace ? "Płatność na miejscu" : el.Price === "0" || el.Price === 0 ? "W cenie wyjazdu" : el.Price + " PLN"} </td>
                                                                        </tr>
                                                                    )}
                                                                </table>
                                                                <div className="vouchersBox">
                                                                    <div className="containerSelect">
                                                                        <label className="containerInputCheckbox"><p>Posiadam voucher</p><input type="checkbox" name="haveAVoucher" checked={props.haveAVoucher} value={props.haveAVoucher} onChange={(event) => props.handleChange24(props.id, event)}></input>
                                                                            <span className="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                    {props.haveAVoucher ? <div className="vouchersContainer">
                                                                        <p>Numer vouchera uczestnika</p>
                                                                        <div className="vouchers">
                                                                            <input type="text" name="voucherCode12" value={props.voucherCode12} onChange={props.handleChange} />
                                                                            <button onClick={() => props.handleSubmitVoucher(props.id)} className="secondaryBtn">Przelicz</button></div>
                                                                        <p style={{ color: props.colorMessage12 }}>{props.messageVoucher12}</p>
                                                                    </div> : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        // USER NR 12 - END
                                                        : null
    )
}
const StepSix = (props) => {

    // ARRAYS WITH STEPS INFO
    const StepFiveArr = props.Reservation.Data.find((el) => el.Step === 5)
    const StepSixArr = props.Reservation.Data.find((el) => el.Step === 6)
    const participantsArr = StepFiveArr.Config.ParticipantsConfig

    // VARIABLE DATEOfTRIP
    let dateTrip = props.Reservation.Offer.From;
    let year = dateTrip.slice(0, 4);
    let month = dateTrip.slice(5, 7);
    let day = dateTrip.slice(8, 10);

    // DATE Of PAYMENT RATE
    const firstRate = new Date(year, month - 1, day);
    firstRate.setDate(firstRate.getDate() - 60);

    let dayFirstRate = firstRate.getDate() < 10 ? '0' + firstRate.getDate() : firstRate.getDate();
    let monthFirstRate = firstRate.getMonth() < 10 ? '0' + (firstRate.getMonth() + 1) : firstRate.getMonth() + 1;
    let yearFirstRate = firstRate.getFullYear()
    let firstRateString = dayFirstRate.toString() + "." + monthFirstRate.toString() + "." + yearFirstRate.toString()

    const secondRate = new Date(year, month - 1, day);
    secondRate.setDate(secondRate.getDate() - 30);

    let daySecondRate = secondRate.getDate() < 10 ? '0' + secondRate.getDate() : secondRate.getDate();
    let monthSecondRate = secondRate.getMonth() < 9 ? '0' + (secondRate.getMonth() + 1) : secondRate.getMonth() + 1;
    let yearSecondRate = secondRate.getFullYear()
    let secondRateString = daySecondRate.toString() + "." + monthSecondRate.toString() + "." + yearSecondRate.toString()

    var today = new Date();
    // today.setDate(today.getDate() - 30); 
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + '.' + mm + '.' + dd;
    let secondRateStringUpdated = yearSecondRate.toString() + "." + monthSecondRate.toString() + "." + daySecondRate.toString()



    useEffect(() => {
        IAmForAnalitics();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const OWU = "/documents/WIENER_OWU-Ubezpieczeni-w-podrozy.pdf"

    return (
        <div id="stepSix" style={{ position: "relative" }}>
            {!props.buttonAvailableStepSix && <div id="stepMask"></div>}
            <div className="container">

                {/* CREATE ARRAY WITH PARTICIPANTS */}
                {(props.participantsConfig).map((el, index) => <OnePerson key={index + 1} id={el.id}
                    haveAVoucher={el.haveAVoucher} reservationId={props.reservationId} Reservation={props.Reservation}
                    participantsConfig={props.participantsConfig}
                    handleChange24={props.handleChange24}
                    messageVoucher={props.messageVoucher}
                    handleChange2={props.handleChange2} handleChange={props.handleChange}
                    firstName={el.firstName}
                    lastName={el.lastName} birthDate={el.birthDate} idDocument={el.idDocument}
                    gender={el.gender} phone={el.phone} email={el.email} userNum={el.userNum}
                    country={el.country}
                    handleSubmitVoucher={props.handleSubmitVoucher}
                    voucherCode1={props.voucherCode1}
                    voucherCode2={props.voucherCode2}
                    voucherCode3={props.voucherCode3}
                    voucherCode4={props.voucherCode4}
                    voucherCode5={props.voucherCode5}
                    voucherCode6={props.voucherCode6}
                    voucherCode7={props.voucherCode7}
                    voucherCode8={props.voucherCode8}
                    voucherCode9={props.voucherCode9}
                    voucherCode10={props.voucherCode10}
                    voucherCode11={props.voucherCode11}
                    voucherCode12={props.voucherCode12}
                    colorMessage={props.colorMessage}
                    handleChange23={props.handleChange23}
                    handleSubmitAmbassador={props.handleSubmitAmbassador}
                    handleUpdateStepSix={props.handleUpdateStepSix}
                    handleBackToStepFive={props.handleBackToStepFive}
                    ambassadorCode={props.ambassadorCode}
                    messageForUs={props.messageForUs}
                    userLogged={props.userLogged}
                    accommodationOption={props.accommodationOption}
                    accommodationConfig={props.accommodationConfig} accommodationConfigNew={props.accommodationConfigNew}
                    valueName={props.accommodationConfig.length === 0 || props.accommodationConfig === [] ? "" : (props.accommodationConfig.length !== 0 || props.accommodationConfig !== []) && props.accommodationOption === "whole" ? props.accommodationConfigNew[props.accommodationConfigNew.findIndex((el) => el.id === index + 1)].valueName : (props.accommodationConfig.length !== 0 || props.accommodationConfig !== []) && (props.accommodationOption === "single" || props.accommodationOption === "cabin") ? props.accommodationConfig[props.accommodationConfig.findIndex((el) => el.id === index + 1)].valueName : null}
                    valueSurname={props.accommodationConfig.length === 0 || props.accommodationConfig === [] ? "" : (props.accommodationConfig.length !== 0 || props.accommodationConfig !== []) && props.accommodationOption === "whole" ? props.accommodationConfigNew[props.accommodationConfigNew.findIndex((el) => el.id === index + 1)].valueSurname : (props.accommodationConfig.length !== 0 || props.accommodationConfig !== []) && (props.accommodationOption === "single" || props.accommodationOption === "cabin") ? props.accommodationConfig[props.accommodationConfig.findIndex((el) => el.id === index + 1)].valueSurname : ""}
                    messageVoucher1={props.messageVoucher1}
                    colorMessage1={props.colorMessage1}
                    messageVoucher2={props.messageVoucher2}
                    colorMessage2={props.colorMessage2}
                    messageVoucher3={props.messageVoucher3}
                    colorMessage3={props.colorMessage3}
                    messageVoucher4={props.messageVoucher4}
                    colorMessage4={props.colorMessage4}
                    messageVoucher5={props.messageVoucher5}
                    colorMessage5={props.colorMessage5}
                    messageVoucher6={props.messageVoucher6}
                    colorMessage6={props.colorMessage6}
                    messageVoucher8={props.messageVoucher8}
                    colorMessage8={props.colorMessage8}
                    messageVoucher9={props.messageVoucher9}
                    colorMessage9={props.colorMessage9}
                    messageVoucher10={props.messageVoucher10}
                    colorMessage10={props.colorMessage10}
                    messageVoucher11={props.messageVoucher11}
                    colorMessage11={props.colorMessage11}
                    messageVoucher12={props.messageVoucher12}
                    colorMessage12={props.colorMessage12}
                    messageVoucher7={props.messageVoucher7}
                    colorMessage7={props.colorMessage7} />
                )}
                {/* CREATE ARRAY WITH PARTICIPANTS - END */}

                {/* IF USER CREATE ACCOUNT IN RESERVATION FLOW */}
                {(props.Reservation.Offer.ShowGroupRebate === true && props.userLogged === false) &&
                    <div id="connectionReservation" className="selectRadio">
                        <h3>Łączenie rezerwacji (dla grup znajomych)</h3>
                        <div className="container">
                            <div className="textBox">
                                <p>Jeśli jedziecie grupą znajomych i chcecie mieszkać razem, możecie zrobić
                                jedną rezerwację wieloosobową lub kilka pojedynczych, które oznaczycie
                                wspólnym kodem.
                                    {props.errorMessage2 === "Kod jest nieprawidłowy" ?
                                        props.render &&
                                        <>
                                            {props.buttonGenerateCodeVisible &&
                                                <>
                                                    <span style={{ display: props.displayNone }}> Jeśli jesteś liderem grupy możesz wygenerować Wasz kod poniżej.</span>
                                                    <div className="boxButton"><button className="secondaryBtn"
                                                        style={{ cursor: "pointer", fontFamily: "SpaceGrotesk-SemiBold", display: props.displayNone }}
                                                        onClick={props.handleCreateGroupCode}>Wygeneruj kod grupowy</button></div>
                                                    <span style={{ display: props.displayNone }}></span>
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            {props.buttonGenerateCodeVisible &&
                                                <>
                                                    <span style={{ display: props.displayNone }}> Jeśli jesteś liderem grupy możesz wygenerować Wasz kod poniżej.</span>
                                                    <div className="boxButton"><button className="secondaryBtn"
                                                        style={{ cursor: "pointer", fontFamily: "SpaceGrotesk-SemiBold", display: props.displayNone }}
                                                        onClick={props.handleCreateGroupCode}>Wygeneruj kod grupowy</button>
                                                    </div>
                                                    <span style={{ display: props.displayNone }}></span>
                                                </>
                                            }
                                        </>
                                    }
                                </p>
                                <p className="confirmedInformation">{props.trueMessage}</p>
                                <p className="errorMessage">{props.errorMessage}</p>
                                {props.addGrupCodeVisible &&
                                    <>
                                        <p>Jeśli dołączasz do istniejącej grupy, wklej Wasz kod poniżej.</p>
                                        <div id="liderBox" className="inputBox">
                                            <p className="inputName">Podaj kod grupowy (opcjonalnie)</p>
                                            <div className="formContainer">
                                                <input type="text" name="groupCode" value={props.groupCode} onChange={props.handleChange} /><button className="secondaryBtn" onClick={props.handleAddGroupCode}>Dołącz</button>
                                            </div>
                                            <p className="confirmedInformation">{props.secondTrueMessage}</p>
                                            <p className="errorMessage">{props.errorMessage2}</p>

                                        </div>
                                    </>
                                }
                                {props.trueMessageVisible && <p className="confirmedInformation">{props.secondTrueMessage}</p>}
                            </div>
                            <div className="greenBox">
                                <p className="infoTitle">Cieszymy się, że jest Was tak dużo!</p>
                                <p style={{ marginTop: 0, fontFamily: 'SpaceGrotesk-SemiBold', color: "#3db74c" }}>Kody grupowe zapewniają</p>
                                <p>1. Zniżki grupowe (dla 6 i więcej osób)</p>
                                <p>2. Wspólne zakwaterowanie (dla osób, które wybiorą ten sam standard)</p>
                                <p>3. Jedziecie razem, ale każdy płaci za siebie</p>
                            </div>
                        </div>
                    </div>
                }
                {/* IF USER CREATE ACCOUNT IN RESERVATION FLOW - END */}

                {/* SECTION WITH ADD OPTIONS */}
                <div className="addPromotions">
                    {props.Reservation.Offer.ShowAmbassadorRebate === true &&
                        <>
                            {props.codeAmbassadorFormVisible &&
                                <>
                                    <h4>Dodatkowe zniżki</h4>
                                    <p>Jeżeli posiadasz kod ambasadora, podaj go poniżej</p>
                                    <div className="ambassadorContainer">
                                        <p>Podaj kod ambasadora</p>
                                        <div className="ambassador"><input type="text" name="ambassadorCode" value={props.ambassadorCode} onChange={props.handleChange} /><button className="secondaryBtn" onClick={props.handleSubmitAmbassador}>Zapisz</button></div>
                                        <p style={{ color: props.colorMessageAmb }}>{props.messageAmbassador}</p>
                                    </div>
                                </>}
                            {props.messageAmbassadorVisible && <p style={{ color: props.colorMessageAmb }}>{props.messageAmbassador}</p>}
                        </>}
                    <div className="ambassadorContainer">
                        <p>Wiadomość dla Nas</p>
                        <div className="ambassador">
                            <textarea name="comments" value={props.comments} onChange={props.handleChange} placeholder="Jeśli masz jakieś specjalne życzenia bądź sugestie dotyczące rezerwacji, daj nam znać!" />
                        </div>
                    </div>
                </div>
                {/* SECTION WITH ADD OPTIONS - END */}
               
                {/* SECTION WITH SUBMIT PAYMENTS */}
                <div className="boxSubmit">
                    <p className="description">Łączna cena twojego wyjazdu</p>
                    <div className="priceBox">
                        <p className="price">{props.Reservation.Offer.CurrentPrice} PLN</p>
                    </div>
                </div>
                {/* SECTION WITH SUBMIT PAYMENTS - END */}

                {/* CHOOSING A PAYMENT METHOD */}
                <div className="paymentsMethods">
                    <h4>Płatność</h4>

                    <div className="containerInputRadio">

                        {(today >= secondRateStringUpdated && props.Reservation.Data[1].Config.IsReserveList === 0) ? null :
                            <label className="inputRadio">Ratalna
                            <input type="radio" name="paymentOption" value="installment" onChange={props.handleChange} checked={props.paymentOption === "installment"}></input>
                                <div className="box">
                                    <p><span className="title">{StepSixArr.InstallmentPaymentDetails[0].Name}</span> <span className="rate">{StepSixArr.InstallmentPaymentDetails[0].Value} PLN w ciągu 24 godzin</span></p>
                                    <p><span className="title">{StepSixArr.InstallmentPaymentDetails[1].Name}</span> <span className="rate">{StepSixArr.InstallmentPaymentDetails[1].Value} PLN do {firstRateString} </span></p>
                                    <p><span className="title">{StepSixArr.InstallmentPaymentDetails[2].Name}</span> <span className="rate">{StepSixArr.InstallmentPaymentDetails[2].Value} PLN do {secondRateString}</span></p>
                                </div>
                                <span className="checkmark"></span></label>
                        }
                        <label className="inputRadio">Jednorazowa
                            <input type="radio" name="paymentOption" value="onetime" onChange={props.handleChange} checked={props.paymentOption === "onetime"}></input>
                            <span className="checkmark"></span></label>
                        {(today >= secondRateStringUpdated && props.Reservation.Data[1].Config.IsReserveList === 0) && <div className="orangeBox"><p >Do rejsu pozostało mniej niż 30 dni, prosimy o jednorazową wpłatę całej należności.</p></div>}
                    </div>

                    <h4>Metoda płatności</h4>
                    <div className="containerInputRadio">
                        {(props.Reservation.Data[1].Config.IsReserveList === 1 || !(props.Reservation.Data[2].Config.InsuranceConfig.some(el => el.resignationInsuranceAccepted === "1"))) &&
                            <label className="inputRadio"><p className="title">Przelew tradycyjny</p><input type="radio" name="paymentType" value="wired" onChange={props.handleChange} checked={props.paymentType === "wired"}></input>
                                <div className='box'>
                                    <p className='title'>Dane do przelewu</p>
                                    <p className='rate'>Nazwa: <span>Taksidi Sp. z o.o.</span></p>
                                    <p className='rate'>Numer konta: <span>06 1050 1025 1000 0090 3067 4858</span></p>
                                    <p className='rate'>Tytułem: <span>{props.reservationId}, {participantsArr[0].firstName} {participantsArr[0].lastName}, {props.Reservation.Offer.Name}</span></p>
                                </div>
                                <span className="checkmark"></span></label>}
                        <label className="inputRadio"><p className="title">Karta/Przelew ekspresowy/Blik - PayU (ok. {(props.Reservation.Offer.CurrentPrice * 0.01).toFixed(2)} PLN prowizja dla operatora przy płatności jednorazowej)</p><input type="radio" name="paymentType" value="payu" onChange={props.handleChange} checked={props.paymentType === "payu"}></input>
                            <span className="checkmark"></span></label>
                    </div>

                    {(props.Reservation.Data[2].Config.InsuranceConfig.some(el => el.resignationInsuranceAccepted === "1")) && <div className="greenBox" style={{ marginBottom: "30px", marginTop: "-30px", backgroundColor: "#ffc1074f", fontWeight: "100", maxWidth: "max-content" }}><p className="infoTitle" style={{ color: 'orange', fontFamily: "SpaceGrotesk" }}><span style={{ color: 'orange', fontFamily: "SpaceGrotesk-SemiBold" }}>UWAGA: </span>Wybrałeś ubezpieczenie od kosztów rezygnacji, w związku z tym prosimy o dokonanie płatności za pośrednictwem payU.</p></div>}
                    <div className="agreements">
                        <label className="containerInputCheckbox allAgreements"><p className="textSmall">* Akceptuję <a href={regulamin} rel="noreferrer" target="_blank" title="Przeczytaj warunki rezerwacji i uczestnictwa The Boat Trip">Warunki Rezerwacji i Uczestnictwa The Boat Trip</a> oraz <a href={OWU} rel="noreferrer" target="_blank" title="Przeczytaj ogólne warunki ubezpieczenia WIENER" >Ogólne Warunki Ubezpieczenia WIENER</a></p><input type="checkbox" name="termsAccepted" checked={props.termsAccepted === 1} value={props.termsAccepted} onChange={props.handleChange3} />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                </div>
                {/* CHOOSING A PAYMENT METHOD - END */}

                <p className="errorMessage">{props.messageStepSix}</p>

                {/* BUTTONS GET/UPDATE CURRENT STEP */}
                <div className="containerButtons">
                    <button className="secondaryBtn" disabled={props.buttonAvailableStepSix ? "" : "disabled"} style={props.buttonAvailableStepSix ? null : { background: "grey" }} onClick={props.handleBackToStepFive}>Wstecz</button>
                    <button className="primaryBtn" onClick={props.handleUpdateStepSix}>{props.Reservation.Data[1].Config.IsReserveList === 0 ? "Rezerwuję i płacę" : "Zapisuję się na listę rezerwową"}</button>
                    {!props.buttonAvailableStepSix && <div class="lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    }
                </div>
                {/* BUTTONS GET/UPDATE CURRENT STEP - END */}
            </div>

            <TripBox Reservation={props.Reservation} />
        </div>
    );
}

export default StepSix;