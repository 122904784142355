import React, { useEffect } from "react";
import "./StepFive.scss";
import "./TripBox.scss";
import politykaPrywatnosci from "./userRegistration/documents/polityka-prywatnosci.pdf";
import regulaminTaksidi from "./userRegistration/documents/regulamin-taksidi.pdf";
import Moment from "moment";
import ReactHtmlParser from "react-html-parser";
import { IAmForAnalitics } from "./utilsApp";
import calendar from "./img/calendar.png";

const TripBox = (props) => {
  return (
    <div id="tripBox">
      <div>
        <div className="imageContainer">
          <img
            src={props.Reservation.Offer.ListImg?.url}
            alt={props.Reservation.Offer.ListImg?.img}
          />
        </div>
        <div className="textBox">
          <h4>{props.Reservation.Offer.Name}</h4>
          <p className="date">
            <img src={calendar} alt="termin" />
            {Moment(props.Reservation.Offer.From).format("DD.MM") +
              "-" +
              Moment(props.Reservation.Offer.To).format("DD.MM.YYYY")}
          </p>
          <p className="price">{props.Reservation.Offer.CurrentPrice} PLN</p>
          <p className="postscript">Łączna cena Twojego wyjazdu</p>
          {props.Reservation.Offer.CurrentRebate !== 0 ? (
            <>
              {/* <p className="postscript">Cena do zapłaty po uwzględnieniu rabatu</p> */}
              <p className="rebate">
                <span style={{ fontFamily: "SpaceGrotesk-SemiBold" }}>
                  W tym rabat, na który składa się:
                </span>
                {props.Reservation.Offer.RebatesList.map((el) => (
                  <p className="rebate">
                    {el.Name}:<span> {el.Value + " PLN"}</span>
                  </p>
                ))}
              </p>
            </>
          ) : null}
        </div>
      </div>
      {props.Reservation.Data[1].Config.IsReserveList === 1 && (
        <div className="orangeBox" id="infoReservationListInStep">
          <p>UWAGA: Zapisujesz się na listę rezerwową</p>
        </div>
      )}
    </div>
  );
};

// const RenderList = (props) => {
//     return props.list.map(el => (

//         <div>
//             <label className="containerInputCheckbox"><p className="textSmall" id={props.colorInputRedAgree}>
//                 {el.id === 2 ? <a href={regulamin} rel="noreferrer" target="_blank">{el.name}</a> : el.name}
//                 </p> <input
//                 key={el.id}
//                 type="checkbox"

//                 name={el.name}
//                 value={el.name}
//                 checked={el.isChecked}
//                 onChange={props.handleChange2}
//             />
//                 <span className="checkmark"></span>
//             </label>

//             {/* {el.id === 3 ? el.details.map((el) =>
//                 <>
//                     <label className="containerInputCheckbox"><p className="textSmall">{el.name}</p> <input
//                         key={el.id}
//                         type="checkbox"
//                         name={el.name}
//                         value={el.name}
//                         checked={el.isChecked}
//                         onChange={props.handleChange2}
//                     />
//                         <span className="checkmark"></span>
//                     </label>
//                 </>
//             ) : null} */}

//         </div>
//     ))
// }

const OnePerson = (props) => {
  const StepFiveArr = props.Reservation.Data.find((el) => el.Step === 5);
  const step1 = props.Reservation.Data.find((el) => el.Step === 1);

  let dateTrip = props.Reservation.Offer.From;
  let year = dateTrip.slice(0, 4);
  let month = dateTrip.slice(5, 7);
  let day = dateTrip.slice(8, 10);

  const firstRate = new Date(year, month - 1, day);
  firstRate.setDate(firstRate.getDate() - 14);
  let dayFirstRate =
    firstRate.getDate() < 10 ? "0" + firstRate.getDate() : firstRate.getDate();
  let monthFirstRate =
    firstRate.getMonth() < 10
      ? "0" + (firstRate.getMonth() + 1)
      : firstRate.getMonth() + 1;
  const dateAttrChoice = dayFirstRate + "." + monthFirstRate;

  return (
    <div className="onePerson">
      {props.userNum === 1 ? (
        <>
          <div className="name">
            <p>{props.id}. Uczestnik (Ty)</p>
          </div>
          <div className="participantDetails">
            <h3>Dane uczestnika</h3>
            {StepFiveArr.Account !== null ? (
              <form noValidate>
                {/* <p>{props.resignationInsuranceAccepted === 1 && props.firstName === "" ? props.wrongMessage : null}</p> */}
                {/* <p style={{color: "orange"}}>{props.wrongMessage}</p> */}
                <p>Imię</p>
                <input
                  name={props.id}
                  onChange={(event) => props.handleChange15(props.id, event)}
                  value={props.firstName}
                  className={props.colorInputRedFirstName}
                  type="text"
                ></input>
                <p>Nazwisko</p>
                <input
                  name={props.id}
                  onChange={(event) => props.handleChange16(props.id, event)}
                  type="text"
                  value={props.lastName}
                  className={props.colorInputRedLastName}
                ></input>
                <p>Data urodzenia</p>
                <input
                  name={props.id}
                  onChange={(event) => props.handleChange17(props.id, event)}
                  type="text"
                  placeholder="YYYY-MM-DD"
                  className={props.colorInputRedBirthDate}
                  value={props.birthDate}
                ></input>
                {props.Reservation.Offer.OfferId === "188" && (
                  <div className="greenBox" style={{ marginTop: "20px" }}>
                    <p className="infoTitle">Zniżki dla dzieci:</p>
                    <div className="benefits">
                      <div className="iconTick">
                        <p>✔</p>
                      </div>
                      <p>
                        Dzieci w wieku do 6 lat śpiące z rodzicami w kajucie
                        -1000 PLN
                      </p>
                    </div>
                    <div className="benefits">
                      <div className="iconTick">
                        <p>✔</p>
                      </div>
                      <p>Dzieci w wieku 7-12 lat śpiące osobno -500 PLN</p>
                    </div>
                    <div className="benefits">
                      <p>
                        W celu naliczenia zniżki dla dzieci prosimy o kontakt
                        mailowy z Biurem Obsługi Klienta po złożeniu rezerwacji:
                        info@theboattrip.eu
                      </p>
                    </div>
                  </div>
                )}
                <p>Seria i numer dowodu osobistego</p>
                <input
                  name={props.id}
                  onChange={(event) => props.handleChange18(props.id, event)}
                  type="text"
                  value={props.idDocument}
                  className={props.colorInputRedIdDocument}
                ></input>
                <p>Kraj</p>
                {/* <input name={props.id} onChange={(event) => props.handleChange25(props.id, event)} type="text" value={props.country} className={props.colorInputRedCountry}></input> */}
                <select
                  name={props.id}
                  onChange={(event) => props.handleChange25(props.id, event)}
                >
                  <option name={props.id} value="Polska">
                    Polska
                  </option>
                  {props.countryList.map((el) => (
                    <option name={props.id} value={el}>
                      {el}
                    </option>
                  ))}
                </select>

                <p>Płeć</p>
                <div className="containerInputRadio">
                  <label className="inputRadio">
                    Kobieta
                    <input
                      type="radio"
                      value="1"
                      name={props.id}
                      onChange={(event) =>
                        props.handleChange21(props.id, event)
                      }
                      checked={props.gender === "1"}
                    ></input>
                    <span
                      className="checkmark"
                      id={props.colorInputRedGender}
                    ></span>
                  </label>
                  <label className="inputRadio">
                    Mężczyzna
                    <input
                      type="radio"
                      name={props.id}
                      onChange={(event) =>
                        props.handleChange21(props.id, event)
                      }
                      value="2"
                      checked={props.gender === "2"}
                    ></input>
                    <span
                      className="checkmark"
                      id={props.colorInputRedGender}
                    ></span>
                  </label>
                </div>
                <p>Numer telefonu</p>
                <input
                  name={props.id}
                  onChange={(event) => props.handleChange19(props.id, event)}
                  type="text"
                  value={props.phone}
                  placeholder="np. 123-456-789"
                  className={props.colorInputRedPhone}
                ></input>
                {/* <p>E-mail</p>
                                <input name={props.id} onChange={(event) => props.handleChange20(props.id, event)} value={props.email} type="email" ></input> */}
              </form>
            ) : (
              <form noValidate>
                {/* <p>{props.resignationInsuranceAccepted === 1 && props.firstName === "" ? props.wrongMessage : null}</p> */}
                {/* <div className="greenBox" style={{marginBottom: "20px", marginTop: "20px", backgroundColor: "#ffc1074f", fontWeight: "100", maxWidth: "100%"}}><p className="infoTitle" style={{color: 'orange', fontFamily: "SpaceGrotesk"}}><span style={{color: 'orange', fontFamily: "SpaceGrotesk-SemiBold"}}>UWAGA: </span>{props.wrongMessage}</p></div> */}
                <p>Imię</p>
                <input
                  name={props.id}
                  onChange={(event) => props.handleChange15(props.id, event)}
                  value={props.firstName}
                  className={props.colorInputRedFirstName}
                  type="text"
                ></input>
                <p>Nazwisko</p>
                <input
                  name={props.id}
                  onChange={(event) => props.handleChange16(props.id, event)}
                  type="text"
                  value={props.lastName}
                  className={props.colorInputRedLastName}
                ></input>
                <p>Data urodzenia</p>
                <input
                  name={props.id}
                  onChange={(event) => props.handleChange17(props.id, event)}
                  type="text"
                  placeholder="YYYY-MM-DD"
                  className={props.colorInputRedBirthDate}
                  value={props.birthDate}
                ></input>
                {props.Reservation.Offer.OfferId === "188" && (
                  <div className="greenBox" style={{ marginTop: "20px" }}>
                    <p className="infoTitle">Zniżki dla dzieci:</p>
                    <div className="benefits">
                      <div className="iconTick">
                        <p>✔</p>
                      </div>
                      <p>
                        Dzieci w wieku do 6 lat śpiące z rodzicami w kajucie
                        -1000 PLN
                      </p>
                    </div>
                    <div className="benefits">
                      <div className="iconTick">
                        <p>✔</p>
                      </div>
                      <p>Dzieci w wieku 7-12 lat śpiące osobno -500 PLN</p>
                    </div>
                    <div className="benefits">
                      W celu naliczenia zniżki dla dzieci prosimy o kontakt
                      mailowy z Biurem Obsługi Klienta po złożeniu rezerwacji:
                      info@theboattrip.eu
                    </div>
                  </div>
                )}
                <p>Seria i numer dowodu osobistego</p>
                <input
                  name={props.id}
                  onChange={(event) => props.handleChange18(props.id, event)}
                  type="text"
                  value={props.idDocument}
                  className={props.colorInputRedIdDocument}
                ></input>
                <p>Kraj</p>
                {/* <input name={props.id} onChange={(event) => props.handleChange25(props.id, event)} type="text" value={props.country} className={props.colorInputRedCountry}></input> */}
                <select
                  name={props.id}
                  onChange={(event) => props.handleChange25(props.id, event)}
                >
                  <option name={props.id} value="Polska">
                    Polska
                  </option>
                  {props.countryList.map((el) => (
                    <option name={props.id} value={el}>
                      {el}
                    </option>
                  ))}
                </select>
                <p>Płeć</p>
                <div className="containerInputRadio">
                  <label className="inputRadio">
                    Kobieta
                    <input
                      type="radio"
                      value="1"
                      name={props.id}
                      onChange={(event) =>
                        props.handleChange21(props.id, event)
                      }
                      checked={props.gender === "1"}
                    ></input>
                    <span
                      className="checkmark"
                      id={props.colorInputRedGender}
                    ></span>
                  </label>
                  <label className="inputRadio">
                    Mężczyzna
                    <input
                      type="radio"
                      name={props.id}
                      onChange={(event) =>
                        props.handleChange21(props.id, event)
                      }
                      value="2"
                      checked={props.gender === "2"}
                    ></input>
                    <span
                      className="checkmark"
                      id={props.colorInputRedGender}
                    ></span>
                  </label>
                </div>
                <p>Numer telefonu</p>
                <input
                  name={props.id}
                  onChange={(event) => props.handleChange19(props.id, event)}
                  type="text"
                  value={props.phone}
                  className={props.colorInputRedPhone}
                  placeholder="np. +48 123 456 789"
                ></input>
                <p>E-mail</p>
                <input
                  name={props.id}
                  onChange={(event) => props.handleChange20(props.id, event)}
                  value={props.email}
                  type="email"
                  className={props.colorInputRedEmail}
                ></input>
                <p>Hasło</p>
                <p
                  style={{
                    fontSize: "13px",
                    color: "grey",
                    letterSpacing: "0.4px",
                  }}
                >
                  Nadaj swoje hasło dostępu, aby za jego pomocą móc logować się
                  do swojego konta i mieć wgląd do historii swoich rezerwacji.
                </p>
                <div className="passwordBox">
                  <input
                    name={props.id}
                    placeholder="Hasło powinno mieć co najmniej 6 znaków"
                    onChange={(event) => props.handleChange30(props.id, event)}
                    value={props.password}
                    type={props.type}
                    className={props.colorInputRedPassword}
                  ></input>

                  <span onClick={props.handleClick}>
                    {props.type === "password" ? (
                      <i className="fas fa-eye-slash"></i>
                    ) : (
                      <i className="fas fa-eye"></i>
                    )}
                  </span>
                </div>
                <label className="containerInputCheckbox">
                  <p className="textSmall">
                    Zapoznałem się z 
                    <a
                      title="Polityka prywatności"
                      href={politykaPrywatnosci}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <strong> Polityką Prywatności </strong>
                    </a>
                    i akceptuję 
                    <a
                      href={regulaminTaksidi}
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Sprawdź regulamin strony"
                      // style={{ color: this.state.policyAcceptedIncorrect }}
                    >
                      <strong> Regulamin Strony</strong>
                    </a>
                  </p>
                  <input
                    type="checkbox"
                    name={props.id}
                    checked={props.policyAccepted === 1}
                    value={props.policyAccepted}
                    onChange={(event) => props.handleChange31(props.id, event)}
                  ></input>
                  <span
                    className="checkmark"
                    id={props.colorInputRedPolicyAcc}
                  ></span>
                </label>

                <label className="containerInputCheckbox">
                  <p className="textSmall">
                    Wyrażam zgodę na otrzymywanie od Taksidi Sp. z o.o. z
                    siedzibą w Górze Kalwarii drogą elektroniczną informacji
                    handlowej w celu przedstawienia mi ofert organizowanych
                    wyjazdach oraz eventach i promocjach.
                  </p>
                  <input
                    type="checkbox"
                    name={props.id}
                    checked={props.newsletterAccepted === 1}
                    value={props.newsletterAccepted}
                    onChange={(event) => props.handleChange36(props.id, event)}
                  ></input>
                  <span className="checkmark"></span>
                </label>
              </form>
            )}
          </div>

          {step1.Config.ParticipantsCount > 1 && (
            <div className="greenBox">
              <p className="infoTitle">
                Dlaczego warto dodać adresy e-mail pozostałych użytkowników
              </p>
              <div className="benefits">
                <div className="iconTick">
                  <p>✔</p>
                </div>
                <p>Mogą dzięki temu otrzymać zniżkę lojalnościową</p>
              </div>
              <div className="benefits">
                <div className="iconTick">
                  <p>✔</p>
                </div>
                <p>Oszczędzasz swój czas</p>
              </div>
              <p className="addText">
                Dane pozostałych uczestników możesz podać teraz, ale nie musisz.
                Musimy je otrzymać najpóźniej do północy {dateAttrChoice}.{" "}
              </p>
            </div>
          )}
        </>
      ) : props.skipper === "TAK" && props.id === 2 ? (
        <>
          <div
            className="name"
            style={{ backgroundColor: "#e5f6ea", border: "2px solid #1eb74c" }}
          >
            <p style={{ color: "#1eb74c" }}>
              {props.userNum}.{" "}
              {props.valueName !== "" ? props.valueName : "Skipper"}{" "}
              {props.valueSurname !== "" ? props.valueSurname : ""}{" "}
            </p>
          </div>
          {props.fillInLater === false ||
          props.accommodationOption === "single" ||
          props.accommodationOption === "cabin" ? null : (
            <div className="containerSelect">
              <label className="containerInputCheckbox">
                <p>Podam dane osobowe później</p>
                <input
                  type="checkbox"
                  name={props.id}
                  checked={props.fillInLater === 1}
                  value={props.fillInLater}
                  onChange={(event) => props.handleChange22(props.id, event)}
                ></input>
                <span className="checkmark"></span>
              </label>
            </div>
          )}
          {props.fillInLater ? (
            <form>
              <p>E-mail (opcjonalny)</p>
              <input
                name={props.id}
                onChange={(event) => props.handleChange20(props.id, event)}
                value={props.email}
                type="email"
              ></input>
            </form>
          ) : (
            <div className="participantDetails">
              <h3>Dane uczestnika</h3>
              <form noValidate>
                {/* <p>{props.resignationInsuranceAccepted === 1 && props.firstName === "" ? props.wrongMessage : null}</p> */}
                {props.wrongMessage !== null && (
                  <div
                    className="greenBox"
                    style={{
                      marginBottom: "20px",
                      marginTop: "20px",
                      backgroundColor: "#ffc1074f",
                      fontWeight: "100",
                      maxWidth: "100%",
                    }}
                  >
                    <p
                      className="infoTitle"
                      style={{ color: "orange", fontFamily: "SpaceGrotesk" }}
                    >
                      <span
                        style={{
                          color: "orange",
                          fontFamily: "SpaceGrotesk-SemiBold",
                        }}
                      >
                        UWAGA:{" "}
                      </span>
                      {props.wrongMessage}
                    </p>
                  </div>
                )}
                <p>Imię</p>
                <input
                  name={props.id}
                  onChange={(event) => props.handleChange15(props.id, event)}
                  value={props.firstName}
                  type="text"
                ></input>
                <p>Nazwisko</p>
                <input
                  name={props.id}
                  onChange={(event) => props.handleChange16(props.id, event)}
                  type="text"
                  value={props.lastName}
                ></input>

                <p>Data urodzenia</p>
                <input
                  name={props.id}
                  onChange={(event) => props.handleChange17(props.id, event)}
                  type="text"
                  placeholder="YYYY-MM-DD"
                  value={props.birthDate}
                ></input>
                {props.Reservation.Offer.OfferId === "188" && (
                  <div className="greenBox" style={{ marginTop: "20px" }}>
                    <p className="infoTitle">Zniżki dla dzieci:</p>
                    <div className="benefits">
                      <div className="iconTick">
                        <p>✔</p>
                      </div>
                      <p>
                        Dzieci w wieku do 6 lat śpiące z rodzicami w kajucie
                        -1000 PLN
                      </p>
                    </div>
                    <div className="benefits">
                      <div className="iconTick">
                        <p>✔</p>
                      </div>
                      <p>Dzieci w wieku 7-12 lat śpiące osobno -500 PLN</p>
                    </div>
                    <div className="benefits">
                      <p>
                        W celu naliczenia zniżki dla dzieci prosimy o kontakt
                        mailowy z Biurem Obsługi Klienta po złożeniu rezerwacji:
                        info@theboattrip.eu
                      </p>
                    </div>
                  </div>
                )}
                <p>Seria i numer dowodu osobistego</p>
                <input
                  name={props.id}
                  onChange={(event) => props.handleChange18(props.id, event)}
                  type="text"
                  value={props.idDocument}
                ></input>
                <p>Kraj</p>
                {/* <input name={props.id} onChange={(event) => props.handleChange25(props.id, event)} type="text" value={props.country} className={props.colorInputRedCountry}></input> */}
                <select
                  name={props.id}
                  onChange={(event) => props.handleChange25(props.id, event)}
                >
                  <option name={props.id} value="Polska">
                    Polska
                  </option>
                  {props.countryList.map((el) => (
                    <option name={props.id} value={el}>
                      {el}
                    </option>
                  ))}
                </select>
                <p>Płeć</p>
                <div className="containerInputRadio">
                  <label className="inputRadio">
                    Kobieta
                    <input
                      type="radio"
                      value="1"
                      name={props.id}
                      onChange={(event) =>
                        props.handleChange21(props.id, event)
                      }
                      checked={props.gender === "1"}
                    ></input>
                    <span className="checkmark"></span>
                  </label>
                  <label className="inputRadio">
                    Mężczyzna
                    <input
                      type="radio"
                      name={props.id}
                      onChange={(event) =>
                        props.handleChange21(props.id, event)
                      }
                      value="2"
                      checked={props.gender === "2"}
                    ></input>
                    <span className="checkmark"></span>
                  </label>
                </div>
                <p>Numer telefonu</p>
                <input
                  name={props.id}
                  onChange={(event) => props.handleChange19(props.id, event)}
                  type="text"
                  value={props.phone}
                  placeholder="np. 123 456 789"
                ></input>
                <p>E-mail</p>
                <input
                  name={props.id}
                  onChange={(event) => props.handleChange20(props.id, event)}
                  value={props.email}
                  type="email"
                ></input>
              </form>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="name">
            <p>
              {props.userNum}.{" "}
              {props.valueName !== "" ? props.valueName : "Uczestnik"}{" "}
              {props.valueSurname !== "" ? props.valueSurname : ""}{" "}
            </p>
          </div>
          {props.fillInLater === false ||
          props.accommodationOption === "single" ||
          props.accommodationOption === "cabin" ? null : (
            <div className="containerSelect">
              <label className="containerInputCheckbox">
                <p>Podam dane osobowe później</p>
                <input
                  type="checkbox"
                  name={props.id}
                  checked={props.fillInLater === 1}
                  value={props.fillInLater}
                  onChange={(event) => props.handleChange22(props.id, event)}
                ></input>
                <span className="checkmark"></span>
              </label>
            </div>
          )}
          {props.fillInLater ? (
            <form>
              <p>E-mail (opcjonalny)</p>
              <input
                name={props.id}
                onChange={(event) => props.handleChange20(props.id, event)}
                value={props.email}
                type="email"
              ></input>
            </form>
          ) : (
            <div className="participantDetails">
              <h3>Dane uczestnika</h3>
              <form noValidate>
                {/* <p>{props.resignationInsuranceAccepted === "1" && props.firstName === "" ? props.wrongMessage : null}</p> */}
                {props.wrongMessage !== null && (
                  <div
                    className="greenBox"
                    style={{
                      marginBottom: "20px",
                      marginTop: "20px",
                      backgroundColor: "#ffc1074f",
                      fontWeight: "100",
                      maxWidth: "100%",
                    }}
                  >
                    <p
                      className="infoTitle"
                      style={{ color: "orange", fontFamily: "SpaceGrotesk" }}
                    >
                      <span
                        style={{
                          color: "orange",
                          fontFamily: "SpaceGrotesk-SemiBold",
                        }}
                      >
                        UWAGA:{" "}
                      </span>
                      {props.wrongMessage}
                    </p>
                  </div>
                )}
                <p>Imię</p>
                <input
                  name={props.id}
                  onChange={(event) => props.handleChange15(props.id, event)}
                  value={props.firstName}
                  type="text"
                ></input>
                <p>Nazwisko</p>
                <input
                  name={props.id}
                  onChange={(event) => props.handleChange16(props.id, event)}
                  type="text"
                  value={props.lastName}
                ></input>
                <p>Data urodzenia</p>
                <input
                  name={props.id}
                  onChange={(event) => props.handleChange17(props.id, event)}
                  type="text"
                  placeholder="YYYY-MM-DD"
                  value={props.birthDate}
                ></input>
                {props.Reservation.Offer.OfferId === "188" && (
                  <div className="greenBox" style={{ marginTop: "20px" }}>
                    <p className="infoTitle">Zniżki dla dzieci:</p>
                    <div className="benefits">
                      <div className="iconTick">
                        <p>✔</p>
                      </div>
                      <p>
                        Dzieci w wieku do 6 lat śpiące z rodzicami w kajucie
                        -1000 PLN
                      </p>
                    </div>
                    <div className="benefits">
                      <div className="iconTick">
                        <p>✔</p>
                      </div>
                      <p>Dzieci w wieku 7-12 lat śpiące osobno -500 PLN</p>
                    </div>
                    <div className="benefits">
                      <p>
                        W celu naliczenia zniżki dla dzieci prosimy o kontakt
                        mailowy z Biurem Obsługi Klienta po złożeniu rezerwacji:
                        info@theboattrip.eu
                      </p>
                    </div>
                  </div>
                )}
                <p>Seria i numer dowodu osobistego</p>
                <input
                  name={props.id}
                  onChange={(event) => props.handleChange18(props.id, event)}
                  type="text"
                  value={props.idDocument}
                ></input>
                <p>Kraj</p>
                {/* <input name={props.id} onChange={(event) => props.handleChange25(props.id, event)} type="text" value={props.country} className={props.colorInputRedCountry}></input> */}
                <select
                  name={props.id}
                  onChange={(event) => props.handleChange25(props.id, event)}
                >
                  <option name={props.id} value="Polska">
                    Polska
                  </option>
                  {props.countryList.map((el) => (
                    <option name={props.id} value={el}>
                      {el}
                    </option>
                  ))}
                </select>
                <p>Płeć</p>
                <div className="containerInputRadio">
                  <label className="inputRadio">
                    Kobieta
                    <input
                      type="radio"
                      value="1"
                      name={props.id}
                      onChange={(event) =>
                        props.handleChange21(props.id, event)
                      }
                      checked={props.gender === "1"}
                    ></input>
                    <span className="checkmark"></span>
                  </label>
                  <label className="inputRadio">
                    Mężczyzna
                    <input
                      type="radio"
                      name={props.id}
                      onChange={(event) =>
                        props.handleChange21(props.id, event)
                      }
                      value="2"
                      checked={props.gender === "2"}
                    ></input>
                    <span className="checkmark"></span>
                  </label>
                </div>
                <p>Numer telefonu</p>
                <input
                  name={props.id}
                  onChange={(event) => props.handleChange19(props.id, event)}
                  type="text"
                  value={props.phone}
                  placeholder="np. 123 456 789"
                ></input>
                <p>E-mail</p>
                <input
                  name={props.id}
                  onChange={(event) => props.handleChange20(props.id, event)}
                  value={props.email}
                  type="email"
                ></input>
              </form>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const StepFive = (props) => {
  useEffect(() => {
    IAmForAnalitics();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="stepFive" style={{ position: "relative" }}>
      {!props.buttonAvailableStepFive && <div id="stepMask"></div>}
      <div className="container">
        {props.participantsConfig.map((el, index) => (
          <OnePerson
            key={index + 1}
            id={el.id}
            insuranceConfig={props.insuranceConfig}
            resignationInsuranceAccepted={
              props.insuranceConfig[
                props.insuranceConfig.findIndex((el) => el.id === index + 1)
              ].resignationInsuranceAccepted
            }
            handleClick={props.handleClick}
            type={props.type}
            countryList={props.countryList}
            accommodationConfig={props.accommodationConfig}
            accommodationConfigNew={props.accommodationConfigNew}
            reservationId={props.reservationId}
            Reservation={props.Reservation}
            skipper={props.skipper}
            handleChange15={props.handleChange15}
            handleChange16={props.handleChange16}
            handleChange17={props.handleChange17}
            handleChange18={props.handleChange18}
            handleChange19={props.handleChange19}
            handleChange20={props.handleChange20}
            handleChange21={props.handleChange21}
            messageStepFive={props.messageStepFive}
            handleChange22={props.handleChange22}
            handleBackToStepFour={props.handleBackToStepFour}
            handleChange25={props.handleChange25}
            wrongMessage={el.wrongMessage}
            participantsConfig={props.participantsConfig}
            accommodationOption={props.accommodationOption}
            valueName={
              props.accommodationConfig.length === 0 ||
              props.accommodationConfig === []
                ? ""
                : (props.accommodationConfig.length !== 0 ||
                    props.accommodationConfig !== []) &&
                  props.accommodationOption === "whole"
                ? props.accommodationConfigNew[
                    props.accommodationConfigNew.findIndex(
                      (el) => el.id === index + 1
                    )
                  ].valueName
                : (props.accommodationConfig.length !== 0 ||
                    props.accommodationConfig !== []) &&
                  (props.accommodationOption === "single" ||
                    props.accommodationOption === "cabin")
                ? props.accommodationConfig[
                    props.accommodationConfig.findIndex(
                      (el) => el.id === index + 1
                    )
                  ].valueName
                : null
            }
            valueSurname={
              props.accommodationConfig.length === 0 ||
              props.accommodationConfig === []
                ? ""
                : (props.accommodationConfig.length !== 0 ||
                    props.accommodationConfig !== []) &&
                  props.accommodationOption === "whole"
                ? props.accommodationConfigNew[
                    props.accommodationConfigNew.findIndex(
                      (el) => el.id === index + 1
                    )
                  ].valueSurname
                : (props.accommodationConfig.length !== 0 ||
                    props.accommodationConfig !== []) &&
                  (props.accommodationOption === "single" ||
                    props.accommodationOption === "cabin")
                ? props.accommodationConfig[
                    props.accommodationConfig.findIndex(
                      (el) => el.id === index + 1
                    )
                  ].valueSurname
                : ""
            }
            handleChange2={props.handleChange2}
            handleChange={props.handleChange}
            handleChange36={props.handleChange36}
            newsletterAccepted={el.newsletterAccepted}
            list={props.list}
            allChecked={props.allChecked}
            fillInLater={el.fillInLater}
            firstName={el.firstName}
            policyAccepted={el.policyAccepted}
            lastName={el.lastName}
            birthDate={el.birthDate}
            idDocument={el.idDocument}
            country={el.country}
            handleChange30={props.handleChange30}
            gender={el.gender}
            phone={el.phone}
            email={el.email}
            userNum={el.userNum}
            password={el.password}
            handleChange31={props.handleChange31}
            colorInputRedPolicyAcc={props.colorInputRedPolicyAcc}
            colorInputRedPhone={props.colorInputRedPhone}
            colorInputRedGender={props.colorInputRedGender}
            colorInputRedCountry={props.colorInputRedCountry}
            colorInputRedFirstName={props.colorInputRedFirstName}
            colorInputRedLastName={props.colorInputRedLastName}
            colorInputRedBirthDate={props.colorInputRedBirthDate}
            colorInputRedIdDocument={props.colorInputRedIdDocument}
            colorInputRedEmail={props.colorInputRedEmail}
            colorInputRedPassword={props.colorInputRedPassword}
          />
        ))}

        {/* <div className="agreements">
                    <label className="containerInputCheckbox allAgreements"><p className="textSmall">Zaznacz wszystkie zgody</p>
                        <input type="checkbox" name="checkAll" checked={props.allChecked} onChange={props.handleChange2} />
                        <span className="checkmark"></span>
                    </label>
                    <RenderList list={props.list} handleChange2={props.handleChange2} colorInputRedAgree={props.colorInputRedAgree} />
                    <p className="errorMessage">{props.message}</p>
                </div> */}

        <p
          className="errorMessage"
          style={{
            fontSize: "15px",
            fontFamily: "SpaceGrotesk-Bold",
            whiteSpace: "break-spaces",
          }}
        >
          {ReactHtmlParser(props.messageStepFive)}
        </p>

        <div className="containerButtons">
          <button className="secondaryBtn" onClick={props.handleBackToStepFour}>
            Wstecz
          </button>
          <button
            className="primaryBtn"
            disabled={props.buttonAvailableStepFive ? "" : "disabled"}
            id={props.buttonAvailableStepFive ? null : "disabledButton"}
            onClick={props.handleUpdateStepFive}
          >
            Dalej
          </button>
          {!props.buttonAvailableStepFive && (
            <div class="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
        </div>
      </div>
      <TripBox Reservation={props.Reservation} />
    </div>
  );
};

export default StepFive;
