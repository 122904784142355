import React from "react";
import DetailsWrapper from "./DetailsWrapper.js";
import ParticipantsWrapper from "./ParticipantsWrapper.js";
import TransportWrapper from "./Transport.js";
import AccomodationWrapper from "./Accomodation.js";
import Payments from "./Payments.js";
import AttractionWrapper from "./AttractionWrapper.js";
import InsuranceWrapper from "./InsuranceWrapper.js";
import {Tabs, Tab, TabPanel, TabList} from "react-web-tabs";

const TourDetailsMenu = (props) => {
  const tabsArr = [
    {tabNumber: "one", tabName: "Informacje", component: <DetailsWrapper {...props} />},
    {tabNumber: "two", tabName: "Uczestnicy", component: <ParticipantsWrapper {...props} />},
    {tabNumber: "three", tabName: "Transport", component: <TransportWrapper {...props} />},
    {tabNumber: "four", tabName: "Jachty", component: <AccomodationWrapper {...props} />},
    {tabNumber: "five", tabName: "Ubezpieczenie", component: <InsuranceWrapper {...props} />},
    {tabNumber: "six", tabName: "Dodatki", component: <AttractionWrapper {...props} />},
    {tabNumber: "seven", tabName: "Płatności", component: <Payments {...props} />},
  ];

  return (
    <div id="tripStatusMenu">
      <Tabs defaultTab="basic-tab-one">
        <TabList>
          {tabsArr.map(({tabNumber, tabName}) => (
            <Tab tabFor={`basic-tab-${tabNumber}`}>{tabName}</Tab>
          ))}
        </TabList>
        {tabsArr.map(({tabNumber, component}) => (
          <TabPanel tabId={`basic-tab-${tabNumber}`}>{component}</TabPanel>
        ))}
      </Tabs>
    </div>
  );
};

export default TourDetailsMenu;
