import React from "react";
import "./BlogPage.scss";
import PartnersWrapper from "./PartnersWrapper.js";
import {connect} from "react-redux";
import {blogListCachedUrl} from "./cachedStoreUrls";
import {fetchPosts} from "./store/actions";
import Loader from "./Loader.js";
import { Link } from 'react-router-dom';
import logo from './img/logo-TBT.png';

export const prefetchMethod = async (dispatch, match) => {
  return dispatch(
    await fetchPosts(
      "",
      blogListCachedUrl
    )
  );
};
const enhance = connect((state) => {
  return {
    Page: state.fetchedUrls[blogListCachedUrl],
    dataLoaded: state.urlStatus[blogListCachedUrl] === "done",
  };
});
class BlogPage extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
    prefetchMethod(this.props.dispatch);
  };

  render() {
    const { Page, dataLoaded } = this.props;

    if (!dataLoaded) {
      return (
        <Loader />
      );
    }
    return (
      <div className="blog">
        <div className="content">
            <div className="mainText">
              <h2>Lista artykułów:</h2>
            </div>
            <>
              {Page?.map((el) => {
                return (
                  <Link id="blogPost" to={`/blog/${el.slug}`}>
                      <div className="photoContainer">
                        {el.featured_media
                        ? (
                            <img src={el.featured_media} alt={el.title.rendered} />
                        ) : (
                          <div className="logoContainer">
                            <img src={logo} alt={el.title.rendered}/>
                          </div>
                        )
                    }</div>
                      
                    <div className="blogInfo">
                      <h5 dangerouslySetInnerHTML={{__html: el.title.rendered}} />
                      <div className="blogDate">{new Date(el.date).toLocaleDateString()}</div>
                      <div dangerouslySetInnerHTML={{__html: el.excerpt.rendered}} />
                    </div>
                  </Link>
                )
              })}
            </>
        </div>
        
        <PartnersWrapper />
      </div>
    );
  }
}

BlogPage.prefetchMethod = prefetchMethod;

export default enhance(BlogPage);
