import React, {useContext, useState} from "react";
import {DataDostawca} from "./DataDostawca";
import Tabs from "./Tabs";
import "./OfferTransports.scss";
import ReactHtmlParser from "react-html-parser";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import OfferTransportMapWrapper from "./OfferTransportMapWrapper";

const Transport = ({offerJourneys, offerPortArr, offerDetails, offerRegion}) => {
  const {setElementToScroll} = useContext(DataDostawca);
  const [activeElementIndex, setActiveElementIndex] = useState(0);

  const setTransportsAvailable = (Tab) => {
    let transportTabNew = [];
    Tab.map((el) => el.Available && transportTabNew.push(el));
    return transportTabNew;
  };

  return (
    <div id="offerTransportsSection" ref={setElementToScroll("transport")}>
      <div className="transportTitle">
        <h2>{offerJourneys.Header}</h2>
        <p></p>
      </div>
      <div className="transportContainer">
        <Tabs
          overwriteActiveIndex={activeElementIndex}
          clickNewIndex={(x) => {
            setActiveElementIndex(x);
          }}
          tabs={setTransportsAvailable(offerJourneys.List).map(({Name, Description, Price, Images}) => {
            return {
              title: (
                <div className="transportsTabWrapper">
                  <p>
                    {Name.includes("Własny") || Name.includes("własny")
                      ? "Dojazd Własny"
                      : Name === "Dojazd zorganizowany - Bus osobowy"
                      ? "Dojazd zorganizowany"
                      : Name}
                  </p>
                </div>
              ),
              content: (
                <div className="transportsTab">
                  <div
                    className={`transportDescriptionWrapper ${
                      offerPortArr[0] === undefined ? "transportFullWidth" : ""
                    }`}
                  >
                    <div className="transportTitleWrapper">
                      <h3>{Name.includes("Własny") || Name.includes("własny") ? "Dojazd Własny" : Name}</h3>
                      {Price === 0 && <span> W cenie wyjazdu</span>}
                      {Price > 0 && <span> +{Price} PLN</span>}
                      {Price < 0 && <span> -{Price} PLN</span>}
                    </div>
                    <p>{ReactHtmlParser(Description)}</p>
                  </div>
                  {(Name.includes("Własny") || Name.includes("własny")) && offerPortArr?.[0] && (
                    <OfferTransportMapWrapper
                      offerPortArr={offerPortArr}
                      offerDetails={offerDetails}
                      offerRegion={offerRegion}
                    />
                  )}
                  {Images.length && (
                    <div className="transportsImagesWrapper">
                      <ul>
                        {Images.map((el) => (
                          <li>
                            <img src={el?.url} alt={el?.alt} />
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              ),
            };
          })}
        />
      </div>
      <div className="boxFilter">
        {offerJourneys.List.map(
          ({Name, Description, Price, Images, Available}) =>
            Available && (
              <Accordion allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="header">
                        <h5>
                          {Name === "Dojazd Własny - Szkolenia"
                            ? "Dojazd własny"
                            : Name === "Dojazd zorganizowany - Bus osobowy"
                            ? "Dojazd zorganizowany"
                            : Name}{" "}
                          {Price === 0 && <span> W cenie wyjazdu</span>}
                          {Price > 0 && <span> +{Price} PLN</span>}
                          {Price < 0 && <span> -{Price} PLN</span>}
                        </h5>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="tripProgram">
                      <div className="titleText">{ReactHtmlParser(Description)}</div>
                      {Name.includes("Własny") && offerPortArr?.[0] && (
                        <OfferTransportMapWrapper
                          offerPortArr={offerPortArr}
                          offerDetails={offerDetails}
                          offerRegion={offerRegion}
                        />
                      )}
                      <div className="transportsImagesWrapper">
                        {Images.map((el) => (
                          <img src={el?.url} alt={el?.alt} />
                        ))}
                      </div>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            )
        )}
      </div>
    </div>
  );
};

export default Transport;
