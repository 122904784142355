import React from "react";
import "./OfferTrips.scss";
import Calendar from "./images/calendar_icon_black.png";

const OfferTrips = ({offerDetails}) => {
  return (
    <div className="OfferTripsSection">
      <h2>Rejsy morskie 2024 - podobne oferty</h2>
      <div className="OfferTripsWrapper">
        {offerDetails?.SimilarOffers?.map(({Region, ListImg, Name, Availability, FriendlyUrl}) => {
          const regionImg = Region.Country.Image;
          return (
            <div className="OfferTrip">
              <div className="OfferTripCover">
                <div className="overlay"></div>
                <img src={ListImg?.url} alt={ListImg?.alt} />
              </div>
              <div className="OfferTripTitle">
                <h3>{Name}</h3>
              </div>
              <div className="OfferTripContainer">
                <div className="textContainer">
                  <img className="OfferFlag" src={regionImg?.url} alt={regionImg?.alt} /> {Region.Country.Name},
                  {Region.Name} <br />
                </div>
                <div>
                  <img className="OfferIcon" src={Calendar} alt="Termin rejsu" /> {Availability.From} -{Availability.To}
                </div>
                <div className="buttonContainer">
                  <a href={`/oferta/${FriendlyUrl}`}>
                    <button className="secondaryBtn" title="Sprawdź szczegóły oferty">
                      Zobacz szczegóły
                    </button>
                  </a>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OfferTrips;
