import React from "react";

const Loader = () => {
  let divs = [];
  for (let i = 0; i < 8; i++) {
    divs.push(<div key={i}></div>);
  }
  return <div class="lds-roller">{divs}</div>;
};

export default Loader;
