import React from "react";
import CruiseCarousel from "./CruiseCarousel.js";
import "./CruiseSelectInfo.scss";

const CruiseSelectionInfo = ({scrollDiv, findHTMLPageHTML, Page}) => {
  return (
    <div id="cruiseSelectInfo" ref={scrollDiv}>
      <div className="programBox">
        <div className="overlayImage"></div>
        <h2>Wybór rejsu</h2>
        <div className="programBoxes">
          <CruiseCarousel Page={Page} findHTMLPageHTML={findHTMLPageHTML} />
        </div>
      </div>
    </div>
  );
};

export default CruiseSelectionInfo;
