import React from "react";
import "./Payments.scss";

const Payments = ({
  reservationArr,
  reservationId,
  colorMessage,
  handleChangeVoucher,
  handlePay,
  handlePayWhole,
  handleSubmitVoucher,
  voucherCode,
  messageVoucher,
}) => {
  let dateOfTrip = reservationArr.Offer.Availability.From;
  let yearOfTrip = dateOfTrip.slice(0, 4);
  let monthOfTrip = dateOfTrip.slice(5, 7);
  let dayOfTrip = dateOfTrip.slice(8, 10);

  const firstPaymentDate = new Date(yearOfTrip, monthOfTrip - 1, dayOfTrip);
  firstPaymentDate.setDate(firstPaymentDate.getDate() - 60);

  let dayFirstRate = firstPaymentDate.getDate() < 10 ? "0" + firstPaymentDate.getDate() : firstPaymentDate.getDate();
  let monthFirstRate =
    firstPaymentDate.getMonth() < 10 ? "0" + (firstPaymentDate.getMonth() + 1) : firstPaymentDate.getMonth() + 1;
  let yearFirstRate = firstPaymentDate.getFullYear();
  let firstRateString = dayFirstRate.toString() + "." + monthFirstRate.toString() + "." + yearFirstRate.toString();

  const secondPaymentDate = new Date(yearOfTrip, monthOfTrip - 1, dayOfTrip);
  secondPaymentDate.setDate(secondPaymentDate.getDate() - 30);

  let daySecondRate =
    secondPaymentDate.getDate() < 10 ? "0" + secondPaymentDate.getDate() : secondPaymentDate.getDate();
  let monthSecondRate =
    secondPaymentDate.getMonth() < 10 ? "0" + (secondPaymentDate.getMonth() + 1) : secondPaymentDate.getMonth() + 1;
  let yearSecondRate = secondPaymentDate.getFullYear();
  let secondRateString = daySecondRate.toString() + "." + monthSecondRate.toString() + "." + yearSecondRate.toString();

  return (
    <>
      {reservationArr.ReserveList && (
        <div className="orangeBox">
          <p>
            Twoja rezerwacja jest na liście rezerwowej. Rezerwację potwierdzimy telefonicznie lub mailowo - prosimy do
            tego momentu nie wpłacać zaliczki.
          </p>
        </div>
      )}
      {reservationArr?.StepConfig.Step6.paymentOption === "installment" && (
        <div id="payments">
          <h4>Kalendarz wplat</h4>
          <table className="detailsBox">
            <tr>
              <td className="firstColumn">{reservationArr.InstallmentDetails[0].Name}</td>
              <td className="secondColumn">{reservationArr.InstallmentDetails[0].Value} PLN w ciągu 24 godzin</td>
            </tr>
            <tr>
              <td className="firstColumn">{reservationArr.InstallmentDetails[1].Name}</td>
              <td className="secondColumn">
                {reservationArr.InstallmentDetails[1].Value} PLN do {firstRateString}
              </td>
            </tr>

            <tr>
              <td className="firstColumn">{reservationArr.InstallmentDetails[2].Name}</td>
              <td className="secondColumn">
                {reservationArr.InstallmentDetails[2].Value} PLN do {secondRateString}
              </td>
            </tr>
          </table>
        </div>
      )}
      <div id="payments">
        <h4>Cena i płatności</h4>
        <table className="detailsBox">
          <tr>
            <td className="firstColumn">Cena całkowita</td>
            <td className="secondColumn">{reservationArr.Price.CurrentPrice} PLN</td>
          </tr>

          <tr>
            <td className="firstColumn">Wpłaty:</td>
            <td className="secondColumn">{reservationArr.Price.Paid === null ? 0 : reservationArr.Price.Paid} PLN</td>
          </tr>
          {reservationArr?.Price?.Rebate && (
            <tr>
              <td className="firstColumn">Rabat:</td>
              <td className="secondColumn">{reservationArr.Price.Rebate} PLN</td>
            </tr>
          )}

          {reservationArr.Rebates.map(({Name}) => Name?.includes("First") || Name?.includes("Second")) &&
            reservationArr.Rebates.map(({Name}) =>
              Name?.includes("First") ? (
                <tr>
                  <td className="firstColumn">Zniżka First Minute: </td>
                  <td className="secondColumn">200 PLN (wliczona w cenę)</td>
                </tr>
              ) : (
                Name?.includes("Second") && (
                  <tr>
                    <td className="firstColumn">Zniżka Second Minute: </td>
                    <td className="secondColumn">100 PLN (wliczona w cenę)</td>
                  </tr>
                )
              )
            )}

          <tr>
            <td className="firstColumn">Pozostało do zapłaty:</td>
            <td className="secondColumn">{reservationArr.Price.LeftToPay} PLN</td>
          </tr>
          <tr>
            <td className="firstColumn">Płatność:</td>
            <td className="secondColumn">
              {reservationArr.StepConfig.Step6.paymentOption === "installment" ? "Ratalna" : "Przelew jednorazowy"}
            </td>
          </tr>
          <tr>
            <td className="firstColumn">Metoda płatności:</td>
            <td className="secondColumn">
              {reservationArr.StepConfig.Step6.paymentType === "wired"
                ? "Przelew tradycyjny"
                : "PayU (prowizja wynosi 1% wpłaty)"}
            </td>
          </tr>
          {reservationArr.StepConfig.Step6.paymentType === "payu" && reservationArr.PaymentsList.length !== 0 && (
            <tr>
              <td className="firstColumn">Prowizja:</td>
              <td className="secondColumn">
                <p style={{maxWidth: "250px", margin: "0"}}>{reservationArr.PaymentsList[0].Name}</p>
              </td>
            </tr>
          )}

          {reservationArr.StepConfig.Step6.paymentType === "wired" && (
            <>
              <tr>
                <td className="firstColumn">Tytuł przelewu:</td>
                <td className="secondColumn">
                  {reservationId}, {reservationArr.StepConfig.Step5.participantsConfig[0].firstName}
                  {reservationArr.StepConfig.Step5.participantsConfig[0].lastName}, {reservationArr.Name}
                </td>
              </tr>
              <tr>
                <td className="firstColumn">Numer rachunku:</td>
                <td className="secondColumn">PL 06 1050 1025 1000 0090 3067 4858</td>
              </tr>
            </>
          )}
        </table>
        {(!reservationArr.ReserveList || reservationArr.ReserveList === undefined) &&
          reservationArr.Status !== "Anulowana" &&
          !reservationArr.IsHistorical &&
          reservationArr.Owner && (
            <>
              {reservationArr.Price.LeftToPay !== 0 &&
                reservationArr.StepConfig.Step6.paymentType === "payu" &&
                reservationArr.Owner && (
                  <div className="btnBox">
                    <button style={{marginLeft: "0"}} className="primaryBtn" onClick={(event) => handlePay(event)}>
                      Zapłać kolejną ratę
                    </button>
                  </div>
                )}
              {reservationArr.Price.LeftToPay !== 0 &&
                reservationArr.StepConfig.Step6.paymentType === "payu" &&
                reservationArr.Owner && (
                  <div className="btnBox">
                    <button style={{marginLeft: "0"}} className="primaryBtn" onClick={(event) => handlePayWhole(event)}>
                      Dokonaj płatności za cały wyjazd
                    </button>
                  </div>
                )}
              <h4>Vouchery</h4>
              <div className="vouchersContainer">
                <p>Numer vouchera</p>
                <div className="vouchers">
                  <input type="text" name="voucherCode" value={voucherCode} onChange={handleChangeVoucher} />
                  <button className="secondaryBtn" onClick={handleSubmitVoucher}>
                    Dodaj
                  </button>
                </div>
                <p style={{color: colorMessage}}>{messageVoucher}</p>
              </div>
            </>
          )}
      </div>
    </>
  );
};

export default Payments;
