import React, {useContext} from "react";
import {DataDostawca} from "./DataDostawca.js";
import ReactHtmlParser from "react-html-parser";
import "./OfferPrice.scss";
import "../SingleDiscount.scss";
import {Link} from "react-router-dom";

function Price({offerCosts, offerDetails, offerPrices}) {
  const offerType = offerDetails?.Type?.OfferTypeId;
  const countryid = offerDetails?.Region?.Country?.CountryId;
  const advancePayment = offerPrices?.AdvancePayment;
  const {setElementToScroll} = useContext(DataDostawca);

  return (
    <>
      <div id="offerPriceSection" ref={setElementToScroll("price")}>
        <div className="container price">
          <h2>Cena rejsu</h2>
          <div className="boxes">
            <div className="oneBox priceInclude">
              <div className="titleContainer">
                <h4>Cena zawiera</h4>
              </div>
              <ul className="elements">
                {offerCosts.Included.map((el) => (
                  <li>{ReactHtmlParser(el)}</li>
                ))}
              </ul>
            </div>
            <div className="oneBox priceNotInclude">
              <div className="titleContainer">
                <h4>Cena nie zawiera</h4>
              </div>
              <div className="elements">
                <ul>
                  {offerCosts.Excluded.map((el) => (
                    <li>{ReactHtmlParser(el)}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="boxes" style={offerType === "6" ? {display: "flex", justifyContent: "center"} : null}>
            {offerType === "6" ? (
              <div className="oneBox">
                <div className="titleContainer">
                  <h4>Harmonogram płatności</h4>
                </div>
                <div className="offerPrice-TimetableWrapper">
                  <div className="timetableItem">
                    <div className="timetableItem-head">
                      <p>Zaliczka - 24h</p>
                      <p>30% ceny</p>
                    </div>
                    <div className="timetableItem-bar"></div>
                  </div>
                  <div className="timetableItem">
                    <div className="timetableItem-head">
                      <p>
                        2 rata <span>60 dni przed terminem rozpoczęcia rejsu</span>
                      </p>
                      <p>30% ceny</p>
                    </div>
                    <div className="timetableItem-bar tItem-bar2"></div>
                  </div>
                  <div className="timetableItem">
                    <div className="timetableItem-head">
                      <p>
                        3 rata <span>30 dni przed terminem rozpoczęcia rejsu</span>
                      </p>
                      <p>40% ceny</p>
                    </div>
                    <div className="timetableItem-bar tItem-bar2 tItem-bar2w"></div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="oneBox">
                <div className="titleContainer">
                  <h4>Harmonogram płatności</h4>
                </div>
                <div className="offerPrice-TimetableWrapper">
                  <div className="timetableItem">
                    <div className="timetableItem-head">
                      <p>Zaliczka - 24h</p>
                      <p>{`${advancePayment} zł`}</p>
                    </div>
                    <div className="timetableItem-bar"></div>
                  </div>
                  <div className="timetableItem">
                    <div className="timetableItem-head">
                      <p>
                        2 rata <span>60 dni przed terminem rozpoczęcia rejsu</span>
                      </p>
                      <p>50%</p>
                    </div>
                    <div className="timetableItem-bar tItem-bar2"></div>
                  </div>
                  <div className="timetableItem">
                    <div className="timetableItem-head">
                      <p>
                        3 rata <span>30 dni przed terminem rozpoczęcia rejsu</span>
                      </p>
                      <p>100%</p>
                    </div>
                    <div className="timetableItem-bar tItem-bar2 tItem-bar2w"></div>
                  </div>
                </div>
              </div>
            )}
            {offerType !== "6" && (
              <div className="oneBox">
                <div className="titleContainer">
                  <h4>Opcje dodatkowe</h4>
                </div>
                <ul className="elements">
                  <li>Rezerwacja sypialni - 200 PLN / osoba (wyłącznie rezerwacje dwuosobowe)</li>
                  <li>
                    Ubezpieczenie od kosztów rezygnacji
                    {countryid === "9" || countryid === "10" || countryid === "11" || countryid === "12"
                      ? " 300 PLN "
                      : " 150 PLN "}
                    / tydzień
                  </li>
                  <li>
                    Żeglarskie Bluzy od 
                    <a
                      href="https://offcoursewear.pl/"
                      target="_blank"
                      title="Sprawdź naszą markę odzieżową offCourseWear"
                      rel="noreferrer"
                    >
                       Off Course
                    </a>
                    - od 199 PLN
                  </li>
                </ul>
              </div>
            )}
          </div>
          {(offerDetails.Name.includes("Kurs") || offerDetails.Name.includes("Akademia")) && (
            <Link to="/szkolenia-informacje" title="Dowiedz się więcej o naszych szkoleniach żeglarskich">
              <button className="secondaryBtn">Dowiedz się więcej o naszych szkoleniach</button>
            </Link>
          )}
        </div>
      </div>
    </>
  );
}

export default Price;
