import React, { useState } from 'react';
import { FaArrowCircleUp } from 'react-icons/fa';
import './ScrollToTop.scss'



const ScrollArrow = () => {

    const [showScroll, setShowScroll] = useState(false)

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400) {
            setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 400) {
            setShowScroll(false)
        }
    };

    const scrollTop = () => {
        global.window?.scrollTo({ top: 0, behavior: 'smooth' });
    };

    global.window?.addEventListener('scroll', checkScrollTop)

    return (
        <FaArrowCircleUp className="scrollTop" onClick={scrollTop} style={{ fill: '#123852fc', height: 30, display: showScroll ? 'flex' : 'none' }} />


    );
};

export default ScrollArrow;