import React from "react";
import "./HowItWorks.scss";

const HowItWorks = ({image, alt, desc}) => {
  return (
    <div className="howItWorksItem">
      <img src={image} alt={alt} />
      <p>{desc}</p>
    </div>
  );
};

export default HowItWorks;
