import React from 'react';
import './OfferInfoBox.scss';
import BookButton from './OfferBookButton.js';
import Moment from 'moment';

function OfferInfoBox({ offerPrices, offerDetails }) {
  const offerTypeName = offerDetails?.Type?.Name;
  const currentDate = Moment().format('YYYY-MM-DD');
  const isTheTripGoingOn =
    Moment(currentDate).isBefore(offerDetails?.Availability?.To) &&
    Moment(currentDate).isAfter(offerDetails?.Availability?.From);
  const isAfterTrip = Moment(currentDate).isAfter(
    offerDetails?.Availability?.To
  );

  return (
    <div className="infoBoxWrapper">
      {offerPrices.Strikethrough === null ? (
        <h3 className="infoBoxPrice"> {offerPrices.Base} PLN</h3>
      ) : (
        <div>
          <h3 className="infoBoxPriceStrikethrought">
            {' '}
            {offerPrices.Strikethrough} PLN
          </h3>

          <h3 className="infoBoxPrice"> {offerPrices.Base} PLN</h3>
        </div>
      )}
      {offerTypeName === 'Rejs Prywatny' ? (
        <p id="infoBoxPriceParagraph">Cena za jacht</p>
      ) : (
        <p id="infoBoxPriceParagraph">Cena od osoby</p>
      )}
      {offerDetails.Availability.GrayedOut ? (
        <p className="availability red">Brak wolnych miejsc</p>
      ) : isTheTripGoingOn ? (
        <p className="availability red">Wyjazd w trakcie</p>
      ) : isAfterTrip ? null : offerDetails.RibbonTextType === 'red' ? (
        <p className="availability red">{offerDetails.RibbonText}</p>
      ) : offerDetails.RibbonTextType === 'green' ? (
        <p className="availability green">{offerDetails.RibbonText}</p>
      ) : offerDetails.RibbonTextType === 'orange' ? (
        <p className="availability orange">{offerDetails.RibbonText}</p>
      ) : (
        <p className="availability green">Są wolne miejsca</p>
      )}
      <BookButton
        name={offerDetails.Name}
        price={offerPrices.Base}
        promoPrice={offerPrices.Strikethrough}
        offerid={offerDetails.OfferId}
        grayedOut={offerDetails.Availability.GrayedOut}
        availableOffer={offerDetails.Availability.Available}
        offerTypeId={offerDetails.Type.OfferTypeId}
      />
    </div>
  );
}

export default OfferInfoBox;
