import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "./Questions.scss";
import "react-accessible-accordion/dist/fancy-example.css";

const Questions = ({ faqArr }) => {
  const questionsArr = faqArr
    ?.slice(1, faqArr.length)
    .filter((element, index) => index % 2 === 0);
  const answerArr = faqArr
    ?.slice(1, faqArr.length)
    .filter((element, index) => index % 2 !== 0);

  const heading = faqArr?.[0];

  return (
    <div id="questions">
      <h2>{heading}</h2>
      <div className="panelBox">
        <Accordion allowZeroExpanded>
          {questionsArr?.map((element, index) => (
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>{element}</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>{answerArr[index]}</AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default Questions;
