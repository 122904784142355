
import React from 'react';
import './PartnersWrapper.scss';
import Partner from "./Partner.js";
import taksidikiImage from './img/logo-Taksidi.png';
import bfImage from './img/logoBF.png';
import sfImage from './img/logoSF.png';
import OFCImage from './img/logoOFC.png';

var PartnersArr = [
    {
        index: 1,
        image: taksidikiImage,
        alt: 'Wyjazdy zimowe dla osób w wieku 18-39.',
        page: 'https://www.taksidi.pl',
        title: "Wyjazdy zimowe dla osób w wieku 18-39 - sprawdź"
    },
    {
        index: 2,
        image: bfImage,
        alt: 'Wyjazdy na żagle dla osób powyżej 40 roku życia.',
        page: 'https://www.boatsandfriends.eu',
        title: "Wyjazdy na żagle dla osób powyżej 40 roku życia - sprawdź"
    },

    {
        index: 3,
        image: sfImage,
        alt: 'Wyjazdy zimowe dla osób w wieku 40 lat i starszych.',
        page: 'https://www.skiandfriends.pl',
        title: 'Wyjazdy zimowe dla osób w wieku 40 lat i starszych - sprawdź'
    },

    {
        index: 4,
        image: OFCImage,
        alt: 'Nasza marka modowa dla ludzi aktywnych.',
        page: 'https://offcoursewear.pl',
        title: 'Nasza marka modowa dla ludzi aktywnych - sprawdź'
    }


];

function PartnersWrapper() {
    return (
        <div id="partnersContainer">
            <div className="partnersWrapper">
                {PartnersArr.map(({image, alt, page, title}, index) =>
                    <Partner key={index} image={image} alt={alt} page={page} title={title} />
                )}

            </div>
        </div>
    );
}

export default PartnersWrapper
