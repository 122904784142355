
import React from 'react';
import './ReservationMenu.scss';
import { Link } from "react-router-dom"
import logo from "./img/logo-TBT.png";


const ReservationMenu = (props) => {

    return (

        <div id="reservationMenu">
            <div className="menu">
                <div className="logoBox">
                    <Link to="/" title="Strona główna The Boat Trip"><img src={logo} alt='logo' /></Link>
                </div>
                <div className="reservationName"><p>Rezerwacja {props.nameTrip}</p></div>
                <div className="help">
                    <p></p>
                    </div>
            </div>
        </div>
    );
}

export default ReservationMenu
