import React from "react";
import "./Ambassador.scss";
import AmbassadorCreate from "./AmbassadorCreate.js";
import Moment from "moment";
import APIbackend from "../config.js";
import ReactHTMLparser from "react-html-parser";

class Ambassador extends React.Component {
  state = {
    siteId: 1,
    description: "",
    name: "",
    ambasadorArr: [],
    generateCodeComponent: false,
    ambassadorCodes: true,
    messageTrue: "",
    messageError: "",
    userId: "",
    incorrectInfoVoucher: "",
    correctInfoVoucher: "",
  };
  componentDidMount = () => {
    fetch(`${APIbackend}v1/account/ambassadorCodes`, {
      method: "POST",
      body: JSON.stringify({
        siteId: this.state.siteId,
      }),
      credentials: "include",
    }).then((response) => {
      response.json().then((data) => {
        if (data.AmbassadorCodes === null) {
          this.setState({
            ambassadorCodes: false,
          });
        }
        this.setState({
          ambasadorArr: data.AmbassadorCodes,
        });
      });
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  generateCode = () => {
    this.setState({
      generateCodeComponent: true,
      ambassadorCodes: false,
    });
  };

  handleBack = () => {
    this.setState({
      generateCodeComponent: false,
      ambassadorCodes: true,
    });
  };

  handleSubmit = () => {
    var data = {
      name: this.state.name,
      siteId: this.state.siteId,
    };

    fetch(`${APIbackend}v1/account/ambassadorCodes/create`, {
      method: "POST",
      body: JSON.stringify(data),
      credentials: "include",
    }).then((response) =>
      response.json().then(() => {
        if (response.status === 200) {
          fetch(`${APIbackend}v1/account/ambassadorCodes`, {
            method: "POST",
            body: JSON.stringify(data),
            credentials: "include",
          }).then((response) => {
            response.json().then((data) => {
              this.setState({
                ambasadorArr: data.AmbassadorCodes,
              });
            });
          });
          this.setState({
            messageTrue: "Kod został utworzony",
          });
        } else if (response.status !== 200) {
          this.setState({
            messageError:
              "Wprowadzony kod ma nieprawidłowy format lub już istnieje w naszej bazie",
          });
        }
      })
    );
  };

  generateVoucher = (value, id) => {
    this.setState({
      userId: id,
    });

    const dataForm = {
      siteId: 1,
      codeId: parseInt(id),
      value: value,
    };

    fetch(`${APIbackend}v1/account/ambassadorCodes/createVoucher`, {
      method: "POST",
      body: JSON.stringify(dataForm),
      credentials: "include",
    }).then((response) => {
      response.json().then((data) => {
        switch (data.code !== undefined) {
          case true:
            this.setState({
              correctInfoVoucher: `Voucher został wygenerowany, znajduje się w zakładce "Vouchery". Kod vouchera to: ${data?.code}`,
              incorrectInfoVoucher: "",
            });
            break;
          case false:
            this.setState({
              correctInfoVoucher: "",
              incorrectInfoVoucher:
                "Voucher nie może zostać wygenerowany. W razie problemów, skontaktuj się z biurem.",
            });
            break;
          default:
            return null;
        }
      });
    });
  };

  render() {
    const { ambasadorsActive } = this.props;
    const {
      ambassadorCodes,
      ambasadorArr,
      generateCodeComponent,
      messageTrue,
      messageError,
      name,
      incorrectInfoVoucher,
      correctInfoVoucher,
      userId,
    } = this.state;
    const {
      handleSubmit,
      handleChange,
      handleBack,
      generateVoucher,
      generateCode,
    } = this;
    return (
      ambasadorsActive && (
        <div id="ambassadorCodes">
          <h2>Panel Ambasadora</h2>
          {ambassadorCodes && (
            <>
              <table className="table">
                <thead>
                  <tr className="tableHead">
                    <th>Nazwa kodu</th>
                    <th>Kwota dla Ciebie/Rabat dla Klienta</th>
                    <th>Data ważności</th>
                    <th>Osoby, które użyły twój kod</th>
                  </tr>
                </thead>
                <tbody>
                  {ambasadorArr.map(
                    ({
                      Name,
                      AmbassadorCommission,
                      RebateValue,
                      ValidUntil,
                      UsersList,
                      RebateSum,
                      Id,
                      Settled,
                    }) => (
                      <>
                        <tr className="ambassadorRow">
                          <td data-label="Nazwa kodu" className="codeName">
                            <input
                              name="ambassadorCode"
                              value={Name}
                              disabled
                            ></input>
                          </td>
                          <td data-label="Kwota dla Ciebie/Rabat dla Klienta">
                            {AmbassadorCommission}/{RebateValue} PLN
                          </td>
                          <td data-label="Data ważności">
                            {ValidUntil === null
                              ? "bezterminowy"
                              : Moment(ValidUntil).format("DD.MM.YYYY")}
                          </td>
                          <td data-label="Osoby, które użyły twój kod">
                            <div className="usersList">
                              {UsersList.length === 0
                                ? "-"
                                : UsersList.map(({ UserName }, index) => (
                                    <span>
                                      {UserName}
                                      {UsersList.length === index + 1
                                        ? ""
                                        : ", "}
                                    </span>
                                  ))}
                            </div>
                          </td>
                          {!Settled &&
                            UsersList.length !== 0 &&
                            AmbassadorCommission !== "0" && (
                              <span
                                id="isMobile"
                                className="generateVoucher"
                                onClick={() => generateVoucher(RebateSum, Id)}
                              >
                                Generuj voucher<i className="arrow"></i>
                                {userId === Id && (
                                  <>
                                    {incorrectInfoVoucher && (
                                      <p className="errorMessage">
                                        {ReactHTMLparser(incorrectInfoVoucher)}
                                      </p>
                                    )}
                                    {correctInfoVoucher && (
                                      <p className="trueMessage">
                                        {ReactHTMLparser(correctInfoVoucher)}
                                      </p>
                                    )}
                                  </>
                                )}
                              </span>
                            )}
                        </tr>
                        {!Settled &&
                          UsersList.length !== 0 &&
                          AmbassadorCommission !== "0" && (
                            <span
                              id="isDesktop"
                              className="generateVoucher"
                              onClick={() => generateVoucher(RebateSum, Id)}
                            >
                              {correctInfoVoucher === "" && (
                                <>
                                  Generuj voucher
                                  <i className="arrow" />
                                </>
                              )}
                              {userId === Id &&
                                (incorrectInfoVoucher !== "" ? (
                                  <p className="errorMessage">
                                    {ReactHTMLparser(incorrectInfoVoucher)}
                                  </p>
                                ) : (
                                  <p className="trueMessage">
                                    {ReactHTMLparser(correctInfoVoucher)}
                                  </p>
                                ))}
                            </span>
                          )}
                      </>
                    )
                  )}
                </tbody>
              </table>
              <button className="primaryBtn" onClick={generateCode}>
                Wygeneruj nowy kod
              </button>
            </>
          )}
          {generateCodeComponent && (
            <AmbassadorCreate
              messageTrue={messageTrue}
              messageError={messageError}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              name={name}
              handleBack={handleBack}
            />
          )}

          <div className="greenBox">
            <p className="infoText">Jak to działa?</p>
            <p>
              Za każdą zapisaną na nasz wyjazd osobę, która skorzysta z Twojego
              kodu ambasadorskiego otrzymasz prowizję w ustalonej wysokości
              wymienną na voucher.
              <br />
              <br />
              Kwoty za poszczególne osoby kumulują się i w dowolnym momencie
              można z powstałej sumy wygenerować voucher, który będzie dostępny
              do wykorzystania w sekcji “Vouchery” w Panelu Klienta.
              <br />
              <br />
              Klient, który wykorzysta Twój kod ambasadorski, dostaje rabat na
              wyjazd w ustalonej kwocie. Rabat ten odliczy się od ceny wyjazdu,
              który będzie on chciał zarezerwować (należy go podać w ostatnim
              kroku rezerwacji). Kody ambasadora łączą się z pozostałymi
              zniżkami do kwoty 120 PLN.
            </p>
          </div>
        </div>
      )
    );
  }
}

export default Ambassador;
