import React from 'react';
import './OfferHeader.scss';
import calendarIcon from './images/calendar_icon.png';
import Moment from 'moment';
import OfferInfoBox from './OfferInfoBox.js'


function Header({ offerDetails, offerCountry, offerRegion, offerTo, offerFrom, offerRooms, offerPrices, offerType, offerClasses }) {
    const offerCountryImg = offerCountry.Image;
    return (
        <div className="offerHeaderWrapper">
            <div>
            <h1 className="offerHeader">{offerDetails.Name}</h1>
            <div className='offerHeaderWrapperInfo'>
                <div className="boxInfoOffer">
                    <img className='offerHeaderFlag' src={offerCountryImg?.url} alt={offerCountryImg?.alt} style={offerCountry.CountryId === "13" ? {width: "40px", height: "100%", marginLeft: "-10px", marginRight: "5px"} : null} />
                    <p className='offerCountryResort'>{offerCountry.Name}, {offerRegion.Name}</p>
                </div>
                <div className="boxInfoOffer">
                    <img className='offerHeaderCalendar' src={calendarIcon} alt="Termin rejsu" style={offerCountry.CountryId === "13" ? {height: "25px"} : null} />
                    <p className='offerHeaderDate'>{Moment(offerFrom).format('DD.MM')}-{Moment(offerTo).format('DD.MM.YYYY')}</p>
                </div>
    
            </div>
            </div>
            <OfferInfoBox offerDetails={offerDetails} offerRooms={offerRooms} offerPrices={offerPrices} offerType={offerType} offerClasses={offerClasses} />
        </div>
    )
}

export default Header