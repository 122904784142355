import React from "react";
import Slider from "react-slick";
import SecureBooking from "./SecureBooking";
import "./SecureBookingWrapper.scss";
import imageOne from "./img/secure-booking-1.png";
import imageTwo from "./img/saveBookingIcon.png";
import imageThree from "./img/secure-booking-3.png";

const SecureBookingArr = [
  {
    image: imageOne,
    desc: "Darmowa zmiana danych uczestnika do 3 dni przed wyjazdem",
    alt: "Rejsy morskie jachtem",
  },

  {
    image: imageTwo,
    desc: "100% zwrotu w przypadku zamknięcia granic",
    alt: "Rejsy morskie jachtem",
  },

  {
    image: imageThree,
    desc: "Ubezpieczenie obejmujące leczenie COVID-19 w cenie",
    alt: "Rejsy morskie jachtem",
  },
];

class SecureBookingWrapper extends React.Component {
  render() {
    var settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1150,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 770,
          settings: {
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div id="secureBookingWrapper">
        <div className="secureBookingTitle">
          <h2>Bezpieczna rezerwacja</h2>
          <p>
            Jesteśmy licencjonowanym organizatorem turystyki, dzięki czemu Twoja rezerwacja jest zawsze bezpieczna, a
            zaliczka wynosi jedynie 200 PLN!
          </p>
        </div>
        <Slider {...settings}>
          {SecureBookingArr.map(({image, desc, alt}, index) => (
            <SecureBooking key={index} image={image} desc={desc} alt={alt} />
          ))}
        </Slider>
      </div>
    );
  }
}

export default SecureBookingWrapper;
