import React from 'react';
import './OfferAddInfo.scss';
import ReactHtmlParser from 'react-html-parser';
import shippers12 from '../img/skippers12.jpg';
import skippers11 from '../img/skippers11.jpg';
import skippers13 from '../img/skippers13.jpg';

const OfferAddInfo = ({ offerHTML, offerDetails }) => {
    return (
        <>
            <div id="addHtml">
                {offerDetails.Name.includes("patent") &&
                    <div className="addHtml2">
                        <p className="paragraph">{ReactHtmlParser(offerHTML.HTML2)}</p>
                        <div className="twoImage">
                            <img src={shippers12} alt="Skipper" />
                            <img src={skippers11} alt="Skipper" />
                        </div>
                    </div>}
                    <div className="addHtml1">
                    <div className="image">
                        <img src={skippers13} alt="Skipper" />
                    </div>
                    <p className="paragraph">{ReactHtmlParser(offerHTML.HTML1)}</p>
                </div>
            </div>   
        </>
    )
}

export default OfferAddInfo
