import React, {useState} from "react";
import "./OfferSlider.scss";
import Tabs from "./Tabs";
import ReactHtmlParser from "react-html-parser";
import "./PopUpImageYachts.scss";
import Slider from "react-slick";

const Popup = ({toggle, Boxes}) => {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="popUpImageYachts" style={{cursor: "pointer"}}>
      <div className="popupInner">
        <button className="closeButton" onClick={toggle} style={{cursor: "pointer", zIndex: "10"}}>
          ✖
        </button>
        <Slider {...settings}>
          {Boxes.slice(0, 3)
            .map((el) => el[1])
            .map((el) => (
              <>
                <img className="OfferSliderPortImage" src={el?.url} alt={el?.alt} />
              </>
            ))}
        </Slider>
      </div>
    </div>
  );
};

export const OfferSlider = ({portArr, activeElementIndex, setActiveElementIndex}) => {
  const [showPopup, togglePopup] = useState(false);
  const [image, showImage] = useState("");

  function toggle(imageRoom) {
    togglePopup(!showPopup);
    showImage(imageRoom);
  }

  const availablePortArr = portArr.filter((el) => el.Available === true);

  return (
    <>
      <div className="OfferSliderWrapper">
        <h3>Porty, które odwiedzimy</h3>
        <Tabs
          overwriteActiveIndex={activeElementIndex}
          clickNewIndex={(x) => {
            setActiveElementIndex(x);
          }}
          tabs={availablePortArr.map(({Name, Description, Boxes}) => {
            return {
              title: <span>{Name}</span>,
              content: (
                <div>
                  <h4>{Name}</h4>
                  <div>{ReactHtmlParser(Description)}</div>
                  <div className="OfferSliderPortBoxesWrapper">
                    {Boxes.slice(0, 3).map((el, index) => (
                      <>
                        <div className="OfferSliderPortBox" onClick={() => toggle(el[1])}>
                          <img className="OfferSliderPortImage" src={el[1]?.url} alt={el[1]?.alt} />
                        </div>

                        {showPopup && (
                          <Popup
                            togglePopup={togglePopup}
                            toggle={toggle}
                            showImage={showImage}
                            image={image}
                            Boxes={Boxes}
                            index={index}
                          />
                        )}
                      </>
                    ))}
                  </div>
                </div>
              ),
            };
          })}
        />
      </div>
    </>
  );
};
