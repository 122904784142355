import React, {useEffect} from "react";
import "./StepThree.scss";
import ReactHtmlParser from "react-html-parser";
import "./TripBox.scss";
import Moment from "moment";
import {IAmForAnalitics} from "./utilsApp";
import imageCalendar from "./img/calendar.png";

const TripBox = (props) => {
  return (
    <div id="tripBox">
      <div>
        <div className="imageContainer">
          <img src={props.Reservation.Offer.ListImg?.url} alt={props.Reservation.Offer.ListImg?.alt} />
        </div>
        <div className="textBox">
          <h4>{props.Reservation.Offer.Name}</h4>
          <p className="date">
            <img src={imageCalendar} alt="termin" />
            {Moment(props.Reservation.Offer.From).format("DD.MM") +
              "-" +
              Moment(props.Reservation.Offer.To).format("DD.MM.YYYY")}
          </p>
          <p className="price">{props.Reservation.Offer.CurrentPrice} PLN</p>
          <p className="postscript">Łączna cena Twojego wyjazdu</p>
          {props.Reservation.Offer.CurrentRebate !== 0 ? (
            <>
              {/* <p className="postscript">Cena do zapłaty po uwzględnieniu rabatu</p> */}
              <p className="rebate">
                <span style={{fontFamily: "SpaceGrotesk-SemiBold"}}>W tym rabat, na który składa się:</span>
                {props.Reservation.Offer.RebatesList.map((el) => (
                  <p className="rebate">
                    {el.Name}:<span> {el.Value + " PLN"}</span>
                  </p>
                ))}
              </p>
            </>
          ) : null}
        </div>
      </div>
      {props.Reservation.Data[1].Config.IsReserveList === 1 && (
        <div className="orangeBox" id="infoReservationListInStep">
          <p>UWAGA: Zapisujesz się na listę rezerwową</p>
        </div>
      )}
    </div>
  );
};

const OnePerson = (props) => {
  const StepThreeArr = props.Reservation.Data.find((el) => el.Step === 3);
  const insuranceListArr = StepThreeArr.InsurancesList;
  const currentDate = Moment().format("YYYY-MM-DD");
  const deadline = Moment(props.Reservation.Offer.From).subtract(7, "d").format("YYYY-MM-DD");
  const isResignationInsurance = Moment(currentDate).isBefore(deadline) && props.Reservation.Offer.EnableResignationInsurance;
  const assistanceCost = StepThreeArr.AssistanceCost;
  const isAssistance = props.Reservation.Offer.EnableAssistance;

  insuranceListArr.sort(function (a, b) {
    return parseFloat(a.InsuranceId) - parseFloat(b.InsuranceId);
  });

  const OWU = "/documents/WIENER_OWU-Ubezpieczeni-w-podrozy.pdf";

  return (
    <>
      {props.userNum === 1 ? (
        <>
          <div className="name">
            <p>{props.userNum}. Uczestnik (Ty)</p>
          </div>
          <>
            <div className="selectRadio">
              <h3>
                Ubezpieczenie turystyczne KL i NNW{" "}
                <button className="secondaryBtn">
                  <a
                    href={OWU}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Przeczytaj ogólne warunki ubezpieczenia"
                  >
                    Przeczytaj OWU<i className="arrow"></i>
                  </a>
                </button>
              </h3>
            </div>

            <div id="insurance" className="selectBoxes">
              <div className="boxes">
                {StepThreeArr.InsurancesList.map(
                  (el) =>
                    el.Available === true && (
                      <label className="boxInputRadio">
                        <div className="descriptionBox">
                          <p className="titleText">{el.Name}</p>
                          <p className="description">{el.Description}</p>
                          {ReactHtmlParser(el.list)}
                        </div>
                        {el.IsRecommended === true ? (
                          <>
                            <div className="recomended">
                              <p className="price">
                                +{el.Price} PLN<span className="smallText">za osobę</span>
                              </p>
                              <p className="postscript">Rekomendujemy dla tego wyjazdu</p>
                            </div>
                            {el.Price === "0" ? (
                              <>
                                <p className="price include">W cenie wyjazdu</p> :
                                <div className="normal">
                                  <p className="price">+{el.Price} PLN</p>
                                  <p className="smallText">osoba</p>
                                </div>
                              </>
                            ) : null}
                          </>
                        ) : (
                          <div className="priceBoxInsurance">
                            {el.Price === "0" ? (
                              <p className="price include">W cenie wyjazdu</p>
                            ) : (
                              <div className="normal">
                                <p className="price">+{el.Price} PLN</p>
                                <p className="smallText">osoba</p>
                              </div>
                            )}
                          </div>
                        )}

                        <input
                          type="radio"
                          name={props.id}
                          checked={props.insuranceId === el.InsuranceId}
                          onChange={(event) => props.handleChange8(props.id, event)}
                          value={el.InsuranceId}
                        ></input>
                        <span className="checkmarkRadio"></span>
                        <div className="boxAfterClick"></div>
                      </label>
                    )
                )}
              </div>
            </div>

            {isResignationInsurance && (
              <div className="selectRadio">
                <h3>Ubezpieczenie od kosztów rezygnacji</h3>
                <div className="container">
                  <div className="textBox">
                    {props.accommodationOption === "single" || props.accommodationOption === "cabin" ? (
                      <>
                        <p>
                          Ubezpieczenie chroni wpłacone środki w sytuacji poważnych komplikacji uniemożliwiających
                          wzięcie udziału w wyjeździe np. nagłej choroby. <br /> Przeczytaj o tym produkcie w naszym{" "}
                          <a
                            href="/faq"
                            target="_blank"
                            rel="noreferrer"
                            title="Przeczytaj FAQ"
                            style={{fontFamily: "SpaceGrotesk-Bold"}}
                          >
                            FAQ
                          </a>
                          .
                        </p>
                        <div
                          className="greenBox"
                          style={{
                            marginBottom: "20px",
                            marginTop: "20px",
                            backgroundColor: "#ffc1074f",
                            fontWeight: "100",
                            maxWidth: "100%",
                          }}
                        >
                          <p className="infoTitle" style={{color: "orange"}}>
                            <span style={{color: "orange", fontFamily: "SpaceGrotesk-SemiBold"}}>UWAGA:</span>{" "}
                            Ubezpieczenie jest dostępne tylko przy składaniu rezerwacji i wymaga podania imienia,
                            nazwiska i daty urodzenia każdej osoby ubezpieczonej w kroku nr 5!
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <p>
                          Ubezpieczenie chroni wpłacone środki w sytuacji poważnych komplikacji uniemożliwiających
                          wzięcie udziału w wyjeździe np. nagłej choroby. <br />
                          Przeczytaj o tym produkcie w naszym{" "}
                          <a
                            href="/faq"
                            target="_blank"
                            rel="noreferrer"
                            title="Przeczytaj FAQ"
                            style={{fontFamily: "SpaceGrotesk-Bold"}}
                          >
                            FAQ
                          </a>
                          .{" "}
                        </p>
                        <div
                          className="greenBox"
                          style={{
                            marginBottom: "20px",
                            marginTop: "20px",
                            backgroundColor: "#ffc1074f",
                            fontWeight: "100",
                            maxWidth: "100%",
                          }}
                        >
                          <p className="infoTitle" style={{color: "orange"}}>
                            <span style={{color: "orange", fontFamily: "SpaceGrotesk-SemiBold"}}>UWAGA:</span>{" "}
                            Ubezpieczenie jest dostępne tylko przy składaniu rezerwacji. i wymaga podania imienia,
                            nazwiska i daty urodzenia każdej osoby ubezpieczonej.
                          </p>
                        </div>
                      </>
                    )}
                    <form>
                      <div className="containerInputRadio" style={{marginTop: "0"}}>
                        <label className="inputRadio">
                          <p>
                            Tak <span style={{textDecoration: "line-through"}}>(200 PLN)</span> +
                            {StepThreeArr.ResignationCost} PLN
                          </p>
                          <input
                            type="radio"
                            name="insurance"
                            value="1"
                            checked={props.resignationInsuranceAccepted === "1"}
                            onChange={(event) => props.handleChange9(props.id, event)}
                          ></input>
                          <span className="checkmark"></span>
                        </label>
                        <label className="inputRadio">
                          <p>Nie</p>
                          <input
                            type="radio"
                            name="insurance"
                            value="0"
                            checked={props.resignationInsuranceAccepted === "0"}
                            onChange={(event) => props.handleChange9(props.id, event)}
                          ></input>
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </form>
                  </div>
                  <div className="redBox">
                    <p className="infoTitle">
                      Ubezpieczenie dotyczy jednej osoby. W rezerwacjach wieloosobowych należy je wybrać dla każdej
                      osoby, którą chcemy ubezpieczyć.
                    </p>

                    <a
                      href={OWU}
                      target="_blank"
                      rel="noopener noreferrer"
                      title="Przeczytaj ogólne warunki ubezpieczenia"
                    >
                      Koniecznie przeczytaj OWU
                    </a>
                  </div>
                </div>
              </div>
            )}

            {isAssistance && (
              <div className="selectRadio">
                <h3>Ubezpieczenie assistance na wypadek kwarantanny</h3>
                <div className="container">
                  <div className="textBox">
                    <form>
                      <div className="containerInputRadio" style={{marginTop: "0"}}>
                        <label className="inputRadio">
                          <p>
                            Tak <span style={{textDecoration: "line-through"}}>(200 PLN)</span> +{assistanceCost} PLN
                          </p>
                          <input
                            type="radio"
                            name="assistance"
                            value="1"
                            checked={props.quarantineAssistanceAccepted === "1"}
                            onChange={(event) => props.handleAssistanceChange(props.id, event)}
                          ></input>
                          <span className="checkmark"></span>
                        </label>
                        <label className="inputRadio">
                          <p>Nie</p>
                          <input
                            type="radio"
                            name="assistance"
                            value="0"
                            checked={props.quarantineAssistanceAccepted === "0"}
                            onChange={(event) => props.handleAssistanceChange(props.id, event)}
                          ></input>
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </>
        </>
      ) : (
        <div id="secondPerson">
          <div className="name">
            <p>
              {props.userNum}. {props.valueName !== "" ? props.valueName : "Uczestnik"}{" "}
              {props.valueSurname !== "" ? props.valueSurname : ""}{" "}
            </p>
          </div>
          <div className="containerSelect">
            <label className="containerInputCheckbox">
              <p>Tak samo jak 1. uczestnik</p>
              <input
                type="checkbox"
                name="sameAsTheFirst"
                checked={props.sameAsTheFirst === true}
                value={props.sameAsTheFirst}
                onChange={(event) => props.handleChange7(props.id, event)}
              ></input>
              <span className="checkmark"></span>
            </label>
          </div>

          {props.sameAsTheFirst === false && (
            <>
              <div>
                <div className="selectRadio">
                  <h3>
                    Ubezpieczenie turystyczne KL i NNW{" "}
                    <button className="secondaryBtn">
                      <a
                        href={OWU}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Przeczytaj ogólne warunki ubezpieczenia"
                      >
                        Przeczytaj OWU<i className="arrow"></i>
                      </a>
                    </button>
                  </h3>
                  <div className="container">
                    <div className="textBox">
                      {/* <p>Przypominamy, że osoby planujące uprawiać windsurfing, kitesurfing lub nurkować muszą
            rozszerzyć nasze ubezpieczenie lub wykupić sobie indywidualne ubezpieczenie sportowe.</p> */}
                    </div>
                    {/* <button className="secondaryBtn"><a href={OWU} target="_blank" rel="noopener noreferrer">Przeczytaj OWU<i className="arrow"></i></a></button> */}
                  </div>
                </div>

                <div id="insurance" className="selectBoxes">
                  <div className="boxes">
                    {StepThreeArr.InsurancesList.map(
                      (el) =>
                        el.Available === true && (
                          <label className="boxInputRadio">
                            <div className="descriptionBox">
                              <p className="titleText">{el.Name}</p>
                              <p className="description">{el.Description}</p>
                              {ReactHtmlParser(el.list)}
                            </div>
                            {el.IsRecommended === true ? (
                              <>
                                <div className="recomended">
                                  <p className="price">+{el.Price} PLN</p>
                                  <p className="smallText">za osobę</p>
                                  <p className="postscript">Rekomendujemy dla tego wyjazdu</p>
                                </div>
                                {el.Price === "0" ? (
                                  <>
                                    <p className="price include">W cenie wyjazdu</p> :
                                    <div className="normal">
                                      <p className="price">+{el.Price} PLN</p>
                                      <p className="smallText">osoba</p>
                                    </div>
                                  </>
                                ) : null}
                              </>
                            ) : (
                              <div className="priceBoxInsurance">
                                {el.Price === "0" ? (
                                  <p className="price include">W cenie wyjazdu</p>
                                ) : (
                                  <div className="normal">
                                    <p className="price">+{el.Price} PLN</p>
                                    <p className="smallText">osoba</p>
                                  </div>
                                )}
                              </div>
                            )}

                            <input
                              type="radio"
                              name={props.id}
                              checked={props.insuranceId === el.InsuranceId}
                              onChange={(event) => props.handleChange8(props.id, event)}
                              value={el.InsuranceId}
                            ></input>
                            <span className="checkmarkRadio"></span>
                            <div className="boxAfterClick"></div>
                          </label>
                        )
                    )}
                  </div>
                </div>
              </div>

              {isResignationInsurance && (
                <div className="selectRadio">
                  <h3>Ubezpieczenie od kosztów rezygnacji</h3>
                  <div className="container">
                    <div className="textBox">
                      {/* {props.accommodationOption === "single" || props.accommodationOption === "cabin" ?
                                            <>
                                                <p>Ubezpieczenie chroni wpłacone środki w sytuacji poważnych komplikacji uniemożliwiających wzięcie udziału w wyjeździe
                                            np. nagłej choroby. <br /> Przeczytaj o tym produkcie w naszym <Link to="/FAQ" style={{fontFamily: "SpaceGrotesk-Bold"}}>FAQ</Link>.</p>
                                            <div className="greenBox"><p>UWAGA: Ubezpieczenie jest dostępne tylko przy składaniu rezerwacji.
                                                
                                                 i wymaga podania imienia, nazwiska i daty urodzenia każdej osoby ubezpieczonej w kroku nr 5!</p></div>
                                            </> : <><p>Ubezpieczenie chroni wpłacone środki w sytuacji poważnych komplikacji uniemożliwiających wzięcie udziału w wyjeździe np. nagłej choroby. <br/> Przeczytaj o tym produkcie w naszym <Link to="/FAQ" style={{fontFamily: "SpaceGrotesk-Bold"}}>FAQ</Link>. </p>
                                            <div className="greenBox"><p>UWAGA: Ubezpieczenie jest dostępne tylko przy składaniu rezerwacji.
                                                 i wymaga podania imienia, nazwiska i daty urodzenia każdej osoby ubezpieczonej.</p></div>
                                                  </>} */}
                      <form>
                        <div className="containerInputRadio" style={{marginTop: "0"}}>
                          <label className="inputRadio">
                            <p>
                              Tak <span style={{textDecoration: "line-through"}}>(200 PLN)</span> +
                              {StepThreeArr.ResignationCost} PLN
                            </p>
                            <input
                              type="radio"
                              name="insurance"
                              value="1"
                              checked={props.resignationInsuranceAccepted === "1"}
                              onChange={(event) => props.handleChange9(props.id, event)}
                            ></input>
                            <span className="checkmark"></span>
                          </label>
                          <label className="inputRadio">
                            <p>Nie</p>
                            <input
                              type="radio"
                              name="insurance"
                              value="0"
                              checked={props.resignationInsuranceAccepted === "0"}
                              onChange={(event) => props.handleChange9(props.id, event)}
                            ></input>
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </form>
                    </div>
                    {/* <div className="redBox"> */}
                    {/* <p className="infoTitle">Ubezpieczenie dotyczy jednej osoby. W rezerwacjach wieloosobowych należy je wybrać dla każdej osoby, którą chcemy ubezpieczyć.</p> */}
                    {/* <p>Zniżki grupowe (dla 6 i więcej osób)Wspólne zakwaterowanie (dla osób, które wybiorą ten sam standard) Wspólne zakwaterowanie (dla osób, które wybiorą ten sam standard)</p> */}
                    {/* <a href={OWU} target="_blank" rel="noopener noreferrer">Koniecznie przeczytaj OWU</a> */}
                    {/* </div> */}
                  </div>
                </div>
              )}
              {isAssistance && (
              <div className="selectRadio">
                <h3>Ubezpieczenie assistance na wypadek kwarantanny</h3>
                <div className="container">
                  <div className="textBox">
                    <form>
                      <div className="containerInputRadio" style={{marginTop: "0"}}>
                        <label className="inputRadio">
                          <p>
                            Tak <span style={{textDecoration: "line-through"}}>(200 PLN)</span> +{assistanceCost} PLN
                          </p>
                          <input
                            type="radio"
                            name="assistance"
                            value="1"
                            checked={props.quarantineAssistanceAccepted === "1"}
                            onChange={(event) => props.handleAssistanceChange(props.id, event)}
                          ></input>
                          <span className="checkmark"></span>
                        </label>
                        <label className="inputRadio">
                          <p>Nie</p>
                          <input
                            type="radio"
                            name="assistance"
                            value="0"
                            checked={props.quarantineAssistanceAccepted === "0"}
                            onChange={(event) => props.handleAssistanceChange(props.id, event)}
                          ></input>
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
              <div></div>
            </>
          )}
        </div>
      )}
    </>
  );
};

const StepThree = (props) => {
  useEffect(() => {
    IAmForAnalitics();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="stepThree" style={{position: "relative"}}>
      {!props.buttonAvailableStepThree && <div id="stepMask"></div>}
      <div className="container">
        {props.insuranceConfig.map((el, index) => (
          <OnePerson
            key={index + 1}
            id={el.id}
            accommodationOption={props.accommodationOption}
            reservationId={props.reservationId}
            Reservation={props.Reservation}
            accommodationConfig={props.accommodationConfig}
            handleChange={props.handleChange}
            sameAsTheFirst={el.sameAsTheFirst}
            handleChange7={props.handleChange7}
            valueName={
              props.accommodationConfig.length === 0 || props.accommodationConfig === []
                ? ""
                : (props.accommodationConfig.length !== 0 || props.accommodationConfig !== []) &&
                  props.accommodationOption === "whole"
                ? props.accommodationConfigNew[props.accommodationConfigNew.findIndex((el) => el.id === index + 1)]
                    .valueName
                : (props.accommodationConfig.length !== 0 || props.accommodationConfig !== []) &&
                  (props.accommodationOption === "single" || props.accommodationOption === "cabin")
                ? props.accommodationConfig[props.accommodationConfig.findIndex((el) => el.id === index + 1)].valueName
                : null
            }
            valueSurname={
              props.accommodationConfig.length === 0 || props.accommodationConfig === []
                ? ""
                : (props.accommodationConfig.length !== 0 || props.accommodationConfig !== []) &&
                  props.accommodationOption === "whole"
                ? props.accommodationConfigNew[props.accommodationConfigNew.findIndex((el) => el.id === index + 1)]
                    .valueSurname
                : (props.accommodationConfig.length !== 0 || props.accommodationConfig !== []) &&
                  (props.accommodationOption === "single" || props.accommodationOption === "cabin")
                ? props.accommodationConfig[props.accommodationConfig.findIndex((el) => el.id === index + 1)]
                    .valueSurname
                : ""
            }
            handleChange8={props.handleChange8}
            handleChange9={props.handleChange9}
            handleAssistanceChange={props.handleAssistanceChange}
            insuranceConfig={props.insuranceConfig}
            userNum={el.userNum}
            quarantineAssistanceAccepted={el.quarantineAssistanceAccepted}
            resignationInsuranceAccepted={el.resignationInsuranceAccepted}
            insuranceId={el.insuranceId}
          />
        ))}
        <p className="errorMessage">{props.messageStepThree}</p>
        <div className="containerButtons">
          <button className="secondaryBtn" onClick={props.handlebackToStepTwo}>
            Wstecz
          </button>
          <button
            className="primaryBtn"
            disabled={props.buttonAvailableStepThree ? "" : "disabled"}
            id={props.buttonAvailableStepThree ? null : "disabledButton"}
            onClick={props.handleUpdateStepThree}
          >
            Dalej
          </button>
          {!props.buttonAvailableStepThree && (
            <div class="lds-roller">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
        </div>
      </div>
      <TripBox Reservation={props.Reservation} />
    </div>
  );
};

export default StepThree;
