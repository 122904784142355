import React from "react";
import "./Testimonial.scss";

const Testimonial = ({image, alt, text, name, job}) => {
  return (
    <div className="oneTestimonial">
      <img className="testimonialImage" src={image} alt={alt} />
      <p className="testimonialText">{text}</p>
      <p className="testimonialName">
        {name}
        <span>{job}</span>
      </p>
    </div>
  );
};

export default Testimonial;
