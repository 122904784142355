import React from "react";
import "./Accomodation.scss";

const InsuranceWrapper = ({
  reservationArr,
  nameParticipant,
  insuranceButton,
  insuranceDetails,
  userNum,
  userNumParticipant,
  handleAllowedInsurance,
  handleCancelInsurance,
  errorMessageParametersGetInsurance,
  parameters,
  handleChangeInsurance,
  handleChangeQuarantineAssistance,
  insuranceConfig,
  handleSubmitParametersInsurance,
  insuranceMessage,
  colorMessage,
  secondMessage,
  firstMessage,
}) => {
  const isAssistance = reservationArr?.Offer?.Prices?.EnableAssistance;
  return (
    <div id="insurance">
      {reservationArr.ReserveList && (
        <div className="orangeBox">
          <p>
            Twoja rezerwacja jest na liście rezerwowej. Rezerwację potwierdzimy telefonicznie lub mailowo - prosimy do
            tego momentu nie wpłacać zaliczki.
          </p>
        </div>
      )}
      {!reservationArr.IsHistorical && reservationArr.Owner && !reservationArr.ReserveList && (
        <div className="greenBox">
          <p className="infoText">
            <i class="fas fa-unlock"></i> Zmiana danych jest możliwa do 2 tygodni przed wyjazdem
          </p>
          <p>
            Do 2 tygodni przed wyjazdem macie możliwość samodzielnej edycji danych. Następnie ta opcja jest blokowana i
            wszelkie zmiany oraz prośby możecie zgłaszać na info@theboattrip.eu
          </p>
        </div>
      )}
      {reservationArr.StepDetails.Step3[0].map(({details}, index) => (
        <div className="onePerson">
          <div className="name">
            <p>{nameParticipant[index]}</p>
          </div>
          <h4>Wybrane ubezpieczenie</h4>
          <table className="detailsBox">
            {details?.map(({name, value}) =>
              isAssistance === false && value === "Nie" && name.includes("assistance") ? null : (
                <tr>
                  <td className="firstColumn">{name}</td>
                  <td className="secondColumn">{value}</td>
                </tr>
              )
            )}
          </table>

          {reservationArr.ReserveList ||
          !reservationArr.Owner ||
          reservationArr.IsHistorical ||
          reservationArr.Status === "Anulowana" ||
          (insuranceDetails && !insuranceButton && userNumParticipant[index] === userNum) ? null : (
            <div className="btnBox">
              <button
                className="primaryBtn"
                onClick={(event) => handleAllowedInsurance(userNumParticipant[index], event)}
              >
                Zmień opcje
              </button>
            </div>
          )}
          <p style={{color: "#9c0d0d"}}>{errorMessageParametersGetInsurance}</p>
          {insuranceDetails && userNumParticipant[index] === userNum ? (
            <>
              <h4 style={{marginTop: "40px"}}>Zmień ubezpieczenie</h4>
              <div
                className="containerSelect"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flexStart",
                  marginTop: "30px",
                  marginLeft: "30px",
                }}
              >
                <select
                  style={{maxWidth: "300px", margin: "0"}}
                  onChange={(event) => handleChangeInsurance(userNumParticipant[index], event)}
                >
                  <option className="placeholderList" selected="true" disabled="disabled">
                    Wybierz z listy
                  </option>
                  {parameters.InsurancesList[1].map(
                    ({Insurance}) =>
                      Insurance.Available && (
                        <>
                          <option
                            name={userNum}
                            value={Insurance.InsuranceId}
                            selected={Insurance.InsuranceId === insuranceConfig[index].insuranceId}
                          >
                            {Insurance.Name}
                          </option>
                        </>
                      )
                  )}
                </select>
                <div className="btnBox" style={{display: "flex"}}>
                  <button
                    className="primaryBtn"
                    style={{margin: "auto 20px"}}
                    onClick={(event) => handleSubmitParametersInsurance(insuranceConfig[index].userNum, event)}
                  >
                    Zapisz zmiany
                  </button>
                  <button className="primaryBtn" style={{margin: "auto 20px"}} onClick={handleCancelInsurance}>
                    Anuluj
                  </button>
                </div>
              </div>
              <div
                className="containerSelect"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flexStart",
                  marginTop: "30px",
                  marginLeft: "30px",
                }}
              >
                {parameters?.Assistance[userNum].Enabled && (
                  <>
                    <select
                      style={{maxWidth: "300px", margin: "0"}}
                      onChange={(event) => handleChangeQuarantineAssistance(userNumParticipant[index], event)}
                    >
                      <option className="placeholderList" selected="true" disabled="disabled">
                        Wybierz z listy
                      </option>
                      <>
                        <option name={userNum} value="1">
                          Ubezpieczenie Assistance - TAK
                        </option>
                        <option name={userNum} value="0">
                          Ubezpieczenie Assistance - NIE
                        </option>
                      </>
                      )
                    </select>

                    <div className="btnBox" style={{display: "flex"}}>
                      <button
                        className="primaryBtn"
                        style={{margin: "auto 20px"}}
                        onClick={(event) => handleSubmitParametersInsurance(insuranceConfig[index].userNum, event)}
                      >
                        Zapisz zmiany
                      </button>
                      <button className="primaryBtn" style={{margin: "auto 20px"}} onClick={handleCancelInsurance}>
                        Anuluj
                      </button>
                    </div>
                  </>
                )}
              </div>
            </>
          ) : null}

          {!reservationArr.IsHistorical &&
            reservationArr.Status !== "Anulowana" &&
            userNumParticipant[index] === userNum &&
            insuranceMessage && (
              <>
                <p style={{color: colorMessage, marginLeft: "30px"}}>{firstMessage}</p>
                <p style={{color: colorMessage, marginLeft: "30px"}}>{secondMessage}</p>
              </>
            )}
        </div>
      ))}
    </div>
  );
};

export default InsuranceWrapper;
