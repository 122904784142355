import React, {useContext} from "react";
import ReactHtmlParser from "react-html-parser";
import "./OfferAttractions.scss";
import {DataDostawca} from "./DataDostawca";
import OfferAttractionsItem from "./OfferAttractionsItem";

const Attractions = ({offerAttractions}) => {
  const {setElementToScroll} = useContext(DataDostawca);

  const listToShow = offerAttractions.List.filter(({ Available }) => Available === true) ?? []

  if (!listToShow.length) return <></>

  return (
    <div id="offerAttractionsSection" ref={setElementToScroll("attraction")}>
      <h2>{offerAttractions.Header}</h2>
      <p className="descriptionAttr">{ReactHtmlParser(offerAttractions.Description)}</p>
      <div>
        <div className="offerAttractionsListWrapper">
          {listToShow.map(({Name, Description, Image}, index) => (
            <OfferAttractionsItem
              index={index}
              offerAttractions={listToShow}
              description={ReactHtmlParser(Description)}
              image={Image}
              name={ReactHtmlParser(Name)}
            />
          ))}
        </div>
      </div>
      <p className="disclaimer">
        * Atrakcje fakultatywne (w tym imprezy) nie są elementem Umowy i nie stanowią przedmiotu Imprezy Turystycznej.
        Ewentualne zmiany lub ograniczenia w programie atrakcji dodatkowych (także z powodu restrykcji
        anty-koronawirusowych) nie są podstawą do kierowania jakichkolwiek roszczeń pod adresem organizatora.
      </p>
    </div>
  );
};

export default Attractions;
