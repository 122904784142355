const URLS = {
    HomePage: `/`,
    Offer: `/oferta/:offerId/:name`,
    Contact: `/kontakt`,
    UserPanel: `/twoj-panel`,
    Login: `/zaloguj`,
    FAQ: `/faq`,
    HowItWorks: `/jak-to-dziala`,
    Trips: `/rejsy-morskie`,
    Promotion: `/promocje`,
    AboutCompany: `/o-nas`,
    Special: `/majowka`,
    Company: `/o-nas`,
    Formal: `/formalnosci`,
    ForSkippers: `/zostan-skipperem`,
    Destination: `/rejsy-morskie/:countryName`,
    Program: `/jak-to-dziala/jak-wybrac-rejs`,
    Reservation: `/rezerwacja`,
    Trainings: `/szkolenia`,
    Mazury: `/mazury`,
    Documents: `/o-nas/dokumenty`,
    Covid: `/rejsy-2022-a-covid`,
    Cruises: `/rejsy-morskie`,
    CruisesSecondLink: `/rejsy`,
    PrivateCruises: `/rejsy-prywatne`,
    SzkoleniaInformacje: `/szkolenia-informacje`,
    Team: `/o-nas/team`,
    Work: `/o-nas/praca`,
    CompanyTrips: `/wyjazdy-firmowe`,
    Blog: `/blog`,
    BlogContent: `/blog/:id`,
};



export const linkToOfferId = (offerId, name) => URLS.Offer.replace(':offerId', offerId).replace(':name', name);

export default URLS;