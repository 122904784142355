import React, {useState, useContext} from "react";
import GoogleApiWrapper from "./OfferMap";
import {OfferSlider} from "./OfferSlider";
import "./OfferTripMap.scss";
import {DataDostawca} from "./DataDostawca.js";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {compose} from "redux";
import {getOfferCachedUrlName} from "../cachedStoreUrls";
import {getOfferPortsArray, getMiddlePoint} from "../store/selectors";
import ReactHtmlParser from "react-html-parser";
import "./PopUpImageYachts.scss";
// import OfferMovie from "./OfferMovie.js";

const enhance = connect((store, ownProps) => {
  const offerId = ownProps.match.params.offerId;
  const cachedUrlName = getOfferCachedUrlName(offerId);

  return {
    offerPortArr: getOfferPortsArray(store, cachedUrlName),
    middlePoint: getMiddlePoint(store, cachedUrlName),
  };
}, {});

const OfferTripMap = ({offerPortArr, middlePoint, offerRegion, offerDetails}) => {
  const {setElementToScroll} = useContext(DataDostawca);

  const [activeElementIndex, setActiveElementIndex] = useState(0);

  return (
    <div id="sectionOfferTripMap">
      <div id="offerTripMapHeader">
        <h2 ref={setElementToScroll("routes")}>
          {offerRegion.Country.Name} - {offerRegion.Name}
        </h2>
      </div>
      <div className="sectionOfferTripMapInfoWrapper">
        <div className="sectionOfferTripMapShortInfo">
          <p>{ReactHtmlParser(offerRegion.ShortInfo)}</p>
        </div>
      </div>
      {/* <OfferMovie offerDetails={offerDetails} /> */}
      <div className="OfferTripMapWrapper">
        <GoogleApiWrapper
          middlePoint={middlePoint}
          portArr={offerPortArr}
          setActiveElementIndex={setActiveElementIndex}
          activeElementIndex={activeElementIndex}
          offerDetails={offerDetails}
        />
        <OfferSlider
          portArr={offerPortArr}
          offerDetails={offerDetails}
          activeElementIndex={activeElementIndex}
          setActiveElementIndex={setActiveElementIndex}
        />
      </div>
      <p className="disclaimer">
        * Proponowana trasa nie jest elementem Umowy i nie stanowi przedmiotu Imprezy Turystycznej, a także może ulec
        zmianie w związku z niekorzystnymi warunkami atmosferycznymi lub sytuacją związaną z pandemią COVID-19.
        Ewentualne zmiany lub ograniczenia w zaproponowanej trasie rejsu (także z powodu restrykcji
        anty-koronawirusowych) nie są podstawą do kierowania jakichkolwiek roszczeń pod adresem organizatora.
      </p>
    </div>
  );
};

export default compose(withRouter, enhance)(OfferTripMap);
