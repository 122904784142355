import React from "react";
import "./ChangePassword.scss";
import "./LogOut.scss";
import "./DeleteAccount.scss";

const LogOut = ({logoutActive, showPopup, closePopup, handleLogoutClick}) => {
  return (
    logoutActive && (
      <div className="deleteAccount">
        {showPopup && (
          <div className="deleteAccountPopup" onClick={closePopup} >
            <div className="popupInner">
              <button className="closeButton" onClick={closePopup}>
                ✖
              </button>
              <h1>Wyloguj</h1>
              <p> Czy na pewno chcesz się wylogować?</p>
              <button
                className="primaryBtn"
                onClick={handleLogoutClick}
              >
                Potwierdzam
              </button>
              <div className="linkPassword" onClick={closePopup}>
                <i className="arrow left"></i>
                <span>Wróć</span>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default LogOut;
