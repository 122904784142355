import React from "react";
import "./mainPage/HowItWorksWrapper.scss";
import image1 from "./img/highlight2.jpg";
import image2 from "./img/yachts-croatia.jpg";
import image3 from "./img/highlight3.jpg";
import image4 from "./img/highlight1.jpg";

const HowItWorksArr = [
  {
    image: image1,
    desc: "Wybierz swoją wymarzoną destynację. Nie musisz się ograniczać do Europy, żeglujemy po całym świecie.",
    altImage: "Wymarzona destynacja na rejs morski",
  },

  {
    image: image2,
    desc: "Zastanów się czy wolisz katamaran, czy jacht i jakiej klasy jednostki szukasz: 4* czy może 5*?",
    altImage: "Wybór jachtu",
  },

  {
    image: image3,
    desc: "Odezwij się do nas, a my przygotujemy ofertę i odpowiemy na wszystkie Twoje pytania.",
    altImage: "Przygotowanie oferty rejsu",
  },

  {
    image: image4,
    desc: "Złóż rezerwację i ciesz się długo wyczekiwanym urlopem, który dla Ciebie przygotujemy.",
    altImage: "Rezerwacja rejsu",
  },
];

const BFHowItWorks = () => {
  return (
    <div id="howItWorksWrapper">
      <div className="howItWorksTitle">
        <h2>Jak to działa?</h2>
      </div>
      <div className="howItWorksWrapper">
        {HowItWorksArr.map(({desc, image, altImage}) => (
          <div className="howItWorksItem">
            <img src={image} alt={altImage} />
            <p>{desc}</p>
          </div>
        ))}
      </div>
      <a href="/jak-to-dziala" className="buttonMore" title="Jak to działa - rejsy rodzinne">
        <button class="button primaryBtn" title="Dowiedz się więcej">
          Dowiedz się więcej
        </button>
      </a>
    </div>
  );
};

export default BFHowItWorks;
