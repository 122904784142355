import React from "react";
import "./YachtSelectionInfo.scss";
import YachtTable from "./YachtTable.js";
import KatamaranTable from "./KatamaranTable.js";
import { Element } from "react-scroll";
import ReactHtmlParser from "react-html-parser";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import imageYacht from "./img/image-yacht.jpg";
import imageKatamaran from "./img/image-katamaran.jpg";

const typeOfBoat = [
  {
    name: "Jacht",
    image: imageYacht,
    altImage: "Jacht",
    paragraphs: [
      {
        paragraph:
          "Zdecydowanie bardziej przystępny cenowo, również postoje w marinach są tańsze.",
      },
      {
        paragraph:
          "Jachty są najczęściej nieco dłuższe od katamaranów, za to znacząco węższe. Miejsca jest tu dużo, ale na katamaranie jest go więcej.",
      },
      {
        paragraph:
          "Jacht zdecydowanie lepiej i szybciej płynie pod żaglami, szczególnie w kursach ostrych i pod falę.",
      },
      {
        paragraph:
          "Na jachcie kokpit (zewnętrzny salon) jest odsłonięty, ale można postawić nad nim płócienny daszek, chroniący przed słońcem i w minimalnym stopniu przed deszczem.",
      },
      {
        paragraph:
          "Jacht pod żaglami płynie zwykle na delikatnym przechyle, co sprawia, że płynie on stabilniej i lepiej wchodzi w fale.",
      },
    ],
  },

  {
    name: "Katamaran",
    image: imageKatamaran,
    altImage: "Katamaran",
    paragraphs: [
      {
        paragraph:
          "Katamaran jest najczęściej droższy od jachtu, więcej zapłacicie też za postoje w marinie (najczęściej około 150% ceny jachtu).",
      },
      {
        paragraph:
          "Na katamaranie jest dużo więcej miejsca - szczególnie na pokładzie. Każdy znajdzie tu bez problemu miejsce do opalania, można też wygodnie rozłożyć się na siatce na dziobie!",
      },
      {
        paragraph:
          "Katamaran pływa szybko kursami pełnymi, ale przy większej fali płynie wolniej pod wiatr, nie jest też w stanie pod żaglami popłynąć tak ostro jak jacht.",
      },
      {
        paragraph:
          "Na katamaranie zewnętrzny salon jest w całości pod sztywnym dachem, zapewniającym ochronę od deszczu i zawsze dobre oświetlenie nocą!",
      },
      {
        paragraph:
          "Katamaran się nie przechyla, ale przez to na fali nieco nim 'chybocze'. Czy to lepiej czy gorzej? Zdania są podzielone.",
      },
    ],
  },
];

class YachtSelectionInfo extends React.Component {
  state = {
    boat: "Jacht",
  };

  onValueChange = (event) => {
    this.setState({
      boat: event.target.value,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Element id="yachtSelectionInfo" name="yachtSelectionInfo">
          <div className="container">
            {this.props.Page.Contents !== undefined
              ? ReactHtmlParser(this.props.findHTMLPageHTML[1].Contents.HTML)
              : null}
            <div id="notResponsive">
              <div className="boxes">
                {typeOfBoat.map((el, index) => (
                  <div className="oneBox">
                    <label className="containerInputRadio">
                      <div className="nameBoatSection">
                        <p>{el.name}</p>

                        <div className="image">
                          {this.props.Page.Contents !== undefined && (
                            <img
                              src={
                                this.props.popElement.Contents.Images[index]
                                  ?.url
                              }
                              alt={
                                this.props.popElement.Contents.Images[index]
                                  ?.alt
                              }
                            />
                          )}
                        </div>
                      </div>
                      <div className="description">
                        {this.props.Page.Contents !== undefined &&
                          ReactHtmlParser(
                            this.props.popElement.Contents.Text.split("</div>")[
                              index
                            ].replace("<div>", "")
                          )}
                      </div>
                      <input
                        type="radio"
                        name="boat"
                        value={el.name}
                        onChange={this.onValueChange}
                        checked={this.state.boat === el.name}
                      ></input>
                      <span className="checkmarkRadio"></span>
                      <div className="oneBox2"></div>
                    </label>
                  </div>
                ))}
              </div>
              {this.state.boat === "Jacht" ? (
                <YachtTable />
              ) : (
                <KatamaranTable />
              )}
            </div>
          </div>

          <div className="boxes" id="responsive">
            {typeOfBoat.map((el) => (
              <Accordion allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="header">{el.name}</div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div
                      className="image"
                      style={{ height: "100px", margin: "30px 10px" }}
                    >
                      <img src={el.image} alt={el.altImage} />
                    </div>
                    {el.paragraphs.map((element) => (
                      <div className="description">
                        <ul style={{ paddingLeft: "15px" }}>
                          <li>{element.paragraph}</li>
                        </ul>
                      </div>
                    ))}

                    {this.state.boat === "Jacht" ? (
                      <YachtTable />
                    ) : (
                      <KatamaranTable />
                    )}
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            ))}
          </div>
        </Element>
      </React.Fragment>
    );
  }
}

export default YachtSelectionInfo;
