import React, {useState} from "react";
import GoogleApiWrapper from "./OfferTransportMap";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {compose} from "redux";
import {getOfferCachedUrlName} from "../cachedStoreUrls";
import {getOfferPortsArray, getMiddlePoint} from "../store/selectors";

const enhance = connect((store, ownProps) => {
  const offerId = ownProps.match.params.offerId;
  const cachedUrlName = getOfferCachedUrlName(offerId);

  return {
    offerPortArr: getOfferPortsArray(store, cachedUrlName),
    middlePoint: getMiddlePoint(store, cachedUrlName),
  };
}, {});

const OfferTransportMapWrapper = ({offerPortArr, middlePoint, offerDetails}) => {
  const [activeElementIndex, setActiveElementIndex] = useState(0);

  return (
    <div className="OfferTransportMapWrapper">
      <GoogleApiWrapper
        middlePoint={middlePoint}
        portArr={offerPortArr}
        setActiveElementIndex={setActiveElementIndex}
        activeElementIndex={activeElementIndex}
        offerDetails={offerDetails}
      />
    </div>
  );
};

export default compose(withRouter, enhance)(OfferTransportMapWrapper);
