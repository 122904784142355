import React from "react";
import "./AboutUs.scss";
import ReactHtmlParser from "react-html-parser";
import {Helmet} from "react-helmet";
import {fetchData} from "./store/actions";
import {aboutUsCachedUrl} from "./cachedStoreUrls";
import {connect} from "react-redux";
import Loader from "./Loader.js";

export const prefetchMethod = (dispatch, match) => {
  return dispatch(
    fetchData(
      "/v1/data/page",
      {
        siteId: 1,
        pageId: "aboutUs",
      },
      aboutUsCachedUrl
    )
  );
};

const enhance = connect((state) => {
  return {
    Page: state.fetchedUrls[aboutUsCachedUrl]?.Page || {},
  };
});

class AboutUs extends React.Component {
  state = {
    loading: true,
  };

  componentDidMount = () => {
    prefetchMethod(this.props.dispatch);
    this.setState({
      loading: false,
    });
  };

  render() {
    const {Page} = this.props;
    const {loading} = this.state;
    const arrWithTextImages = Page?.Contents?.filter(({Type}) => Type === "TextWithImages");
    const sliderObject = Page?.Contents ? arrWithTextImages.shift() : null;
    const metaData = Page?.META;

    return loading ? (
      <Loader />
    ) : (
      <>
        <Helmet>
          <title>{metaData?.Title}</title>
          <meta name="description" content={metaData?.Description} data-react-helmet="true" />
        </Helmet>
        <div id="aboutUs">
          {Page?.Contents && (
            <div className="slider">
              <img src={sliderObject.Contents.Images[0]?.url} alt={sliderObject.Contents.Images[0]?.alt} />
              {ReactHtmlParser(sliderObject.Contents.Text)}
            </div>
          )}
          <div className="info">
            <div className="container">
              {Page?.Contents &&
                arrWithTextImages?.map(({Contents}, index) => (
                  <div
                    className={
                      index === 1 || index === 3 ? "secondSection" : index === 0 || index === 2 ? "firstSection" : null
                    }
                    key={index}
                  >
                    {(index === 0 || index === 2) && (
                      <div className="imageSection">
                        <img src={Contents.Images[0]?.url} alt={Contents.Images[0]?.alt} />
                      </div>
                    )}
                    <div className="textSection">
                      {ReactHtmlParser(Contents.Text)}
                      {index === 0 && (
                        <>
                          <p>
                            <a
                              target="_blank"
                              title="Licencja Organizatora Turystyki"
                              rel="noreferrer"
                              href={Contents.Buttons[0]}
                            >
                              Licencja Organizatora Turystyki
                            </a>
                            <i class="arrow"></i>
                          </p>
                          <p>
                            <a
                              target="_blank"
                              title="Certyfikat posiadania gwarancji ubezpieczeniowej"
                              rel="noreferrer"
                              href={Contents.Buttons[1][0]}
                            >
                              Certyfikat posiadania gwarancji ubezpieczeniowej
                            </a>
                            <i class="arrow"></i>
                          </p>
                        </>
                      )}
                    </div>
                    {(index === 1 || index === 3) && (
                      <div className="imageSection">
                        <img src={Contents.Images[0]?.url} alt={Contents.Images[0]?.alt} />
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

AboutUs.prefetchMethod = prefetchMethod;

export default enhance(AboutUs);
