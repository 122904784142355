import React from 'react';
import "./GeneralInfo.scss";
import { Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';

class GeneralInfo extends React.Component {

    render() {
    
        return (
            <div id="generalInfo">
                <div className="container">
                {this.props.Page.Contents !== undefined && this.props.arrWithTextImages.map((el, index) =>
                            <div className={(index === 0 || index === 2 || index === 4) ? "firstSection" : (index === 1 || index === 3 || index === 5) ? "secondSection" : null} key={index}>
                                {(index === 1 || index === 3 || index === 5) &&
                                    <div className="imageSection">
                                        <img className="firstPhoto" src={el.Contents.Images[0]?.url} alt={el.Contents.Images[0]?.alt} />
                                        <img className="secondPhoto" src={el.Contents.Images[1]?.url} alt={el.Contents.Images[1]?.alt} />
                                    </div>}
                                <div className="textSection">
                                    {ReactHtmlParser(el.Contents.Text)}
                                    {el.Contents.Buttons.length > 0 && <Link to={el.Contents.Buttons[0][0]} title="Zobacz rejsy morskie" ><button id="button" className="primaryBtn">Zobacz rejsy morskie</button></Link>}
                                </div>
                                {(index === 0 || index === 2 || index === 4) &&
                                    <div className="imageSection">
                                        <img className="firstPhoto" src={el.Contents.Images[0]?.url} alt={el.Contents.Images[0]?.alt} />
                                        
                                    </div>}
                            </div>
                        )}
                </div>
            </div>
        );
    }
}

export default GeneralInfo;