import React from 'react';
import Slider from 'react-slick';
import OfferLiveOnYacht from './OfferLiveOnYacht';
import './OfferLiveOnYachtWrapper.scss';
import image1 from './images/offerLiveOnYacht-1.jpg';
import image2 from './images/offerLiveOnYacht-2.jpg';
import image3 from './images/offerLiveOnYacht-3.jpg';
import image4 from './images/offerLiveOnYacht-4.jpg';
import image5 from './images/offerLiveOnYacht-5.jpg';

const offerLiveOnYachArr = [
  {
    image: image1,
    desc: 'Jachty to komfortowe, pływające apartamenty',
    altImage: 'Komfortowe jachty',
  },
  {
    image: image2,
    desc: 'Nowe ekskluzywne jachty',
    altImage: 'Nowe, ekskluzywne jachty',
  },
  {
    image: image3,
    desc: 'Codziennie inny widok z okna',
    altImage: 'Widoki z okien na rejsie',
  },
  {
    image: image4,
    desc: 'Wieczory spędzone pod tysiącem gwiazd',
    altImage: 'Wieczory pod gwiazdami',
  },
  {
    image: image5,
    desc: 'Prywatny basen otwarty 24h',
    altImage: 'Prywatny basen',
  },
];

const OfferLiveOnYachWrapper = () => {
  var settings = {
    infinite: true,
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div id="offerLiveOnYachWrapper">
      <div className="offerLiveOnYachTitle">
        <h2>Mieszkaj na ekskluzywnym jachcie</h2>
      </div>
      <Slider {...settings}>
        {offerLiveOnYachArr.map(({ image, desc, altImage }) => (
          <OfferLiveOnYacht
            key={image}
            image={image}
            desc={desc}
            altImage={altImage}
          />
        ))}
      </Slider>
    </div>
  );
};

export default OfferLiveOnYachWrapper;
