import React, {useContext, useState} from "react";
import OfferResidences from "./OfferResidences.js";
import {DataDostawca} from "./DataDostawca";
import "./OfferAccomodation.scss";
import ReactHtmlParser from "react-html-parser";

function Accomodation({accommodationArr, offerRooms, offerType, offerDetails, offerPrices, offerRegion}) {
  const [activeElementIndex, setActiveElementIndex] = useState(0);
  const {setElementToScroll} = useContext(DataDostawca);

  return (
    <div className="offerAccommodationSection" ref={setElementToScroll("accommodation")}>
      {!offerDetails.Name.includes("patent") && (
        <div className="offerAccommodationTitleWrapper">
          <h2>{offerRooms.Header}</h2>
          <p>{ReactHtmlParser(offerRooms.Description)}</p>
        </div>
      )}
      <div id="offerAccommodationResidencesWrapper">
        <OfferResidences
          activeElementIndex={activeElementIndex}
          setActiveElementIndex={setActiveElementIndex}
          accommodationArr={accommodationArr}
          offerType={offerType}
          offerDetails={offerDetails}
          offerPrices={offerPrices}
          offerRegion={offerRegion}
        />
      </div>
    </div>
  );
}

export default Accomodation;
