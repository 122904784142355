import React from "react";
import "./CruisesInPoland.scss";
import "./userRegistration/Trips.scss";
import ReactHtmlParser from "react-html-parser";
import "./FilteredTrips.scss";
import Moment from "moment";
import {Link} from "react-router-dom";
import {fetchData} from "./store/actions";
import {cruisesInPolandUrl, offerListFilterByCountry6} from "./cachedStoreUrls";
import {connect} from "react-redux";
import calendarIcon from "./img/calendar2.png";
import {Helmet} from "react-helmet";

const FilteredTrips = (props) => {
  let actualDate = new Date().toLocaleDateString();
  let actualDate2 = new Date();
  const cruisesInProgress = props.List.some(
    (el) =>
      el.Availability.Available === false &&
      actualDate2.getTime() < new Date(el.Availability.To).getTime() &&
      Moment(actualDate).format("MM.DD.YY") !== Moment(el.Availability.From).format("DD.MM.YY")
  );

  props.List.sort((a, b) => a.Availability.From.split("-").join().localeCompare(b.Availability.From.split("-").join()));

  const availableCruises = props.List.filter((el) => el.Availability.Available);

  return (
    availableCruises?.length !== 0 && (
      <div id="filteredTrips" style={{margin: "0", paddingTop: "40px"}}>
        <h1 style={{marginBottom: "42px", textAlign: "center"}}>Rejsy po Mazurach</h1>
        <div className="container">
          {availableCruises?.map(
            (el) => {
              const regionImg = el.Region.Country.Image;
              const typeImg = el.Type.Image;
              return (
                el.Availability.Available && (
                  <div>
                    <Link to={`/oferta/${el.FriendlyUrl}`}>
                      <div id="oneTripFiltered">
                        <div className="photoContainer">
                          <Link to={`/oferta/${el.FriendlyUrl}`}>
                            <img src={el.ListImg?.url} alt={el.ListImg?.alt} />
                            <div className="overlay"></div>
                          </Link>
                          <div className="tripInfo">
                            <h3>
                              <Link to={`/oferta/${el.FriendlyUrl}`}>{el.Name}</Link>
                            </h3>
                            <div className="infoTripBox">
                              <p>
                                <img src={regionImg?.url} alt={regionImg?.alt} />
                                {el.Region.Country.Name}, {el.Region.Name}
                              </p>

                              <p>
                                <img src={calendarIcon} alt="termin" />
                                {Moment(el.Availability.From).format("DD.MM")} -{" "}
                                {Moment(el.Availability.To).format("DD.MM.YYYY")}
                                {el.RibbonTextExt === "Wyjazd potwierdzony" ? (
                                  <span className="confirmedTrip">{el.RibbonTextExt}</span>
                                ) : (
                                  <span>{el.RibbonTextExt}</span>
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="tagsBox">
                            <div className="oneTag">
                              <img src={typeImg?.url} alt={typeImg?.alt} /> {el.Type.Name}
                            </div>
                          </div>

                          <div className="priceSection">
                            <div className="priceBox">
                              {el.Prices.Strikethrough === null ? (
                                <div className="regularPrice">
                                  <p className="price">{el.Prices.Base} PLN</p>
                                  <p className="text">Cena od osoby</p>
                                </div>
                              ) : (
                                <>
                                  <div className="discount">
                                    <p className="newPrice">{el.Prices.Base} PLN</p>
                                    <p className="text">Promocyjna cena od osoby</p>
                                  </div>
                                  <p className="price">{el.Prices.Strikethrough} PLN</p>
                                  <p className="text">Cena od osoby</p>
                                </>
                              )}
                            </div>
                            <div class="detailsBox">
                              {el.Availability.Available === false &&
                              Moment(actualDate).format("MM.DD.YY") ===
                                Moment(el.Availability.From).format("DD.MM.YY") ? (
                                <p className="availability red" style={{backgroundColor: "green"}}>
                                  Startuje dzisiaj
                                </p>
                              ) : null}
                              <p className="amountOfPeople">Liczba jachtów: {el.Rooms.AllocationsListCount}</p>
                              {el.RibbonText?.includes("Lista") ||
                              el.RibbonText?.includes("Ostatnie") ||
                              el.RibbonText?.includes("Start") ||
                              el.RibbonText?.includes("start") ||
                              el.RibbonText?.includes("Dzisiaj") ? (
                                <p className="availability red">{el.RibbonText}</p>
                              ) : (
                                <p className="availability">{el.RibbonText}</p>
                              )}
                              <button className="primaryBtn">
                                <Link to={`/oferta/${el.FriendlyUrl}`}>Zobacz szczegóły</Link>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                )
              );
            }

            // :
            // null
          )}
        </div>

        {cruisesInProgress && (
          <>
            <h2>W trakcie</h2>
            <div className="container">
              {props.List.map((el) => {
                const regionImg = el.Region.Country.Image;
                const typeImg = el.Type.Image;
                return el.Availability.Available === false &&
                  actualDate2.getTime() < new Date(el.Availability.To).getTime() &&
                  Moment(actualDate).format("MM.DD.YY") !== Moment(el.Availability.From).format("DD.MM.YY") ? (
                  <div>
                    <Link to={`/oferta/${el.FriendlyUrl}`}>
                      <div id="oneTripFiltered">
                        <div className="photoContainer">
                          <Link to={`/oferta/${el.FriendlyUrl}`}>
                            <img src={el.ListImg?.url} alt={el.ListImg?.alt} />
                            <div className="overlay"></div>
                          </Link>
                          <div className="tripInfo">
                            <h3>
                              {" "}
                              <Link to={`/oferta/${el.FriendlyUrl}`}>{el.Name}</Link>
                            </h3>
                            <div className="infoTripBox">
                              <p>
                                <img src={regionImg?.url} alt={regionImg?.alt} />
                                {el.Region.Country.Name}, {el.Region.Name}
                              </p>

                              <p>
                                <img src={calendarIcon} alt="Termin rejsu" />
                                {Moment(el.Availability.From).format("DD.MM")} -{" "}
                                {Moment(el.Availability.To).format("DD.MM.YYYY")}
                                {el.RibbonTextExt === "Wyjazd potwierdzony" ? (
                                  <span className="confirmedTrip">{el.RibbonTextExt}</span>
                                ) : (
                                  <span>{el.RibbonTextExt}</span>
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="tagsBox">
                            <div className="oneTag">
                              <img src={typeImg?.url} alt={typeImg?.alt} /> {el.Type.Name}
                            </div>
                          </div>

                          <div className="priceSection">
                            <div className="priceBox">
                              {el.Prices.Strikethrough === null ? (
                                <div className="regularPrice">
                                  <p className="price">{el.Prices.Base} PLN</p>
                                  <p className="text">Cena od osoby</p>
                                </div>
                              ) : (
                                <>
                                  <div className="discount">
                                    <p className="newPrice">{el.Prices.Base} PLN</p>
                                    <p className="text">Promocyjna cena od osoby</p>
                                  </div>
                                  <p className="price">{el.Prices.Strikethrough} PLN</p>
                                  <p className="text">Cena od osoby</p>
                                </>
                              )}
                            </div>
                            <div class="detailsBox">
                              <p className="availability red" style={{backgroundColor: "green"}}>
                                W trakcie
                              </p>
                              <p className="amountOfPeople">Liczba jachtów: {el.Rooms.AllocationsListCount}</p>
                              {el.RibbonText?.includes("Lista") ||
                              el.RibbonText?.includes("Ostatnie") ||
                              el.RibbonText?.includes("Start") ||
                              el.RibbonText?.includes("start") ||
                              el.RibbonText?.includes("Dzisiaj") ? (
                                <p className="availability red">{el.RibbonText}</p>
                              ) : (
                                <p className="availability">{el.RibbonText}</p>
                              )}
                              <button className="primaryBtn">
                                {" "}
                                <Link to={`/oferta/${el.FriendlyUrl}`}>Zobacz szczegóły</Link>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ) : null;
              })}
            </div>
          </>
        )}
      </div>
    )
  );
};

export const prefetchMethod = (dispatch) => {
  return Promise.all([
    dispatch(
      fetchData(
        "/v1/data/page",
        {
          siteId: 1,
          pageId: "cruisesInPoland",
        },
        cruisesInPolandUrl
      )
    ),
    dispatch(
      fetchData(
        "/v1/offers/list",
        {
          siteId: 1,
          filterCountry: [6],
          filterType: [],
          filterTerm: [],
          filterOptions: [],
        },
        offerListFilterByCountry6
      )
    ),
  ]);
};

const enhance = connect((store) => {
  return {
    dataFetched: {
      List: store.fetchedUrls[offerListFilterByCountry6]?.List || {},
      Page: store.fetchedUrls[cruisesInPolandUrl]?.Page,
    },
    dataLoaded: store.urlStatus[cruisesInPolandUrl] === "done" && store.urlStatus[offerListFilterByCountry6] === "done",
  };
});

class CruisesInPoland extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount = () => {
    this.setState({
      isLoading: false,
    });
    prefetchMethod(this.props.dispatch);

    // this.state.isLoading ?
  };

  render() {
    if (!this.props.dataLoaded) {
      return null;
    }

    const {Page, List} = this.props.dataFetched;

    const arrWithTextImages =
      Page.Contents !== undefined ? Page.Contents.filter((el) => el.Type === "TextWithImages") : null;
    const arrMiniBoxes = Page.Contents !== undefined ? Page.Contents.filter((el) => el.Type === "MiniBoxes") : null;
    const sliderObject = Page.Contents !== undefined ? arrWithTextImages.shift() : null;
    const metaData = Page?.META;

    return (
      <div id="cruisesInPoland">
        <Helmet>
          <title>{metaData?.Title}</title>
          <meta name="description" content={metaData?.Description} data-react-helmet="true" />
        </Helmet>
        {Page.Contents !== undefined && (
          <div className="slider">
            <img src={sliderObject.Contents.Images[0]?.url} alt={sliderObject.Contents.Images[0]?.alt} />
            {ReactHtmlParser(sliderObject.Contents.Text)}
          </div>
        )}
        <div className="info">
          <div className="container">
            {Page.Contents !== undefined &&
              arrWithTextImages.map((el, index) => (
                <div className={index === 0 ? "firstSection" : "secondSection"}>
                  {index === 0 && (
                    <div className="imageSection">
                      <img src={el.Contents.Images[0]?.url} alt={el.Contents.Images[0]?.alt} />
                    </div>
                  )}
                  <div className="textSection">
                    <p>{ReactHtmlParser(el.Contents.Text)}</p>
                  </div>
                  {index === 1 && (
                    <div className="imageSection">
                      <img src={el.Contents.Images[0]?.url} alt={el.Contents.Images[0]?.alt} />
                    </div>
                  )}
                </div>
              ))}
          </div>

          <div className="tags">
            {Page.Contents !== undefined &&
              arrMiniBoxes[0].Contents.Boxes.map((el, index) => (
                <div className="oneTag">
                  <img
                    src={Page.Contents !== undefined && arrMiniBoxes[0].Contents.Images[index]?.url}
                    alt={Page.Contents !== undefined && arrMiniBoxes[0].Contents.Images[index]?.alt}
                  ></img>
                  <p>{el[1]}</p>
                </div>
              ))}
          </div>
        </div>

        <FilteredTrips List={List} />
      </div>
    );
  }
}
CruisesInPoland.prefetchMethod = prefetchMethod;
export default enhance(CruisesInPoland);
