import React, {useState} from "react";
import {Map, GoogleApiWrapper, Marker} from "google-maps-react";
import Anchor from "./images/anchor.png";

export const OfferTransportMap = ({google, offerDetails, portArr}) => {
  const [myPorts] = useState(portArr);

  return (
    <>
      <div className="OfferTransportMap">
        <Map
          google={google}
          style={{height: "100%", width: "100%"}}
          zoom={offerDetails.OfferId === "31" ? 6 : 9}
          initialCenter={myPorts[0].position}
          zoomControl={false}
          mapTypeControl={false}
          scaleControl={false}
          streetViewControl={false}
          rotateControl={false}
          fullscreenControl={false}
        >
          <Marker icon={Anchor} key={myPorts[0].RegionOptionId} id={myPorts[0].index} position={myPorts[0].position} />
        </Map>
      </div>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyCZ65F4V7IWTO4W7YV2LRmT-fsW2YGBe4s",
})(OfferTransportMap);
