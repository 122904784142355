import React from 'react';
import './MenuPage.scss';
import { Navbar, NavDropdown, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import URLS from './urls';
import logo from './img/logo-TBT.png';
import APIbackend from './config.js';
import { fetchData } from './store/actions';
import { connect } from 'react-redux';

export const prefetchMethod = (dispatch, match) => {
  return dispatch(
    fetchData('/v1/data/page', {
      siteId: 1,
      pageId: 'destinationList',
    })
  );
};
const enhance = connect((state) => {
  return {
    Page: state.fetchedUrls?.['/v1/data/page']?.Page || {},
  };
});

class MenuPage extends React.Component {
  state = {
    bgMenu: false,
  };

  handleLogoutClick = () => {
    fetch(`${APIbackend}v1/auth/logout`, {
      method: 'GET',
      credentials: 'include',
    }).then((response) => {
      response.json().then(() => {
        if (response.status === 200) {
          window.location.href = '/';
        }
      });
    });
  };

  componentDidMount() {
    prefetchMethod(this.props.dispatch);
    this.setState({
      bgMenu: false,
    });
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.pageYOffset > 100) {
      this.setState({
        bgMenu: true,
      });
    } else if (window.pageYOffset < 100) {
      this.setState({
        bgMenu: false,
      });
    }
  };

  render() {
    const destinationListArr =
      this.props.Page?.Contents?.[0]?.Contents?.HTML.split('\r\n');
    return (
      <>
        <div
          id="menuPage"
          className={this.state.bgMenu ? 'menuPageSticky' : null}
        >
          <div className="menu">
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
              <Navbar.Brand href="/">
                <img
                  className="imageLogo"
                  src={logo}
                  alt="logo The Boat Trip"
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                  <LinkContainer to="/rejsy-morskie" title="Rejsy morskie">
                    <Nav.Link>Rejsy</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/szkolenia" title="Szkolenia żeglarskie">
                    <Nav.Link>Szkolenia</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/promocje" title="Promocje na rejsy">
                    <Nav.Link>Promocje</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/jak-to-dziala" title="Jak to działa?">
                    <Nav.Link>Jak to działa</Nav.Link>
                  </LinkContainer>
                  <NavDropdown title="Destynacje" id="collasible-nav-dropdown">
                    {destinationListArr?.map((countryName, index) => (
                      <a
                        href={`/rejsy-morskie/${countryName
                          .toLowerCase()
                          .replace('ł', 'l')
                          .replace('ó', 'o')
                          .replace(' ', '-')}`}
                        title={`Rejsy morskie - ${countryName}`}
                        className="dropdown-item"
                        key={index}
                      >
                        {countryName}
                      </a>
                    ))}
                  </NavDropdown>
                  <NavDropdown
                    title="Kim jesteśmy?"
                    id="collasible-nav-dropdown"
                  >
                    <LinkContainer to="/o-nas" title="O firmie">
                      <NavDropdown.Item>O nas</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer
                      to="/o-nas/team"
                      title="Zespół The Boat Trip"
                    >
                      <NavDropdown.Item>Nasz zespół</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer
                      to="/o-nas/praca"
                      title="Praca w The Boat Trip"
                    >
                      <NavDropdown.Item>Praca</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer
                      to="/o-nas/dokumenty"
                      title="Dokumenty do pobrania"
                    >
                      <NavDropdown.Item>Dokumenty</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>

                  <NavDropdown title="FAQ" id="collasible-nav-dropdown">
                    <LinkContainer to="/faq" title="FAQ">
                      <NavDropdown.Item>FAQ</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer
                      to="/rejsy-2022-a-covid"
                      title="Rejsy 2022 a COVID"
                    >
                      <NavDropdown.Item>COVID</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>

                  <LinkContainer to={URLS.Contact} title="Kontakt">
                    <Nav.Link>Kontakt</Nav.Link>
                  </LinkContainer>

                  <LinkContainer to={URLS.Blog} title="Blog">
                    <Nav.Link>Blog</Nav.Link>
                  </LinkContainer>

                  {/* <LinkContainer to={this.props.elementMenu} id="linkLog">
                                        <Nav.Link>{this.props.elementMenuName}</Nav.Link>
                                    </LinkContainer> */}

                  {this.props.elementMenuName !== 'zaloguj' ? (
                    <>
                      <NavDropdown
                        title={this.props.elementMenuName}
                        id="collasible-nav-dropdown"
                      >
                        <LinkContainer to={this.props.elementMenu} id="linkLog">
                          <NavDropdown.Item>Twój Panel</NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to="/wyloguj">
                          <NavDropdown.Item onClick={this.handleLogoutClick}>
                            Wyloguj
                          </NavDropdown.Item>
                        </LinkContainer>
                      </NavDropdown>{' '}
                      <div
                        className={this.props.hasNewMessages ? 'led-red' : ''}
                      ></div>{' '}
                    </>
                  ) : (
                    <LinkContainer
                      to={this.props.elementMenu}
                      id="linkLog"
                      title=""
                    >
                      <Nav.Link>{this.props.elementMenuName}</Nav.Link>
                    </LinkContainer>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
      </>
    );
  }
}

MenuPage.prefetchMethod = prefetchMethod;

export default enhance(MenuPage);
