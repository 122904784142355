import React from "react";
import "./OfferShortInfo.scss";
import OfferTagBox from "./OfferTagBox.js";

function ShortInfo({shortInfo, offerType, offerCountry, offerDetails, offerRooms, offerBoxes}) {
  return (
    shortInfo.Long !== null && (
      <div id="sectionShortInfoWrapper">
        <div id="ShortInfoContainer">
          <div id="ShortInfoWrapper">
            <h2>{offerDetails.Name}</h2>
            <OfferTagBox
              offerType={offerType}
              offerDetails={offerDetails}
              offerCountry={offerCountry}
              offerRooms={offerRooms}
              shortInfo={shortInfo}
              offerBoxes={offerBoxes}
            />
          </div>
          <div id="shortInfoImagesWrapper">
            <div className="shortInfoImagesLeft">
              <img src={shortInfo.Images[0]?.url} alt={shortInfo.Images[0]?.alt} />
            </div>
            <div className="shortInfoImagesRight">
              <img src={shortInfo.Images[1]?.url} alt={shortInfo.Images[1]?.alt} />
              <img src={shortInfo.Images[2]?.url} alt={shortInfo.Images[2]?.alt} />
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default ShortInfo;
