import React, {useEffect} from "react";
import "./Ambassador.scss";

const AmbassadorCreate = ({handleChange, name, messageError, messageTrue, handleSubmit, handleBack}) => {
    
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="createCode">
      <h4>Generuj nowy kod</h4>
      <form className="form" noValidate>
        <p>Nazwa kodu</p>
        <input name="name" onChange={handleChange} value={name} placeholder="Wpisz kod" />
      </form>
      <p className="errorMessage" style={{fontSize: "14px"}}>
        {messageError}
      </p>
      <p className="trueMessage" style={{margin: "0"}}>
        {messageTrue}
      </p>
      <div className="containerButton">
        <button className="primaryBtn" onClick={handleSubmit}>
          Wygeneruj nowy kod
        </button>
        <button className="secondaryBtn" onClick={handleBack} style={{marginTop: "20px"}}>
          Wróć do widoku swoich kodów
        </button>
      </div>
    </div>
  );
};

export default AmbassadorCreate;
