import React, { useEffect } from 'react';
import "./StepSix.scss";
import "./StepSubmit.scss";
import { Link } from 'react-router-dom';
import { IAmForAnalitics } from "./utilsApp";
import TagManager from 'react-gtm-module';



const StepSubmit = (props) => {

    useEffect(() => {
        IAmForAnalitics();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const additionalProductsList = props.Reservation?.Offer?.OptionsList?.length > 1 ? props.Reservation.Offer?.OptionsList?.slice(1).map(({ItemCount, Name, PricePerSingleItem }) => ({
        'name': Name,
        'price': PricePerSingleItem,
        'brand': 'The Boat Trip',
        'category': 'Dodatkowe opcje',
        'quantity': ItemCount
    })) : []


    useEffect(() => {
        TagManager.dataLayer({
        dataLayer: {
        'event': 'transaction',
        'ecommerce': {
            'purchase': {
                'actionField': {
                    'id': props?.Reservation?.Offer?.OfferId,           
                    'revenue': props?.Reservation?.Offer?.CurrentPrice,
                    'tax': props?.Reservation?.Offer?.CurrentPrice * 0.23,
                    'coupon': props?.Reservation?.Data[5]?.AmbassadorRebateCodeDetails?.Name
                },
	            'products': [{
                    'name': props?.Reservation?.Offer?.Name,
                    'id': props?.Reservation?.Offer?.OfferId,
                    'price': props?.Reservation?.Offer?.OptionsList[0]?.PricePerSingleItem,
                    'brand': 'The Boat Trip',
                    'category': 'Wyjazdy',
                    'variant': props?.Reservation?.Offer.TermsList[0].Name,
                    'quantity': props?.Reservation?.Offer?.OptionsList[0]?.ItemCount,
                    'coupon': props?.Reservation?.Data[5]?.AmbassadorRebateCodeDetails?.Name
                }, ...additionalProductsList]
            }
        }
        }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])


    return (
        <>
            <div id="stepSubmit">
                <div className="container">
                    <div className="image">
                        <img src={props.Reservation.Offer.ListImg?.url} alt={props.Reservation.Offer.ListImg?.alt}></img>
                    </div>
                    <h4>{props.Reservation.Offer.Name}</h4>
                    <p style={{ width: "400px", margin: "auto", textAlign: "center", maxWidth: "90vw" }}></p>
                    {props.Reservation.Data[1].Config.IsReserveList === 0 ?
                        <div className="greenBox">
                            <p className="infoTitle" style={{ fontWeight: "300" }}>Dziękujemy za złożenie rezerwacji. Na wskazany adres e-mail zostanie wysłane potwierdzenie dokonanej rezerwacji. <span style={{ fontFamily: "SpaceGrotesk-SemiBold", color: "#1eb74c" }} >Mail powinien dotrzeć w ciągu 15 minut.</span></p>
                        </div>
                        :
                        <div className="greenBox" style={{ background: "rgba(255, 193, 7, 0.28)" }}>
                            <p className="infoTitle" style={{ fontWeight: "300", color: "orange", textAlign: "left" }}>Dziękujemy, za dokonanie zapisu. <span style={{ fontFamily: "SpaceGrotesk-SemiBold", color: "orange" }} >Twoja rezerwacja trafiła na listę rezerwową.</span> Jeżeli pojawią się wolne miejsca - skontaktujemy się z Tobą. Prosimy do tego momentu nie wpłacać zaliczki</p>
                        </div>
                    }
                    <Link to="/twoj-panel" title="Przejdź do Panelu Klienta" ><button className="secondaryBtn" style={{ cursor: "pointer", margin: "auto", marginTop: "20px", display: "flex" }}>Przejdź do panelu klienta</button></Link>
                    {/* {(!props.Reservation?.Offer?.Name.includes("Kurs") && props.Reservation.Offer?.HTML?.HTML3 && props.Reservation.Offer?.HTML?.HTML3.includes("https")) &&
                        <div className="containerButton" style={{ paddingBottom: "40px", cursor: "pointer" }}>
                            <button onClick={() => window.open(props.Reservation.Offer?.Name?.HTML?.HTML3, '_blank')} className='primaryBtn' style={{ background: "white", color: "black", cursor: "pointer", display: "flex", alignItems: "center" }}>
                                <img className="facebookIcon" src={require("./offersite/images/facebook-icon.png").default} alt={{}}></img><span style={{ marginLeft: "10px", fontFamily: "SpaceGrotesk-SemiBold", cursor: "pointer" }}>Dołącz do wydarzenia</span></button>
                        </div>
                    } */}
                </div>
            </div>

        </>
    );
}

export default StepSubmit;