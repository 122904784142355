import React, {Component} from "react";
import "./FAQ.scss";
import {Tab, Tabs, TabPanel, TabList} from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";
import Contact from "./Contact.js";
import ReactHtmlParser from "react-html-parser";
import {faqCachedUrl} from "./cachedStoreUrls";
import {fetchData} from "./store/actions";
import {connect} from "react-redux";
import {Helmet} from "react-helmet";
import Loader from "./Loader.js";

export const prefetchMethod = (dispatch, match) => {
  return dispatch(
    fetchData(
      "/v1/data/faq",
      {
        siteId: 1,
      },
      faqCachedUrl
    )
  );
};

const enhance = connect((state) => {
  return {
    FAQ: state.fetchedUrls[faqCachedUrl]?.FAQ || [],
    dataLoaded: state.urlStatus[faqCachedUrl] === "done",
  };
});

const titleTab = ["one", "two", "three", "four", "five", "six", "seven", "eight"];

class FaqWrapper extends Component {
  componentDidMount = () => {
    prefetchMethod(this.props.dispatch);
  };

  handleClick = () => {
    window.scrollTo(0, 0);
  };

  render() {
    const {dataLoaded, FAQ, handleClick} = this.props;

    return !dataLoaded ? (
      <Loader />
    ) : (
      <>
        <Helmet>
          <title>O czym warto wiedzieć na rejsach morskich | The Boat Trip</title>
          <meta name="description" content="Co warto wiedzieć przed wyruszeniem w rejs" data-react-helmet="true" />
        </Helmet>
        <div id="faqWrapper">
          <h3>FAQ - najczęściej zadawane pytania</h3>
          <Tabs vertical>
            <TabList>
              {FAQ.map(({Name}, index) => (
                <Tab tabFor={`vertical-tab-${titleTab[index]}`}>
                  {Name}
                  <i class="arrow right"></i>
                </Tab>
              ))}
            </TabList>
            <div className="panelBox">
              {FAQ.map(({QuestionsList}, index) => (
                <TabPanel tabId={`vertical-tab-${titleTab[index]}`}>
                  <div className="faqContainer">
                    <Accordion allowZeroExpanded>
                      {QuestionsList.map(({Question, Answer}) => (
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>{ReactHtmlParser(Question)}</AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>{ReactHtmlParser(Answer)}</AccordionItemPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </div>
                </TabPanel>
              ))}
            </div>
          </Tabs>
        </div>
        <Contact handleClick={handleClick} />
      </>
    );
  }
}

FaqWrapper.prefetchMethod = prefetchMethod;

export default enhance(FaqWrapper);
