import React, {useState} from "react";
import OfferAttractionsPopup from "./OfferAttractionsPopup";

const OfferAttractionsItem = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const attractionImg = props.image;

  return (
    <div
      className="offerSingleAttraction"
      style={props.offerAttractions.length % 3 === 0 ? {width: "32%"} : {width: "45%"}}
    >
      <div className="attractionBox" onClick={togglePopup}>
        <h3 className="attractionTitle">{props.name}</h3>
        <img src={attractionImg?.url} alt={attractionImg?.alt} />
      </div>
      {isOpen && (
        <OfferAttractionsPopup
          index={props.index}
          offerAttractions={props.offerAttractions}
          content={props.description}
          handleClose={togglePopup}
          name={props.name}
          img={props.image}
        />
      )}
    </div>
  );
};

export default OfferAttractionsItem;
