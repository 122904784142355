import React from 'react';
import './HowItWorks.scss';
import PaymentsInfo from './PaymentsInfo.js';
import PaymentsIncluded from './PaymentsIncluded.js';
import GeneralInfo from './GeneralInfo.js';
import ReservationInfo from './ReservationInfo.js';
import CruiseSelectionInfo from './CruiseSelectionInfo.js';
import YachtSelectionInfo from './YachtSelectionInfo.js';
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
} from 'react-scroll';
import ReactHtmlParser from 'react-html-parser';
import { fetchData } from './store/actions';
import { howItWorksPageUrl } from './cachedStoreUrls';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

export const prefetchMethod = (dispatch) => {
  return dispatch(
    fetchData(
      '/v1/data/page',
      {
        siteId: 1,
        pageId: 'howItWorks',
      },
      howItWorksPageUrl
    )
  );
};

const enhance = connect((store) => {
  return {
    Page: store.fetchedUrls[howItWorksPageUrl]?.Page,
    dataLoaded: store.urlStatus[howItWorksPageUrl] === 'done',
  };
});

class HowItWorks extends React.Component {
  componentDidMount = () => {
    prefetchMethod(this.props.dispatch);

    Events.scrollEvent.register('begin', function () {});

    Events.scrollEvent.register('end', function () {});

    scrollSpy.update();
  };

  componentWillUnmount = () =>
    function () {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };

  scrollToTop = () =>
    function () {
      scroll.scrollToTop();
    };
  scrollToBottom = () =>
    function () {
      scroll.scrollToBottom();
    };
  scrollTo = () =>
    function () {
      scroll.scrollTo(100);
    };
  scrollMore = () =>
    function () {
      scroll.scrollMore(100);
    };
  handleSetActive = () => function (to) {};

  render() {
    if (!this.props.dataLoaded) {
      return (
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    }

    const arrWithTextImages =
      this.props.Page.Contents !== undefined
        ? this.props.Page.Contents.filter((el) => el.Type === 'TextWithImages')
        : null;
    const sliderObject =
      this.props.Page.Contents !== undefined ? arrWithTextImages.shift() : null;
    const popElement =
      this.props.Page.Contents !== undefined ? arrWithTextImages.pop() : null;
    const findHTMLPageHTML =
      this.props.Page.Contents !== undefined
        ? this.props.Page.Contents.filter((el) => el.Contents.HTML)
        : null;

    const metaData = this.props.Page?.META;

    return (
      <div id="howItWorks">
        <Helmet>
          <title>{metaData?.Title}</title>
          <meta
            name="description"
            content={metaData?.Description}
            data-react-helmet="true"
          />
        </Helmet>

        {this.props.Page.Contents !== undefined && (
          <div className="slider">
            <img
              src={sliderObject.Contents.Images[0]?.url}
              alt={sliderObject.Contents.Images[0]?.alt}
            />
            {ReactHtmlParser(sliderObject.Contents.Text)}
          </div>
        )}

        <div className="menuPage">
          <div className="container">
            <Link
              activeClass="active"
              to="generalInfo"
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              onSetActive={this.handleSetActive}
            >
              Informacje ogólne
            </Link>
            <Link
              activeClass="active"
              to="cruiseSelectionInfo"
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              onSetActive={this.handleSetActive}
            >
              Wybór rejsu
            </Link>
            <Link
              activeClass="active"
              to="yachtSelectionInfo"
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              onSetActive={this.handleSetActive}
            >
              Wybór jachtu
            </Link>
            <Link
              activeClass="active"
              to="reservationInfo"
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              onSetActive={this.handleSetActive}
            >
              Rezerwacje
            </Link>
            <Link
              activeClass="active"
              to="paymentsInfo"
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              onSetActive={this.handleSetActive}
            >
              Płatności
            </Link>
            <Link
              activeClass="active"
              to="paymentsIncluded"
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              onSetActive={this.handleSetActive}
            >
              Cena
            </Link>
            {/* <Link activeClass="active" to="gallery" spy={true} smooth={true} offset={-80} duration={500} onSetActive={this.handleSetActive}>
                            Galeria
                        </Link> */}
          </div>
        </div>

        <div id="ogolne" className="generalInfoID"></div>
        <Element
          id="generalInfo"
          key="generalInfo"
          name="generalInfo"
          className="element"
        >
          <GeneralInfo
            Page={this.props.Page}
            arrWithTextImages={arrWithTextImages}
          />
        </Element>

        <div id="jak-wybrac-rejs" className="cruiseSelectionID"></div>
        <Element
          id="cruiseSelection"
          key="cruiseSelection"
          name="cruiseSelectionInfo"
          className="element"
        >
          <CruiseSelectionInfo
            Page={this.props.Page}
            arrWithTextImages={arrWithTextImages}
            findHTMLPageHTML={findHTMLPageHTML}
          />
        </Element>
        <div id="jak-wybrac-jacht" className="yachtSelectionID"></div>
        <Element
          id="yachtSelection"
          key="yachtSelection"
          name="yachtSelectionInfo"
          className="element"
        >
          <YachtSelectionInfo
            Page={this.props.Page}
            arrWithTextImages={arrWithTextImages}
            popElement={popElement}
            findHTMLPageHTML={findHTMLPageHTML}
          />
        </Element>

        <div id="jak-sie-zapisac-na-rejs" className="reservationInfoID"></div>
        <Element
          id="reservationInfo"
          key="reservationInfo"
          name="reservationInfo"
          className="element"
        >
          <ReservationInfo
            Page={this.props.Page}
            arrWithTextImages={arrWithTextImages}
            findHTMLPageHTML={findHTMLPageHTML}
          />
        </Element>

        <Element
          id="paymentsInfo"
          key="paymentsInfo"
          name="paymentsInfo"
          className="element"
        >
          <PaymentsInfo
            Page={this.props.Page}
            arrWithTextImages={arrWithTextImages}
            findHTMLPageHTML={findHTMLPageHTML}
          />
        </Element>

        <div id="platnosci-i-cena" className="paymentsIncludedID"></div>
        <Element
          id="paymentsIncluded"
          key="paymentsIncluded"
          name="paymentsIncluded"
          className="element"
        >
          <PaymentsIncluded
            Page={this.props.Page}
            arrWithTextImages={arrWithTextImages}
            findHTMLPageHTML={findHTMLPageHTML}
          />
        </Element>
        {/* <Element name="gallery" className="element">
                    <Gallery />
                </Element> */}
      </div>
    );
  }
}

HowItWorks.prefetchMethod = prefetchMethod;

export default enhance(HowItWorks);
