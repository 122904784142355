import React from 'react';
import './HowItWorksWrapper.scss';
import HowItWorks from './HowItWorks';
import imageOne from './img/howItWorks-1.jpg';
import imageSecond from './img/howItWorks-2.jpg';
import imageThird from './img/howItWorks-3.jpg';
import imageFour from './img/howItWorks-4.jpg';

const HowItWorksArr = [
  {
    image: imageOne,
    alt: 'Rejsy morskie jachtem',
    desc: 'Wybierz wymarzoną destynację z naszej oferty. Pamiętaj, że nie musisz mieć doświadczenia żeglarskiego - o wszystko zadba wykwalifikowany sternik!',
  },

  {
    image: imageSecond,
    alt: 'Rejsy morskie jachtem',
    desc: 'Powiedz o wyjeździe swoim znajomym, abyście mogli przeżyć tę przygodę razem',
  },

  {
    image: imageThird,
    alt: 'Rejsy morskie jachtem',
    desc: 'Zarezewuj pojedyncze miejsce na wyjeździe lub cały jacht ze zniżką',
  },

  {
    image: imageFour,
    alt: 'Rejsy morskie jachtem',
    desc: 'Rozkoszuj się bezstresowym wyjazdem, ciesz się pięknymi widokami i baw się do wschodu słońca z nowo poznanymi ludźmi!',
  },
];

const HowItWorksWrapper = () => {
  return (
    <div id="howItWorksWrapper">
      <div className="howItWorksTitle">
        <h2>Rejsy morskie - Jak to działa?</h2>
      </div>
      <div className="howItWorksWrapper">
        {HowItWorksArr.map(({ image, desc, alt }, index) => (
          <HowItWorks key={index} image={image} desc={desc} alt={alt} />
        ))}
      </div>
      <a
        href="/jak-to-dziala"
        className="buttonMore"
        title="Dowiedz się więcej o organizacji naszych rejsów"
      >
        <button className="button primaryBtn">Dowiedz się więcej</button>
      </a>
    </div>
  );
};

export default HowItWorksWrapper;
