import React from "react";
import {Tabs, Tab, TabPanel, TabList} from "react-web-tabs";
import "./TripStatusMenu.scss";
import CurrentTrip from "./CurrentTrip.js";
import LastTrip from "./LastTrip.js";
import CanceledTrip from "./CanceledTrip.js";

const TripStatusMenu = (props) => {
  const tabsArray = [
    {tabNumber: "one", tabName: "Bieżące", componentName: <CurrentTrip {...props} />},
    {tabNumber: "two", tabName: "Minione", componentName: <LastTrip {...props} />},
    {tabNumber: "three", tabName: "Anulowane", componentName: <CanceledTrip {...props} />},
  ];

  return (
    <div id="tripStatusMenu">
      <Tabs defaultTab="basic-tab-one">
        <TabList>
          {tabsArray.map(({tabNumber, tabName}) => (
            <Tab tabFor={`basic-tab-${tabNumber}`}>{tabName}</Tab>
          ))}
        </TabList>
        {tabsArray.map(({tabNumber, componentName}) => (
          <TabPanel tabId={`basic-tab-${tabNumber}`}>{componentName}</TabPanel>
        ))}
      </Tabs>
    </div>
  );
};

export default TripStatusMenu;
