import React, {Component} from "react";
import Testimonial from "./Testimonial.js";
import "./TestimonialsWrapper.scss";
import "./HighlightsWrapper.scss";
import $ from "jquery";
import image1 from "./img/referal-lukasz-tbt.jpg";
import image2 from "./img/referal-marta-tbt.jpg";
import image3 from "./img/referal-bartek-tbt.jpg";
import image4 from "./img/referal-martyna-tbt.jpg";
import image5 from "./img/referal-kuba-tbt.jpg";
import image7 from "./img/referal-paulina-tbt.jpg";
import image8 from "./img/referal-karol-tbt.jpg";
import image6 from "./img/testimonials-fb.png";

var TestimonialsArr = [
  {
    index: 1,
    image: image1,
    name: "Łukasz",
    alt: "Opinia uczestnika - The Boat Trip",
    testimonial_text:
      "Serdecznie polecam rejsy turystyczne, gdzie pływałem w różnych kierunkach zawsze z wyjątkowym towarzystwem i fachowym sternikiem.",
  },

  {
    index: 2,
    image: image2,
    name: "Marta",
    alt: "Opinia uczestnika - The Boat Trip",
    testimonial_text:
      "Imprezy, żagle, day stopy i poczucie ze wszystko jest ogarnięte - nie wiem czy kiedykolwiek byłam na lepszym urlopie.",
  },

  {
    index: 3,
    image: image3,
    name: "Bartek",
    alt: "Opinia uczestnika - The Boat Trip",
    testimonial_text:
      "Organizacja na wysokim poziomie, super ludzie, i masa niezapomnianych wspomnień - aż mam zakwasy na twarzy od śmiechu. ",
  },

  {
    index: 4,
    image: image4,
    name: "Martyna",
    alt: "Opinia uczestnika - The Boat Trip",
    testimonial_text:
      "Najlepsze wakacje ever. Już nie wyobrażam sobie innych. Świetna organizacja czasu, zarówno tego spędzonego na łodzi, jak i na lądzie.",
  },

  {
    index: 6,
    image: image5,
    name: "Kuba",
    alt: "Opinia uczestnika - The Boat Trip",
    testimonial_text:
      "Boat Trip to przede wszystkim fantastyczna organizacja wyjazdu. Wszystko na czas, super zaplanowane, mnogość atrakcji.",
  },

  {
    index: 8,
    image: "",
    name: "Tomasz",
    testimonial_text:
      "Genialne wyjazdy! Zawsze super ogarnięte - w ciemno polecać ludziom chcącym fajnego, aktywnego wypoczynku z dobrą ekipą!",
  },
  {
    index: 9,
    image: image7,
    name: "Paulina",
    alt: "Opinia uczestnika - The Boat Trip",
    testimonial_text:
      "Fantastyczni ludzie, profesjonalni skipperzy, nieziemskie pomysły, rewelacyjna organizacja! Gorąco polecam!",
  },
  {
    index: 10,
    image: image8,
    name: "Karol",
    alt: "Opinia uczestnika - The Boat Trip",
    testimonial_text:
      "Ciężko to słowami opisać, naprawdę, spróbujesz raz, wpadłeś/aś !!! Pierwszy Boat Trip i już nie mogę doczekać się następnego... ",
  },
];

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}
function GenerateTestimonialsArr() {
  var arr = [];
  var arr2 = [];
  while (arr.length < 7) {
    let r = getRandomInt(1, 8);
    if (arr.indexOf(r) === -1) {
      arr.push(r);
      TestimonialsArr.forEach((el) => {
        if (el.index === r) {
          arr2.push(el);
        }
      });
    }
  }
  return arr2;
}

class TestimonialsWrapper extends Component {
  constructor(props) {
    super(props);
    this.scroll = this.scroll.bind(this);
    this.state = {
      tag: "all",
    };
  }

  scroll(direction) {
    let far = ($(".testimonialsWrapper").width() / 1) * direction;
    let pos = $(".testimonialsWrapper").scrollLeft() + far;
    $(".testimonialsWrapper").animate({scrollLeft: pos}, 1000);
  }

  handleAll = () => {
    this.setState({tag: "all"});
  };

  componentDidUpdate() {}

  render() {
    if (this.state.tag === "all") {
      return (
        <div id="testimonialsContainer">
          <div className="testimonialsTitle">
            <h2>Dołącz do grona zadowolonych klientów</h2>
            <p>Od ponad 10 lat organizujemy dla Was świetnie zaplanowane wyjazdy w najpiękniejsze zakątki świata!</p>
          </div>
          <div className="testimonialsContentWrapper">
            <div className="testimonialsSocialRate">
              <div className="testimonialsSocialItem">
                <img src={image6} alt="Rejsy morskie jachtem" />
                <p>Srednia 5.0 z ponad 400 opinii</p>
                <a
                  href="https://www.facebook.com/TheBoatTrip/reviews/?ref=page_internal"
                  target="_blank"
                  rel="noreferrer"
                  title="Sprawdź jak uczestnicy oceniają nasze rejsy"
                >
                  <button className="button primaryBtn">Zobacz opinie</button>
                </a>
              </div>
            </div>
            <div className="testimonialsContent">
              <div className="prev" onClick={this.scroll.bind(null, -1)}>
                &#10094;
              </div>
              <div className="testimonialsWrapper">
                {GenerateTestimonialsArr().map(({image, name, job, testimonial_text, alt}, index) => (
                  <Testimonial key={index} image={image} name={name} job={job} text={testimonial_text} alt={alt} />
                ))}
              </div>
              <div className="next" onClick={this.scroll.bind(null, 1)}>
                &#10095;
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default TestimonialsWrapper;
