import React from 'react';
import './OfferBookButton.scss';
import { Link } from 'react-router-dom';
import TagManager from 'react-gtm-module';

const BookButton = ({
  price,
  name,
  offerid,
  grayedOut,
  availableOffer,
  analiticsDataAddToCart,
  offerTypeId,
}) => {
  return (
    <>
      {!grayedOut && availableOffer ? (
        <Link
          to={{
            pathname: '/rezerwacja',
            state: {
              id: offerid,
              nameTrip: name,
              offerTypeId: offerTypeId,
            },
          }}
          onClick={() => {
            TagManager.dataLayer({
              dataLayer: { ecommerce: null },
            });
            TagManager.dataLayer({
              dataLayer: analiticsDataAddToCart,
            });
          }}
          title="Rezerwuj"
        >
          <div className="bookButtonWrapper">
            {
              <p>
                {name} - {price} PLN
              </p>
            }
            <button className="primaryBtn">REZERWUJ</button>
          </div>
        </Link>
      ) : (
        <div className="bookButtonWrapper">
          {
            <p>
              {name} - {price} PLN
            </p>
          }
          <div id="hoverBtn">
            <button
              className="primaryBtn"
              style={{ background: 'grey', cursor: 'default' }}
            >
              REZERWUJ
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default BookButton;
