import React from "react";
import ReactHtmlParser from "react-html-parser";
import "./BFAttraction.scss";
import image1 from "./img/familyTrip6.jpg";
import image2 from "./img/familyTrip7.jpg";
import image3 from "./img/familyTrip11.jpg";
import image4 from "./img/familyTrip9.jpg";
import image5 from "./img/familyTrip10.jpg";
import image6 from "./img/familyTrip13.jpg";
import image7 from "./img/familyTrip12.jpg";
import image8 from "./img/familyTrip8.jpg";

const attractionArr = [
  {
    name: "Totalny relaks, poczucie wolności i niezapomniane wrażenia.",
    description: "",
    image: image1,
  },
  {
    name: "Możliwość podszkolenia się z wiedzy żeglarskiej",
    description: "",
    image: image2,
  },
  {
    name: "Zwiedzanie najciekawszych atrakcji w danej lokalizacji",
    description: "",
    image: image3,
  },
  {
    name: "Degustacja lokalnej kuchni w polecanych knajpach",
    description: "",
    image: image4,
  },
  {
    name: "Kąpiele i snorkling w spokojnych zatoczkach",
    description: "",
    image: image5,
  },
  {
    name: "Najpiękniejsze zachody słońca z ulubionym drinkiem w ręce",
    description: "",
    image: image6,
  },
  {
    name: "Śniadania w niezapomnianej scenerii z dala od tłumu",
    description: "",
    image: image7,
  },
  {
    name: "Możliwość dopasowania rejsu do potrzeb rodziny z dziećmi",
    description: "",
    image: image8,
  }
];

const BFAttractions = () => {
  return (
    <div id="offerAttractionsSection" className="bf">
      <h3>Co Cię czeka na rejsie</h3>
      <div style={{width: "auto", overflowX: "auto"}}>
        <div className="offerAttractionsListWrapper">
          {attractionArr.map(({name, image}) => (
            <div className="offerSingleAttraction">
              <div className="attractionBox">
                <h5>{ReactHtmlParser(name)}</h5>
                <img src={image} alt={name} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BFAttractions;
