import React from "react";
import "./YachtTable.scss";

import image1 from "./img/premier-kat-1.jpg";
import image2 from "./img/premier-kat-2.jpg";
import image3 from "./img/kat-premier-3.jpg";
import image4 from "./img/superior-kat-1.jpg";
import image5 from "./img/superior-kat-2.jpg";
import image6 from "./img/superior-kat-3.jpg";
import image7 from "./img/luxury-1.jpg";
import image8 from "./img/luxury-2.jpg";
import image9 from "./img/luxury-3.jpg";

const katamaranTableArr = [
  {
    imageClass: image1,
    class: "Katamaran Premier",
    description:
      "Znajdziesz tu 12 osobowe katamarany o długości ok. 12 metrów z 4 kabinami 2-os. oraz 2 mini kabinami 1-os. na dziobach.",
    imageBoat: image2,
    descriptionBoat:
      "Każdy katamaran ma min. 2-4 łazienki z prysznicem, przestronny salon  (gdzie śpi  1-2 osoby na rozkładanym łóżku) oraz w pełni wyposażoną kuchnię. Najczęściej kwaterujemy tu 10 uczestników + skippera. Kluczowe cechy to: zewnętrzny salon, siatka do chill'out na dziobie, często elektryczne WC, sound system, ładowanie USB (prąd 220V dostępny po zacumowaniu w porcie).",
    imageSittingRoom: image3,
    descriptionRoom:
      "Przykładowe jednostki w tej klasie:  Lagoon 39, Lagoon 40, Bali 4.1, Lucia 40, Lagoon 400 s2.",
    altClass: "Katamaran Premier",
    altBoat: "Salon na Katamaranie Premier",
    altSittingRoom: "Sypialnia na Katamaranie Premier",
  },
  {
    imageClass: image4,
    class: "Katamaran Superior",
    description:
      "Znajdziesz tu 12 osobowe katamarany o długości ok. 13 metrów z 4 kabinami 2-os. oraz 2 mini kabinami 1-os. na dziobach.",
    imageBoat: image5,
    descriptionBoat:
      "Każdy katamaran ma min. 4 łazienki z prysznicem, przestronny salon  (gdzie śpi  1-2 osoby na rozkładanym łóżku) oraz w pełni wyposażoną kuchnię. Najczęściej kwaterujemy tu 10 uczestników + skippera. Kluczowe cechy to: duży zewnętrzny salon, siatka do chill'out na dziobie, często elektryczne WC, sound system, ładowanie USB (prąd 220V dostępny po zacumowaniu w porcie).",
    imageSittingRoom: image6,
    descriptionRoom:
      "Przykładowe jednostki w tej klasie: Lagoon 42, Lagoon 450, Bali 4.3, Bali 4.5, Astrea 42, Nautitech 46.",
    altClass: "Katamaran Superior",
    altBoat: "Salon na Katamaranie Superior",
    altSittingRoom: "Sypialnia na Katamaranie Superior",
  },
  {
    imageClass: image7,
    class: "Katamaran Luxury",
    description:
      "Znajdziesz tu ekskluzywne 12 osobowe katamarany o długości ok. 15 metrów z 6 kabinami 2-os. (nikt nie śpi w salonie).",
    imageBoat: image8,
    descriptionBoat:
      "Każdy katamaran ma 4-6 łazienek z prysznicem, duży salon wewnętrzny, olbrzymi salon zewnętrzny oraz w pełni wyposażoną kuchnię. Kluczowe cechy to: klimatyzacja, agregat prądu, elektryczne WC, sound system, a często można spotkać odsalarki do wody, czy kostkarki do lodu.",
    imageSittingRoom: image9,
    descriptionRoom:
      "Przykładowe jednostki w tej klasie: Bali 4.8, Lagoon 50, Lagoon 52, Saba 50",
    altClass: "Katamaran Luxury",
    altBoat: "Salon na Katamaranie Luxury",
    altSittingRoom: "Sypialnia na Katamaranie Luxury",
  },
];

class KatamaranTable extends React.Component {
  render() {
    return (
      <div id="yachtTableSection">
        <h2>Wybierz klasę jachtu</h2>
        <p style={{ width: "800px" }}>
          Nasze 3 klasy łódek: Premier (4★), Superior (4★+) i Luxury (5★) różnią
          się głównie wielkością jednostki, co przekłada się bezpośrednio na
          ilość miejsca i w konsekwencji Twój komfort w części hotelowej.
          Wyposażenie i wiek jednostek między klasami są do siebie zbliżone.
          Wszystkie są względnie nowe (max. 5 letnie) i przewyższają standardową
          jakość łódek oferowanych przez innych organizatorów. Poniżej
          znajdziesz bardziej szczegółową specyfikę każdej klasy z dużą ilością
          przykładowych zdjęć i modelami łódek jakie mogą się tam znaleźć:
        </p>
        <div className="row">
          {katamaranTableArr.map((el) => (
            <div className="column">
              <div className="sectionClass">
                <div className="image">
                  <img src={el.imageClass} alt={el.altClass} />
                </div>
                <h4>{el.class}</h4>
                <p>{el.description}</p>
              </div>
              <div className="sectionGeneralInfo">
                <div className="image">
                  <img src={el.imageBoat} alt={el.altBoat} />
                </div>
                <p>{el.descriptionBoat}</p>
              </div>
              <div className="sectionSittingRoom">
                <h5>Przykładowe jednostki</h5>
                <div className="image">
                  <img src={el.imageSittingRoom} alt={el.altSittingRoom} />
                </div>
                <p>{el.descriptionRoom}</p>
              </div>
            </div>
          ))}
        </div>
        {/* <button className="primaryBtn">Dowiedz się więcej o jachtach i katamaranach</button> */}
      </div>
    );
  }
}

export default KatamaranTable;
