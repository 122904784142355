import React from "react";
import "./Destination.scss";

const Destination = ({image, alt, country_name, filterCountry}) => {
  const destinationImg = image;
  return (
    <div className="oneDestination">
      <div className="image">
        <img src={destinationImg} alt={alt} />
      </div>
      <h3 className="name">{country_name}</h3>
      <a href={`/rejsy-morskie?destination=${filterCountry}&`} title={`Rejsy morskie - ${country_name}`}>
        <button className="button primaryBtn">Zobacz rejsy</button>
      </a>
    </div>
  );
};

export default Destination;
