import React from "react";
import "./COVID.scss";
import "./userRegistration/Trips.scss";
import "./FilteredTrips.scss";
import ReactHtmlParser from "react-html-parser";
import {Helmet} from "react-helmet";
import {fetchData} from "./store/actions";
import {covidCachedUrl} from "./cachedStoreUrls";
import {connect} from "react-redux";
import Loader from "./Loader.js";

export const prefetchMethod = (dispatch, match) => {
  return dispatch(
    fetchData(
      "/v1/data/page",
      {
        siteId: 1,
        pageId: "covidPageInfo",
      },
      covidCachedUrl
    )
  );
};

const enhance = connect((state) => {
  return {
    Page: state.fetchedUrls[covidCachedUrl]?.Page || {},
    dataLoaded: state.urlStatus[covidCachedUrl] === "done",
  };
});

class Covid extends React.Component {
  componentDidMount = () => {
    prefetchMethod(this.props.dispatch);
  };

  render() {
    const {Page, dataLoaded} = this.props;
    const arrWithTextImages = Page?.Contents?.filter(({Type}) => Type === "TextWithImages");
    const sliderObject = Page?.Contents ? arrWithTextImages.shift() : null;
    const metaData = Page.META;

    if (!dataLoaded) {
      return <Loader />;
    }
    return (
      <div id="covid">
        <Helmet>
          <title>{metaData?.Title}</title>
          <meta name="description" content={metaData?.Descriptions} data-react-helmet="true" />
        </Helmet>
        <>
          <div className="slider">
            <img src={sliderObject.Contents.Images[0]?.url} alt={sliderObject.Contents.Images[0]?.alt} />
          </div>
          <div className="info">
            <div className="container">
              <div className="mainText">{ReactHtmlParser(sliderObject?.Contents?.Text?.replace('<a ', '<a target="_blank"'))}</div>
              {Page?.Contents &&
                arrWithTextImages?.map(({Contents}) => (
                  Contents?.Text &&
                  <div className="imageArticle">
                    {Contents?.Images[0]?.url &&
                    <div className="imageSection">
                      <img className="firstPhoto" src={Contents.Images[0]?.url} alt={Contents.Images[0]?.alt} />
                    </div>}
                    <div className="textSection">{ReactHtmlParser(Contents?.Text?.replace('<a ', '<a target="_blank"'))}</div>
                  </div>
                ))}
            </div>
          </div>
        </>
      </div>
    );
  }
}

Covid.prefetchMethod = prefetchMethod;

export default enhance(Covid);
