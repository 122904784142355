import React from 'react';
import "./StepsNumber.scss"

const stepsCircle = [
    {
        id: 1,
        name: "Twój Rejs",
        step: "StepOne"
    },

    {
        id: 2,
        name: "Zakwaterowanie i dojazd",
        step: "StepTwo"
    },

    {
        id: 3,
        name: "Ubezpieczenie",
        step: "StepThree"
    },

    {
        id: 4,
        name: "Dodatki",
        step: "StepFour"
    },

    {
        id: 5,
        name: "Dane uczestników",
        step: "StepFive"
    },

    {
        id: 6,
        name: "Podsumowanie i płatność",
        step: "StepSix"
    },

]

const StepsNumber = (props) => {

    return (
        <div id="stepsNumber">
            <div className="container">
                <div className="line"></div>
                {stepsCircle.map((el) =>
                    <div className="oneBox">
                        {props.stepOne && el.name === "Twój Rejs" ?
                            <>
                                <div className="circle active">
                                    <p>{el.id}</p>
                                </div>
                                <p className="title active">{el.name}</p>
                            </>
                            :
                            props.stepNumberOne && el.name === "Twój Rejs" ?
                                <>
                                    <div className="circle active">
                                        <p className="greentick">✔</p>
                                    </div>
                                    <p className="title active">{el.name}</p>
                                </>

                                :
                                props.stepTwo && el.name === "Zakwaterowanie i dojazd" ?
                                    <><div className="circle active">
                                        <p>{el.id}</p>
                                    </div>
                                        <p className="title active">{el.name}</p>
                                    </>
                                    :

                                    props.stepNumberTwo && el.name === "Zakwaterowanie i dojazd" ?
                                        <>
                                            <div className="circle active">
                                                <p className="greentick">✔</p>
                                            </div>
                                            <p className="title active">{el.name}</p>
                                        </>
                                        :

                                        props.stepThree && el.name === "Ubezpieczenie" ?
                                            <><div className="circle active">
                                                <p>{el.id}</p>
                                            </div>
                                                <p className="title active">{el.name}</p>
                                            </>
                                            :

                                            props.stepNumberThree && el.name === "Ubezpieczenie" ?
                                                <>
                                                    <div className="circle active">
                                                        <p className="greentick">✔</p>
                                                    </div>
                                                    <p className="title active">{el.name}</p>
                                                </>

                                                :
                                                props.stepFour && el.name === "Dodatki" ?
                                                    <><div className="circle active">
                                                        <p>{el.id}</p>
                                                    </div>
                                                        <p className="title active">{el.name}</p>
                                                    </>
                                                    :
                                                    props.stepNumberFour && el.name === "Dodatki" ?
                                                        <>
                                                            <div className="circle active">
                                                                <p className="greentick">✔</p>
                                                            </div>
                                                            <p className="title active">{el.name}</p>
                                                        </>

                                                        :
                                                        props.stepFive && el.name === "Dane uczestników" ?
                                                            <><div className="circle active">
                                                                <p>{el.id}</p>
                                                            </div>
                                                                <p className="title active">{el.name}</p>
                                                            </>
                                                            :
                                                            props.stepNumberFive && el.name === "Dane uczestników" ?
                                                                <>
                                                                    <div className="circle active">
                                                                        <p className="greentick">✔</p>
                                                                    </div>
                                                                    <p className="title active">{el.name}</p>
                                                                </>
                                                                :
                                                                props.stepSix && el.name === "Podsumowanie i płatność" ?
                                                                    <><div className="circle active">
                                                                        <p>{el.id}</p>
                                                                    </div>
                                                                        <p className="title active">{el.name}</p>
                                                                    </>
                                                                    :
                                                                    props.stepNumberSix && el.name === "Podsumowanie i płatność" ?
                                                                        <>
                                                                            <div className="circle active">
                                                                                <p className="greentick">✔</p>
                                                                            </div>
                                                                            <p className="title active">{el.name}</p>
                                                                        </>
                                                                        :
                                                                        <> <div className="circle">
                                                                            <p>{el.id}</p>
                                                                        </div>
                                                                            <p className="title">{el.name}</p>
                                                                        </>
                        }

                    </div>
                )}
            </div >
        </div>
    );
}


export default StepsNumber;