import React from "react";
import "./AdventureWrapper.scss";
import Adventure from "./Adventure";
import adventure_1 from "./img/adventure_1.jpg";
import adventure_2 from "./img/adventure_2.jpg";
import adventure_3 from "./img/adventure_3.jpg";

const AdventureArr = [
  {
    image: adventure_1,
    desc: "Rejsy morskie w 4 różnych formułach, dopasowane do Twoich potrzeb: Party, Explore, Chill i Adventure",
    alt: "Rejsy morskie jachtem",
  },

  {
    image: adventure_2,
    desc: "Wyrusz solo lub z ekipą znajomych i spędź wakacje na ekskluzywnym jachcie!",
    alt: "Rejsy morskie jachtem",
  },

  {
    image: adventure_3,
    desc: "Nie musisz mięc doświadczenia żeglarskiego! O wszystko zadba wykwalifikowany sternik!",
    alt: "Rejsy morskie jachtem",
  },
];

const AdventureWrapper = () => {
  return (
    <div id="adventureWrapper">
      <div className="adventureTitle">
        <h2>Rejsy morskie z The Boat Trip - najlepsze wakacje na jachcie!</h2>
        <p>
          Rejsy z The Boat Trip to tydzień niezapomnianych wakacji, zabawy we flocie nawet 40 jednostek i poznawania
          nowych, entuzjastycznych młodych ludzi. To tydzień spędzony na ekskluzywnym jachcie i niesamowitych imprez w
          fascynujących sceneriach. Tydzień odkrywania nowych miejsc w najpiękniejszych zakątkach świata, zwiedzania,
          niepowtarzalnych wrażeń i doznań. Przeżyj najlepsze wakacje życia!
        </p>
      </div>
      <div className="adventureWrapper">
        {AdventureArr.map(({image, desc, alt}, index) => (
          <Adventure key={index} image={image} desc={desc} alt={alt} />
        ))}
      </div>
    </div>
  );
};

export default AdventureWrapper;
