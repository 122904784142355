import React, { Component } from "react";
import './InstagramFeed.scss';

class InstagramFeed extends Component {
    render () {
        return (
            <div className="instagramFeedItem">
                <a target="_blank" rel="noreferrer" href={this.props.link} title="Sprawdź na instagramie nasze galerie z rejsów" >
                    <img src={this.props.image} alt={this.props.alt} />
                </a>
            </div>
        );
    }
}

export default InstagramFeed;