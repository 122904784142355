import React, {useState} from "react";
import "./Tabs.scss";

export const Tabs = ({tabs, clickNewIndex, overwriteActiveIndex}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <div>
      <ul className="listTabsMenu">
        {tabs.map(({title}, index) => {
          return (
            <li
              onClick={() => {
                setActiveTabIndex(index);
                if (clickNewIndex) {
                  clickNewIndex(index);
                }
              }}
              className={`${index === activeTabIndex ? "activeTab" : "tab"}`}
            >
              {title}
            </li>
          );
        })}
      </ul>
      <div>{tabs[overwriteActiveIndex || activeTabIndex].content}</div>
    </div>
  );
};
export default Tabs;
