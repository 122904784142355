import React from "react";
import "./DetailedInformation.scss";
import ReactHtmlParser from "react-html-parser";

const DetailedInformation = ({ detailedInfo }) => {
  const infoArr = detailedInfo?.splice(1, 6);
  const info = infoArr?.map(({ Contents }) => Contents?.Text?.split("\r\n"));
  const firstImage = infoArr?.map(({ Contents }) => Contents?.Images[0]);
  const secondImage = infoArr?.map(({ Contents }) => Contents?.Images[1]);

  return (
    <div id="detailedInformation">
      {info?.map((el, index) => (
        <div className="container">
          {index % 2 ? (
            <div className="secondSection">
                <div className="imageSection">
                <img
                  className="firstPhoto"
                  src={firstImage?.[index]?.url}
                  alt={firstImage?.[index]?.alt}
                />
                <img
                  className="secondPhoto"
                  src={secondImage?.[index]?.url}
                  alt={secondImage?.[index]?.alt}
                />
              </div>
              <div className="textSection">
                <h2>{el[0]}</h2>
                <p>{ReactHtmlParser(el[1])}</p>
                <p>{ReactHtmlParser(el[2])}</p>
              </div>

            
            </div>
          ) : (
            <div className="firstSection">
              <div className="imageSection">
                <img
                  src={firstImage?.[index]?.url}
                  alt={firstImage?.[index]?.alt}
                />
              </div>
              <div className="textSection">
                <h2>{el[0]}</h2>
                <p>{ReactHtmlParser(el[1])}</p>
              </div>

              
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default DetailedInformation;
