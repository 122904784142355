import React from 'react';
import "./ProgramList.scss"
import "./userRegistration/Trips.scss";
import ReactHtmlParser from 'react-html-parser';
import "./FilteredTrips.scss"
import calendarImage from "./img/calendar.png";
import APIbackend from "./config.js";

const FilteredTrips = (props) => {

    return (
        <div id="filteredTrips">
            <h2>Wyjazdy Sail&Party</h2>
            <div className="container">
                {props.List.map((el) => {
                    const regionImg = el.Region.Country.Image;
                return (
                    <div>
                        <div id="oneTripFiltered" >
                            <div className="photoContainer">
                                <img src={el.ListImg?.url} alt={el.ListImg?.alt} />
                                <div className="overlay"></div>
                            </div>
                            <div className="tripInfo">
                                <h3>{el.Name}</h3>
                                <div className="infoTripBox">
                                    <p><img src={regionImg?.url} alt={regionImg?.alt} />{el.Region.Country.Name}, {el.Region.Name}</p>
                                    <p><img src={calendarImage} alt="termin" />{el.Availability.From} - {el.Availability.To}
                                        {el.RibbonTextExt === "Wyjazd potwierdzony" ? <span className="confirmedTrip">{el.RibbonTextExt}</span> : <span>{el.RibbonTextExt}</span>}
                                    </p>
                                </div>



                                <div className="tagsBox">
                                    {el.Options.map((el) => {
                                        const tagImg = el.Image;
                                        return (
                                        <div className="oneTag"><img src={tagImg?.url} alt={tagImg?.alt} /> {el.Name}</div>
                                        )
                                    }
                                    )}
                                </div>
                                <div className="shortInfo">{ReactHtmlParser(el.Region.ShortInfo)}</div>

                            </div>
                            <div className="priceSection">
                                <div className="priceBox">
                                    {el.Prices.Strikethrough === null ?
                                        <div className="regularPrice">
                                            <p className="price">{el.Prices.Base} PLN</p>
                                            <p className="text">Cena od osoby</p>
                                        </div>
                                        :
                                        <>
                                            <div className="discount">
                                                <p className="newPrice">{el.Prices.Base} PLN</p>
                                                <p className="text">Promocyjna cena od osoby</p>
                                            </div>
                                            <p className="price">{el.Prices.Strikethrough} PLN</p>
                                            <p className="text">Cena od osoby</p>
                                        </>
                                    }
                                </div>
                                <div class="detailsBox">
                                    <p className="amountOfPeople">Liczba miejsc: {el.Rooms.AllocationsListCount}</p>
                                    {el.RibbonText === "Ostatnie wolne miejsca" || el.RibbonText === "Ostatnie miejsca" ? <p className="availability red">{el.RibbonText}</p> : <p className="availability">{el.RibbonText}</p>}
                                    <button className="primaryBtn">Zobacz szczegóły</button></div>
                            </div>
                        </div>
                    </div>)}
                )}
            </div>
        </div>

    );
}

class SailAndParty extends React.Component {
    state = {
        List: []
    }

    componentDidMount = () => {
        fetch(`${APIbackend}v1/offers/list`, {
            method: "POST",
            body: JSON.stringify({
                "siteId": 1,
                "filterType": 1,

            }),
        })
            .then((response) => response.json())
            .then((data) => this.setState({ List: data.List }),
            )


        fetch(`${APIbackend}v1/offers/filters`, {
            method: "POST",
            body: JSON.stringify({
                "siteId": 1,
            }),
        })
            .then((response) => {
                response.json().then((data) => {
                    this.setState({
                        Types: data.Types,
                    })
                });
            })
    };

    render() {
        return (
            <div id="programList">
                <FilteredTrips List={this.state.List} />
            </div>
        );
    }
}




export default SailAndParty