import React, {useState, useEffect} from "react";
import "./Formalities.scss";
import cover from "./img/formalities-cover.jpg";
import formalities from "./img/image-formalities.jpg";
import {Helmet} from "react-helmet";
import Loader from "./Loader.js";

const documentsArray = [
  {
    link: "/documents/Regulamin_TBT_29.11.2021.pdf",
    title: "Przeczytaj regulamin The Boat Trip",
    name: "Regulamin The Boat Trip",
  },
  {
    link: "/documents/WIENER_OWU-Ubezpieczeni-w-podrozy.pdf",
    title: "Przeczytaj ogólne warunki ubezpieczenia WIENER",
    name: "Ogólne warunki ubezpieczenia WIENER",
  },
  {
    link: "/documents/Druk-zgłoszenia-szkody-KL-i-NNW.pdf",
    title: "Koszty rezygnacji z imprezy OWU WIENER",
    name: "Formularz zgłoszenia szkody WIENER (w języku polskim)",
  },
  {
    link: "/documents/WIENER_OWU-Koszty-rezygnacji-z-imprezy.pdf",
    title: "Koszty rezygnacji z imprezy OWU WIENER",
    name: "Koszty rezygnacji z imprezy OWU WIENER",
  },
  {
    link: "/documents/Regulamin-Strony-Taksidi-30.08.2018.pdf",
    title: "Przeczytaj regulamin strony The Boat Trip",
    name: "Regulamin Strony The Boat Trip",
  },
  {
    link: "/documents/Polityka-Prywatnosci-The-Boat-Trip-31.07.2018.pdf",
    title: "Polityka prywatności",
    name: "Polityka prywatności The Boat Trip",
  },
  {
    link: "/documents/Licencja-Organizatora-Turystyki.png",
    title: "Licencja Organizatora Turystyki",
    name: "Licencja Organizatora Turystyki",
  },
  {
    link: "/documents/certyfikat.jpeg",
    title: "Certyfikat posiadania gwarancji ubezpieczeniowej",
    name: "Certyfikat posiadania gwarancji ubezpieczeniowej",
  },
  {
    link: "/documents/certyfikat-PIT2022.pdf",
    title: "Certyfikat członkostwa w Polskiej Izby Turystyki",
    name: "Certyfikat członkostwa w Polskiej Izbie Turystyki",
  },
];

const Formalities = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);
  return isLoading ? (
    <Loader />
  ) : (
    <div id="formalities">
      <Helmet>
        <title>Formalności</title>
        <meta name="description" content="Dokumenty do pobrania" data-react-helmet="true" />
        <meta name="keywords" content="dokumenty, formalności, pobierz, pliki, regulamin" data-react-helmet="true" />
      </Helmet>
      <div className="slider">
        <img src={cover} alt="do pobrania" />
        <h1>Formalności</h1>
      </div>
      <div className="info">
        <div className="container">
          <div className="firstSection">
            <div className="imageSection">
              <img src={formalities} alt="dokumenty do pobrania" />
            </div>
            <div className="textSection">
              <h2>Do pobrania</h2>
              {documentsArray.map(({title, name, link}) => (
                <p>
                  <a target="_blank" rel="noreferrer" href={link} title={title}>
                    {name}
                  </a>
                  <i class="arrow"></i>
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Formalities;
