import React from 'react';
import "./ReservationInfo.scss";
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import ReactHtmlParser from 'react-html-parser';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import image1 from "./img/singleReservation-image.png";
import image2 from "./img/cabin-image.png";
import image3 from "./img/yacht-image.png";


const reservationTab = [
    {
        index: 1,
        tabFor: "basic-tab-one",
        name: "Pojedyncze miejsce",
        content: [{
            image: image1,
            altImage: "rezerwacja pojedynczego miejsca na rejs",
            firstParagraf: "Jeżeli chcesz zapisać na rejs tylko siebie (albo siebie i kilku znajomych): \n 1. Wybierz opcję Rezerwuj miejsce na jachcie lub Kabinę \n 2. Wybierz Klasę Jachtu \n 3. Kliknij Rezerwuj, a następnie zaznacz ile osób chcesz zgłosić. \n 4. W kolejnym kroku podaj dane swoje i wszystkich zgłaszanych przez Ciebie osób i już! Witamy na pokładzie!",
            secondParagraf: "Zapisując się w ten sposób zostaniesz zakwaterowany w 2-osobowej kabinie (łóżko podwójne lub piętrowe), albo w salonie. Pamiętaj, że zgłaszając kilka osób, bierzesz na siebie odpowiedzialność za zarządzanie rezerwacją, pilnowanie terminów i przekazywanie wszystkich informacji znajomym."
        }]

    },
    {
        index: 2,
        tabFor: "basic-tab-two",
        name: "Kabina 2-osobowa \n <span>(dopłata 200 zł/os.)</span>",
        content: [{
            image: image2,
            altImage: "rezerwacja kabiny na rejs",
            firstParagraf: "Jeżeli jest was dwoje/dwóch/dwie i zależy Wam na gwarancji zakwaterowania w zamykanej kabinie z podwójnym łóżkiem, skorzystaj z opcji REZERWUJ MIEJSCE NA JACHCIE LUB KABINĘ, wybierz Klasę Jachtu, kliknij Rezerwuj i następnie zaznacz 'Kabina 2- osobowa'. W kolejnym kroku podaj swoje dane i dane osoby towarzyszącej* i witamy na pokładzie!",
            secondParagraf: "Jeżeli nie zależy Wam na gwarancji spania w kabinie, możecie też zarezerwować 2 pojedyncze miejsca - wciąż będziecie razem na jednym jachcie, choć może będziecie spać na łóżku piętrowym albo w salonie."
        }]

    },
    {
        index: 3,
        tabFor: "basic-tab-three",
        name: "Cały jacht",
        content: [{
            image: image3,
            altImage: "rezerwacja całego jachtu na rejs",
            firstParagraf: "Jeżeli jesteście ekipą 7-12 osób, wystarczy że skorzystasz z opcji REZERWUJ CAŁY JACHT, wybierzesz interesujący Was jacht i podasz skład Twojej załogi*! Nie musicie mieć pojęcia o żeglowaniu - przydzielimy Wam skippera, który wszystkim się zajmie!",
            secondParagraf: "I w sumie to najlepsze co możecie zrobić! Dlaczego? Bo jedziecie całą paczką znajomych, bo wybieracie konkretny jacht jaki chcecie (zamiast ogólnej Klasy) i ponieważ oszczędzacie do 120 PLN/os. w stosunku do zapisów indywidualnych!"
        }]
    }
]

const reservationStep = [
    {
        step: "1. Obierz swój kurs",
        description: "Prawdopodobnie najtrudniejsze co musisz zrobić - wybrać rejs, który Cię interesuje spośród naszej oferty. Zapoznaj się z czterema rodzajami rejsów - pomoże Ci to wybrać najlepiej dopasowany do Ciebie!",
        buttonName: "Jak zapisać sie na rejs?"
    },

    {
        step: "2. Zarezerwuj",
        description: "Korzystając z systemu na naszej stronie zapisz się na rejs. Możesz zapisać się sam, możesz też zgłosić swoich znajomych! A najlepiej zarezerwujcie sobie cały jacht i jedźcie dużą ekipą! Instrukcje poniżej!",
        buttonName: "Jak zapisać sie na rejs?"
    },

    {
        step: "3. Łap wiatr w żagle",
        description: "W tym momencie jedyne co musicie zrobić to już tylko stawić się na miejscu zbiórki - my zajmiemy się wszystkim innym! Wskakuj na pokład, postaw żagle i ciesz się urlopem swojego życia!",
        buttonName: "Jak zapisać sie na rejs?"
    }
]

const reservationInfo = {
    header: "Jak zapisać się na rejs?",
    description: "Poniżej znajdziesz wszystkie niezbędne informacje o tym jak rozpocząć wakacje Twojego życia! Udział w naszym rejsie to jedynie 3 proste kroki - tylko tyle dzieli Cię i Twoich znajomych od niezapomnianego wyjazdu!"
}


class ReservationInfo extends React.Component {

    render() {
        return (
            <div id="reservationInfo"  ref={this.props.scrollDiv}>
                <div className="container">
                    <h2>{reservationInfo.header}</h2>
                    <p>{reservationInfo.description}</p>
                    <div className="boxes">
                        {reservationStep.map((el) =>
                            <div className="oneBox">
                                <h4>{el.step}</h4>
                                <p>{el.description}</p>
                                {/* <button className="secondaryBtn">{el.buttonName}</button> */}
                            </div>
                        )}
                    </div>
                    <div className="container">
                        <h2>System zapisów na rejsy morskie z TBT</h2>
                        <p>Dla Twojej wygody stworzyliśmy prosty w obsłudze system zapisów, który pozwoli Ci w kilka chwil zarejestrować się, a następnie zapisać na wyjazd. Masz 3 opcje wyboru miejsc:</p>
                        <div className="wayOfReservation" id="notResponsive">
                            <Tabs defaultTab="basic-tab-one">
                                <TabList>
                                    {reservationTab.map((el, index) => <Tab key={index} tabFor={el.tabFor}>{ReactHtmlParser(el.name)}</Tab>)}
                                </TabList>
                                {reservationTab.map((el) => {
                                    return el.content.map((element, index) =>
                                        <TabPanel key={index} tabId={el.tabFor}>
                                            <div id="singleReservation">
                                                <div className="image">
                                                    <img src={element.image} alt={element.altImage} />
                                                </div>
                                                <div className="text">
                                                    <p style={{whiteSpace:"break-spaces", lineHeight: "1.8"}}>{element.firstParagraf}</p>
                                                    <p>{element.secondParagraf}</p>
                                                </div>
                                            </div>
                                        </TabPanel>)
                                })}
                            </Tabs>
                        </div>



                        <div className="wayOfReservation" id="responsive">
                            {reservationTab.map((el) =>
                                <Accordion allowZeroExpanded>
                                    <AccordionItem>

                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <div className="header">
                                                    {ReactHtmlParser(el.name)}
                                                </div>
                                            </AccordionItemButton>

                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <div id="singleReservation">
                                                {el.content.map((el) =>
                                                    <>
                                                        <div className="image">
                                                            <img src={el.image} alt={el.altImage} />
                                                        </div>
                                                        <div className="text">
                                                            <p style={{whiteSpace:"break-spaces"}}>{el.firstParagraf}</p>
                                                            <p>{el.secondParagraf}</p>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion >)}

                        </div>


                    </div>
                </div>
            </div>
        );
    }
}


export default ReservationInfo;




