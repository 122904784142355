import React from 'react';
import "./FamilyTrips.scss";
import "./NewFamilyTrip.scss";
import { Helmet } from "react-helmet";
import "./userRegistration/Trips.scss";
import { Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import TestimonialsWrapper from "./mainPage/TestimonialsWrapper.js";
import BFHowItWorks from "./BFHowItWorks.js";
import SecureBookingWrapper from "./mainPage/SecureBookingWrapper.js";
import DestinationWrapper from "./DestinationWrapper.js";
import BFAttractions from "./BFAttraction.js";
import OfferLiveOnYachtWrapper from "./offersite/OfferLiveOnYachtWrapper.js";
import AccommodationLp from "./AccommodationLp.js"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import InstagramFeedWrapper from "./mainPage/InstagramFeedWrapper.js"
import image1 from "./img/coverBBCroatia.jpg";
import image2 from "./img/image-formalities.jpg";
import image3 from "./img/familyTrip4.jpg";
import image4 from "./img/skippers13.jpg";



import imageYacht1 from "./img/day-on-yacht-1.jpg";
import imageYacht2 from "./img/day-on-yacht-2.jpg";
import imageYacht3 from "./img/day-on-yacht-3.jpg";
import imageYacht4 from "./img/day-on-yacht-4.jpg";
import imageYacht5 from "./img/day-on-yacht-5.jpg";

var QuestionsList = [
    {
        Question: "Nie mam pojęcia o żeglarstwie, a jachty znam tylko ze zdjęć. Czy to problem?",
        Answer: "Absolutnie nie! Przecież nie musisz umieć pilotować samolotu, żeby gdzieś polecieć. Tak samo nie musisz umieć prowadzić jachtu, żeby spędzić wakacje życia pod żaglami! Na pokładzie płynie profesjonalny skipper, czyli sternik, który zajmuje się prowadzeniem i obsługą jachtu oraz czuwa nad Twoim bezpieczeństwem i komfortem. Nie potrzebujesz żadnej żeglarskiej wiedzy."

    },
    {
        Question: "Ile osób wchodzi na jeden jacht?",
        Answer: "W zależności od długości jachtu, na pokładzie znajduje się od 3 do 5 dwuosobowych kabin. Dodatkowo są dwa miejsca do spania w mesie (salonie) - zajmują je skipper i hostessa, w standardowej ofercie nigdy nie przeznaczamy tych miejsc dla Uczestników. Na katamaranach najczęściej są 4 dwuosobowe kabiny. Podsumowując - przy założeniu wykorzystania wszystkich łóżek, załoga wliczając skippera i hostessę może liczyć 8, 10 lub 12 osób."

    },
    {
        Question: "Gdzie będę spać?",
        Answer: "Na jachcie oczywiście! Będziesz spać w wygodnej, 2-osobowej kabinie, z podwójnym lub piętrowym łóżkiem. Nie martw się o komfort - współczesny jacht można porównać do luksusowego kampera, z tą różnicą, że jest znacznie większy - ma około 14-18 m. W środku jest dużo przestrzeni i światła, można swobodnie stać i chodzić. Na jachcie jest również kilka łazienek z prysznicami i gorącą wodą."

    },
    {
        Question: "Gdzie mogę się umyć, skorzystać z toalety i podłączyć suszarkę do włosów?",
        Answer: "Na jachcie są 2-3 łazienki, z toaletą, prysznicem i gorącą wodą. Dodatkowo, w większości portów są bardzo ładne sanitariaty, z których można do woli korzystać w trakcie postoju. Na jachcie są gniazdka 230 V, jednak działają tylko, jeśli jach stoi w porcie i jest podpięty do zasilania z brzegu, lub gdy włączony jest generator lub inwerter. Samochodowe gniazdka 12 V są dostępne cały czas - dobrze zabrać odpowiednią ładowarkę lub przetwornicę!"

    },

    {
        Question: "Co z jedzeniem i gotowaniem? Jest lodówka?",
        Answer: "Na jachcie jest przynajmniej jedna duża i wydajna lodówka, czasem są też instalowane zamrażarki. Do gotowania służy 2-palnikowa kuchenka oraz piekarnik gazowy, z których można korzystać nawet w trakcie żeglugi. Na jachtach z najwyższej półki są również takie udogodnienia jak mikrofala, kostkarka do lodu czy zmywarka. Gotowaniem zajmuje się hostessa albo chętni z załogi. Warto też czasem pójść do restauracji!"

    },
    {
        Question: "Czy żeglowanie po morzu nie jest niebezpieczne? Co z chorobą morską?",
        Answer: "Żeglowanie po morzu nie jest niebezpieczne, a na pewnie nie żeglowanie w formie turystycznej. Większość firm ubezpieczeniowych nawet nie traktuje tego jako sportu. Masz odwagę pojechać na narty? Miej pewność, że na jachcie będzie bezpieczniej. Co do choroby morskiej - tak naprawdę, to zdarza się rzadko i szybko przechodzi. Wiadomo, że każdy reaguje inaczej, ale zazwyczaj jest to strach, co ma wielkie oczy."

    },
]

const programArr = [
    {
        image: imageYacht1,
        name: "Rano",
        description: "Otwierasz oczy bez budzika, słyszysz jak port dookoła powoli budzi się do życia. Jest około 9:00 - 10:00. Rozchodzi się zapach kawy przygotowywanej przez kogoś z załogi. Stoicie w zatoce na kotwicy? Wyskocz za burtę na poranne pływanie w krystalicznej wodzie!",
        altImage: "Poranek na jachcie"
    },
    {
        image: imageYacht2,
        name: "Przedpołudnie",
        description: "Po śniadaniu, kawie i gorącym prysznicu (na jachcie lub w porcie) wychodzicie w morze. Jest około 11:00. Spokojnie ze sternikiem stawiasz żagle i po 2-3 h żeglugi trafiasz do malowniczej zatoczki, gdzie cała flota spotyka się razem na wspólną kąpiel, snorkeling, drinka i lunch.",
        altImage: "Przedpołudnie na jachcie"
    },
    {
        image: imageYacht3,
        name: "Popołudnie",
        description: "Zbieracie się z kotwicowiska i znów żeglujecie. Czas na opalanie, drzemkę, książkę, ugotowanie czegoś po drodze, sesję zdjęciową, gry planszowe czy nawet naukę żeglarskich węzłów! Około 16:00 cumujesz w docelowym porcie - codziennie gdzie indziej!",
        altImage: "Popołudnie na jachcie"
    },
    {
        image: imageYacht4,
        name: "Wieczór",
        description: "Po spłynięciu do portu czas na prysznic, przebranie się ze stroju kąpielowego i zwiedzenie starówki. Po spacerze warto wpaść do lokalnej knajpy poznać tutejsze przysmaki! Można też np. wypożyczyćskutery i zwiedzić okolicę!",
        altImage: "Wieczór na jachcie"
    },
    {
        image: imageYacht5,
        name: "Noc",
        description: "Możesz posiedzieć w kokpicie i prowadzić ożywione rozmowy do rana, możesz wcześnie położyć się spać, możesz pójść na tańce! Dzięki temu, że każdy ma swoją kabinę, nic Cię nie ogranicza, możesz w sumie robić co chcesz. To Twoje wakacje, pamiętasz? ",
        altImage: "Noc na jachcie"
    }
]

class FamilyTrips extends React.Component {


    render() {
        return (
            <div id="familyTrips">
                <Helmet>
                    <title>Rejsy prywatne</title>
                    <meta name="description"
                        content="Organizacja wyjazdów na rejsy prywatne | Lato 2022 - TheBoatTrip"
                        data-react-helmet="true" />
                        <meta name="keywords" content="rodzina, wyjazd, rejs, morski, rodzinny, katamaran, jacht" data-react-helmet="true" />
                </Helmet>
                <div className="slider" style={{ height: "400px" }}>
                    <img src={image1} alt="Rejsy prywatne"/>
                    <h1>Rejsy prywatne</h1>
                </div>
                <div className="info" >
                    <h3 style={{ paddingTop: "70px" }}>Bezpieczne wakacje na katamaranie</h3>
                    <p className="generalInfo">
                        Jeśli szukasz opcji na niezapomniany urlop z dala od tłumów to pomyśl o wakacjach na ekskluzywnym jachcie lub katamaranie:</p>
                    <div className="tags">
                        <div className="oneTag">
                            <img src={image2} alt="Nowe łódki" ></img>
                            <p>Pływamy na nowych i w pełni wyposażonych jednostkach, które <span>zapewnią Wam komfort</span> porównywalny z luksusowym apartamentem.</p>
                        </div>
                        <div className="oneTag">

                            <img src={image3} alt="Trasy rejsów"></img>
                            <p>Dobieramy trasy tak, żeby pokazać Wam crème de la crème każdej destynacji dając jednocześnie opcję <span>dopasowania planu do Waszych potrzeb</span>.</p>
                        </div>
                        <div className="oneTag">

                            <img src={image4} alt="Profesjonalni sternicy"></img>
                            <p><span>Zapewniamy obsługę profesjonalnych sterników</span>, którzy zadbają o bezpieczeństwo żeglugi, a także doradzą co warto zobaczyć, czy gdzie dobrze zjeść.</p>
                        </div>
                    </div>
                    <TestimonialsWrapper />
                    <BFHowItWorks />
                    <OfferLiveOnYachtWrapper />
                    <AccommodationLp />


                    <SecureBookingWrapper />
                    <DestinationWrapper />
                    <BFAttractions />



                    <div id="program">
                        <div className="container">
                            <div className="mainText">
                                <h2>Przykladowy dzień na jachcie</h2>
                                <p>Jeżeli nigdy nie spędzałeś (lub spędzałaś) urlopu na jachcie, to z pewnością masz mnóstwo pytań! Specjalnie dla Ciebie przygotowaliśmy poniżej krótki opis jak to wygląda!</p>
                            </div>
                            <div className="dayPlan">
                                {programArr.map((el) =>
                                    <div className="oneBox">
                                        <div className="photoContainer">
                                            <img src={el.image} alt={el.altImage}></img>
                                        </div>
                                        <div className="textBox">
                                            <h4>{el.name}</h4>
                                            <p>{el.description}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>


                    </div>



                    <div className="container">
                        {/* {familyTripArr.map((el) =>
                            <div className="firstSection">
                                <div className="imageSection">
                                    <img src={require(`${el.img}`).default} alt={""} />
                                </div>
                                <div className="textSection">
                                    {ReactHtmlParser(el.text)}
                                </div>
                            </div>
                        )} */}
                        <h2>Częste pytania</h2>
                        <Accordion allowZeroExpanded>

                            {QuestionsList.map((el) =>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            {ReactHtmlParser(el.Question)}
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        {ReactHtmlParser(el.Answer)}
                                    </AccordionItemPanel>
                                </AccordionItem>

                            )

                            }
                        </Accordion >
                    </div>

                    <div id="paymentsIncluded" >
                        <div className="container price" >
                            <h2>Co zawiera cena?</h2>
                            <div className="boxes">
                                <div className="oneBox priceInclude">
                                    <div className="titleContainer"><h4>Cena zawiera zawsze</h4></div>
                                    <div className="elements">
                                        <p>Czarter jachtu</p>
                                        <p>Usługi profesjonalnego skippera</p>
                                        <p>Starter pack (pościel, ręczniki, sprzątanie końcowe, transit log)</p>
                                        <p>Ponton z silnikiem</p>
                                        <p>Pełne ubezpieczenie kaucji (nie zawiera zatkanych toalet)</p>
                                        <p>Ubezpieczenie turystyczne KL i NNW</p>
                                        <p>Składkę na Turystyczny Fundusz Gwarancyjny</p>
                                        <p>Składkę na Turystyczny Fundusz Zwrotów</p>
                                        <p>Transport skippera z Polski i do Polski</p>
                                    </div>
                                    <div className="infoBox">
                                        <p>Zaliczka wynosi 100 PLN zapewniamy możliwość bezskosztowej rezygnacji na 30 dni roboczych przed rejsem.</p>
                                    </div>

                                </div>
                                <div style={{ display: "block" }}>
                                    <div className="oneBox priceNotInclude" style={{ width: "100%", marginBottom: "50px" }}>
                                        <div className="titleContainer"><h4>Cenia nie zawiera</h4></div>
                                        <div className="elements">
                                            <p>Dojazdu</p>
                                            <p>Taksy turystycznej ok. 10 eur/osoba/tydzień</p>
                                            <p>Opłat za porty i paliwo do jachtu</p>
                                            <p>Wyżywienia</p>
                                            <p>(UWAGA - na rejsie obowiązuje zasada "załoga żywi skippera")</p>
                                        </div>

                                    </div>
                                    <div className="oneBox priceNotInclude" style={{ width: "100%" }}>
                                        <div className="titleContainer"><h4>Harmonogram płatności</h4></div>
                                        <div className="elements">
                                            <p>Dojazdu</p>
                                            <p>I rata - 30% w ciągu 24 h od rezerwacji</p>
                                            <p>II rata - drugie 30% ceny 60 dni przed rejsem</p>
                                            <p>III rata - pozstałe 40% ceny 30 dni przed rejsem</p>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <InstagramFeedWrapper />
                    <div style={{ marginTop: "0px", marginBottom: "50px", textAlign: "center" }}>
                        <p className="infoBF" style={{ marginTop: "0" }}>Jeśli chcesz żebyśmy zorganizowali Ci rejs "szyty na miarę", skontaktuj się z Obsługą Klienta.</p>
                        <Link to="/kontakt" target={"_blank"} ><button className="secondaryBtn">Kontakt</button></Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default FamilyTrips;

