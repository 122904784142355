import React, { useEffect } from 'react';
import "./StepFour.scss";
import "./TripBox.scss";
import ReactHtmlParser from 'react-html-parser';
import Moment from 'moment';
import {IAmForAnalitics} from "./utilsApp";
import calendarImage from "./img/calendar.png";

const TripBox = (props) => {
    return (
        <div id="tripBox">
            <div>
                <div className="imageContainer">
                    <img src={props.Reservation.Offer.ListImg?.url} alt={props.Reservation.Offer.ListImg?.alt} />
                </div>
                <div className="textBox">
                    <h4>{props.Reservation.Offer.Name}</h4>
                    <p className="date"><img src={calendarImage} alt="termin" />{Moment(props.Reservation.Offer.From).format('DD.MM') + "-" + Moment(props.Reservation.Offer.To).format('DD.MM.YYYY')}</p>
                    <p className="price">{props.Reservation.Offer.CurrentPrice} PLN</p>
                    <p className="postscript">Łączna cena Twojego wyjazdu</p>
                        {props.Reservation.Offer.CurrentRebate !== 0 ? 
                    <>
                    {/* <p className="postscript">Cena do zapłaty po uwzględnieniu rabatu</p> */}
                    <p className="rebate"><span style={{fontFamily: "SpaceGrotesk-SemiBold"}}>W tym rabat, na który składa się:</span>
                        {props.Reservation.Offer.RebatesList.map(el =>
                            <p className="rebate">{el.Name}:   
                            <span> {el.Value + " PLN"}</span></p>
                        )}
                    </p>
                    </> : null }
                </div>
            </div>
            {props.Reservation.Data[1].Config.IsReserveList === 1 && <div className="orangeBox" id="infoReservationListInStep"><p>UWAGA: Zapisujesz się na listę rezerwową</p></div>}
         
        </div>
    );
}



const OnePerson = (props) => {

    const StepFourArr = props.Reservation.Data.find((el) => el.Step === 4);

    return (


        <div className="container">

            {props.userNum === 1 ?
                <div className="onePerson">
                    <div className="name">
                        <p>{props.userNum}. Uczestnik (Ty)</p>
                    </div>
                    

                    {props.trainingId !== "" &&
                        <div id="trainingLevel" className="selectRadio">
                            <h3>Poziom</h3>
                            <div className="boxes">
                                {StepFourArr.TrainingsList.map((el) =>
                                    props.trainingId === el.TrainingId ?
                                        <>
                                            {el.OptionsList.map((el) =>
                                                <label className="boxInputRadio"><p className="titleText">{el.Name}</p>
                                                    <p className="description">{el.Description}</p>
                                                    <p className="price">{el.Price}</p>
                                                    <input type="radio" name="level" onChange={(event) => props.handleChange11(props.trainId, event)} value={el.TrainingOptionId} checked={props.trainingOptionId === el.TrainingOptionId}></input>
                                                    <span className="checkmarkRadio"></span>
                                                    <div className="boxAfterClick"></div>
                                                </label>
                                            )}
                                        </>
                                        : null
                                )}
                            </div>
                        </div>
                    }
                    {StepFourArr.AddonsList.length > 0 ? <div id="clothes" className="selectRadio">
                        <h3>Ciuchy</h3>
                        
                        <div className="boxes">
                            {StepFourArr.AddonsList.map((el) => {
                                const addonImage = el.Image;
                                return (
                            !el.Name.includes("Transport") &&
                                <label className="boxInputRadio"><p className="titleText">{el.Name}</p>
                                    <div className="imageTraining">
                                            <img src={addonImage?.url} alt={addonImage?.alt} />
                                        </div>
                                    
                                    <p className="description">{ReactHtmlParser(el.Details)}</p>
                                    {/* <a href="/" rel="noopener noreferrer">{el.link}</a> */}
                                    {el.Price === "0" || el.Price === 0? 
                                    <p className="price">GRATIS</p> :
                                    <p className="price">{el.Price} PLN <br /><span>za osobę</span></p>}
                                    <input type="radio" name={props.addid} onChange={(event) => props.handleChange12(props.addid, event)} value={el.AddonId} checked={props.addonId === el.AddonId}></input>
                                    <span className="checkmarkRadio"></span>
                                    <div className="boxAfterClick"></div>
                                </label>)}
                            )}
                            {/* <label id="notInterested" className="boxInputRadio"><p className="titleText">Nie jestem zainteresowany</p>

                                <input type="radio" name={props.addid} onChange={(event) => props.handleChange12(props.addid, event)} value="" checked={props.addonId === ""}></input>
                                <span className="checkmarkRadio"></span>
                                <div className="boxAfterClick"></div>
                            </label> */}

                        </div>
                        <p style={{marginTop: "20px"}}>Wiecej informacji o naszych bluzach znajdziesz <a href="https://offcoursewear.pl" title="Sprawdź naszą markę odzieżową OffCourse!" target="_blank" rel="noreferrer" style={{fontFamily: 'SpaceGrotesk-SemiBold'}} >pod tym linkiem</a>.</p>
                        <div className="containerSelect">
                            
                            {StepFourArr.AddonsList.length > 0 && StepFourArr.AddonsList.map((el) =>
                                props.addonId === el.AddonId ?
                                    <>
                                             <p>Rozmiar {el.Name}</p>
                                            <select style={props.messageSizeAddon !== "" ? {border: "2px solid #de1212"} : null} name={props.addid} onChange={(event) => props.handleChange13(props.addid, event)} >
                                            <option selected="true" disabled="disabled">Wybierz z listy</option>
                                                {
                                                    el.OptionsList.map((el) =>
                                                        <option value={el.AddonOptionId} seleted={props.addonOptionId === el.AddonOptionId}>{el.Name}</option>
                                                    )
                                                }
                                            </select>
                                            <p style={{color: "#de1212"}}>{props.messageSizeAddon}</p>
                                    </> : null
                            )}
                        </div>
                    </div>
                     : <div id="clothes" className="selectRadio">
                     <h3>Brak opcji dodatkowych</h3>
                     <p style={{marginTop: "20px"}}>Na tym wyjeździe nie oferujemy opcji dodatkowych</p>
                     
                 </div>}


                </div>

                :

                <div className="container">

                    <div className="onePerson">
                        <div className="name">
                        <p>{props.userNum}. {props.valueName !== "" ? props.valueName : "Uczestnik"} {props.valueSurname !== "" ? props.valueSurname : ""} </p>
                        </div>
                        {/* <div id="training" className="selectRadio"> */}
                            {/* <h3>Szkolenie</h3> */}
                            {/* <div className="boxes"> */}
                                {/* {StepFourArr.TrainingsList.map((el) => */}
                                     {/* <label className="boxInputRadio"><p className="titleText">{el.Name}</p> */}
                                        {/* <div className="imageTraining">
                                            <img src={require(`${el.image}`).default} alt={""} />
                                        </div> */}
                                        {/* <p className="description">{el.Description}</p> */}
                                        {/* <a href="/" rel="noopener noreferrer">{el.link}</a> */}
                                        {/* <p className="price">{el.Price} PLN <br /><span>za osobę</span></p>
                                        <input type="radio" name={props.trainId} onChange={(event) => props.handleChange10(props.trainId, event)} value={el.TrainingId} checked={props.trainingId === el.TrainingId}></input>

                                        <span className="checkmarkRadio"></span>
                                        <div className="boxAfterClick"></div>
                                    </label> */}
                                {/* )} */}
                                {/* <label id="notInterested" className="boxInputRadio"><p className="titleText">Nie jest zainteresowany</p>

                                    <p className="description"></p>

                                    <input type="radio" name={props.trainId} onChange={(event) => props.handleChange10(props.trainId, event)} value="" checked={props.trainingId === ""}></input>
                                    <span className="checkmarkRadio"></span>
                                    <div className="boxAfterClick"></div>
                                </label>

                            </div> */}
                        {/* </div> */}

                        {/* {props.trainingId !== "" &&
                            <div id="trainingLevel" className="selectRadio">
                                <h3>Poziom</h3>
                                <div className="boxes">
                                    {StepFourArr.TrainingsList.map((el) =>
                                        props.trainingId === el.TrainingId ?
                                            <>
                                                {el.OptionsList.map((el) =>
                                                    <label className="boxInputRadio"><p className="titleText">{el.Name}</p>
                                                        <p className="description">{el.Description}</p>
                                                        <p className="price">{el.Price}</p>
                                                        <input type="radio" name={props.id} onChange={(event) => props.handleChange11(props.trainId, event)} value={el.TrainingOptionId} checked={props.trainingOptionId === el.TrainingOptionId}></input>
                                                        <span className="checkmarkRadio"></span>
                                                        <div className="boxAfterClick"></div>
                                                    </label>
                                                )}
                                            </>
                                            : null
                                    )}
                                </div>
                            </div>
                        } */}
                        {StepFourArr.AddonsList.length > 0 ? <div id="clothes" className="selectRadio">
                            <h3>Ciuchy</h3>
                            <div className="boxes">
                                {StepFourArr.AddonsList.map((el) => {
                                    const addonImage = el.Image;
                                    return (
                                !el.Name.includes("Transport") &&
                                    <label className="boxInputRadio"><p className="titleText">{el.Name}</p>
                                        <div className="imageTraining">
                                            <img src={addonImage?.url} alt={addonImage?.alt} />
                                        </div>
                                        <p className="description">{ReactHtmlParser(el.Details)}</p>
                                        {el.Price === 0 || el.Price === "0" ?
                                        <p className="price">GRATIS</p> :
                                        <p className="price">{el.Price} PLN <br /><span>za osobę</span></p>}
                                        <input type="radio" name={props.addid} onChange={(event) => props.handleChange12(props.addid, event)} value={el.AddonId} checked={props.addonId === el.AddonId}></input>
                                        <span className="checkmarkRadio"></span>
                                        <div className="boxAfterClick"></div>
                                    </label>)}
                                )}
                                {/* <label id="notInterested" className="boxInputRadio"><p className="titleText">Nie jest zainteresowany</p>

                                    <input type="radio" name={props.id} onChange={(event) => props.handleChange12(props.addid, event)} value="" checked={props.addonId === ""}></input>
                                    <span className="checkmarkRadio"></span>
                                    <div className="boxAfterClick"></div>
                                </label>
                          */}
                            </div>

                            <div className="containerSelect">
                                {StepFourArr.AddonsList.length > 0 && StepFourArr.AddonsList.map((el) =>
                                    props.addonId === el.AddonId ?
                                        <>
                                            <p>Rozmiar</p>
                                            <select tyle={props.messageSizeAddon !== "" ? {border: "2px solid #de1212"} : null} name={props.addid} onChange={(event) => props.handleChange13(props.addid, event)} >
                                            <option selected="true" disabled="disabled">Wybierz z listy</option>
                                                {
                                                    el.OptionsList.map((el) =>
                                                        <option value={el.AddonOptionId} seleted={props.addonOptionId === el.AddonOptionId}>{el.Name}</option>
                                                    )
                                                }
                                            </select>
                                            <p style={{color: "#de1212"}}>{props.messageSizeAddon}</p>
                                        </> : null
                                )}
                            </div>
                        </div>
                        : <div id="clothes" className="selectRadio">
                        <h3>Brak opcji dodatkowych</h3>
                        <p style={{marginTop: "20px"}}>Na tym wyjeździe nie oferujemy opcji dodatkowych</p>
                        
                    </div>}


                    </div>
                </div>
            }
        </div>




    );
}

const StepFour = (props) => {
    let dateTrip = props.Reservation.Offer.From;
    let year = dateTrip.slice(0, 4);
    let month = dateTrip.slice(5, 7);
    let day = dateTrip.slice(8, 10);

    const firstRate = new Date(year, month - 1, day);
    firstRate.setDate(firstRate.getDate() - 14);
    let dayFirstRate = firstRate.getDate() < 10 ? '0' + firstRate.getDate() : firstRate.getDate();
    let monthFirstRate = firstRate.getMonth() < 9 ? '0' + (firstRate.getMonth() + 1) : firstRate.getMonth() + 1;
    const dateAttrChoice = dayFirstRate + "." + monthFirstRate

    useEffect(()=> {
        IAmForAnalitics();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
 
    return (
        <>
        
        <div id="stepFour" style={{position: "relative"}}>
        {!props.buttonAvailableStepFour && <div id="stepMask"></div>}
            <div className="container">
                <div className="greenBox">
                    <p className="infoTitle">Pamietaj, aby wybrać dodatki do {dateAttrChoice}</p>
                    <p>Tu możesz zamówić sobie odzież i dodatkowe gadżety z dostawą na event. Pamiętaj, że musisz je zamówić najpóźniej na 2 tygodnie przed startem wycieczki.</p>
                    <button className="secondaryBtn" disabled={props.buttonAvailableStepFour ? "" : "disabled" } onClick={props.handleGoToStepFive}>Przechodzę dalej, wybiorę dodatki później</button>
                </div>
                {(props.trainingsConfig).map((el, index) => <OnePerson key={index + 1} id={el.id}
                messageSizeAddon={props.messageSizeAddon}
                accommodationOption={props.accommodationOption} accommodationConfig={props.accommodationConfig} accommodationConfigNew={props.accommodationConfigNew}
                    reservationId={props.reservationId} Reservation={props.Reservation} handleChange11={props.handleChange11}
                    handleChange12={props.handleChange12} handleChange10={props.handleChange10} handleChange13={props.handleChange13}
                    trainingsConfig={props.trainingsConfig} trainingId={el.trainingsSelected.trainingId}
                    trainId={el.trainingsSelected.id} addid={el.addonsSelected.id}  
                    valueName={props.accommodationConfig.length === 0 || props.accommodationConfig === [] ? "" : (props.accommodationConfig.length !== 0 || props.accommodationConfig !== []) && props.accommodationOption === "whole" ?  props.accommodationConfigNew[props.accommodationConfigNew.findIndex((el) => el.id === index + 1)].valueName : (props.accommodationConfig.length !== 0 || props.accommodationConfig !== []) && (props.accommodationOption === "single" || props.accommodationOption === "cabin") ? props.accommodationConfig[props.accommodationConfig.findIndex((el) => el.id === index + 1)].valueName : null}
                    valueSurname={props.accommodationConfig.length === 0 || props.accommodationConfig === [] ? "" : (props.accommodationConfig.length !== 0 || props.accommodationConfig !== []) && props.accommodationOption === "whole" ? props.accommodationConfigNew[props.accommodationConfigNew.findIndex((el) => el.id === index + 1)].valueSurname : (props.accommodationConfig.length !== 0 || props.accommodationConfig !== []) && (props.accommodationOption === "single" || props.accommodationOption === "cabin") ? props.accommodationConfig[props.accommodationConfig.findIndex((el) => el.id === index + 1)].valueSurname : ""}
                    trainingOptionId={el.trainingsSelected.trainingOptionId} addonId={el.addonsSelected.addonId}
                    addonOptionId={el.addonsSelected.addonOptionId} userNum={el.userNum}
                />
                )}
                <p className="errorMessage">{props.messageStepFour}</p>
                <div className="containerButtons">
                    <button className="secondaryBtn" onClick={props.handleBackToStepThree}>Wstecz</button>
                    <button className="primaryBtn" disabled={props.buttonAvailableStepFour ? "" : "disabled" } id={props.buttonAvailableStepFour ? null : "disabledButton"} onClick={props.handleUpdateStepFour}>Dalej</button>
                    {!props.buttonAvailableStepFour && <div class="lds-roller">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                        }
                </div>


            </div>
            <TripBox Reservation={props.Reservation} />
        </div>
    </>
    );
}

export default StepFour;
