import React from "react";
import "./YourTrips.scss";
import TripStatusMenu from "./TripStatusMenu.js";
import Tourdetails from "./TourDetails.js";

const YourTrips = (props) => {
  const {
    tourDetails,
    yourtripsActive,
    isLoading,
    Reservations,
    reservationId,
    handleClick,
    showYourTrips,
    ReservationHistory,
    showDetailsLastTrip,
    handleCreateGroupCode,
    trueMessage,
    isGroupCodeJoinSection,
    isGroupCodeCreateSection
  } = props;
  return (
    <>
      {tourDetails && <Tourdetails {...props} />}
      {yourtripsActive && (
        <div id="yourTrips">
          <h2>Twoje wyjazdy</h2>
          <TripStatusMenu
            isGroupCodeJoinSection={isGroupCodeJoinSection}
            isGroupCodeCreateSection={isGroupCodeCreateSection}
            isLoading={isLoading}
            Reservations={Reservations}
            reservationId={reservationId}
            handleClick={handleClick}
            showYourTrips={showYourTrips}
            ReservationHistory={ReservationHistory}
            showDetailsLastTrip={showDetailsLastTrip}
            handleCreateGroupCode={handleCreateGroupCode}
            trueMessage={trueMessage}
          />
        </div>
      )}
    </>
  );
};
export default YourTrips;
