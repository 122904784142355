import React from "react";

const OfferResidencesPopup = ({
  handleClose,
  content,
  images0a,
  images0b,
  images1a,
  images1b,
  images2b,
  images3b,
  images3a,
  images2a,
  images4a,
  images4b,
  name,
}) => {
  return (
    <div className="popup-box">
      <div className="box">
        <span className="close-icon" onClick={handleClose}>
          x
        </span>
        <div className="popupContent">
          <p className="popup-title">{name}</p>
          <p>{content}</p>
        </div>
        <div className="popupImgGallery">
          <div className="ColageImages">
            <div className="image" onClick={images0a}>
              <img src={images0b?.url} alt={images0b?.alt} />
            </div>
            <div className="image" onClick={images1a}>
              <img src={images1b?.url} alt={images1b?.alt} />
            </div>
            <div className="image" onClick={images2a}>
              <img src={images2b?.url} alt={images2b?.alt} />
            </div>
            <div className="image" onClick={images3a}>
              <img src={images3b?.url} alt={images3b?.alt} />
            </div>
          </div>
          <div className="PlanImage image" onClick={images4a}>
            <img src={images4b?.url} alt={images4b?.alt} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferResidencesPopup;
