import React from "react";
import "./InfoDestination.scss";

const InfoDestination = ({ infoDestination }) => {
  const info = infoDestination?.map(({ Contents }) =>
    Contents.Text.split("\r\n")
  )[0];
  const images = infoDestination?.map(({ Contents }) => Contents.Images[0])[0];

  return (
    <div id="infoDestination">
      <div className="container">
        <div className="firstSection">
          <div className="textSection">
            <h2>{info?.[0]}</h2>
            <p>{info?.[1]}</p>
            <p>{info?.[2]}</p>
          </div>
          <div className="imageSection">
            <img src={images?.url} alt={images?.alt} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoDestination;
