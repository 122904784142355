import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "./CruiseProgram.scss";
import "./CruiseCarousel.scss";
import partyIcon from "./img/party-icon.png";
import partyIconHover from "./img/party-icon-hover.png";
import exploreIcon from "./img/explore-icon.png";
import exploreHoverIcon from "./img/explore-icon-hover.png";
import adventureIcon from "./img/adventure-icon.png";
import adventureHoverIcon from "./img/adventure-icon-hover.png";
import chillImage from "./img/chill-icon.png";
import chillHoverImage from "./img/chill-icon-hover.png";

const CruiseCarouselArr = [
  {
    image: partyIcon,
    imageHover: partyIconHover,
    program: "Sail & Party",
    info: "Idealny wybór, jeżeli chcesz łagodnie wejść w świat żagli, wyszaleć się na imprezach i szukasz dużego eventu!",
    btn: "Imprezuj",
    title: "Sprawdź szczegóły rejsów realizowanych w formule Sail & Party",
    altImage: "Rejsy morskie w formule Sail & Party",
    linkTo: "/rejsy-morskie?type=1&"
  },

  {
    image: exploreIcon,
    imageHover: exploreHoverIcon,
    program: "Sail & Explore",
    info: "Jeżeli chcesz poszerzyć horyzonty, spędzić wakacje, jakich jeszcze nie miałeś, to są to rejsy dla Ciebie",
    btn: "Zwiedzaj",
    title: "Sprawdź szczegóły rejsów realizowanych w formule Sail & Explore",
    altImage: "Rejsy morskie w formule Sail & Explore",
    linkTo: "/rejsy-morskie?type=2&"
  },

  {
    image: adventureIcon,
    imageHover: adventureHoverIcon,
    program: "Sail & Adventure",
    info: "Oferta dla prawdziwych miłośników mocnych wrażeń, spełnienie marzeń o przygodzie życia!",
    btn: "Odkrywaj",
    title: "Sprawdź szczegóły rejsów realizowanych w formule Sail & Adventure",
    altImage: "Rejsy morskie w formule Sail & Adventure",
    linkTo: "/rejsy-morskie?type=3&"
  },

  {
    image: chillImage,
    imageHover: chillHoverImage,
    program: "Sail & Chill",
    info: "Ten rejs to mniej żeglowania, akweny z małą falą i nieduże odległości - w zamian za to zyskujesz dużo relaksu",
    btn: "Odpoczywaj",
    title: "Sprawdź szczegóły rejsów realizowanych w formule Sail & Chill",
    altImage: "Rejsy morskie w formule Sail & Chill",
    linkTo: "/rejsy-morskie?type=4&"
  },
];

const CruiseCarousel = ({ Page, findHTMLPageHTML }) => {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 770,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let nodeVal = Page?.Contents ? findHTMLPageHTML[0].Contents.HTML.split("</p>") : null;

  return (
    <div className="containerCruiseCarousel">
      <Slider {...settings}>
        {CruiseCarouselArr.map(({ altImage, imageHover, image, linkTo, title, btn }, index) => (
          <div className="oneCruiseProgramBox" key={index}>
            <img className="image" src={image} alt={altImage} />
            <img className="imageHover" src={imageHover} alt={altImage} />
            <p>{Page?.Contents ? nodeVal[index].replace("<p>", "") : null}</p>
            <Link
              to={linkTo}
              title={title}
            >
              <button className="primaryBtn">{btn}</button>
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CruiseCarousel;
