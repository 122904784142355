import React from "react";
import "./YachtTable.scss";
import image1 from "./img/premier-1.jpg";
import image2 from "./img/premier-2.jpg";
import image3 from "./img/premier-3.jpg";
import image4 from "./img/superior-1.jpg";
import image5 from "./img/superior-2.jpg";
import image6 from "./img/superior-3.jpg";

const yachtTableArr = [
  {
    imageClass: image1,
    class: "Jacht Premier",
    description:
      "Znajdziesz tu 10 osobowe jachty o długości ok. 13-14 metrów  z 4 kabinami 2-os.",
    imageBoat: image2,
    descriptionBoat:
      "Każdy jacht ma 2-3 łazienki z prysznicem, przestronny salon (gdzie śpią 2 osoby na rozkładanym łóżku) oraz w pełni wyposażoną kuchnię. Kluczowe cechy to: szeroki kokpit, platforma kąpielowa, najczęściej elektryczne WC, sound system, ładowanie USB (prąd 220V dostępny po zacumowaniu w porcie).",
    imageSittingRoom: image3,
    descriptionRoom:
      "Przykładowe jednostki w tej klasie: Sun Odyssey 440, Oceanis 45, Bavaria 46, Elan 45, Hanse 455, Dufour 460",
    altClass: "Jacht Premier",
    altBoat: "Salon na Jachcie Premier",
    altSittingRoom: "Sypialnia na Jachcie Premier",
  },
  {
    imageClass: image4,
    class: "Jacht Superior",
    description:
      "Znajdziesz tu 12 osobowe jachty o długości ok. 15 metrów  z 5 kabinami 2-os. (czasem też dodatkowo mini kabiną 1-os. na dziobie).",
    imageBoat: image5,
    descriptionBoat:
      "Każdy jacht ma min. 3 łazienki z prysznicem, przestronny salon (gdzie śpi  1-2 osoby na rozkładanym łóżku) oraz w pełni wyposażoną kuchnię. Kluczowe cechy to: szeroki kokpit, duża platforma kąpielowa, najczęściej elektryczne WC, sound system, ładowanie USB (prąd 220V dostępny po zacumowaniu w porcie).",
    imageSittingRoom: image6,
    descriptionRoom:
      "Przykładowe jednostki w tej klasie: Sun Odyssey 519, Oceanis 51.1  ; Bavaria 51, Elan 50, Hanse 508, Dufour 520",
    altClass: "Jacht Superior",
    altBoat: "Salon na Jachcie Superior",
    altSittingRoom: "Sypialnia na Jachcie Superior",
  },
];

class YachtTable extends React.Component {
  state = {};

  render() {
    return (
      <div id="yachtTableSection">
        <h2>Wybierz klasę jachtu</h2>
        <p style={{ width: "800px" }}>
          Nasze 3 klasy łódek: Premier (4★), Superior (4★+) i Luxury (5★) różnią
          się głównie wielkością jednostki, co przekłada się bezpośrednio na
          ilość miejsca i w konsekwencji Twój komfort w części hotelowej.
          Wyposażenie i wiek jednostek między klasami są do siebie zbliżone.
          Wszystkie są względnie nowe (max. 5 letnie) i przewyższają standardową
          jakość łódek oferowanych przez innych organizatorów. Poniżej
          znajdziesz bardziej szczegółową specyfikę każdej klasy z dużą ilością
          przykładowych zdjęć i modelami łódek jakie mogą się tam znaleźć:
        </p>
        <div className="row">
          {yachtTableArr.map((el) => (
            <div className="column">
              <div className="sectionClass">
                <div className="image">
                  <img src={el.imageClass} alt={el?.altClass} />
                </div>
                <h4>{el.class}</h4>
                <p>{el.description}</p>
              </div>
              <div className="sectionGeneralInfo">
                <div className="image">
                  <img src={el.imageBoat} alt={el?.altBoat} />
                </div>
                <p>{el.descriptionBoat}</p>
              </div>
              <div className="sectionSittingRoom">
                <h5>Przykładowe jednostki</h5>
                <div className="image">
                  <img src={el.imageSittingRoom} alt={el?.altSittingRoom} />
                </div>
                <p>{el.descriptionRoom}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default YachtTable;
