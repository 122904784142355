import React from "react";
import "./OfferSchedule.scss";
import ReactHtmlParser from "react-html-parser";
import Logo from "../img/manufaktura-przygody-tbt.jpg";

const Schedule = ({offerDailyPlan, offerType, offerDetails}) => {
  return (
    <>
      {offerType.OfferTypeId === "5" ? (
        <div className="OfferScheduleContainer" id="academy">
          <h2>{offerDailyPlan.Header}</h2>
          <div className="academyBox">
            <p className="schedule">{ReactHtmlParser(offerDailyPlan.Description)}</p>
          </div>
        </div>
      ) : (
        <div className="OfferScheduleContainer">
          <h2 style={{textAlign: "center", marginTop: "50px"}}>{offerDailyPlan.Header}</h2>
          <img src={Logo} alt="Logo" />
          <h3>Dla dzieci:</h3>
          {offerDailyPlan.List.map(({Name, Description}, index) => (
            <div className="ScheduleRow">
              <div className="ScheduleColumnLeft">
                {index % 2 === 0 && (
                  <div className="ScheduleItem">
                    <p>{ReactHtmlParser(Description)}</p>
                  </div>
                )}
              </div>
              <div className="ScheduleColumnMiddle">
                <div className="OfferCircleBox">
                  {index > 0 && <div className="itemLine"></div>}
                  <span>{Name}</span>
                </div>
              </div>
              <div className="ScheduleColumnRight">
                {index % 2 === 1 && (
                  <div className="ScheduleItem">
                    <p>{ReactHtmlParser(Description)}</p>
                  </div>
                )}
              </div>
            </div>
          ))}
          {offerDetails.OfferId !== "31" && <p className="schedule">{ReactHtmlParser(offerDailyPlan.Description)}</p>}
        </div>
      )}
    </>
  );
};

export default Schedule;
