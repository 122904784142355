import React from "react";
import ReactPlayer from "react-player";
import "./OfferMovie.scss";

const Movie = ({offerDetails}) => (
    <>
      {offerDetails.Movie === null ? (
        <div className="sectionMovieWrapper" style={{paddingTop: "150px"}}></div>
      ) : (
        <div className="sectionMovieWrapper">
          <div className="movieWrapper">
            <ReactPlayer
              className="react-player"
              url={offerDetails.Movie.url}
              playing="true"
              loop="true"
              width="100%"
              height="70%"
              muted="true"
              controls="true"
            />
          </div>
        </div>
      )}
    </>
  );

export default Movie;
