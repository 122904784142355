import React, {Component} from "react";

import OfferSiteContent from "./OfferSiteContent.js";
import {DataDostawca} from "./DataDostawca.js";
import {connect} from "react-redux";
import {fetchData} from "../store/actions";
import {getOfferSelector} from "../store/selectors";
import {getOfferCachedUrlName} from "../cachedStoreUrls";

export const prefetchMethod = (dispatch, match) => {
  const offerId = match.params.offerId;
  const cachedUrlName = getOfferCachedUrlName(offerId);
  return dispatch(
    fetchData(
      "/v1/offers/details",
      {
        siteId: 1,
        offerId: offerId,
      },
      cachedUrlName
    )
  );
};

const enhance = connect(
  (store, ownProps) => {
    const offerId = ownProps.match.params.offerId;
    const cachedUrlName = getOfferCachedUrlName(offerId);

    return {
      loadedData: {
        ...getOfferSelector(store, cachedUrlName),
        loading: false,
      },
      offerId,
      cachedUrlName,
      dataLoaded: store.urlStatus[cachedUrlName] === "done",
    };
  },
  {fetchData}
);

class OfferSite extends Component {
  elementToScroll = {};

  componentDidMount = () => {
    this.props.fetchData(
      "/v1/offers/details",
      {
        siteId: 1,
        offerId: this.props.offerId,
      },
      this.props.cachedUrlName
    );
  };

  scrollToElement = (name) => {
    if (this.elementToScroll[name]) {
      this.elementToScroll[name].scrollIntoView({behavior: "smooth"});
    }
  };

  setElementToScroll = (name) => (element) => {
    this.elementToScroll = {
      ...this.elementToScroll,
      [name]: element,
    };
  };

  render() {
    return (
      <div>
        {this.props.dataLoaded && (
          <DataDostawca.Provider
            value={{
              ...this.props.loadedData,
              scrollToElement: this.scrollToElement,
              setElementToScroll: this.setElementToScroll,
            }}
          >
            <OfferSiteContent {...this.props.loadedData} />
          </DataDostawca.Provider>
        )}
      </div>
    );
  }
}

OfferSite.prefetchMethod = prefetchMethod;

export default enhance(OfferSite);
