import React from "react";
import "./WorkForSkipper.scss";
import Contact from "./Contact.js";
import ReactHtmlParser from "react-html-parser";
import { fetchData } from "./store/actions";
import { workCachedUrl } from "./cachedStoreUrls";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

export const prefetchMethod = (dispatch, match) => {
  return dispatch(
    fetchData(
      "/v1/data/page",
      {
        siteId: 1,
        pageId: "work",
      },
      workCachedUrl
    )
  );
};

const enhance = connect((state) => {
  return {
    Page: state.fetchedUrls[workCachedUrl]?.Page || {},
    dataLoaded: state.urlStatus[workCachedUrl] === "done",
  };
});

class WorkForSkipper extends React.Component {
  componentDidMount = () => {
    prefetchMethod(this.props.dispatch);
  };

  render() {
    const arrWithTextImages =
      this.props.Page.Contents !== undefined
        ? this.props.Page.Contents.filter((el) => el.Type === "TextWithImages")
        : null;
    const sliderObject =
      this.props.Page.Contents !== undefined ? arrWithTextImages.shift() : null;

    const metaData = this.props.Page?.META;

    if (!this.props.dataLoaded) {
      return (
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    }
    return (
      <div id="workForSkipper">
        <Helmet>
          <title>{metaData?.Title}</title>
          <meta
            name="description"
            content={metaData?.Description}
            data-react-helmet="true"
          />
        </Helmet>
        {this.props.Page.Contents !== undefined && (
          <div className="slider">
            <img
              src={sliderObject.Contents.Images[0]?.url}
              alt={sliderObject.Contents.Images[0]?.alt}
            />
            {ReactHtmlParser(sliderObject.Contents.Text)}
          </div>
        )}
        <div className="info">
          <div className="container">
            {this.props.Page.Contents !== undefined &&
              arrWithTextImages.map((el, index) => (
                <div
                  className={
                    index === 0 || index === 2 || index === 4
                      ? "secondSection"
                      : index === 1 || index === 3 || index === 5
                      ? "firstSection"
                      : null
                  }
                  key={index}
                >
                  {(index === 1 || index === 3 || index === 5) && (
                    <div className="imageSection">
                      <img
                        className="firstPhoto"
                        src={el.Contents.Images[0]?.url}
                        alt={el.Contents.Images[0]?.alt}
                      />
                    </div>
                  )}
                  <div className="textSection">
                    {ReactHtmlParser(el.Contents.Text)}
                  </div>
                  {(index === 0 || index === 2 || index === 4) && (
                    <div className="imageSection">
                      <img
                        className="firstPhoto"
                        src={el.Contents.Images[0]?.url}
                        alt={el.Contents.Images[0]?.alt}
                      />
                      <img
                        className="secondPhoto"
                        src={el.Contents.Images[1]?.url}
                        alt={el.Contents.Images[1]?.alt}
                      />
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
        <Contact />
      </div>
    );
  }
}

WorkForSkipper.prefetchMethod = prefetchMethod;

export default enhance(WorkForSkipper);
