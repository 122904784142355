import React from 'react';
import './userRegistration/Trips.scss';
import './OneTrip.scss';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import calendarIcon from './img/calendar2.png';
import imageSter from './img/ster.png';

const OneTrip = (props) => {
  let actualDate = new Date().toLocaleDateString();
  let actualDate2 = new Date();
  const sortedArray = props.List.sort((a, b) =>
    a.Availability.From.split('-')
      .join()
      .localeCompare(b.Availability.From.split('-').join())
  );
  const updatedArray = sortedArray
    .filter(
      (el) =>
        el.Type.OfferTypeId !== '5' && el.Region.Country.CountryId !== '11'
    )
    .filter((el) =>
      props.isPromotedTrip ? el.OfferId === '174' : el.OfferId !== '174'
    );

  const cruisesInProgress = updatedArray.some(
    (el) =>
      el.Availability.Available === false &&
      actualDate2.getTime() < new Date(el.Availability.To).getTime()
  );

  return (
    <>
      {updatedArray.map((el) => {
        const regionImg = el.Region.Country.Image;
        const typeImg = el.Type.Image;
        return (
          (Moment(actualDate).format('MM.DD.YY') ===
            Moment(el.Availability.From).format('DD.MM.YY') ||
            el.Availability.Available) && (
            <>
              <div>
                <Link to={`/oferta/${el.FriendlyUrl}`}>
                  <div
                    id="oneTrip"
                    className={props.isPromotedTrip ? 'ribbonBorder' : ''}
                  >
                    {props.isPromotedTrip && (
                      <div class="ribbon">
                        <p>Wyjazd polecany</p>
                      </div>
                    )}
                    <div className="photoContainer">
                      {el.Availability.GrayedOut && (
                        <>
                          <div className="overlayOffer desktopVersion">
                            <img src={imageSter} alt="Lista rezerwowa"></img>
                          </div>
                          <div className="overlayOffer responsive">
                            <img src={imageSter} alt="Lista rezerwowa"></img>
                          </div>
                        </>
                      )}

                      <Link to={`/oferta/${el.FriendlyUrl}`}>
                        <img src={el.ListImg?.url} alt={el.ListImg?.alt} />
                        <div className="overlay"></div>
                      </Link>

                      <div className="tripInfo">
                        <div className="infoTripBox">
                          <div>
                            <h3>
                              <Link to={`/oferta/${el.FriendlyUrl}`}>
                                {el.Name}
                              </Link>
                            </h3>
                            <p>
                              <img src={regionImg?.url} alt={regionImg?.alt} />
                              {el.Region.Country.Name}, {el.Region.Name}
                            </p>
                            <p>
                              <img src={calendarIcon} alt="termin" />
                              {Moment(el.Availability.From).format(
                                'DD.MM'
                              )} -{' '}
                              {Moment(el.Availability.To).format('DD.MM.YYYY')}
                            </p>
                          </div>
                          <div className="tagsBox">
                            <div className="oneTag">
                              <img src={typeImg?.url} alt={typeImg?.alt} />{' '}
                              {el.Type.Name}
                            </div>
                          </div>
                        </div>

                        {el.RibbonTextExt?.includes('Potwierdzony') ||
                        el.RibbonTextExt?.includes('potwierdzony') ? (
                          <p className="confirmedTrip">
                            <span
                              style={{ color: 'white', marginRight: '8px' }}
                            >
                              ✔
                            </span>{' '}
                            {el.RibbonTextExt}
                          </p>
                        ) : el?.RibbonTextExt ? (
                          <p className="confirmedTrip">{el.RibbonTextExt}</p>
                        ) : null}
                        <div className="priceSection">
                          <div className="priceBox">
                            {el.Prices.Strikethrough === null ? (
                              <div className="regularPrice">
                                <p className="price">{el.Prices.Base} PLN</p>
                                <p className="text">Cena od osoby</p>
                              </div>
                            ) : (
                              <>
                                <div className="discount">
                                  <p className="newPrice">
                                    {el.Prices.Base} PLN
                                  </p>
                                  <p className="text">
                                    Promocyjna cena od osoby
                                  </p>
                                </div>
                                <p className="price">
                                  {el.Prices.Strikethrough} PLN
                                </p>
                                <p className="text">Cena od osoby</p>
                              </>
                            )}
                          </div>
                          <div className="detailsBox">
                            <p className="amountOfPeople">
                              Liczba jachtów: {el.Rooms.AllocationsListCount}
                            </p>
                            {el.RibbonTextType === 'red' && (
                              <p className="availability red">
                                {el.RibbonText}
                              </p>
                            )}
                            {el.RibbonTextType === 'green' && (
                              <p className="availability green">
                                {el.RibbonText}
                              </p>
                            )}
                            {el.RibbonTextType === 'orange' && (
                              <p className="availability orange">
                                {el.RibbonText}
                              </p>
                            )}
                            <button className="primaryBtn">
                              <Link
                                to={`/oferta/${el.FriendlyUrl}`}
                                title="Zobacz szczegóły oferty"
                              >
                                Zobacz szczegóły
                              </Link>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </>
          )
        );
      })}

      {cruisesInProgress && (
        <>
          <h2>W trakcie</h2>
          {updatedArray.map((el) => {
            const regionImg = el.Region.Country.Image;
            const typeImg = el.Type.Image;
            return (
              el.Availability.Available === false &&
              actualDate2.getTime() <
                new Date(el.Availability.To).getTime() && (
                <>
                  <div>
                    <Link to={`/oferta/${el.FriendlyUrl}`}>
                      <div id="oneTrip">
                        <div className="photoContainer">
                          {el.Availability.GrayedOut && (
                            <>
                              <div className="overlayOffer desktopVersion">
                                <img
                                  src={imageSter}
                                  alt="Lista rezerwowa"
                                ></img>
                              </div>
                              <div className="overlayOffer responsive">
                                <img
                                  src={imageSter}
                                  alt="Lista rezerwowa"
                                ></img>
                              </div>
                            </>
                          )}

                          <Link to={`/oferta/${el.FriendlyUrl}`}>
                            <img src={el.ListImg?.url} alt={el.ListImg?.alt} />
                            <div className="overlay"></div>
                          </Link>

                          <div className="tripInfo">
                            <div className="infoTripBox">
                              <div>
                                <h3>
                                  <Link to={`/oferta/${el.FriendlyUrl}`}>
                                    {el.Name}
                                  </Link>
                                </h3>
                                <p>
                                  <img
                                    src={regionImg?.url}
                                    alt={regionImg?.alt}
                                  />
                                  {el.Region.Country.Name}, {el.Region.Name}
                                </p>
                                <p>
                                  <img src={calendarIcon} alt="Termin rejsu" />
                                  {Moment(el.Availability.From).format(
                                    'DD.MM'
                                  )}{' '}
                                  -{' '}
                                  {Moment(el.Availability.To).format(
                                    'DD.MM.YYYY'
                                  )}
                                </p>
                              </div>
                              <div className="tagsBox">
                                <div className="oneTag">
                                  <img src={typeImg?.url} alt={typeImg?.alt} />{' '}
                                  {el.Type.Name}
                                </div>
                              </div>
                            </div>

                            {el.RibbonTextExt?.includes('Potwierdzony') ||
                            el.RibbonTextExt?.includes('potwierdzony') ? (
                              <p className="confirmedTrip">
                                <span
                                  style={{ color: 'white', marginRight: '8px' }}
                                >
                                  ✔
                                </span>{' '}
                                {el.RibbonTextExt}
                              </p>
                            ) : el?.RibbonTextExt ? (
                              <p className="confirmedTrip">
                                {el.RibbonTextExt}
                              </p>
                            ) : null}
                            <div className="priceSection">
                              <div className="priceBox">
                                {el.Prices.Strikethrough === null ? (
                                  <div className="regularPrice">
                                    <p className="price">
                                      {el.Prices.Base} PLN
                                    </p>
                                    <p className="text">Cena od osoby</p>
                                  </div>
                                ) : (
                                  <>
                                    <div className="discount">
                                      <p className="newPrice">
                                        {el.Prices.Base} PLN
                                      </p>
                                      <p className="text">
                                        Promocyjna cena od osoby
                                      </p>
                                    </div>
                                    <p className="price">
                                      {el.Prices.Strikethrough} PLN
                                    </p>
                                    <p className="text">Cena od osoby</p>
                                  </>
                                )}
                              </div>
                              <div className="detailsBox">
                                <p
                                  className="availability red"
                                  style={{ backgroundColor: 'green' }}
                                >
                                  Wyjazd w trakcie
                                </p>
                                <p className="amountOfPeople">
                                  Liczba jachtów:{' '}
                                  {el.Rooms.AllocationsListCount}
                                </p>

                                <button className="primaryBtn">
                                  <Link to={`/oferta/${el.FriendlyUrl}`}>
                                    Zobacz szczegóły
                                  </Link>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </>
              )
            );
          })}
        </>
      )}
    </>
  );
};

export default OneTrip;
