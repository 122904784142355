import React from "react";
import "./ProgramTypes.scss";
import "./Program.scss";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
} from "react-scroll";
import { Helmet } from "react-helmet";
import party1 from "./img/party-1.jpg";
import party2 from "./img/party-2.jpg";
import party3 from "./img/party-3.jpg";
import party4 from "./img/party-4.jpg";
import party5 from "./img/party-5.jpg";
import explore1 from "./img/explore-1.jpg";
import explore2 from "./img/explore-2.jpg";
import explore3 from "./img/explore-3.jpg";
import explore4 from "./img/explore-4.jpg";
import explore5 from "./img/explore-5.jpg";
import chill1 from "./img/chill-1.jpg";
import chill2 from "./img/chill-2.jpg";
import chill3 from "./img/chill-3.jpg";
import chill4 from "./img/chill-4.jpg";
import chill5 from "./img/chill-5.jpg";
import adventure1 from "./img/adventure-6.jpg";
import adventure2 from "./img/adventure-7.jpg";
import adventure3 from "./img/adv-3.jpg";
import adventure4 from "./img/adv-4.jpg";
import adventure5 from "./img/adv-5.jpg";
import programCoverImage from "./img/program-cover.jpg";

const programArr = [
  {
    index: 1,
    filterType: 1,
    name: "sailandparty",
    linkTo: "/SailAndParty",
    title: "Sail & Party",
    description:
      "Duża flota - do 25 jachtów, dużo ludzi, dużo imprez, dużo kąpieli w morzu, wygłupów, zabawy i chilloutu. Mniej żeglowania, najłatwiejsze akweny z małą falą, nieduże odległości. Idealny wybór, jeżeli chcesz łagodnie wejść w świat żagli, wyszaleć się na imprezach i szukasz dużego eventu!",
    boxes: [
      {
        image: party1,
        name: "Rano",
        altImage: "Poranek na jachcie",
        description:
          "Pobudka! Ale nie za wcześnie, około 9:00 - 10:00. Prysznic, kawa, śniadanie, ogarnięcie jachtu po wczorajszej imprezie, zatankowanie wody. Oddanie cum około 11:00",
      },
      {
        image: party2,
        name: "Przedpołudnie",
        altImage: "Przedpołudnie na jachcie",
        description:
          "Po wyjściu w morze razem ze sternikiem stawiacie żagle i spokojnie płyniecie 2-3 h do malowniczej zatoczki, gdzie cała flota spotyka się razem na wspólną kąpiel, snorkeling, chillout i lunch.",
      },
      {
        image: party3,
        name: "Popołudnie",
        altImage: "Popołudnie na jachcie",
        description:
          "Zbieracie się z kotwicowiska i znów żeglujecie. Czas na opalanie, drzemkę, książkę, ugotowanie czegoś. Około 16:00 cumujecie w docelowym porcie - codziennie gdzie indziej!",
      },
      {
        image: party4,
        name: "Wieczór",
        altImage: "Wieczór na jachcie",
        description:
          "Po spłynięciu do portu czas na prysznic, przebranie się, zwiedzenie miasteczka i wypad do lokalnej knajpy z pysznym seafoodem! A potem beforeparty na jachtach obowiązkowo!",
      },
      {
        image: party5,
        name: "Noc",
        altImage: "Noc na jachcie",
        description:
          "Około 22:00 zwykle startuje gruba impreza w zarezerwowanym tylko dla Was klubie. Zagra nasz sprawdzony DJ, a na barze załatwiliśmy specjalną ofertę tylko dla Was!",
      },
    ],
  },
  {
    index: 2,
    filterType: 2,
    name: "sailandexplore",
    linkTo: "/SailAndExplore",
    title: "Sail & Explore",
    description:
      "Mniej huczne, na 3-6 jachtów, wycieczki z nastawieniem na odkrywanie nowych miejsc, poznawanie nowych krajów i smakowania kultur. Nastaw się na więcej pływania, może nawet będzie szansa pożeglować nocą - to niesamowite doświadczenie! Jachtem można dotrzeć do normalnie niedostępnych miejsc i zobaczyć więcej, niż na zwykłych wakacjach, zwłaszcza w takich miejscach jak Baleary, Karaiby czy Seszele.",
    boxes: [
      {
        image: explore1,
        name: "Rano",
        altImage: "Poranek na jachcie",
        description:
          "Często będzie Cię budzić łagodne kołysanie jachtu na porannej fali - zwłaszcza w tropikach popularne jest nocowanie na kotwicy, ale porty też będą, spokojnie. Wypływa się dość rano.",
      },
      {
        image: explore2,
        name: "Przedpołudnie",
        altImage: "Przedpołudnie na jachcie",
        description:
          "Jeśli wypłynęliście bardzo wcześnie, to jak już wszyscy się obudzą robicie śniadanie pod żaglami! W tym czasie wasz sternik już prowadzi jacht w kierunku nowych miejsc. Po kilku godzinach postój na kąpiel.",
      },
      {
        image: explore3,
        name: "Popołudnie",
        altImage: "Popołudnie na jachcie",
        description:
          "Po pływaniu czas na zwiedzanie! Dopływacie do docelowej lokalizacji i ruszacie na ekspedycję! Zabytkowe chorwackie miasto? Karaibska dżungla? Tajlandzkie wodospady? Zależy co i gdzie!",
      },
      {
        image: explore4,
        name: "Wieczór",
        altImage: "Wieczór na jachcie",
        description:
          "Po zwiedzaniu zasłużony relaks! Odkryjesz przysmaki lokalnej kuchni, potrawy, trunki i rozrywki niedostępne nigdzie indziej, zwyczaje obcej kultury, poznasz nowych ludzi i ich światopoglądy.",
      },
      {
        image: explore5,
        name: "Noc",
        altImage: "Noc na jachcie",
        description:
          "Baw się w najbardziej niesamowitych miejscach! Zatańcz na plaży! Zatańcz na barze! W tropikalnym lesie, w klubie na Ibizie i w knajpie na tratwie! Na trzydziestym piętrze hotelu w Bangkoku i na luksusowym katamaranie!",
      },
    ],
  },
  {
    index: 3,
    filterType: 3,
    name: "sailandadventure",
    linkTo: "/SailAndAdventure",
    title: "Sail & Adventure",
    description:
      "To oferta specjalna dla odważnych. Rejsy S&A robimy bardzo rzadko, ale jeżeli uda Ci się złapać miejsce, możesz mieć pewność że przeżyjesz przygodę życia. Jaką? Pełnomorskie regaty żaglowców, zimne i ciepłe wody, pasaże oceaniczne i kilkudniowe przeloty, czyli prawdziwe żeglarstwo morskie. Nie musisz być żeglarzem - wystarczy, że kochasz adrenalinę! Oferta dla prawdziwych miłośników mocnych wrażeń, spełnienie marzeń o przygodzie życia! Nie tylko dla wilków morskich!",
    boxes: [
      {
        image: adventure1,
        name: "Rano",
        altImage: "Poranek na jachcie",
        description:
          "Nie da się zamknąć przygody w sztywnym opisie, więc nie traktuj tego opisu jak rozkładu jazdy. Rano możesz mieć wachtę, możesz stawiać żagle, wchodzić albo wychodzić z portu! Możesz spać w koi albo wciąż bawić się w tawernie!",
      },
      {
        image: adventure2,
        name: "Przedpołudnie",
        altImage: "Przedpołudnie na jachcie",
        description:
          "Czy jesteście w morzu czy w porcie życie na jachcie toczy się stałym rytmem wachtowym - ktoś ma zadanie ugotować śniadanie, ktoś ma zadanie dbać o jacht i jego kurs, a część rozkoszuje się zasłużonym odpoczynkiem!",
      },
      {
        image: adventure3,
        name: "Popołudnie",
        altImage: "Popołudnie na jachcie",
        description:
          "Jacht stanowi Twój dom i środek transportu do najbardziej niesamowitych miejsc! Dbając o niego i współpracując z resztą załogi odkryjesz najbardziej niesamowite miejsca. Poczuj prawdziwą wolność żeglarzy!",
      },
      {
        image: adventure4,
        name: "Wieczór",
        altImage: "Wieczór na jachcie",
        description:
          "Poczuj przynależność do ignorującej granice wspólnoty żeglarzy całego świata! Zawiń do portu i baw się załogami najmniejszych jachtów i największych żaglowców, dzielcie się doświadczeniami i emocjami!",
      },
      {
        image: adventure5,
        name: "Noc",
        altImage: "Noc na jachcie",
        description:
          "Podejmij wyzwanie i przekrocz limity. Może się zdarzyć, że będziesz płynąć w dzień, w nocy, w ciszy i w sztormie. Czasem kilka godzin, czasem kilka dni. To nie tak, że życie na lądzie nie ma smaku, ale życie na morzu jest lepsze. Sir Francis Drake",
      },
    ],
  },
  {
    index: 4,
    filterType: 4,
    name: "sailandchill",
    linkTo: "/SailAndChill",
    title: "Sail & Chill",
    description:
      "To oferta skierowana dla osób, które planują przede wszystkim wypocząć. W tej formule nastawiasz się na chill i odpoczynek, trasy nie są ambitne i nie ma tutaj wielu wycieczek lądowych - większość czasu spędzasz relaksując się, snorkelując i zwiedzając miasteczka do których przypłyneliśmy. Ten rejs to mniej żeglowania, łatwiejsze akweny z małą falą i nieduże odległości - w zamian za to zyskujesz dużo relaksu i chillowania!",
    boxes: [
      {
        image: chill1,
        name: "Rano",
        altImage: "Poranek na jachcie",
        description:
          "Dzień zaczynasz od kawy, leniwego śniadania, prysznica. Najczęściej nigdzie nam się nie śpieszy, więc można jeszcze przejść się na spacer po miasteczku, pochillować - odpłynięcie dopiero około 11!",
      },
      {
        image: chill2,
        name: "Przedpołudnie",
        altImage: "Przedpołudnie na jachcie",
        description:
          "Jak już wszyscy się ogarną, wypływamy! Po kilku godzinach postój na wspólną kąpiel, snorkeling i chillout.",
      },
      {
        image: chill3,
        name: "Popołudnie",
        altImage: "Popołudnie na jachcie",
        description:
          "Zbieracie się z kotwicowiska i znów żeglujecie. Czas na opalanie, drzemkę, książkę, ugotowanie czegoś. Około 16:00 cumujecie w docelowym porcie.",
      },
      {
        image: chill4,
        name: "Wieczór",
        altImage: "Wieczór na jachcie",
        description:
          "Po spłynięciu do portu czas na prysznic, przebranie się, zwiedzenie miasteczka i wypad do lokalnej knajpy z pysznym seafoodem!",
      },
      {
        image: chill5,
        name: "Noc",
        altImage: "Noc na jachcie",
        description:
          "Pełna dowolność. Chilloutowy wieczór z kieliszkiem wina albo delikatna imprezka z tańcami i siedzeniem do późna - wszystko zależy od Was!",
      },
    ],
  },
];

const Program = () => {
  return (
    <>
      <Helmet>
        <title>Program rejsu</title>
        <meta
          name="description"
          content="Program rejsu z The Boat Trip. Tutaj dowiesz się, jak wygląda przykładowy dzień na statku."
          data-react-helmet="true"
        />
        <meta
          name="keywords"
          content="program, rejs, typ, plan"
          data-react-helmet="true"
        />
      </Helmet>
      {programArr.map((el) => (
        <Element name={el.name} className="element" id={el.index}>
          <div id="program">
            <div className="container">
              <div className="mainText">
                <h2>{el.title}</h2>
                <p>{el.description}</p>
              </div>
              <div className="dayPlan">
                {el.boxes.map((el) => (
                  <div className="oneBox">
                    <div className="photoContainer">
                      <img src={el.image} alt={el.altImage}></img>
                    </div>
                    <div className="textBox">
                      <h4>{el.name}</h4>
                      <p>{el.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <a href={`/rejsy-morskie?type=${el.filterType}&`} title="Sprawdź rejsy" >
              <button className="primaryBtn">Sprawdź rejsy</button>
            </a>
          </div>
        </Element>
      ))}
    </>
  );
};

class ProgramTypes extends React.Component {
  componentDidMount = () => {
    Events.scrollEvent.register("begin", function () {});

    Events.scrollEvent.register("end", function () {});

    scrollSpy.update();
  };

  componentWillUnmount = () =>
    function () {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };

  scrollToTop = () =>
    function () {
      scroll.scrollToTop();
    };
  scrollToBottom = () =>
    function () {
      scroll.scrollToBottom();
    };
  scrollTo = () =>
    function () {
      scroll.scrollTo(100);
    };
  scrollMore = () =>
    function () {
      scroll.scrollMore(100);
    };
  handleSetActive = () => function (to) {};

  render() {
    return (
      <div id="programTypes">
        <div className="slider">
          <img src={programCoverImage} alt="Program rejsu" />
          <h1>Program rejsu</h1>
        </div>

        <div id="menuPage">
          <div className="container">
            <Link
              activeClass="active"
              to="sailandparty"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
              onSetActive={this.handleSetActive}
            >
              Sail & Party
            </Link>

            <Link
              activeClass="active"
              to="sailandexplore"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
              onSetActive={this.handleSetActive}
            >
              Sail & Explore
            </Link>

            <Link
              activeClass="active"
              to="sailandadventure"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
              onSetActive={this.handleSetActive}
            >
              Sail & Adventure
            </Link>

            <Link
              activeClass="active"
              to="sailandchill"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
              onSetActive={this.handleSetActive}
            >
              Sail & Chill
            </Link>
          </div>
        </div>

        <Program />
      </div>
    );
  }
}

export default ProgramTypes;
