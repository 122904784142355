import React, { useEffect } from 'react';
import "./StepSix.scss";
import "./StepSubmit.scss";
import { Link } from 'react-router-dom';
import image from "./img/italyCov.jpg";


const StepSubmitPayU = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div id="stepSubmit" style={{paddingTop: "150px"}}>
                <div className="container">
                    <div className="image">
                        <img src={image} alt="Rejsy morskie jachtem - The Boat Trip"></img>
                    </div>
                    <h4>Płatność PayU</h4>
                    <p style={{ width: "400px", margin: "auto", textAlign: "center", maxWidth: "90vw" }}></p>
                    <div className="greenBox">
                        <p className="infoTitle" style={{fontWeight: "300"}}><span style={{fontFamily: "SpaceGrotesk-SemiBold", color: "#1eb74c"}}>Dziękujemy za wpłatę</span>. Na wskazany adres e-mail zostanie wysłane potwierdzenie dokonanej rezerwacji. <span style={{fontFamily: "SpaceGrotesk-SemiBold", color: "#1eb74c"}} >Mail powinien dotrzeć w ciągu 15 minut.</span></p>
                    </div>
                    <Link to="/twoj-panel" title="Przejdź do Panelu klienta" ><button className="secondaryBtn" style={{cursor: "pointer", margin: "auto", marginTop: "20px", display: "flex"}}>Przejdź do panelu klienta</button></Link>
                </div>
            </div>
        </>
    );
}

export default StepSubmitPayU;