import React, {useEffect} from "react";
import "./Details.scss";
import Moment from "moment";

const DetailsWrapper = ({
  reservationArr,
  handleChange,
  errorMessage,
  trueMessage,
  groupCode,
  handleAddGroupCode,
  handleCreateGroupCode,
  handleDownloadContract,
  handleDownloadInvoice,
  messageNotReadyContractDownload,
  messageNotReadyInvoiceDownload,
  isGroupCodeJoinSection,
  isGroupCodeCreateSection
}) => {
  const nameParticipant = reservationArr.StepConfig.Step5.participantsConfig.map(({firstName, lastName}, index) => (
    <>{firstName === "" ? "Uczestnik " + (index + 1) : firstName + " " + lastName}</>
  ));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {reservationArr.ReserveList && (
        <div className="orangeBox">
          <p>
            Twoja rezerwacja jest na liście rezerwowej. Rezerwację potwierdzimy telefonicznie lub mailowo - prosimy do
            tego momentu nie wpłacać zaliczki.
          </p>
        </div>
      )}
      <div id="details">
        <h3>Szczegóły wyjazdu</h3>
        <table className="detailsBox">
          <tr>
            <td className="firstColumn">Nazwa wyjazdu:</td>
            <td className="secondColumn">{reservationArr.Name}</td>
          </tr>
          <tr>
            <td className="firstColumn">Termin wyjazdu:</td>
            <td className="secondColumn">
              {Moment(reservationArr.Offer.Availability.From).format("DD.MM")} -
              {Moment(reservationArr.Offer.Availability.To).format("DD.MM.YYYY")}
            </td>
          </tr>
          <tr>
            <td className="firstColumn">Resort:</td>
            <td className="secondColumn">{reservationArr.Offer.Region.Country.Name}</td>
          </tr>

          <tr>
            <td className="firstColumn">Rodzaj wyjazdu:</td>
            <td className="secondColumn">{reservationArr.Offer.Type.Name}</td>
          </tr>
          <tr>
            <td className="firstColumn">Liczba osób:</td>
            <td className="secondColumn">{reservationArr.StepConfig.Step1.participantsCount}</td>
          </tr>
        </table>
      </div>
      <div id="details">
        <h3>Szczegóły rezerwacji</h3>
        <table className="detailsBox">
          <tr>
            <td className="firstColumn">ID rezerwacji:</td>
            <td className="secondColumn">{reservationArr.ReservationId}</td>
          </tr>
          <tr>
            <td className="firstColumn">Status rezerwacji:</td>
            <td className="secondColumn">{reservationArr.Status}</td>
          </tr>
          <tr>
            <td className="firstColumn">Data rezerwacji:</td>
            <td className="secondColumn">{Moment(reservationArr.CreatedTime).format("DD.MM.YYYY hh:mm")}</td>
          </tr>
          <tr>
            <td className="firstColumn">Cena finalna:</td>
            <td className="secondColumn">{reservationArr.Price.CurrentPrice} PLN</td>
          </tr>
        </table>
      </div>
      {!reservationArr.ReserveList && reservationArr.Owner && (
        <>
          {reservationArr.GroupCode === null &&
          !reservationArr.IsHistorical &&
          reservationArr.Status !== "Anulowana" ? (
            <div id="details">
              <h3>Kody grupowe</h3>
              {isGroupCodeJoinSection && (
                <>
                  <p>Jeżeli chcesz dołączyć do istniejącej grupy, wklej Wasz kod poniżej:</p>
                  <div id="liderBox" className="inputBox">
                    <div className="formContainer">
                      <input type="text" name="groupCode" value={groupCode} onChange={handleChange} />
                      <button className="secondaryBtn" onClick={handleAddGroupCode}>
                        Dołącz
                      </button>
                    </div>
                  </div>
                </>
              )}
              {isGroupCodeCreateSection && 
              <div id="liderBox" className="inputBox">
                <p>Jeżeli jesteś liderem grupy i chcesz utworzyć kod grupowy kliknij w przycisk poniżej:</p>
                <div className="formContainer">
                  <button className="secondaryBtn" onClick={(event) => handleCreateGroupCode(event)}>
                    Wygeneruj kod grupowy
                  </button>
                </div>
              </div>}
            </div>
          ) : (
            reservationArr.GroupCode !== null && (
              <div id="details">
                <h3>Kody grupowe</h3>
                <table className="detailsBox">
                  <tr>
                    <td className="firstColumn">Kod grupowy:</td>
                    <td className="secondColumn">{reservationArr.GroupCode.Name} </td>
                  </tr>
                  {reservationArr?.GroupCode?.UsersCount !== 0 && (
                    <tr>
                      <td className="firstColumn">Ilość osób, kóre użyły kod:</td>
                      <td className="secondColumn">{reservationArr.GroupCode.UsersCount} </td>
                    </tr>
                  )}
                  {reservationArr?.GroupCode?.UsersList?.length !== 0 && (
                    <tr>
                      <td className="firstColumn">Twoja grupa:</td>
                      <td className="secondColumn" style={{textAlign: "right"}}>
                        {reservationArr?.GroupCode?.UsersList?.map(({GroupOwnerName}) => (
                          <>
                            {GroupOwnerName}
                            <br />
                          </>
                        ))}
                      </td>
                    </tr>
                  )}
                  {reservationArr.GroupCode.Valid && reservationArr.GroupCode.CurrentValue !== 0 && (
                    <tr>
                      <td className="firstColumn">Zniżka grupowa</td>
                      <td className="secondColumn">{reservationArr.GroupCode.CurrentValue} </td>
                    </tr>
                  )}
                </table>
              </div>
            )
          )}
        </>
      )}
      <div className="messagesContainer">
        {trueMessage && <p className="trueMessage">{trueMessage}</p>}
        {errorMessage && <p className="errorMessage">{errorMessage}</p>}
      </div>
      {reservationArr.Rebates.length !== 0 && (
        <div id="details">
          <h3>Rabaty</h3>
          <table className="detailsBox">
            {reservationArr.Rebates.map(
              ({Value, Name}) =>
                Value !== 0 && (
                  <tr>
                    <td className="firstColumn">{Name}</td>
                    <td className="secondColumn">
                      {Name?.includes("First")
                        ? 200 + " PLN"
                        : Name?.includes("Second")
                        ? 100 + " PLN"
                        : Name?.includes("Voucher")
                        ? null
                        : Value + " PLN"}
                    </td>
                  </tr>
                )
            )}
          </table>
        </div>
      )}
      {Object.values(reservationArr?.OptionsSelected)?.map((participantOptions, index) => (
        <div id="details">
          <h3>Wybrane opcje - {nameParticipant[index]}</h3>
          <table className="detailsBox" id="options">
            {participantOptions?.map(({extendData, Price, Description}) => (
              <tr>
                <td className="firstColumn">{Description}</td>
                <td className="secondColumn">
                  {extendData?.IsPayOnPlace ? "Płatność na miejscu" : Price === 0 ? "W cenie rejsu" : Price + " PLN"}
                </td>
              </tr>
            ))}
          </table>
        </div>
      ))}

      {!reservationArr.ReserveList && reservationArr.Owner && (
        <div id="details">
          <h3>Dokumenty</h3>
          <table className="documentsBox" style={{display: "inline-table"}}>
            <tr>
              <td className="firstColumn">Umowa</td>
              <td className="secondColumn">
                <span onClick={handleDownloadContract}>
                  Wygeneruj umowę<i className="arrow"></i>
                </span>
              </td>
            </tr>
            <tr>
              <td className="firstColumn">Faktura</td>
              <td className="secondColumn">
                <span onClick={handleDownloadInvoice}>
                  Wygeneruj fakturę<i className="arrow"></i>
                </span>
              </td>
            </tr>
            <p
              style={
                messageNotReadyContractDownload.includes("chwilę")
                  ? {color: "green", width: "100%", fontFamily: "SpaceGrotesk-SemiBold"}
                  : {color: "tomato", width: "100%"}
              }
            >
              {messageNotReadyContractDownload}
            </p>
            <p
              style={
                messageNotReadyInvoiceDownload.includes("chwilę")
                  ? {color: "green", width: "100%", fontFamily: "SpaceGrotesk-SemiBold"}
                  : {color: "tomato", width: "100%"}
              }
            >
              {messageNotReadyInvoiceDownload}
            </p>
          </table>
        </div>
      )}
    </>
  );
};

export default DetailsWrapper;
