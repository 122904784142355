import React, {useState} from "react";
import {Map, GoogleApiWrapper, Marker} from "google-maps-react";
import Anchor from "./images/anchor.png";
import AnchorActive from "./images/anchor-active.png";
import "./OfferMap.scss";

export const MapContainer = ({
  google,
  offerDetails,
  portArr,
  setActiveElementIndex,
  activeElementIndex,
  middlePoint
}) => {
  const availablePortArr = portArr.filter(({Available}) => Available);
  const [myPorts] = useState(availablePortArr);

  return (
    <>
      <div className="OfferMapWrapper">
        <Map
          google={google}
          style={{height: "650px", width: "60%"}}
          zoom={offerDetails.OfferId === "31" ? 6 : offerDetails.OfferId === "82" ? 7 : 9}
          initialCenter={middlePoint}
          zoomControl={false}
          mapTypeControl={false}
          scaleControl={false}
          streetViewControl={false}
          rotateControl={false}
          fullscreenControl={false}
        >
          {myPorts.map(({RegionOptionId, position}, index) => (
            <Marker
              onClick={() => {
                setActiveElementIndex(index);
              }}
              icon={activeElementIndex === index ? AnchorActive : Anchor}
              key={RegionOptionId}
              id={index}
              position={position}
            />
          ))}
        </Map>
      </div>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyBBpZTu2oGBduxeK34VBkMhYkNmBX1JqWw",
})(MapContainer);
