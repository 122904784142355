import React from "react";
import "./CruiseList.scss";
import "./FilteredTrips.scss";
import Moment from "moment";
import calendarIcon from "./img/calendar.png";
import {Link} from "react-router-dom";

const FilteredTrips = ({List, heading, countryFilter}) => {
  const arraySortByDateFrom = List.sort((a, b) =>
    a.Availability.From.split("-").join().localeCompare(b.Availability.From.split("-").join())
  );

  return (
    <div id="filteredTrips">
      <h2>{heading}</h2>
      <div className="container">
        {arraySortByDateFrom.map(
          ({
            Prices,
            Name,
            Rooms,
            Region,
            Type,
            Availability,
            ListImg,
            FriendlyUrl,
            RibbonText,
            RibbonTextExt,
            RibbonTextType,
          }) => {
            const regionImg = Region.Country.Image;
            const typeImg = Type.Image;
            const countryId = Region.Country.CountryId;
            return (
              Availability.Available &&
              countryId === countryFilter && (
                <Link to={`/oferta/${FriendlyUrl}`}>
                  <div id="oneTrip">
                    <div className="photoContainer">
                      <Link to={`/oferta/${FriendlyUrl}`}>
                        <img src={ListImg?.url} alt={ListImg?.alt} />
                        <div className="overlay"></div>
                      </Link>
                      <div className="tripInfo">
                        <div className="infoTripBox">
                       
                          <div>
                          <h3>
                          <Link to={`/oferta/${FriendlyUrl}`}>{Name}</Link>
                        </h3>
                          <p>
                            <img src={regionImg?.url} alt={regionImg?.alt} />
                            {Region.Country.Name}, {Region.Name}
                          </p>
                          <p>
                            <img src={calendarIcon} alt="termin" />
                            {Moment(Availability.From).format("DD.MM")} - {Moment(Availability.To).format("DD.MM.YYYY")}
                          </p>
                          </div>
                          <div className="tagsBox">
                        <div className="oneTag">
                          <img src={typeImg?.url} alt={typeImg?.alt} /> {Type.Name}
                        </div>
                      </div>
                        </div>

                        {RibbonTextExt && <p className="confirmedTrip">{RibbonTextExt}</p>}
                        <div className="priceSection">
                        <div className="priceBox">
                          {Prices.Strikethrough === null ? (
                            <div className="regularPrice">
                              <p className="price">{Prices.Base} PLN</p>
                              <p className="text">Cena od osoby</p>
                            </div>
                          ) : (
                            <>
                              <div className="discount">
                                <p className="newPrice">{Prices.Base} PLN</p>
                                <p className="text">Promocyjna cena od osoby</p>
                              </div>
                              <p className="price">{Prices.Strikethrough} PLN</p>
                              <p className="text">Cena od osoby</p>
                            </>
                          )}
                        </div>
                        <div className="detailsBox">
                          <p className="amountOfPeople">Liczba jachtów: {Rooms.AllocationsListCount}</p>
                          {RibbonTextType === "red" && <p className="availability red">{RibbonText}</p>}
                          {RibbonTextType === "green" && <p className="availability green">{RibbonText}</p>}
                          {RibbonTextType === "orange" && <p className="availability orange">{RibbonText}</p>}
                          <button className="primaryBtn">
                            <Link to={`/oferta/${FriendlyUrl}`} title="Zobacz szczegóły oferty">
                              Zobacz szczegóły
                            </Link>
                          </button>
                        </div>
                      </div>
                      </div>
                      
                     
                    </div>
                  </div>
                </Link>
              )
            );
          }
        )}
      </div>
      <Link to="/rejsy-morskie">
        <button class="primaryBtn">Sprawdź inne rejsy</button>
      </Link>
    </div>
  );
};

const CruiseList = ({List, heading, countryFilter}) => {
  return (
    <div id="cruiseList">
      <FilteredTrips List={List} heading={heading} countryFilter={countryFilter} />
    </div>
  );
};

export default CruiseList;
