import {createSelector} from "reselect";

export const getOfferSelector = createSelector(
  (store, cachedUrlName) => store.fetchedUrls[cachedUrlName],
  (resultData) => {
    if (resultData) {
      const {Details} = resultData;
      return {
        offerDetails: Details,
        offerHTML: Details.HTML,
        offerType: Details.Type,
        offerPrices: Details.Prices,
        offerAvailability: Details.Availability,
        offerRegion: Details.Region,
        offerCountry: Details.Region.Country,
        offerRooms: Details.Rooms,
        offerBoxes: Details.Boxes,
        offerPortArr: Details.Region.OptionsList.filter((el) => el.Available),
        offerClasses: Details.Region.ResortsList,
        offerDailyPlan: Details.DailyPlan,
        offerJourneys: Details.Journeys,
        offerCosts: Details.Costs,
        offerAttractions: Details.Attractions,
        offerMETA: Details.META,
      };
    }
    return {};
  }
);

export const getOfferPortsArray = createSelector(getOfferSelector, (offer) => {
  if (offer.offerPortArr) {
    return offer.offerPortArr.map((port) => ({
      ...port,
      position: {lat: port.GeoCords.GeoX, lng: port.GeoCords.GeoY},
    }));
  }
  return [];
});

export const getMiddlePoint = createSelector(getOfferPortsArray, (myPorts) => {
  const {lat, lng} = myPorts.reduce(
    (prevValue, nextValue) => {
      const {lat, lng} = prevValue;
      const {GeoX, GeoY} = nextValue.GeoCords;

      return {
        lat: lat + parseFloat(GeoX),
        lng: lng + parseFloat(GeoY),
      };
    },
    {lat: null, lng: null}
  );

  return {lat: lat / myPorts.length, lng: lng / myPorts.length};
});
