import React from 'react';
import './Footer.scss';
import { HashLink } from 'react-router-hash-link';

const FooterColumn = ({ link, hashLink, titleAttribute, title }) => {
  return (
    <p>
      <HashLink
        activeclassname="is-active"
        smooth
        to={{ pathname: link, hash: hashLink }}
        title={titleAttribute}
      >
        {title}
      </HashLink>
    </p>
  );
};

export default FooterColumn;
