import React from "react";
import "./Gallery.scss";

const GalleryCountry = ({ galleryArr1, galleryArr2 }) => {
  const galleryText = galleryArr2 ? galleryArr1?.Boxes?.concat(galleryArr2?.Boxes) : galleryArr1?.Boxes;
  const galleryImages = galleryArr2 ? galleryArr1?.Images?.concat(galleryArr2?.Images) : galleryArr1?.Images;
  
  return (
    <div id="gallery" className="country">
      <div className="container">
        <h2>Galerie zdjęć z rejsów morskich</h2>
        <p>Rzuć okiem na to, jak wyglądają nasze rejsy w obiektywie.</p>
        <div
          className="boxes"
          style={
            galleryImages?.length > 4
              ? { width: "90%", justifyContent: "space-around" }
              : { }
          }
        >
          {galleryText?.map((el, index) => (
            <a href={el?.[1]} target="_blank" rel="noreferrer" title="Sprawdź jak bawimy się na rejsach">
            <div className="oneBox">
              <div className="imageContainer">
                <img
                  src={galleryImages[index]?.url}
                  alt={galleryImages[index]?.alt}
                />
              </div>
              <div className="textContainer">
                <p className="tripName">{el?.[0]}</p>
                <a href={el?.[1]} target="_blank" rel="noreferrer" title="Sprawdź jak bawimy się na rejsach" >
                  Zobacz galerię zdjęć <i className="arrow"></i>
                </a>
              </div>
            </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GalleryCountry;
