import React from "react";
import "./Blog.scss";
import Loader from "./Loader.js";
import { fetchPostBySlug } from "./store/actions.js";
import {connect} from "react-redux";
import {getBlogItemCachedUrlName} from "./cachedStoreUrls";

export const prefetchMethod = async (dispatch, slug) => {
  const blogItemCachedUrl = getBlogItemCachedUrlName(slug)
  return dispatch(
    await fetchPostBySlug(
      `?slug=${slug}`,
      blogItemCachedUrl
    )
  );
};
const enhance = connect((state, props) => {
  const slug = props.match.params.id;
  const blogItemCachedUrl = getBlogItemCachedUrlName(slug)
  return {
    Page: state.fetchedUrls[blogItemCachedUrl],
    dataLoaded: state.urlStatus[blogItemCachedUrl] === "done",
  };
});

class Blog extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
    const id = this.props.match.params.id;
    prefetchMethod(this.props.dispatch, id);
  };

  render() {
    const { Page, dataLoaded} = this.props;

    if (!dataLoaded) {
      return (
        <Loader />
      );
    }
    return (
      <div className="blogContainer">
        <div className="content">
          <h1 dangerouslySetInnerHTML={{__html: Page?.title.rendered}} />
          <div className="blogDate">{new Date(Page?.date).toLocaleDateString()}</div>
          <div dangerouslySetInnerHTML={{__html: Page?.content.rendered}} />
        </div>
      </div>
    );
  }
}

Blog.prefetchMethod = prefetchMethod;

export default enhance(Blog);
