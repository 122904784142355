import React from "react";
import Slider from "react-slick";

const OfferTagBoxItem = ({handleClose, image, altImage, description, name}) => {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="popup-box">
      <div className="box">
        <span className="close-icon" onClick={handleClose} style={{zIndex: "10"}}>
          x
        </span>
        <Slider {...settings}>
          <>
            <div className="popup-img">
              <img src={image} alt={altImage} />
            </div>
            <div className="popup-content-wrapper">
              {<p className="popup-title">{name}</p>}
              <p>{description}</p>
            </div>
          </>
        </Slider>
      </div>
    </div>
  );
};

export default OfferTagBoxItem;
