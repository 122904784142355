import React from "react";
import "./ParticipantDetails.scss";

const ParticipantDetails = ({
  participantsInfo,
  key,
  participantsForm,
  handleChangeFirstName,
  handleChangeLastName,
  handleChangeBirthDate,
  handleChangeIdDocument,
  handleChangeGender,
  handleChangeCountry,
  handleChangePhone,
  handleChangeEmail,
  handleSubmit,
  colorMessage,
  message
}) => {
  const isTrue = participantsInfo?.firstName;

  return (
    <div id="participantDetails" key={key}>
      <div className="form">
        <form noValidate style={{paddingLeft: "0", width: "auto"}}>
          {participantsForm && (
            <>
              <p>Imię</p>
              <input
                name="firstName"
                type="text"
                onChange={(event) => handleChangeFirstName(participantsInfo.userNum, event)}
                value={isTrue ? participantsInfo.firstName : null}
              ></input>
              <p>Nazwisko</p>
              <input
                name="lastName"
                type="text"
                onChange={(event) => handleChangeLastName(participantsInfo.userNum, event)}
                value={isTrue ? participantsInfo.lastName : null}
              ></input>
              <p>Data urodzenia</p>
              <input
                name="birthDate"
                type="text"
                placeholder="YYYY-MM-DD"
                onChange={(event) => handleChangeBirthDate(participantsInfo.userNum, event)}
                value={isTrue ? participantsInfo.birthDate : null}
              ></input>
              <p>Seria i numer dowodu osobistego</p>
              <input
                name="idDocument"
                type="text"
                onChange={(event) => handleChangeIdDocument(participantsInfo.userNum, event)}
                value={isTrue ? participantsInfo.idDocument : null}
              ></input>
              <p>Pleć</p>
              <div className="containerGender">
                <label className="inputGender">
                  Kobieta
                  <input
                    type="radio"
                    name="radio"
                    value="1"
                    onChange={(event) => handleChangeGender(participantsInfo.userNum, event)}
                    checked={isTrue && participantsInfo.gender && participantsInfo.gender === "1"}
                  ></input>
                  <span className="checkmark"></span>
                </label>
                <label className="inputGender">
                  Mężczyzna
                  <input
                    type="radio"
                    name="radio"
                    value="2"
                    onChange={(event) => handleChangeGender(participantsInfo.userNum, event)}
                    checked={isTrue && participantsInfo.gender && participantsInfo.gender === "2"}
                  ></input>
                  <span className="checkmark"></span>
                </label>
              </div>
              <p>Kraj</p>
              <input
                name="country"
                type="text"
                onChange={(event) => handleChangeCountry(participantsInfo.userNum, event)}
                value={isTrue ? participantsInfo.country : null}
              ></input>
              <p>Numer telefonu</p>
              <input
                name="phone"
                type="text"
                onChange={(event) => handleChangePhone(participantsInfo.userNum, event)}
                value={isTrue ? participantsInfo.phone : null}
              ></input>
              <p>E-mail</p>
              <input
                name="email"
                type="email"
                onChange={(event) => handleChangeEmail(participantsInfo.userNum, event)}
                value={isTrue ? participantsInfo.email : null}
              ></input>

              <div className="btnBox" style={{display: "flex"}}>
                <button
                  className="primaryBtn"
                  onClick={(event) =>
                    handleSubmit(
                      participantsInfo.userNum,
                      event,
                      participantsInfo.email,
                      participantsInfo.phone,
                      participantsInfo.idDocument
                    )
                  }
                >
                  Zapisz zmiany
                </button>
              </div>
            </>
          )}
          <p style={{color: colorMessage, fontSize: "17px"}}>{message}</p>
        </form>
      </div>
    </div>
  );
};

export default ParticipantDetails;
