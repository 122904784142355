import React, {useEffect, useState} from "react";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import "./Slider.scss";
import {Player} from "video-react";
import {Link} from "react-router-dom";
import APIbackend from "../config";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const Slider = () => {
  const [sliderInfo, setSliderInfo] = useState("");

  useEffect(() => {
    fetch(`${APIbackend}v1/data/page`, {
      method: "POST",
      body: JSON.stringify({
        siteId: "1",
        pageId: "homePage",
      }),
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => setSliderInfo(data.Page.Contents[1].Contents.Text));
  });

  const textOnSlider = sliderInfo.split("\r\n");
  const sliderHeader = textOnSlider?.shift();

  return (
    <div id="sliderContainer">
      <AutoplaySlider play={true} cancelOnInteraction={false} interval={6000}>
        <div>
          <Player muted={true} autoPlay={true} loop={true} playsinline={true} controls={false} url="">
            <source src={"video/Summer-Slider-Video.mov"} type="video/mp4" />
          </Player>
        </div>
      </AutoplaySlider>
      <div className="stripeBox">
        <div className="stripe">
          <div className="textBox">
            <div className="text">
              <h1>{sliderHeader}</h1>
              <p>{textOnSlider}</p>
              <Link to="/rejsy-morskie" title="Zobacz rejsy morskie">
                <button id="button" className="primaryBtn">
                  Zobacz rejsy morskie
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
