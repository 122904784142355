import React, {Component} from "react";
import OfferCover from "./OfferCover";
import OfferMenu from "./OfferMenu.js";
import PromiseLoader from "./PromiseLoader.js";
import OfferResort from "./OfferResort.js";
import OfferAccomodation from "./OfferAccomodation.js";
import OfferAttractions from "./OfferAttractions.js";
import OfferTransport from "./OfferTransport.js";
import OfferTrips from "./OfferTrips.js";
import OfferSchedule from "./OfferSchedule.js";
import OfferPrice from "./OfferPrice.js";
import OfferMovie from "./OfferMovie";
import ShortInfo from "./OfferShortInfo";
import OfferAddInfo from "./OfferAddInfo.js";
import OfferAddInfoBox from "./OfferAddInfoBox.js";
import OfferFaq from "./OfferFaq";
import OfferLiveOnYachtWrapper from "./OfferLiveOnYachtWrapper.js";
import {Helmet} from "react-helmet";
import ReservationButton from "./ReservationButton.js";
import TagManager from "react-gtm-module";

class OfferSiteContent extends Component {
  componentDidMount = () => {
    TagManager.dataLayer({
      dataLayer:
        {
          event: "productViewed",
          ecommerce: {
            detail: {
              actionField: {list: this.props.offerCountry.Name},
              products: [
                {
                  name: this.props.offerDetails.Name,
                  id: this.props.offerDetails.OfferId,
                  price: this.props.offerDetails.Prices?.Base,
                  brand: "The Boat Trip",
                  category: "Wyjazdy",
                  variant: this.props.offerAvailability?.Terms[0]?.Name,
                  kraj: this.props?.offerCountry?.Name,
                  "styl wyjazdu": this.props.offerType?.Name,
                  termin: this.props.offerAvailability?.Terms[0]?.Name,
                },
              ],
            },
          },
        } || {},
    });
  };

  render() {
    const {
      offerMETA,
      offerHTML,
      offerBoxes,
      offerAttractions,
      offerRooms,
      offerCosts,
      offerJourneys,
      offerDailyPlan,
      offerType,
      offerRegion,
      offerClasses,
      offerCountry,
      offerDetails,
      offerPrices,
      offerAvailability,
      offerPortArr,
    } = this.props;

    const analiticsDataAddToCart = {
      event: "addToCart",
      ecommerce: {
        currencyCode: "PLN",
        add: {
          products: [
            {
              name: this.props.offerDetails.Name,
              id: this.props.offerDetails.OfferId,
              price: this.props.offerDetails.Prices?.Base,
              brand: "The Boat Trip",
              category: "Wyjazdy",
              variant: this.props.offerAvailability?.Terms[0]?.Name,
              kraj: this.props?.offerCountry?.Name,
              "styl wyjazdu": this.props.offerType?.Name,
              termin: this.props.offerAvailability?.Terms[0]?.Name,
              quantity: 1,
            },
          ],
        },
      },
    };

    return (
      <div className="offerSiteContainer">
        <Helmet>
          <title>{offerMETA.Title}</title>
          <meta name="description" content={offerMETA.Description} data-react-helmet="true" />
          <meta name="keywords" content={offerMETA.Keywords} data-react-helmet="true" />
        </Helmet>
        <OfferCover
          offerRooms={offerRooms}
          offerType={offerType}
          offerRegion={offerRegion}
          offerCountry={offerCountry}
          offerDetails={offerDetails}
          offerPrices={offerPrices}
          offerAvailability={offerAvailability}
          offerClasses={offerClasses}
        />
        <OfferMenu
          analiticsDataAddToCart={analiticsDataAddToCart}
          offerType={offerType}
          offerPrices={offerPrices}
          offerDetails={offerDetails}
          offerClasses={offerClasses}
        />
        <ShortInfo
          offerBoxes={offerBoxes}
          offerHTML={offerHTML}
          shortInfo={offerDetails.Info}
          offerCountry={offerCountry}
          offerType={offerType}
          offerDetails={offerDetails}
          offerRooms={offerRooms}
        />
        {offerDetails.OfferId !== "31" && offerDetails.OfferId !== "33" && offerDetails.OfferId !== "32" && (
          <OfferMovie offerDetails={offerDetails} />
        )}
        {offerDetails.OfferId === "248" && (
          <OfferSchedule offerDailyPlan={offerDailyPlan} offerType={offerType} offerDetails={offerDetails} />
        )}
        {offerDetails.Name.includes("patent") && <OfferAttractions offerAttractions={offerAttractions} />}
        {offerDetails.OfferId !== "75" && (
          <OfferResort offerPortArr={offerPortArr} offerRegion={offerRegion} offerDetails={offerDetails} />
        )}
        {!offerDetails.Name.includes("Mazury") && !offerDetails.Name.includes("Cup") && <OfferLiveOnYachtWrapper />}
        <OfferAccomodation
          accommodationArr={offerClasses}
          offerDetails={offerDetails}
          offerRooms={offerRooms}
          offerType={offerType}
          offerPrices={offerPrices}
          offerRegion={offerRegion}
        />
        {offerDetails.Name.includes("patent") && <OfferAddInfo offerHTML={offerHTML} offerDetails={offerDetails} />}
        {!offerDetails.Name.includes("patent") && <OfferAttractions offerAttractions={offerAttractions} />}
        {offerDetails.Name.includes("patent") && <OfferAddInfoBox offerHTML={offerHTML} offerDetails={offerDetails} />}
        <OfferTransport
          offerJourneys={offerJourneys}
          offerPortArr={offerPortArr}
          offerRegion={offerRegion}
          offerDetails={offerDetails}
        />
        {!offerDetails.Name.includes("Mazury") && !offerDetails.Name.includes("Cup") && <OfferFaq />}
        <OfferPrice offerCosts={offerCosts} offerDetails={offerDetails} offerPrices={offerPrices} />
        <OfferTrips offerDetails={offerDetails} />
        <ReservationButton offerId={offerDetails.OfferId} offerDetails={offerDetails} />
      </div>
    );
  }
}

export default PromiseLoader(OfferSiteContent);
