import React, { Component } from "react";
import "./PopUpCookies.scss";
import politykaPrywatnosci from "./userRegistration/documents/polityka-prywatnosci.pdf";

class PopUpCookies extends Component {
  render() {
    return (
      <>
        <div id="popUpCookies">
          <div className="containerPopUp">
            <p>
              Ta strona używa plików cookies, pełne informacje na ten temat
              znajdziesz w{" "}
              <a
                style={{ fontFamily: "SpaceGrotesk-SemiBold" }}
                href={politykaPrywatnosci}
                title="Przeczytaj Politykę Prywatności"
                rel="noreferrer"
                target="_blank"
              >
                Polityce Prywatności
              </a>
              . Niedokonanie zmian ustawień przeglądarki wyłączających obsługę
              cookies jest równoznaczne z wyrażeniem zgody na ich zapisywanie.{" "}
            </p>
            <button className="secondaryBtn" onClick={this.props.handleDisplay}>
              Zgadzam się
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default PopUpCookies;
