import React from "react";

const OfferLiveOnYacht = ({image, altImage, desc}) => {
  return (
    <div className="liveOnYachtItem">
      <img src={image} alt={altImage} />
      <p>{desc}</p>
    </div>
  );
};

export default OfferLiveOnYacht;
