import React from "react";
import "./Reset.scss";
import {Link} from "react-router-dom";
import "./NewPassword.scss";
import APIbackend from "../config.js";

class NewPassword extends React.Component {
  state = {
    email: "",
    incorrectResetMessage: "",
    value: "",
    loginActive: false,
    registrationActive: false,
    redirectRegistration: false,
    redirectLogin: false,
    newPassword: "",
    trueMessage: "",
    secondTrueMessage: "",
    errorMessage: "",
    errorMessage2: "",
    newPasswordRepeat: "",
    typeNewPassword: "password",
    typeNewPasswordRepeat: "password",
    errorMessageNew: "",
    errorMessageStyleNew: "",
    buttonVisible: true,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSaveNewPassword = () => {
    const {newPassword, newPasswordRepeat} = this.state;
    if (newPassword.length < 6) {
      this.setState({
        errorMessage2: "Hasło musi mieć minimum 6 znaków",
        errorMessageStyleNew: "",
      });
    } else if (newPassword !== newPasswordRepeat) {
      this.setState({
        errorMessage2: "Hasła muszą być zgodne",
        errorMessageStyleNew: "errorMessageInput",
      });
    } else {
      this.setState({
        errorMessage2: "",
        errorMessageStyleNew: "",
      });
    }

    if (newPassword === newPasswordRepeat && newPassword.length >= 6) {
      fetch(`${APIbackend}v1/auth/passwordResetSave`, {
        method: "POST",
        body: JSON.stringify({
          token: this.props.match.params.token,
          newPassword: this.state.newPassword,
        }),
        credentials: "include",
      }).then((response) =>
        response.json().then((responseData) => {
          if (response.status === 200) {
            this.setState({
              token: "",
              newPassword: "",
              email: "",
              errorMessage: "",
              errorMessage2: "",
              secondTrueMessage: "Twoje hasło zostało zmienione. Aby się zalogować, kliknij przycisk poniżej",
              trueMessage: "",
              buttonVisible: false,
            });
          } else if (response.status !== 200) {
            this.setState({
              errorMessage2: "Wprowadzony kod lub hasło są nieprawidłowe",
              secondTrueMessage: "",
            });
          }
        })
      );
    }
  };

  handleClickNewPass = () =>
    this.setState(({typeNewPassword}) => ({
      typeNewPassword: typeNewPassword === "password" ? "text" : "password",
    }));
  2;
  handleClickNewPassRepeat = () =>
    this.setState(({typeNewPasswordRepeat}) => ({
      typeNewPasswordRepeat: typeNewPasswordRepeat === "password" ? "text" : "password",
    }));

  render() {
    const {
      typeNewPassword,
      errorMessageStyleNew,
      newPasswordRepeat,
      typeNewPasswordRepeat,
      buttonVisible,
      errorMessage2,
      secondTrueMessage,
    } = this.state;
    const {handleChange, handleClickNewPass, handleClickNewPassRepeat, handleSaveNewPassword} = this;
    return (
      <>
        <div id="reset" className="newPass">
          <div className="container">
            <div className="firstPart">
              <div className="box">
                <h3>Resetuj hasło</h3>
                <p>Wprowadź nowe hasło</p>
                <div className="passwordBox">
                  <input
                    name="newPassword"
                    onChange={handleChange}
                    placeholder="Wprowadź nowe hasło"
                    type={typeNewPassword}
                    className={errorMessageStyleNew}
                  ></input>
                  <span onClick={handleClickNewPass}>
                    {typeNewPassword === "password" ? <i class="fas fa-eye-slash"></i> : <i class="fas fa-eye"></i>}
                  </span>
                </div>
                <p>Powtórz nowe hasło</p>
                <div className="passwordBox">
                  <input
                    name="newPasswordRepeat"
                    onChange={handleChange}
                    type={typeNewPasswordRepeat}
                    value={newPasswordRepeat}
                    placeholder="Powtórz nowe hasło"
                    className={errorMessageStyleNew}
                  ></input>
                  <span onClick={handleClickNewPassRepeat}>
                    {typeNewPasswordRepeat === "password" ? (
                      <i class="fas fa-eye-slash"></i>
                    ) : (
                      <i class="fas fa-eye"></i>
                    )}
                  </span>
                </div>
                <p style={{color: "#de1212", fontSize: "14px"}}>{errorMessage2}</p>
                {buttonVisible && (
                  <button className="primaryBtn send" onClick={handleSaveNewPassword}>
                    Potwierdź
                  </button>
                )}
              </div>
            </div>
            <div className="trueMessage">{secondTrueMessage}</div>
            <div className="btnBox2">
              <Link to="/zaloguj" title="Wróć do logowania">
                <button className="secondaryBtn">Wróć do logowania</button>
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default NewPassword;
