import URLS from './urls';
import OfferSite from "./offersite/OfferSite.js";
import SailingTraining from "./SailingTraining.js";
import CruisesInPoland from "./CruisesInPoland.js";
import HowItWorks from "./HowItWorks";
import Country from "./Country.js";
import MainPage from './mainPage/MainPage.js'
import ContactPage from "./ContactPage.js";
import Discounts from "./Discounts.js";
import FaqWrapper from "./FaqWrapper.js";
import AboutUs from "./AboutUs.js";
import Formalities from "./Formalities";
import COVID from "./COVID";
import OurTrips from "./OurTrips.js";
import NewFamilyTrip from "./NewFamilyTrip.js";
import ProgramTypes from "./ProgramTypes.js";
import SailingTrainingLp from "./SailingTrainingLp.js"
import Team from "./Team";
import WorkForSkipper from "./WorkForSkipper";
import CompanyTrips from "./CompanyTrips.js"
import PanelMenu from "./userRegistration/PanelMenu.js"
import BlogPage from "./BlogPage.js";
import Blog from "./Blog.js";

const ROUTES = [
  { path: [URLS.Offer, '/oferta/:offerId'], component: OfferSite },
  { path: URLS.Trainings, component: SailingTraining },
  { path: URLS.Mazury, component: CruisesInPoland },
  { path: URLS.HowItWorks, component: HowItWorks, exact: true },
  { path: URLS.Contact, component: ContactPage },
  { path: URLS.Promotion, component: Discounts },
  { path: URLS.AboutCompany, component: AboutUs, exact: true },
  { path: URLS.FAQ, component: FaqWrapper },
  { path: URLS.Documents, component: Formalities },
  { path: URLS.Covid, component: COVID },
  { path: URLS.Cruises, component: OurTrips, exact: true },
  { path: URLS.CruisesSecondLink, component: OurTrips },
  { path: [URLS.Destination, '/rejsy-morskie/:countryName'], component: Country },
  { path: URLS.PrivateCruises, component: NewFamilyTrip },
  { path: URLS.Program, component: ProgramTypes, exact: true },
  { path: URLS.SzkoleniaInformacje, component: SailingTrainingLp },
  { path: URLS.Team, component: Team },
  { path: URLS.UserPanel, component: PanelMenu },
  { path: URLS.Work, component: WorkForSkipper },
  { path: URLS.CompanyTrips, component: CompanyTrips },
  { path: URLS.HomePage, component: MainPage, exact: true },
  { path: URLS.Blog, component: BlogPage, exact: true },
  { path: URLS.BlogContent, component: Blog }
];

export default ROUTES;