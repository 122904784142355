export const IAmForAnalitics = () => {

    if(global.window){
        if(global.window.dataLayer){
            global.window?.dataLayer.push({
                event: 'pageview'
            })
        }else{
            global.window.dataLayer = []
        }
    }

    return null;
};