import React, { useState } from 'react';
import OfferResidencesPopup from './OfferResidencesPopup';
import ster from '../img/ster.png';
import { useMediaQuery } from 'react-responsive';

const OfferResidencesItem = ({
  name,
  totalPrice,
  Availability,
  price,
  offerType,
  images0,
  images1a,
  images1b,
  description,
  images0b,
  images3b,
  images2b,
  images2a,
  images0a,
  images4a,
  images3a,
  images4b,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  let numberSeats, bathrooms, cabins, lenghtYacht, skipper, productionYear;

  switch (name) {
    case 'Jacht PREMIER':
      numberSeats = '10';
      bathrooms = '2-3';
      cabins = '4 + salon';
      lenghtYacht = '44-46';
      skipper = 'TAK';
      productionYear = '2016-2022';

      break;
    case 'Katamaran PREMIER':
      numberSeats = '12';
      bathrooms = '2-4';
      cabins = '4 + 2 + salon';
      lenghtYacht = '39-40';
      skipper = 'TAK';
      productionYear = '2016-2022';

      break;
    case 'Katamaran COMFORT':
      numberSeats = '10';
      bathrooms = '2';
      cabins = '4 + 1 + salon';
      lenghtYacht = '41';
      skipper = 'TAK';
      productionYear = '2010';

      break;
    case 'Jacht COMFORT':
      numberSeats = '8-12';
      bathrooms = '2-3';
      cabins = '3-5 + salon';
      lenghtYacht = '40-50';
      skipper = 'TAK';
      productionYear = '2009-2015';

      break;
    case 'Jacht SUPERIOR':
      numberSeats = '12';
      bathrooms = '3-4';
      cabins = '5 + salon';
      lenghtYacht = '48-52';
      skipper = 'TAK';
      productionYear = '2016-2022';

      break;
    case 'Katamaran SUPERIOR':
      numberSeats = '12';
      bathrooms = '4';
      cabins = '4 + 2 + salon';
      lenghtYacht = '42-46';
      skipper = 'TAK';
      productionYear = '2016-2022';

      break;
    case 'Katamaran LUXURY':
      numberSeats = '12';
      bathrooms = '4-6';
      cabins = '5-6';
      lenghtYacht = '48-52';
      skipper = 'TAK';
      productionYear = '2016-2022';

      break;
    case 'Jacht LUXURY':
      numberSeats = '12';
      bathrooms = '4-5';
      cabins = '5';
      lenghtYacht = '52-58';
      skipper = 'TAK';
      productionYear = '2016-2022';

      break;
    case name.includes('Sun'):
      numberSeats = '12';
      bathrooms = '4';
      cabins = '6';
      lenghtYacht = '47';
      skipper = 'TAK';
      productionYear = '2020';

      break;
    case 'Jacht SPORT':
      numberSeats = '5+1';
      bathrooms = '1';
      cabins = '-';
      lenghtYacht = '26';
      skipper = 'Dla indywidualnych zapisów - TAK';
      productionYear = '2010-2012';

      break;
    case 'Załoga MĘSKA':
      numberSeats = '5+1';
      bathrooms = '-';
      cabins = '-';
      lenghtYacht = '26';
      skipper = 'Dla indywidualnych zapisów - TAK';
      productionYear = '2010-2012';

      break;
    case 'Załoga DAMSKA':
      numberSeats = '5+1';
      bathrooms = '-';
      cabins = '-';
      lenghtYacht = '26';
      skipper = 'Dla indywidualnych zapisów - TAK';
      productionYear = '2010-2012';

      break;
    case 'Załoga MIESZANA':
      numberSeats = '5+1';
      bathrooms = '-';
      cabins = '-';
      lenghtYacht = '26';
      skipper = 'Dla indywidualnych zapisów - TAK';
      productionYear = '2010-2012';

      break;
    case 'Jacht Comfort':
      numberSeats = '5+1';
      bathrooms = '1';
      cabins = '1';
      lenghtYacht = '26';
      skipper = 'Dla indywidualnych zapisów - TAK';
      productionYear = '2016-2017';

      break;
    case 'Zapis Indywidualny KOBIETY':
      numberSeats = '5+1';
      bathrooms = '-';
      cabins = '-';
      lenghtYacht = '26';
      skipper = 'TAK';
      productionYear = '2010-2012';

      break;
    case 'Zapis Indywidualny MĘŻCZYŹNI':
      numberSeats = '5+1';
      bathrooms = '-';
      cabins = '-';
      lenghtYacht = '26';
      skipper = 'TAK';
      productionYear = '2010-2012';

      break;
    case 'Antila 24':
      numberSeats = '8';
      bathrooms = '-';
      cabins = '-';
      lenghtYacht = '24';
      skipper = 'Dla indywidualnych zapisów - TAK';
      productionYear = '2011-2015';

      break;
    case 'Antila 24.4':
      numberSeats = '8';
      bathrooms = '-';
      cabins = '-';
      lenghtYacht = '24.4';
      skipper = 'Dla indywidualnych zapisów - TAK';
      productionYear = '2017-2022';

      break;
    case 'Solina 27':
      numberSeats = '8';
      bathrooms = '-';
      cabins = '2';
      lenghtYacht = '27';
      skipper = 'Dla indywidualnych zapisów - TAK';
      productionYear = '2013-2015';

      break;
    case 'Antila 27':
      numberSeats = '8';
      bathrooms = '1';
      cabins = '3';
      lenghtYacht = '27';
      skipper = 'Dla indywidualnych zapisów - TAK';
      productionYear = '2013-2015';

      break;
    case 'Antila 28.2':
        numberSeats = '8';
        bathrooms = '1';
        cabins = '3';
        lenghtYacht = '28';
        skipper = 'Dla indywidualnych zapisów - TAK';
        productionYear = '2013-2015';
  
        break;
    case 'Jacht COMFORT 8os':
      numberSeats = '8';
      bathrooms = '1-2';
      cabins = '3';
      lenghtYacht = '38-43';
      skipper = 'TAK';
      productionYear = '2016-2022';

      break;
    case 'Załoga MĘSKA - Fortuna':
      numberSeats = '5+1';
      bathrooms = '-';
      cabins = '-';
      lenghtYacht = '26';
      skipper = 'Dla indywidualnych zapisów - TAK';
      productionYear = '2001';

      break;
    case 'Załoga DAMSKA/MIESZANA - Fortuna':
      numberSeats = '5+1';
      bathrooms = '-';
      cabins = '-';
      lenghtYacht = '26';
      skipper = 'Dla indywidualnych zapisów - TAK';
      productionYear = '2001';

      break;
    case 'Zapis Indywidualny KOBIETY - Fortuna':
      numberSeats = '5+1';
      bathrooms = '-';
      cabins = '-';
      lenghtYacht = '26';
      skipper = 'TAK';
      productionYear = '2001';

      break;
    case 'Zapis Indywidualny MĘŻCZYŹNI - Fortuna':
      numberSeats = '5+1';
      bathrooms = '-';
      cabins = '-';
      lenghtYacht = '26';
      skipper = 'TAK';
      productionYear = '2001';

      break;
    default:
      numberSeats = '10';
      bathrooms = '2-3';
      cabins = '4 + salon';
      lenghtYacht = '44-46';
      skipper = 'TAK';
      productionYear = '2016-2022';
  }

  const isMobileScreen = useMediaQuery({ query: '(max-width: 768px)' });

  const basePrice = parseInt(totalPrice?.Base);
  const strikePrice = parseInt(totalPrice?.Strikethrough);
  const addPrice = parseInt(price.Place);
  const priceWhole = parseInt(price.Whole);

  return (
    <>
      {!name.includes('skipper') && (
        <>
          {Availability?.ReservePlace &&
          Availability?.RoomsAvailableForReservationCount *
            Availability?.AccommodationsCount >
            Availability?.ReservedPlacesCount &&
          Availability?.RoomsAvailableForReservationCount *
            Availability?.AccommodationsCount ? (
            <div className="offerResidenceRoom">
              <div className="RoomLeft" onClick={togglePopup}>
                <div className="image">
                  <img src={images0?.url} alt={images0?.alt} />
                  {Availability?.ReservePlace &&
                  Availability?.RoomsAvailableForReservationCount *
                    Availability?.AccommodationsCount -
                    (Availability?.AccommodationsCount - 1) <=
                    Availability?.ReservedPlacesCount &&
                  Availability?.RoomsAvailableForReservationCount *
                    Availability?.AccommodationsCount -
                    (Availability?.AccommodationsCount - 1) ? (
                    <div className="infoAboutPlace">
                      <p>Ostatnie wolne miejsca</p>
                    </div>
                  ) : (
                    Availability?.ReservePlace &&
                    Availability?.RoomsAvailableForReservationCount *
                      Availability?.AccommodationsCount -
                      (2 * Availability?.AccommodationsCount - 1) <=
                      Availability?.ReservedPlacesCount &&
                    Availability?.RoomsAvailableForReservationCount *
                      Availability?.AccommodationsCount -
                      (Availability?.AccommodationsCount - 1) * 2 && (
                      <div className="infoAboutPlace">
                        <p>Ostatni jacht</p>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="RoomRight">
                <div className="RoomRightTitle">
                  <p>{name}</p>
                  {offerType.Name === 'Rejs Prywatny' ? (
                    <div className="priceBox">
                      <strong>{priceWhole}</strong> PLN/jacht
                    </div>
                  ) : priceWhole !== 0 && strikePrice ? (
                    <div
                      className="priceBox"
                      style={{ background: '#008000' }}
                    >
                      <span>{totalPrice?.StrikethroughName}</span>{' '}
                      <span>{addPrice + basePrice}</span> PLN/osoba lub{' '}
                      <span>{priceWhole}</span> PLN/jacht
                    </div>
                  ) : priceWhole === 0 && strikePrice ? (
                    <div
                      className="priceBox"
                      style={{ background: '#008000' }}
                    >
                      <span>{totalPrice?.StrikethroughName}</span>{' '}
                      <span>{addPrice + basePrice}</span> PLN/osoba
                    </div>
                  ) : (
                    <div className="priceBox">
                      <span>{addPrice + basePrice}</span> PLN/osoba lub{' '}
                      <span>{priceWhole}</span> PLN/jacht
                    </div>
                  )}
                </div>
                <div className="RoomRightContent">
                  <div className="RoomRightItem">
                    <div className="RoomRightItem-Icon RoomRightItem-Icon1">
                      <div></div>
                      <p>Ilość miejsc</p>
                    </div>
                    <p className="RoomRightItem-Value">{numberSeats}</p>
                  </div>
                  <div className="RoomRightItem">
                    <div className="RoomRightItem-Icon RoomRightItem-Icon2">
                      <div></div>
                      <p>Łazienki</p>
                    </div>
                    <p className="RoomRightItem-Value">{bathrooms}</p>
                  </div>
                  <div className="RoomRightItem">
                    <div className="RoomRightItem-Icon RoomRightItem-Icon3">
                      <div></div>
                      <p>Skipper</p>
                    </div>
                    <p className="RoomRightItem-Value">{skipper}</p>
                  </div>
                {/*   <div className="RoomRightItem">
                    <div className="RoomRightItem-Icon RoomRightItem-Icon4">
                      <div></div>
                      <p>Rok produkcji</p>
                    </div>
                    <p className="RoomRightItem-Value">{productionYear}</p>
                  </div> */}
                  <div className="RoomRightItem">
                    <div className="RoomRightItem-Icon RoomRightItem-Icon5">
                      <div></div>
                      <p>Kajuty</p>
                    </div>
                    <p className="RoomRightItem-Value">{cabins}</p>
                  </div>
                  <div className="RoomRightItem">
                    <div className="RoomRightItem-Icon RoomRightItem-Icon6">
                      <div></div>
                      <p>Długość jednostki</p>
                    </div>
                    <p className="RoomRightItem-Value">{lenghtYacht}ft</p>
                  </div>
                </div>
              </div>
              {isOpen && (
                <OfferResidencesPopup
                  content={description}
                  handleClose={togglePopup}
                  name={name}
                  images0a={images0a}
                  images0b={images0b}
                  images1a={images1a}
                  images1b={images1b}
                  images2a={images2a}
                  images2b={images2b}
                  images3a={images3a}
                  images3b={images3b}
                  images4a={images4a}
                  images4b={images4b}
                />
              )}
            </div>
          ) : Availability?.ReserveWhole &&
            Availability.RoomsAvailableForReservationCount *
              Availability.AccommodationsCount -
              Availability.ReservedPlacesCount >=
              Availability.AccommodationsCount ? (
            <div className="offerResidenceRoom">
              <div className="RoomLeft" onClick={togglePopup}>
                <div className="image">
                  <img src={images0?.url} alt={images0?.alt} />
                </div>
              </div>
              <div className="RoomRight">
                <div className="RoomRightTitle">
                  <p>{name}</p>
                  {offerType.Name === 'Rejs Prywatny' ? (
                    <div className="priceBox">
                      <strong>{priceWhole}</strong> PLN/jacht
                    </div>
                  ) : priceWhole !== 0 && strikePrice ? (
                    <div
                      className="priceBox"
                      style={{ background: 'rgb(195, 49, 56)' }}
                    >
                      <span>{totalPrice?.StrikethroughName}</span>{' '}
                      <span>{addPrice + basePrice}</span> PLN/osoba lub{' '}
                      <span>{priceWhole}</span> PLN/jacht
                    </div>
                  ) : priceWhole === 0 && strikePrice ? (
                    <div
                      className="priceBox"
                      style={{ background: 'rgb(195, 49, 56)' }}
                    >
                      <span>{totalPrice?.StrikethroughName}</span>{' '}
                      <span>{addPrice + basePrice}</span> PLN/osoba
                    </div>
                  ) : (
                    <div className="priceBox">
                      <span>{addPrice + basePrice}</span> PLN/osoba lub{' '}
                      <span>{priceWhole}</span> PLN/jacht
                    </div>
                  )}
                </div>
                <div className="RoomRightContent">
                  <div className="RoomRightItem">
                    <div className="RoomRightItem-Icon RoomRightItem-Icon1">
                      <div></div>
                      <p>Ilość miejsc</p>
                    </div>
                    <p className="RoomRightItem-Value">{numberSeats}</p>
                  </div>
                  <div className="RoomRightItem">
                    <div className="RoomRightItem-Icon RoomRightItem-Icon2">
                      <div></div>
                      <p>Łazienki</p>
                    </div>
                    <p className="RoomRightItem-Value">{bathrooms}</p>
                  </div>
                  <div className="RoomRightItem">
                    <div className="RoomRightItem-Icon RoomRightItem-Icon3">
                      <div></div>
                      <p>Skipper</p>
                    </div>
                    <p className="RoomRightItem-Value">{skipper}</p>
                  </div>
                  <div className="RoomRightItem">
                    <div className="RoomRightItem-Icon RoomRightItem-Icon4">
                      <div></div>
                      <p>Rok produkcji</p>
                    </div>
                    <p className="RoomRightItem-Value">{productionYear}</p>
                  </div>
                  <div className="RoomRightItem">
                    <div className="RoomRightItem-Icon RoomRightItem-Icon5">
                      <div></div>
                      <p>Kajuty</p>
                    </div>
                    <p className="RoomRightItem-Value">{cabins}</p>
                  </div>
                  <div className="RoomRightItem">
                    <div className="RoomRightItem-Icon RoomRightItem-Icon6">
                      <div></div>
                      <p>Długość jednostki</p>
                    </div>
                    <p className="RoomRightItem-Value">{lenghtYacht}ft</p>
                  </div>
                </div>
              </div>
              {isOpen && (
                <OfferResidencesPopup
                  content={description}
                  handleClose={togglePopup}
                  name={name}
                  images0a={images0a}
                  images0b={images0b}
                  images1a={images1a}
                  images1b={images1b}
                  images2a={images2a}
                  images2b={images2b}
                  images3a={images3a}
                  images3b={images3b}
                  images4a={images4a}
                  images4b={images4b}
                />
              )}
            </div>
          ) : (
            <div className="offerResidenceRoom">
              <div
                className="overlayResidence"
                style={isMobileScreen ? { display: 'none' } : null}
              >
                <img
                  src={ster}
                  alt="Lista rezerwowa"
                  onClick={togglePopup}
                  style={{ cursor: 'pointer' }}
                ></img>
              </div>
              <div
                className="RoomLeft"
                onClick={togglePopup}
                style={isMobileScreen ? { height: '50px' } : null}
              >
                <div
                  className="image"
                  style={isMobileScreen ? { display: 'none' } : null}
                >
                  <img src={images0?.url} alt={images0?.alt} />
                </div>
              </div>
              <div
                className="RoomRight"
                style={isMobileScreen ? { display: 'none' } : null}
              >
                <div className="RoomRightTitle">
                  <p>{name}</p>
                  {offerType.Name === 'Rejs Prywatny' ? (
                    <div className="priceBox">
                      <strong>{priceWhole}</strong> PLN/jacht
                    </div>
                  ) : priceWhole !== 0 && strikePrice ? (
                    <div
                      className="priceBox"
                      style={{ background: 'rgb(195, 49, 56)' }}
                    >
                      <span>{totalPrice?.StrikethroughName}</span>{' '}
                      <span>{addPrice + basePrice}</span> PLN/osoba lub{' '}
                      <span>{priceWhole}</span> PLN/jacht
                    </div>
                  ) : priceWhole === 0 && strikePrice ? (
                    <div
                      className="priceBox"
                      style={{ background: 'rgb(195, 49, 56)' }}
                    >
                      <span>{totalPrice?.StrikethroughName}</span>{' '}
                      <span>{addPrice + basePrice}</span> PLN/osoba
                    </div>
                  ) : (
                    <div className="priceBox">
                      <span>{addPrice + basePrice}</span> PLN/osoba lub{' '}
                      <span>{priceWhole}</span> PLN/jacht
                    </div>
                  )}
                </div>
                <div
                  className="RoomRightContent"
                  style={isMobileScreen ? { display: 'none' } : null}
                >
                  <div
                    className="RoomRightItem"
                    style={isMobileScreen ? { display: 'none' } : null}
                  >
                    <div className="RoomRightItem-Icon RoomRightItem-Icon1">
                      <div></div>
                      <p>Ilość miejsc</p>
                    </div>
                    <p className="RoomRightItem-Value">{numberSeats}</p>
                  </div>
                  <div className="RoomRightItem">
                    <div className="RoomRightItem-Icon RoomRightItem-Icon2">
                      <div></div>
                      <p>Łazienki</p>
                    </div>
                    <p className="RoomRightItem-Value">{bathrooms}</p>
                  </div>
                  <div className="RoomRightItem">
                    <div className="RoomRightItem-Icon RoomRightItem-Icon3">
                      <div></div>
                      <p>Skipper</p>
                    </div>
                    <p className="RoomRightItem-Value">{skipper}</p>
                  </div>
                  <div className="RoomRightItem">
                    <div className="RoomRightItem-Icon RoomRightItem-Icon4">
                      <div></div>
                      <p>Rok produkcji</p>
                    </div>
                    <p className="RoomRightItem-Value">{productionYear}</p>
                  </div>
                  <div className="RoomRightItem">
                    <div className="RoomRightItem-Icon RoomRightItem-Icon5">
                      <div></div>
                      <p>Kajuty</p>
                    </div>
                    <p className="RoomRightItem-Value">{cabins}</p>
                  </div>
                  <div className="RoomRightItem">
                    <div className="RoomRightItem-Icon RoomRightItem-Icon6">
                      <div></div>
                      <p>Długość jednostki</p>
                    </div>
                    <p className="RoomRightItem-Value">{lenghtYacht}ft</p>
                  </div>
                </div>
              </div>
              <div className="orangeBox">
                {isMobileScreen ? (
                  <p>
                    Brak Miejsc. Zapisz się na Listę Rezerwową! Jak tylko zwolni
                    się miejsce lub zbierzemy grupę na dodatkową łódkę, to
                    otrzymasz powiadomienie i wskoczysz na Listę Główną!
                  </p>
                ) : (
                  <p>
                    Zapisz się na Listę Rezerwową! Jak tylko zwolni się miejsce
                    lub zbierzemy grupę na dodatkową łódkę, to otrzymasz
                    powiadomienie i wskoczysz na Listę Główną!
                  </p>
                )}
              </div>
              {isOpen && (
                <OfferResidencesPopup
                  content={description}
                  handleClose={togglePopup}
                  name={name}
                  images0a={images0a}
                  images0b={images0b}
                  images1a={images1a}
                  images1b={images1b}
                  images2a={images2a}
                  images2b={images2b}
                  images3a={images3a}
                  images3b={images3b}
                  images4a={images4a}
                  images4b={images4b}
                />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default OfferResidencesItem;
