import React from "react";
import Slider from "react-slick";

const OfferAttractionsPopup = (props) => {
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="popup-box">
      <div className="box">
        <span className="close-icon" onClick={props.handleClose} style={{zIndex: "10"}}>
          x
        </span>
        <Slider {...settings}>
          {props.offerAttractions.slice(props.index)
            .concat(props.offerAttractions)
            .map(({Name, Description, Image}) => {
              const popUpImg = Image;
              return (
                <>
                  <div className="popup-img">
                    <img src={popUpImg?.url} alt={popUpImg?.alt} />
                  </div>
                  <div className="popup-content-wrapper">
                    {<p className="popup-title">{Name}</p>}
                    <p>{Description}</p>
                  </div>
                </>
              );
            })}
        </Slider>
      </div>
    </div>
  );
};

export default OfferAttractionsPopup;
