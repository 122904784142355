import React from "react";
import "./Newsletter.scss";
import regulaminTaksidi from "../userRegistration/documents/regulamin-taksidi.pdf";
import APIbackend from "../config.js";

class Newsletter extends React.Component {
  state = {
    check: false,
    email: "",
    colorText: "",
    message: "",
  };

  handleChange = (e) => {
    if (e.target.type === "checkbox") {
      this.setState({
        [e.target.name]: e.target.checked,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();

    var data = {
      siteId: 1,
      email: this.state.email,
    };

    if (this.state.check === false) {
      this.setState({
        colorText: "#c71c1c",
      });
    } else if (this.state.check) {
      this.setState({
        colorText: "",
      });

      fetch(`${APIbackend}v1/contact/newsletter`, {
        method: "POST",
        body: JSON.stringify(data),
      }).then((response) => {
        response.json().then((responseData) => {
          if (response.status !== 200) {
            this.setState({
              message: "Niepoprawny adres email",
              color: "#c71c1c",
            });

            if (responseData.message.includes("already")) {
              this.setState({
                message: "Podany email jest już w naszej bazie",
                color: "#c71c1c",
              });
            }
          }

          if (response.status === 200) {
            this.setState({
              colorText: "",
              message: "Dziękujemy za zapis",
              color: "green",
            });
          }
        });
      });
    }
  };
  render() {
      const { message, color, email, check, colorText } = this.state;
    return (
      <div id="newsLetterContainer">
        <div className="newsLetterWrapper">
          <div className="newsLetterTitle">
            <h1>Zapisz się do Newslettera</h1>
            <p>
              Też nienawidzimy spamu, dlatego go nie wysyłamy! Otrzymasz od nas tylko najważniejsze informacje i
              specjalne zniżki!
            </p>
          </div>
          <div className="form">
            <div className="newsLetterBox">
              <form>
                <input
                  name="email"
                  type="email"
                  value={email}
                  placeholder="Twój adres email"
                  onChange={this.handleChange}
                ></input>
                <div className="btnBox">
                  <button className="primaryBtn" onClick={this.handleSubmit}>
                    Zapisz się
                  </button>
                </div>
              </form>
            </div>
            <div className="agree">
              <label className="containerInputCheckbox">
                <input type="checkbox" name="check" checked={check} onChange={this.handleChange} />
                <span className="checkmark" style={{top: "10px"}}></span>
              </label>
              <p className="textSmall" style={{margin: "0", color: colorText}}>
                Wyrażam zgodę na
                <a
                  href={regulaminTaksidi}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Przeczytaj regulamin The Boat Trip"
                  style={{fontFamily: "SpaceGrotesk-SemiBold"}}
                >
                  przetwarzanie moich danych osobowych
                </a>
                zawartych w zgłoszeniu.
              </p>
            </div>
            {message && (
              <p className="responseModal" style={{color: color, margin: "0"}}>
                {message}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Newsletter;
